import {
  type ChangeEvent, type KeyboardEvent, type ClipboardEvent, type RefObject,
  useContext, useState, useRef, useEffect, useCallback, createRef
} from 'react'
import { useLoader } from '@carfluent/common'

import { Routes } from 'routing/constants'
import apiProvider from 'website/api/apiProvider'
import { isNumbersOnly } from 'utils/validation'
import AuthCTX from 'store/auth'

import type { UseSendCodeModalProps, UseSendCodeModalReturn } from './types'
import { FocusPos } from './types'

const NUMBER_OF_DIGITS = 4
const TIMER_DURATION = 60
const DELAY_TICK_TIME = 1000
const CHECK_CODE_DELAY = 200
const INITIAL_STATE = Array(NUMBER_OF_DIGITS).fill('')

const useSendCodeModal = ({
  isLogin,
  email,
  phoneNumber,
  onCreatePersonalDetails,
  onCloseModal: _onCloseModal
}: UseSendCodeModalProps): UseSendCodeModalReturn => {
  const [code, setCode] = useState<string[]>(INITIAL_STATE)
  const [isInvalidCode, setInvalidCode] = useState<boolean>(false)
  const [isResendActive, setResendActive] = useState<boolean>(true)
  const [remainingTime, setRemainingTime] = useState<number>(0)
  const [focusedInput, setFocusedInput] = useState<number>(0)

  const { stopLoader, startLoader } = useLoader()
  const { setAuth } = useContext(AuthCTX)
  const inputRefs = useRef<Array<RefObject<HTMLInputElement>>>([
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>()
  ])

  const setFocus = useCallback((position: FocusPos | number) => {
    let index: number

    if (typeof position === 'number') {
      index = Math.max(0, Math.min(NUMBER_OF_DIGITS - 1, position))
    } else {
      switch (position) {
        case FocusPos.First:
          index = 0
          break
        case FocusPos.Last:
          index = NUMBER_OF_DIGITS - 1
          break
        case FocusPos.Next:
          index = focusedInput + 1
          break
        case FocusPos.Prev:
          index = focusedInput - 1
          break
        default:
          return
      }
    }

    const input = inputRefs.current[index]?.current

    if (input != null) {
      input.focus()
      setFocusedInput(index)
    }
  }, [focusedInput])

  const checkVerificationCode = useCallback((): void => {
    const verificationCode = code.join('')

    const login = async (): Promise<void> => {
      try {
        // blur all
        inputRefs.current.forEach((ref) => {
          const input = ref.current
          if (input != null) {
            input.blur()
          }
        })

        startLoader()
        const res = await apiProvider.identity.loginUser({ email, phoneNumber, verificationCode })
        await setAuth(res)

        if (isLogin) {
          window.location.replace(Routes.MultipleDeals)
          return
        } else {
          await onCreatePersonalDetails?.()
        }

        setInvalidCode(false)
      } catch (error) {
        console.error('checkVerificationCode', error)
        setInvalidCode(true)
        setCode(INITIAL_STATE)

        setFocus(FocusPos.First)
      } finally {
        stopLoader()
      }
    }

    void login()
  }, [
    code, email, isLogin, setAuth,
    onCreatePersonalDetails, phoneNumber,
    setFocus, startLoader, stopLoader
  ])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onCodeChange = useCallback((event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target

    if (!isNumbersOnly(value)) {
      return
    }

    const newCode: string[] = [...code]
    newCode[index] = value
    setCode(newCode)

    setFocus(FocusPos.Next)
  }, [code, setFocus])

  const onInputKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>, index: number) => {
    const newCode = [...code]

    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault()
        setFocus(FocusPos.Prev)
        break

      case 'ArrowRight':
        event.preventDefault()
        setFocus(FocusPos.Next)
        break

      case 'Backspace':
        newCode[index] = ''
        setCode(newCode)
        setFocus(FocusPos.Prev)
        break

      default:
        if (isNumbersOnly(event.key) && code[index] !== '') {
          event.preventDefault()
          newCode[index] = event.key
          setCode(newCode)
          setFocus(FocusPos.Next)
        }
        break
    }
  }, [code, setFocus])

  const onCodePaste = useCallback((event: ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData('text')

    if (!isNumbersOnly(pastedData)) {
      setFocus(FocusPos.First)
      return
    }

    /**
     * AS-NOTE: We are slicing the pasted data to the number of digits we need
     */
    const newCode = pastedData.split('').slice(0, NUMBER_OF_DIGITS)
    const focusPos = newCode.length - 1

    const filledCode = newCode.concat(Array(NUMBER_OF_DIGITS - newCode.length).fill(''))

    setCode(filledCode)

    setFocus(focusPos)
  }, [setFocus])

  const onResendCode = useCallback(() => {
    void apiProvider.identity.sendVerificationCode({ phoneNumber })
    setResendActive(false)
    setRemainingTime(TIMER_DURATION)
  }, [phoneNumber])

  const onCloseModal = useCallback(() => {
    _onCloseModal?.()
    setCode(INITIAL_STATE)
    setInvalidCode(false)
  }, [_onCloseModal])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    let timeout: NodeJS.Timeout
    /**
     * AS-NOTE: fix race condition.
     * Under the hood the code is being checked after each input change.
     * When we paste code for each digit there is checking the code.
     */

    if (code.every((digit) => digit !== '')) {
      timeout = setTimeout(checkVerificationCode, CHECK_CODE_DELAY)
    }

    return () => clearTimeout(timeout)
  }, [checkVerificationCode, code, remainingTime])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (!isResendActive && remainingTime > 0) {
      interval = setInterval(() => {
        setRemainingTime((time) => time - 1)
      }, DELAY_TICK_TIME)
    } else if (remainingTime === 0) {
      setResendActive(true)
      if (interval != null) {
        clearInterval(interval)
      }
    }

    return () => {
      if (interval != null) {
        clearInterval(interval)
      }
    }
  }, [isResendActive, remainingTime])

  // ========================================== //

  return {
    isLogin,
    isResendActive,
    isInvalidCode,
    code,
    remainingTime,
    phoneNumber,
    inputRefs,
    onCodeChange,
    onInputKeyDown,
    onCodePaste,
    onResendCode,
    onCloseModal
  }
}

export default useSendCodeModal
