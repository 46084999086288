import { css } from '@emotion/css'

export enum ScreenWidth {
  Mobile = 728,
  Tablet = 1024
}

export default css(`
  button.cf-button-root {
    .cf-button-content {
      font-weight: 700;
      font-size: 18px;
    }

    .cf-button-end-adornment {
      height: auto;
      display: flex;
    }
  }
  
  .g-opened-button {
    transform: rotate(180deg);
  }
  
  .subtitle {
    font-size: 16px;
    color: #101010;
    font-weight: 500;
    line-height: 27px;
  }
  
  .g-options-content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    
    .option-column {
      width: 100%;
      margin-bottom: 8px;
      
      @media screen and (min-width: ${ScreenWidth.Mobile}px) {
        width: 50%;
      }
      
      @media screen and (min-width: ${ScreenWidth.Tablet}px) {
       width: 33%;
      }
    }
    
    .g-option-item {
      display: flex;
      margin-top: 12px;
      align-items: flex-start;
      line-height: 24px;
      
      img {
        margin-right: 8px;
        margin-top: 4px;
      }
      
      p {
        max-width: calc(100% - 24px);
        white-space: normal;
        align-items: flex-start;
      }
    }
  }
`)
