export const FILTER_LIST_SEPARATOR = ';'

export const _updateListEntryInQs = (
  currentUrl: URL,
  key: string,
  value: string | null,
  isDeleting = false
): string => {
  const currVal = currentUrl.searchParams.get(key)

  const valArr = currVal != null ? currVal.split(FILTER_LIST_SEPARATOR).filter(v => v !== '') : []
  let nextValArr: string[] = []

  /**
   * if value is null we delete full keyValue anyway
   */
  if (value != null && value !== '') {
    nextValArr = isDeleting
      ? valArr.filter((v: string) => v !== value)
      : valArr.includes(value)
        ? valArr // Don't add if already exists
        : [...valArr, value]
  }

  return _updateEntryInQs(
    currentUrl, key,
    nextValArr.length > 0 ? nextValArr.join(FILTER_LIST_SEPARATOR) : null
  )
}

/**
 * @param currentUrl current url
 * @param key qs entry key
 * @param value qs entry value.
 * if value == null it means it should delete key form qs
 */
export const _updateEntryInQs = (
  currentUrl: URL,
  key: string,
  value?: string | null
): string => {
  if (value == null || value === '') { /**
     * if value is null we delete full keyValue anyway
     */
    currentUrl.searchParams.delete(key)
  } else if (!currentUrl.searchParams.has(key)) {
    /**
     * if there is no key in the query string we add new key with value
     */
    currentUrl.searchParams.set(key, value)
  } else {
    /**
     * this branch is for updating values.
     */
    currentUrl.searchParams.set(key, value)
  }

  return decodeURIComponent(currentUrl.toString())
}
