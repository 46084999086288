import { useCallback, useEffect, useState, type FC } from 'react'
import debounce from 'lodash-es/debounce'
import { cnx, IconButton } from '@carfluent/common'
import RightArrowIcon from 'website/components/icons/RightArrow'
import useMediaQueryByBreakpoints from 'website/hooks/useMediaQueryByBreakpoints'
import CLASS_NAME, { ARROW_CLS } from './styles'

interface ScrollHorizontalButtonsProps {
  isVisible: boolean
  scrollStep: number
  refList: React.RefObject<HTMLDivElement>
}

const RESIZE_DEBOUNCE_DELAY = 250
const ARROW_VISIBLE_FROM = 450

const ScrollHorizontalButtons: FC<ScrollHorizontalButtonsProps> = ({
  isVisible,
  refList,
  scrollStep
}) => {
  const [isLeftBtnVisible, setIsLeftBtnVisible] = useState(false)
  const [isRightBtnVisible, setIsRightBtnVisible] = useState(true)

  const { isMatchingBreakpoints } = useMediaQueryByBreakpoints([ARROW_VISIBLE_FROM])

  const onScrollAndResize = useCallback((): void => {
    const el = refList.current

    if (el == null) {
      return
    }

    const isScrollPresent = (el.scrollWidth ?? 0) > (el.clientWidth ?? 0)

    if (!isScrollPresent) {
      setIsLeftBtnVisible(false)
      setIsRightBtnVisible(false)
      return
    }

    const isOnLeftEdge = el.scrollLeft === 0

    if (isOnLeftEdge) {
      setIsLeftBtnVisible(false)
      setIsRightBtnVisible(true)
      return
    }

    const isOnRightEdge = el.scrollWidth === el.clientWidth + el.scrollLeft

    if (isOnRightEdge) {
      setIsRightBtnVisible(false)
      setIsLeftBtnVisible(true)
    }
  }, [refList])

  useEffect(() => {
    const checkForScroll = debounce(onScrollAndResize, RESIZE_DEBOUNCE_DELAY)
    window.addEventListener('resize', checkForScroll)
    checkForScroll()

    return () => window.removeEventListener('resize', checkForScroll)
  }, [refList])

  useEffect(() => {
    refList.current?.addEventListener('scroll', onScrollAndResize)

    return () => { refList.current?.removeEventListener('scroll', onScrollAndResize) }
  }, [refList, onScrollAndResize])

  if (!isVisible || !isMatchingBreakpoints) {
    return null
  }

  return (
    <div className={cnx(CLASS_NAME, 'cf-slider-overlay')}>
      <IconButton
        className={cnx(ARROW_CLS, 'left', isLeftBtnVisible && 'visible')}
        onClick={() => {
          if (refList.current != null) {
            setIsRightBtnVisible(true)
            refList.current.scrollBy({ left: -scrollStep, behavior: 'smooth' })
          }
        }}
      >
        <RightArrowIcon />
      </IconButton>

      <IconButton
        className={cnx(ARROW_CLS, 'right', isRightBtnVisible && 'visible')}
        onClick={() => {
          if (refList.current != null) {
            setIsLeftBtnVisible(true)
            refList.current.scrollBy({ left: scrollStep, behavior: 'smooth' })
          }
        }}
      >
        {/* it is rotated */}
        <RightArrowIcon />
      </IconButton>
    </div>
  )
}

export default ScrollHorizontalButtons
