import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useModal, type UseModalReturn } from '@carfluent/common'

import apiProvider from 'website/api/apiProvider'
import FilesApiProvider from 'website/api/files.api'
import VehicleApiProvider from 'website/api/vehicles.api'
import { ServiceType } from 'website/api/types'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import { WebsiteRoutes } from 'website/routing/constants'
import { VehicleMediaTypeEnum, type FeatureOptionItem, type GalleryMediaProps } from 'website/components/types'
import sendDeepAutomotiveVisitEvent from 'website/utils/googleTagManager/deepAutomotive'
import { CarfaxIcon } from 'website/components/VehicleView/VehicleViewContent/components/BriefInfoHelpLinks/CarfaxIcon'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import defaultApiProvider from 'website/api/apiProvider/defaultApiProvider'

import { parseFeatures, parseOptions, parseVehicleMedia } from './parser'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

export interface UseVehicleViewContentReturn {
  description: string | null
  dealership: API.DealerInfo | null
  features: FeatureOptionItem[]
  galleryMedia: GalleryMediaProps[]
  isLoading: boolean
  media: API.VehicleMediaData | null
  mainImageUrl: string
  onAskClick: () => void
  onCarfaxClick: () => void
  onTestDriveClick: () => void
  onWindowStickerClick: () => Promise<void>
  carfaxIcon: ReactNode
  requestModalData: ServiceType | null
  setRequestModalData: (type: ServiceType | null) => void
  videoModalProps: UseModalReturn
  videoUrl: string | null
  videoThumbnailUrl: string | null
  vehicle: API.VehicleItem | null
  settings: API.Settings | null
  isSettingsLoading: boolean
}

interface GetCarfaxDataProps {
  carfaxData?: API.CarFaxData | null
  vin?: string | null
  carfaxPartnerCode: string
}

interface GetCarfaxDataReturn {
  url: string
  icon: ReactNode
}

const getCarfaxData = ({
  carfaxData,
  vin,
  carfaxPartnerCode
}: GetCarfaxDataProps): GetCarfaxDataReturn => {
  const url = carfaxData?.carFaxUrl ?? `http://www.carfax.com/VehicleHistory/p/Report.cfx?partner=${carfaxPartnerCode}&vin=${vin ?? ''}`
  const icon = carfaxData != null
    ? <img src={carfaxData.carFaxIconUrl ?? ''} alt={carfaxData.badgeText ?? 'Carfax Logo'} />
    : <CarfaxIcon />

  return { url, icon }
}

const SCREEN_SIZE_BREAKPOINT = 1200

const useSettingsState = SharedStateHook<Store.Settings>(StoreBranches.Settings)

export const useVehicleViewContent = (carfaxPartnerCode: string): UseVehicleViewContentReturn => {
  const [{ settings }] = useSettingsState(defaultInstance(StoreBranches.Settings))
  const [vehicle, setVehicle] = useState<API.VehicleItem | null>(null)
  const [description, setDescription] = useState<string | null >(null)
  const [dealersInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const { showAlert } = useCustomSnackbar()
  const [requestModalData, setRequestModalData] = useState<ServiceType | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSettingsLoading, setIsSettingsLoading] = useState(false)
  const [dealershipSettings, setDealershipSettings] = useState<API.Settings | null>(null)
  const videoModalProps = useModal()

  const {
    vehicleId,
    dealerId,
    videoId
  } = useParams<{ vehicleId: string, dealerId: string, videoId: string}>()

  const {
    videoUrl,
    galleryMedia,
    videoThumbnailUrl
  } = useMemo(() => {
    const res: API.MediaData[] = []
    const images = vehicle?.vehicleImages ?? []
    let videoUrl: string | null = null
    let videoThumbnailUrl: string | null = null

    if (vehicle?.mainImageUrl != null) {
      res.push({ originalUrl: vehicle.mainImageUrl, vehicleMediaTypeId: VehicleMediaTypeEnum.Image })

      const mainImage = images
        .find(img => img.originalUrl === vehicle?.mainImageUrl)

      if (mainImage != null) {
        res[0].thumbnailUrl = mainImage.thumbnailUrl
      }
    }

    for (const img of images) {
      /**
       * res[0] - is the main image
       */
      if (img.originalUrl !== res[0]?.originalUrl) {
        res.push(img)
      }

      if ((img.fileId != null) && (img.id != null) && (videoId === img.id.toString())) {
        videoUrl = FilesApiProvider.getVehicleVideoUrl(img.fileId) ?? null
        videoThumbnailUrl = FilesApiProvider.getVehicleVideoThumbnailUrl(img.fileId) ?? null
      }
    }

    return {
      galleryMedia: res.map(parseVehicleMedia),
      videoUrl,
      videoThumbnailUrl
    }
  }, [vehicle?.mainImageUrl, vehicle?.vehicleImages, videoId])

  const { url: carfaxUrl, icon: carfaxIcon } = getCarfaxData({
    carfaxData: vehicle?.carFaxData,
    vin: vehicle?.vin,
    carfaxPartnerCode
  })

  const dealership = dealersInfo.dealerships.find(v => v.id === Number(dealerId)) ?? null

  const features = useMemo(() => {
    const options = parseOptions(vehicle?.options ?? {})

    return [
      ...parseFeatures(vehicle?.features ?? {}),
      ...options
    ]
  }, [vehicle?.features, vehicle?.options])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onTestDriveClick = (): void => {
    setRequestModalData(ServiceType.TestDrive)
  }

  const onAskClick = (): void => {
    setRequestModalData(ServiceType.AskQuestion)
  }

  const onCarfaxClick = (): void => {
    window.open(carfaxUrl, '_blank')
  }

  const onWindowStickerClick = async (): Promise<void> => {
    if (vehicle?.vin == null) {
      return
    }

    try {
      const res = await apiProvider.vehicles.getWindowSticker(vehicle.vin)
      const { url, mobile } = res?.vehicleWindowStickerDto ?? {}

      window.open(
        window.innerWidth > SCREEN_SIZE_BREAKPOINT ? url : mobile,
        '_blank'
      )
    } catch {
      showAlert('VinForm sticker failed to load', 'vin-stcker-error')
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    try {
      if (vehicleId != null) {
        void VehicleApiProvider.updatePageVisits(vehicleId)
        void sendDeepAutomotiveVisitEvent()
      }
    } catch {
      // silent error handler
    }
  }, [vehicleId])

  useEffect(() => {
    const getVehicle = async (): Promise<void> => {
      if (vehicleId == null || dealerId == null) {
        return
      }

      try {
        setIsLoading(true)
        const vehicle = await VehicleApiProvider.getVehicle(vehicleId, dealerId)

        setVehicle(vehicle)
        setDescription(vehicle.description)
      } catch (e: any) {
        console.error(e)

        if (e.response?.status === 404) {
          window.location.replace(WebsiteRoutes.NotFound)
        }
      } finally {
        setIsLoading(false)
      }
    }

    void getVehicle()
  }, [vehicleId, dealerId])

  useEffect(() => {
    if (videoUrl != null) {
      videoModalProps.onOpenModal()
    }
  }, [videoUrl, videoModalProps.onOpenModal])

  useEffect(() => {
    const getDealershipSettings = async (): Promise<void> => {
      if (dealerId != null) {
        try {
          setIsSettingsLoading(true)
          const res = await defaultApiProvider.getDealerSettings(dealerId)

          setDealershipSettings(res)
        } catch (e) {
          setDealershipSettings(settings)
        } finally {
          setIsSettingsLoading(false)
        }
      }
    }

    void getDealershipSettings()
  }, [settings, dealerId])

  // ========================================== //

  return {
    vehicle,
    description,
    dealership,
    galleryMedia,
    media: vehicle?.media ?? null,
    mainImageUrl: vehicle?.mainImageUrl ?? '',
    features,
    onCarfaxClick,
    onWindowStickerClick,
    carfaxIcon,
    requestModalData,
    setRequestModalData,
    onTestDriveClick,
    onAskClick,
    isLoading,
    videoUrl,
    videoThumbnailUrl,
    videoModalProps,
    settings: dealershipSettings,
    isSettingsLoading
  }
}
