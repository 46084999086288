import { type FC, useEffect, useState } from 'react'
import { cnx } from '@carfluent/common'

import Button from 'website/components/Button'

import { DEFAULT_TRADE_IN_DETAILS_FORM_VALUE, DEFAULT_TRADE_IN_VEHICLE_VALUE } from 'website/constants'
import SharedStateHook, { StoreBranches } from 'website/store'
import { CarAppraisalFormProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'

import VinForm from './components/VinForm'
import MakeModel from './components/MakeModel'

import { createStyleClass } from './styles'

enum TabKeys {
  Vin = 0,
  MakeModel
}

const useSharedState = SharedStateHook<Store.TradeInVehicleState>(StoreBranches.TradeInVehicle)
const useSharedTradeInDetailsState = SharedStateHook<Store.TradeInDetailsVehicleState>(StoreBranches.TradeInDetailsVehicle)

const CarAppraisalForm: FC<CarAppraisalFormProps> = ({
  nameInLayout = SupportedComponents.CarAppraisalForm,
  variant,
  states
}) => {
  const stylesRoot = createStyleClass()
  const componentStylesCls = useComponentStyles(nameInLayout, variant)
  const [activeTab, setActiveTab] = useState(TabKeys.Vin)
  const onChangeTab = (key: TabKeys) => () => setActiveTab(key)
  const [, setTradeInVehicle] = useSharedState(states.tradeInVehicle)
  const [, setTradeInDetailsVehicle] = useSharedTradeInDetailsState(states.tradeInDetailsVehicle)

  useEffect(() => {
    setTradeInVehicle({
      lastUpdateTs: Date.now(),
      ...DEFAULT_TRADE_IN_VEHICLE_VALUE
    })

    setTradeInDetailsVehicle({
      lastUpdateTs: Date.now(),
      ...DEFAULT_TRADE_IN_DETAILS_FORM_VALUE
    })
  }, [])

  return (
    <div className={cnx(nameInLayout, stylesRoot, componentStylesCls.root)}>
      <div className={componentStylesCls.content}>
        <div className='form-controls'>
          <Button
            nameInLayout=''
            className={activeTab === TabKeys.Vin ? 'active' : ''}
            text='VIN'
            variant='textTabStyled'
            onClick={onChangeTab(TabKeys.Vin)}
          />

          <Button
            nameInLayout=''
            className={activeTab === TabKeys.MakeModel ? 'active' : ''}
            text='MAKE/MODEL'
            variant='textTabStyled'
            onClick={onChangeTab(TabKeys.MakeModel)}
          />
        </div>

        <div className='form-container'>
          {activeTab === TabKeys.Vin && (
            <VinForm states={states} />
          )}
          {activeTab === TabKeys.MakeModel && (
            <MakeModel states={states} />
          )}
        </div>
      </div>
    </div>
  )
}

export default CarAppraisalForm
