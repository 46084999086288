import type { KeyboardEvent, RefObject, FocusEventHandler, FocusEvent, ChangeEvent } from 'react'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'

import { getHeight, getNumberHeight } from './utils'

const COMPUTED_STYLED_DELAY = 42
const BACKSPACE_KEY = 'Backspace'

const isValueExist = (value?: string): boolean => value != null && value.trim() !== ''

interface UseTextareaProps {
  value: string
  maxRows: number
  minRows: number
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  onKeyUp?: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  label?: string
  onChange?: (id: string, value: string) => void
  id: string | undefined
  additionalHeight?: number
}

interface UseTextareaReturn {
  ref: RefObject<HTMLTextAreaElement>
  isScrollBarHidden: boolean
  onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  onKeyUp: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  onFocus: FocusEventHandler<HTMLTextAreaElement>
  onBlur: FocusEventHandler<HTMLTextAreaElement>
  value: string
  isActiveLabel: boolean
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

export const useTextarea = ({
  value,
  maxRows,
  minRows,
  onKeyDown: _onKeyDown,
  onKeyUp: _onKeyUp,
  onFocus: _onFocus,
  onBlur: _onBlur,
  label,
  id,
  onChange: _onChange,
  additionalHeight
}: UseTextareaProps): UseTextareaReturn => {
  const [isScrollBarHidden, hideScrollbar] = useState(true)
  const [isActiveLabel, setIsActiveLabel] = useState(isValueExist(value))
  const textareaEl = useRef<HTMLTextAreaElement | null>(null)
  const downLineLength = useRef<number>(1)

  const onHeightChange = useCallback((reducedLines: number | null = null) => {
    if (textareaEl.current == null) {
      return
    }

    const stringedValue = value != null ? value.toString() : ''
    const styles = getComputedStyle(textareaEl.current)
    const lineHeight = getNumberHeight(styles.lineHeight)
    const paddingTop = getNumberHeight(styles.paddingTop)
    const paddingBottom = getNumberHeight(styles.paddingBottom)
    const minHeight = paddingTop + paddingBottom + (lineHeight * minRows)
    const maxHeight = paddingTop + paddingBottom + (lineHeight * maxRows)
    const height = getHeight({
      height: textareaEl.current.scrollHeight,
      minHeight,
      maxHeight,
      lineHeight,
      value: stringedValue,
      reducedLines,
      additionalHeight
    })

    textareaEl.current.style.height = `${height}px`

    hideScrollbar(height < maxHeight)
  }, [value, maxRows, minRows])

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === BACKSPACE_KEY) {
      downLineLength.current = value.split('\n').length
    }

    _onKeyDown?.(e)
  }

  const onKeyUp = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === BACKSPACE_KEY) {
      const rows = value.split('\n')
      const reducedLines = downLineLength.current - rows.length

      if (reducedLines > 0 && rows.length < maxRows) {
        onHeightChange(reducedLines)
      }
    }

    _onKeyUp?.(e)
  }

  const onFocus = (e: FocusEvent<HTMLTextAreaElement>): void => {
    if (label != null) {
      setIsActiveLabel(true)
    }

    _onFocus?.(e)
  }

  const onBlur = (e: FocusEvent<HTMLTextAreaElement>): void => {
    if (label != null) {
      setIsActiveLabel(false)
    }

    _onBlur?.(e)
  }

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    _onChange?.(id ?? '', e.target.value)
  }

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      onHeightChange()
    }, COMPUTED_STYLED_DELAY)

    return () => {
      clearTimeout(timeout)
    }
  }, [onHeightChange])

  return {
    value,
    ref: textareaEl,
    isScrollBarHidden: value === '' || value == null || isScrollBarHidden,
    onKeyDown,
    onKeyUp,
    onFocus,
    onBlur,
    isActiveLabel: isActiveLabel || isValueExist(value),
    onChange
  }
}
