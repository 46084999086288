import { useEffect, useState } from 'react'

interface UseMediaQueryByBreakpointsReturn {
  isMatchingBreakpoints?: boolean | null
}

const useMediaQueryByBreakpoints = (
  breakpoints?: [number, number?]
): UseMediaQueryByBreakpointsReturn => {
  const [
    isMatchingBreakpoints, setIsMatchingBreakpoints
  ] = useState<boolean | undefined>()

  useEffect(() => {
    if (breakpoints == null) {
      setIsMatchingBreakpoints(true)
      return
    }

    const query = `(min-width: ${breakpoints[0]}px)${breakpoints[1] != null ? ` and (max-width: ${breakpoints[1]}px)` : ''}`

    const mediaQuery = window.matchMedia(query)
    setIsMatchingBreakpoints(mediaQuery.matches)

    const handler = (event: MediaQueryListEvent): void => setIsMatchingBreakpoints(event.matches)

    /**
     * added support for older versions of iOS.
     * It was breaking on Iphone XS v.12
     */
    if ('addEventListener' in mediaQuery) {
      mediaQuery.addEventListener('change', handler)
    } else {
      mediaQuery.addListener(handler)
    }

    return () => {
      if ('removeEventListener' in mediaQuery) {
        mediaQuery.removeEventListener('change', handler)
      } else {
        mediaQuery.removeListener(handler)
      }
    }
  }, [breakpoints])

  return { isMatchingBreakpoints }
}

export default useMediaQueryByBreakpoints
