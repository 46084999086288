import { FC, useState, useCallback } from 'react'
import NativeInfiniteScroll, { Props as InfiniteScrollProps } from 'react-infinite-scroll-component'

interface Props extends InfiniteScrollProps {
  isLoading: boolean
}

const InfiniteScroll: FC<Props> = ({
  dataLength,
  next,
  hasMore,
  loader,
  children,
  isLoading,
  ...rest
}) => {
  const [isFetching, setFetching] = useState<boolean>(false)
  const showLoader = isLoading || (isFetching && !isLoading)

  const onFetch = useCallback(async () => {
    if (isFetching || isLoading) {
      return
    }

    setFetching(true)

    await next()

    setFetching(false)
  }, [isFetching, isLoading, next])

  return (
    <NativeInfiniteScroll
      dataLength={dataLength}
      next={onFetch}
      hasMore={hasMore}
      loader={showLoader && loader}
      {...rest}
    >
      {children}
    </NativeInfiniteScroll>
  )
}

export default InfiniteScroll
