import { useEffect, useRef, useState } from 'react'
import { loadGoogleMapScript } from 'components/AddressAutocomplete/utils'

interface UseLoadGoogleMapScriptReturn {
  placesAutocompleteService: google.maps.places.AutocompleteService | null
  isGoogleScriptLoaded: boolean
}

const useLoadGoogleMapScript = (language?: string): UseLoadGoogleMapScriptReturn => {
  const isLoadingRef = useRef(false)
  const [placesAutocompleteService, setPlacesAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null)

  useEffect(() => {
    const handleLoadScript = async (): Promise<void> => {
      try {
        isLoadingRef.current = true
        await loadGoogleMapScript(language)
        setPlacesAutocompleteService(new google.maps.places.AutocompleteService())
      } catch {
        setPlacesAutocompleteService(null)
      } finally {
        isLoadingRef.current = false
      }
    }

    if (placesAutocompleteService != null || isLoadingRef.current) {
      return
    }

    void handleLoadScript()
  }, [language])

  return {
    placesAutocompleteService,
    isGoogleScriptLoaded: placesAutocompleteService != null
  }
}

export default useLoadGoogleMapScript
