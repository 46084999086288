import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import GetPrequalifiedComponent from 'website/components/GetPrequalifiedComponent'

import type { GetPrequalifiedRedirectComponentProps } from 'website/components/types'
import { SupportedComponents } from 'website/components/types'

const GetPrequalifiedRedirectComponent: FC<GetPrequalifiedRedirectComponentProps> = ({ to, ...props }) => {
  const navigate = useNavigate()

  const onClick = useCallback(() => {
    navigate(to)
  }, [navigate])

  return (
    <GetPrequalifiedComponent
      {...props}
      nameInLayout={SupportedComponents.GetPrequalifiedComponent}
      buttonProps={{
        ...props.buttonProps,
        onClick
      }}
    />
  )
}

export default GetPrequalifiedRedirectComponent
