import { TypographyVariant } from 'website/components/types'

const textHeaderTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const
export type TextHeaderTags = typeof textHeaderTags[number]
export type TextTag = TextHeaderTags | 'p' | 'span'

const isVariantAHeaderTag = (variant?: TypographyVariant): variant is TextHeaderTags => {
  return variant != null && textHeaderTags.includes(variant as TextHeaderTags)
}

export default (variant?: TypographyVariant): TextTag => {
  if (variant == null || variant === 'smallText') {
    return 'p'
  }

  if (variant === 'h4Thin' || variant === 'subtitle1Thin' || variant === 'noCarInfoTitle') {
    return 'h4'
  }

  if (variant === 'header1Thin') {
    return 'h1'
  }

  if (isVariantAHeaderTag(variant)) {
    return variant
  }

  if (variant.includes('header')) {
    const digits = variant.replace(/\D/g, '')
    const num = Math.max(Number(digits !== '' ? digits : '1'), 6)
    return `h${num}` as TextHeaderTags
  }

  if (variant.includes('subtitle')) {
    return 'h6'
  }

  if (variant === 'button' || variant === 'caption' || variant === 'overline') {
    return 'span'
  }

  return 'p'
}
