import React, { FC } from 'react'
import completedStepIcon from 'assets/check.svg'
import waitingStepIcon from 'assets/Path.svg'
import { useStyles } from './styles'

interface stepIconProps {
  active: boolean
  completed: boolean
}

const QontoStepIcon: FC<stepIconProps> = ({ active, completed }) => {
  const styles = useStyles()

  return (
    <div className={`${styles.root} ${(active ? styles.activeMark : '')}`}>
      {
        completed
          ? (
            <div className={styles.completedBlock}>
              <img alt='check' src={completedStepIcon} />
            </div>)
          : active
            ? <div className={styles.activeBlock} />
            : <div><img alt='iconCheckbox' src={waitingStepIcon} /></div>
      }
    </div>
  )
}

export default QontoStepIcon
