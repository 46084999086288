import type { FC } from 'react'
import { IconProps } from 'website/components/types'

const RightArrowIcon: FC<IconProps> = ({ fill = '#212121' }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M16 12L10 6L8.59 7.41L13.17 12L8.59 16.59L10 18L16 12Z' fill={fill} fillOpacity='0.5' />
    </svg>
  )
}

export default RightArrowIcon
