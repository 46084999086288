import type { FC } from 'react'
import type { IconProps } from 'website/components/types'

const SmallCheckedIcon: FC<IconProps> = ({ fill = '#219653' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path d="M4.89542 8.68235C4.58457 8.36497 4.5846 7.85728 4.89549 7.53994C5.21557 7.2132 5.74162 7.2132 6.06171 7.53994L7.38303 8.88871L10.6102 5.59511C10.9303 5.26843 11.4563 5.26845 11.7763 5.59517C12.0872 5.91253 12.0872 6.42027 11.7763 6.73764L8.01807 10.574C7.66949 10.9298 7.0966 10.9298 6.74806 10.5739L4.89542 8.68235Z" fill={fill}/>
    </svg>
  )
}

export default SmallCheckedIcon
