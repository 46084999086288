export const ACCESS_TOKEN = 'access_token'
export const ACH_VALUE = 'ACHValue'
export const BANK_PAID = 'bankPAID'
export const CLOUD_ACCESS_TOKEN = 'CLOUD_ACCESS_TOKEN'
export const CLIENT_SECRET = 'clientSecret'
export const CASH_VALUE = 'cashValue'
export const CHECK = 'check'
export const CHECKOUT = 'checkout'
export const CHECKOUT_ADDRESS = 'checkout_address'
export const CRYPTOCURRENCY_VALUE = 'cryptocurrencyValue'
export const DOCUMENT = 'document'
export const HAS_DELIVERY = 'has_delivery'
export const PAID_CARD = 'PaidCard'
export const REFRESH_TOKEN = 'refresh_token'
export const REFRESH_DOCUMENT = 'refresh_document'
export const VEHICLE_ID = 'vehicleId'
export const VEHICLE_INFO = 'vehicleInfo'
export const WORKFLOW_ID = 'workflow_id'
export const WORKFLOW_VERSION = 'workflow_version'
export const SHOULD_ZIP_CODE_POPOVER_OPEN = 'shouldZipCodePopoverOpen'
