export const MUST_HAVE_ROLES = ['customer']
export const ADMIN_ROLE = 'dealer admin'

export enum TokenValidationStatus {
  VALID,
  ROLE_MISMATCH,
  INVALID_TOKEN
}

export interface TokenValidationResult {
  roles: Set<string>
  status: TokenValidationStatus
}

export const checkTokenStatus = (accessToken: string): TokenValidationResult => {
  if (accessToken === '') {
    return {
      roles: new Set(),
      status: TokenValidationStatus.INVALID_TOKEN
    }
  }

  const parsed = parseJwt(accessToken)

  if (parsed == null) {
    return {
      status: TokenValidationStatus.INVALID_TOKEN,
      roles: new Set()
    }
  }

  const parsedRoles = new Set(parsed.role.map(v => v.toLowerCase()))

  if (MUST_HAVE_ROLES.some(v => !parsedRoles.has(v.toLowerCase()))) {
    return {
      status: TokenValidationStatus.ROLE_MISMATCH,
      roles: parsedRoles
    }
  }

  return {
    status: TokenValidationStatus.VALID,
    roles: parsedRoles
  }
}

export const parseJwt = (token: string): Api.JwtPayload | null => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
    )

    const parsed = JSON.parse(jsonPayload)
    if (!Array.isArray(parsed.role)) {
      parsed.role = [parsed.role]
    }
    return parsed as Api.JwtPayload
  } catch {
    return null
  }
}
