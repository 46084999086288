import { FC } from 'react'

import VehicleCardSkeleton from 'website/components/_base/VehicleCardSkeleton'

import { useComponentStyles } from 'website/styles/useComponentStyles'
import { SupportedComponentNames } from 'website/components/types'

const LoadingScreen: FC = () => {
  const styles = useComponentStyles(SupportedComponentNames.VehicleCardSkeleton, 'default')
  
  return (
    <>
      {Array.from({ length: 4 }).map((_, i) =>
        <VehicleCardSkeleton className={styles.root} key={i} />)}
    </>
  )
}

export default LoadingScreen
