import { useMemo } from 'react'
import { type TemplateProps } from 'website/siteGenerator/types'
import { createLayoutTemplate } from 'website/styles/createLayoutTemplate'
import { generateCssFromGridLayout } from './utils'

export const useLayoutStyles = ({ template, layout }: TemplateProps): string => {
  return useMemo(() => {
    if (layout != null) {
      return generateCssFromGridLayout(layout)
    }

    if (template != null) {
      return createLayoutTemplate(template)
    }

    return ''
  }, [template, layout])
}
