import type { FC } from 'react'
import { ComponentRootProps } from 'website/components/types'
import { UITools } from '@carfluent/common'

const { cn } = UITools

/**
 * Standard wrapper for all components, used in generated websites.
 * All UI and behavior that is common for ALL components - should be placed here.
 */

const ComponentRoot: FC<ComponentRootProps> = ({
  children,
  classes,
  className,
  nameInLayout,
  onClick
}) => {
  return (
    <div
      className={cn('ComponentRoot-root', nameInLayout, className, classes?.root)}
      onClick={onClick}
    >
      <div className={cn('ComponentRoot-content', classes?.content)}>
        {children}
      </div>
    </div>
  )
}

export default ComponentRoot
