import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type OnActionResultHandler } from '@carfluent/common'

import GeoApiProvider from 'website/api/geo.api'
import { isAddressValid } from 'website/utils/addressGuards'
import { serializeFinancingData, serializeLeadData } from 'website/components/GetPrequalifiedForm/hook/serializer'
import apiProvider from 'website/api/apiProvider'
import { DEFAULT_PREQUALIFIED_FORM_VALUES } from 'website/constants'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import { setPrequalifyStateInLS } from 'website/services/storage/prequalifyData'
import { FinancingSubRoutes } from 'website/routing/constants'
import type { PrequalifiedFormData } from 'website/components/GetPrequalifiedForm/hook'

const usePrequalifyState = SharedStateHook<Store.PrequalifyState>(StoreBranches.Prequalify)
const usePrequalifiedFormValuesState = SharedStateHook<Store.PrequalifiedFormValues>(StoreBranches.PrequalifiedFormValues)
const DEFAULT_TERM = 60

interface UseSubmitActionProps {
  errorNavigationRoute?: string
  shouldResetValuesOnError?: boolean
  shouldCreateLead?: boolean
}

export interface FormValuesIncomeSSN {
  lastDigits: number | null
  yearIncome: number | null
}

interface UseSubmitActionResult {
  prequalifiedFormValues: PrequalifiedFormData
  submitAction: (values: FormValuesIncomeSSN) => Promise<void>
  onActionResult: OnActionResultHandler<FormValuesIncomeSSN>
}

export const useSubmitGetPrequalifiedFormAction = ({
  errorNavigationRoute = FinancingSubRoutes.Error,
  shouldResetValuesOnError = false,
  shouldCreateLead = false
}: UseSubmitActionProps = {}): UseSubmitActionResult => {
  const navigate = useNavigate()
  const [, setSuccessFormData] = usePrequalifyState(
    defaultInstance(StoreBranches.Prequalify),
    setPrequalifyStateInLS
  )
  const [
    prequalifiedFormState,
    setPrequalifiedFormValues
  ] = usePrequalifiedFormValuesState(
    defaultInstance(StoreBranches.PrequalifiedFormValues)
  )

  const [formBaseValues, setFormBaseValues] = useState<PrequalifiedFormData>(DEFAULT_PREQUALIFIED_FORM_VALUES)

  useEffect(() => {
    const { lastUpdateTs, ...rest } = prequalifiedFormState
    setFormBaseValues(rest)
  }, [prequalifiedFormState])

  const submitAction = useCallback(async (values: FormValuesIncomeSSN) => {
    const mergeValues = { ...formBaseValues, ...values }
    if (!isAddressValid(mergeValues.addressData)) {
      return
    }

    const { latitude, longitude } = await GeoApiProvider.getGeoByZip(mergeValues.addressData.zipCode) ?? {}
    const serializePayload = { ...mergeValues, locationLatitude: latitude, locationLongitude: longitude }

    if (shouldCreateLead) {
      const leadPayload = await serializeLeadData(serializePayload)

      if (leadPayload == null) {
        return
      }

      await apiProvider.crm.createLead(leadPayload)
    }

    const prequalifyPayload = serializeFinancingData(serializePayload)

    if (prequalifyPayload == null) {
      return
    }

    setPrequalifiedFormValues({
      ...mergeValues,
      lastUpdateTs: Date.now()
    })

    const res = await apiProvider.dealer.getPrequalified(prequalifyPayload)

    setSuccessFormData({
      ...res,
      term: res.term == null || res.term === '' ? DEFAULT_TERM : Number(res.term),
      lastUpdateTs: Date.now()
    })
  }, [formBaseValues, setPrequalifiedFormValues, setSuccessFormData, shouldCreateLead])

  const onActionResult = useCallback(({ kind }) => {
    if (kind === 'Ok') {
      navigate(FinancingSubRoutes.Success)
      setPrequalifiedFormValues({
        ...DEFAULT_PREQUALIFIED_FORM_VALUES,
        lastUpdateTs: Date.now()
      })
    } else {
      navigate(errorNavigationRoute)
      if (shouldResetValuesOnError) {
        setPrequalifiedFormValues({
          ...DEFAULT_PREQUALIFIED_FORM_VALUES,
          lastUpdateTs: Date.now()
        })
      }
    }
  }, [navigate, setPrequalifiedFormValues, errorNavigationRoute, shouldResetValuesOnError])

  return {
    onActionResult,
    submitAction,
    prequalifiedFormValues: prequalifiedFormState
  }
}
