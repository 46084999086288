import { DictionaryItem } from 'website/api/types'

const DEFAULT_GET_YEARS_PROPS = {
  from: 1991,
  to: new Date().getFullYear()
}

export interface GetYearsForDropdownFieldProps {
  from: number
  to: number
}

export interface WithExpiryProps {
  value: string
  expiry: number
}

export const getYearsForDropdownField = ({
  from,
  to
}: GetYearsForDropdownFieldProps = DEFAULT_GET_YEARS_PROPS): DictionaryItem[] => {
  if (from >= to) {
    return []
  }

  const dictionary: DictionaryItem[] = []
  let firstEl: DictionaryItem | null = null

  while (firstEl?.id !== from) {
    if (firstEl == null) {
      firstEl = { id: to, name: to.toString() }
      dictionary.push(firstEl)
    } else {
      const prevYear: number = firstEl.id - 1

      firstEl = { id: prevYear, name: prevYear.toString() }

      dictionary.push(firstEl)
    }
  }

  return dictionary
}

export const setWithExpiry = ({ key = 'threadId', value, expiryInMinutes = 30 }: {key?: string, value: string, expiryInMinutes?: number}): void => {
  const now = new Date()
  const item: WithExpiryProps = {
    value: value,
    expiry: now.getTime() + expiryInMinutes * 60000
  }

  localStorage.setItem(key, JSON.stringify(item))
}
