import stringifyTemplate from 'website/siteGenerator/utils/stringifyTemplate'

import { type JsonTemplateData, AvailableTemplates } from './types'
import headerTemplate from './configFiles/header'
import footerTemplate from './configFiles/footer'
import financingPageTemplate from './configFiles/financingPage'
import homePageTemplate from './configFiles/homePage'
import tradePageTemplate from './configFiles/tradePage'
import aboutUsPageTemplate from './configFiles/aboutUsPage'
import dealerInfoTemplate from './configFiles/dealerInfo'
import inventoryPageTemplate from './configFiles/inventoryPage'
import prequalifiedFormTemplate from './configFiles/prequalifiedForm'
import prequalifiedFormSuccessTemplate from './configFiles/prequalifiedFormSuccessPage'
import prequalifiedFormErrorTemplate from './configFiles/prequalifiedFormErrorPage'
import prequalifiedFormSSNIncomeTemplate from './configFiles/prequalifiedFormSSNIncome'
import { aboutUsPromosForAboutUsPage, aboutUsPromosForMainPage } from './configFiles/aboutUsPromos'
import includedWithCarAdsTemplate from './configFiles/includedWithCarAds'
import vehicleDetailsTemplate from './configFiles/vehicleDetails'
import unsubscribePageTemplate from './configFiles/unsubscribePage'
import unsubscribedPageTemplate from './configFiles/unsubscribedPage'
import locationsPageTemplate from './configFiles/locationsPage'
import dealershipLocationPage from './configFiles/dealershipLocationPage'
import rootTemplate from './configFiles/root'

/**
 * Order is important.
 * Please start from those json configs which does not have any other json references.
 */
const templates: JsonTemplateData[] = [
  /** components */
  stringifyTemplate(headerTemplate),
  stringifyTemplate(footerTemplate),
  stringifyTemplate(dealerInfoTemplate),
  stringifyTemplate(aboutUsPromosForMainPage),
  stringifyTemplate(aboutUsPromosForAboutUsPage),
  stringifyTemplate(includedWithCarAdsTemplate),

  /** pages */
  stringifyTemplate(homePageTemplate),
  stringifyTemplate(locationsPageTemplate),
  stringifyTemplate(dealershipLocationPage),
  stringifyTemplate(inventoryPageTemplate),
  stringifyTemplate(prequalifiedFormTemplate),
  stringifyTemplate(prequalifiedFormSSNIncomeTemplate),
  stringifyTemplate(prequalifiedFormSuccessTemplate),
  stringifyTemplate(prequalifiedFormErrorTemplate),
  stringifyTemplate(financingPageTemplate),
  stringifyTemplate(tradePageTemplate),
  stringifyTemplate(aboutUsPageTemplate),
  stringifyTemplate(vehicleDetailsTemplate),
  stringifyTemplate(unsubscribePageTemplate),
  stringifyTemplate(unsubscribedPageTemplate),

  /** root (always must go last) */
  stringifyTemplate(rootTemplate)
]

/**
 * used as a global and sharable cache map for parsed templates
 */
export const parsedTemplatesCache: Record<ValueOf<typeof AvailableTemplates>, string> = {
  headerTemplate: '',
  footerTemplate: '',
  aboutUsPromosForMainPageTemplate: '',
  aboutUsPromosForAboutUsPageTemplate: '',
  financingPageTemplate: '',
  prequalifiedFormTemplate: '',
  prequalifiedFormSuccessTemplate: '',
  prequalifiedFormErrorTemplate: '',
  prequalifiedFormSSNIncomeTemplate: '',
  includedWithCarAdsTemplate: '',
  homePageTemplate: '',
  locationsPageTemplate: '',
  tradePageTemplate: '',
  aboutUsPageTemplate: '',
  dealerInfoTemplate: '',
  inventoryPageTemplate: '',
  vehicleDetailsTemplate: '',
  unsubscribePageTemplate: '',
  unsubscribedPageTemplate: '',
  dealershipLocationPageTemplate: '',
  rootTemplate: ''
}

export default templates
