import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import type { VehicleViewContentProps } from 'website/components/types'
import { ServiceType } from 'website/api/types'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import testDriveIcon from 'website/assets/test_drive.svg'

import ComponentRoot from 'website/components/_base/ComponentRoot'
import Text from 'website/components/Text'
import GalleryVehicleView from 'website/components/GalleryVehicleView'
import FeatureOptionList from 'website/components/FeatureOptionList'
import InfoLink from 'website/components/InfoLink'
import Button from 'website/components/Button'
import VehicleServiceRequestModalForm from 'website/components/_base/VehicleServiceRequestModalForm'
import VideoPlayerDialog from 'website/components/_base/VideoPlayerDialog'

import VehicleBriefInfo from './components/VehicleBriefInfo'
import VehicleDescription from './components/VehicleDescription'
import IncludedWithCarAds from './components/IncludedWithCarAds'
import VehicleSpecsOverview from './components/VehicleSpecsOverview'
import VehicleDealershipInfo from './components/VehicleDealershipInfo'
import LoadingScreen from './components/LoadingScreen'
import { useVehicleViewContent } from './hook'

import CLASS_NAME from './styles'

const VehicleViewContent: FC<VehicleViewContentProps> = ({
  nameInLayout = 'VehicleViewContent',
  template,
  layout,
  componentProps,
  noPhotoImage
}) => {
  const componentStylesCls = useComponentStyles(nameInLayout)
  const templateCls = useLayoutStyles({ template, layout })

  const {
    vehicle,
    description,
    dealership,
    galleryMedia,
    media,
    features,
    onCarfaxClick,
    onWindowStickerClick,
    carfaxIcon,
    requestModalData,
    setRequestModalData,
    onTestDriveClick,
    onAskClick,
    isLoading,
    videoModalProps,
    videoUrl,
    videoThumbnailUrl,
    settings,
    isSettingsLoading
  } = useVehicleViewContent(componentProps.VehicleBriefInfo.carfaxPartnerCode)

  /**
   * empty description that comes from back is '<p></p>'
   */
  const isDescription = description != null && description !== '' && description !== '<p></p>'

  const altTextForMainImage = (vehicle?.model != null && vehicle?.trim != null)
    ? `${vehicle.model ?? ''} ${vehicle.trim ?? ''}`
    : 'Main car image'

  const isFeatureExist = features.length > 0

  if (isLoading || isSettingsLoading) {
    return <LoadingScreen />
  }

  return (
    <ComponentRoot
      nameInLayout={nameInLayout}
      className={CLASS_NAME}
      classes={{
        root: componentStylesCls.root,
        content: cnx(componentStylesCls.content, templateCls)
      }}
    >
      <GalleryVehicleView
        {...componentProps.GalleryVehicleView}
        galleryMedia={galleryMedia}
        media={media}
        emptyListImage={noPhotoImage}
        altTextForMainImage={altTextForMainImage}
        noPhotoClassName='no-image-wrapper'
      />

      <VehicleBriefInfo {...componentProps.VehicleBriefInfo} vehicle={vehicle} onClick={onAskClick} />
      <VehicleDealershipInfo dealership={dealership} />
      <VehicleSpecsOverview {...componentProps.VehicleSpecsOverview} vehicle={vehicle} />
      <div className={componentProps.InfoLinkBlock.nameInLayout}>
        <InfoLink
          title='Free history report'
          content='Get a CARFAX report for every car, ensuring transparent shopping.'
          actionTitle='VIEW CARFAX REPORT'
          icon={carfaxIcon}
          onClick={onCarfaxClick}
        />

        <InfoLink
          title='Original Window Sticker'
          content='Explore vehicle features, fuel efficiency, MSRP, and other details.'
          actionTitle='OPEN WINDOW STICKER'
          onClick={onWindowStickerClick}
        />
      </div>

      <IncludedWithCarAds {...componentProps.IncludedWithCarAds} settings={settings} />
      <Text {...componentProps.Text1} className={cnx('g-title', !isFeatureExist && 'g-element-hidden')} />

      <FeatureOptionList
        {...componentProps.FeatureOptionList}
        options={features}
        className={!isFeatureExist ? 'g-element-hidden' : ''}
      />

      {isDescription && (
        <>
          <Text {...componentProps.Text} />
          <VehicleDescription description={description} />
        </>
      )}

      <div className={cnx(componentProps.TestDriveBlock.nameInLayout, isDescription && 'with-description')}>
        <p className='test-drive-title'>Want test drive?</p>
        <p className='test-drive-content'>You can schedule a test drive for a specific day and time immediately.</p>
        <Button
          className='test-drive-button'
          text='Schedule test drive'
          onClick={onTestDriveClick}
        />
        <img src={testDriveIcon} alt='' />
      </div>

      <VehicleServiceRequestModalForm
        dealerId={vehicle?.dealerId ?? 0}
        vehicleId={vehicle?.id ?? 0}
        isModalOpen={requestModalData != null}
        serviceType={requestModalData ?? ServiceType.CarOffer}
        onCloseModal={() => setRequestModalData(null)}
      />

      <VideoPlayerDialog
        isOpen={videoModalProps.isModalOpen}
        onClose={videoModalProps.onCloseModal}
        posterUrl={videoThumbnailUrl}
        videoUrl={videoUrl}
      />
    </ComponentRoot>
  )
}

export default VehicleViewContent
