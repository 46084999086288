import { useContext, MouseEvent, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { TransactionTypeId, PaymentStatusId, WorkflowStateName, WorkflowStep, WorkflowStepName } from 'api/types'
import { NavigationStep } from 'constants/constants'
import { getAbsolutePath, Routes } from 'routing/constants'
import WizardCTX from 'components/wizard/wizard.store'
import { PaymentsCTX } from 'pages/payments/store'
import { YourDetailsStepsArray } from './constants'

interface UseStepperReturnConfig {
  toggleDrawer: (isOpen?: boolean) => void
}

interface UseStepperReturn {
  activeStep: WorkflowStep | null
  navigationSteps: WorkflowStep[]
  activeStepIndex: number
  handleClick: (e: MouseEvent<HTMLAnchorElement>) => void
  getRoutePathname: (navigationStepName: string, workflowTemplateStep: string) => string
  isStepLocked: (navigationStepName: string, workflowTemplateStep: string) => boolean
  getDataTestId: (workTemplateStep: string) => string | null
  isStepCompleted: (step: WorkflowStep) => boolean
  getNavigationStep: (step: WorkflowStepName) => NavigationStep | null
}

const NAVIGATION_STEPS: Record<string, NavigationStep> = {
  [WorkflowStepName.PersonalDetails]: NavigationStep.YourDetails,
  [WorkflowStepName.YourDetails]: NavigationStep.YourDetails,
  [WorkflowStepName.TradeIn]: NavigationStep.TradeIn,
  [WorkflowStepName.Financing]: NavigationStep.CashFinancing,
  [WorkflowStepName.ServiceAndProtection]: NavigationStep.Coverage,
  [WorkflowStepName.CreditApplication]: NavigationStep.CreditApplication,
  [WorkflowStepName.DealerCheck]: NavigationStep.Review,
  [WorkflowStepName.Documents]: NavigationStep.Documents,
  [WorkflowStepName.PickupAndDelivery]: NavigationStep.Checkout
}

const useWorkflowStepper = ({ toggleDrawer }: UseStepperReturnConfig): UseStepperReturn => {
  const Wz = useContext(WizardCTX)
  const { isPaidLocal } = useContext(PaymentsCTX)
  const location = useLocation()
  const isFinancingSkipped = Wz.getStepByName(WorkflowStepName.Financing)?.workflowStepState === WorkflowStateName.Skipped

  const isYourDetailsPage = location.pathname.includes(Routes.YourDetails)
  const isFinancingCalculator = location.pathname.includes(Routes.FinancingCalculator)

  const STEP_ORDER_SHIFT: Record<string, number> = {
    [WorkflowStepName.DealerCheck]: isFinancingSkipped ? 3 : 2,
    [WorkflowStepName.DealerCheckCompleted]: isFinancingSkipped ? 4 : 3,
    [WorkflowStepName.Documents]: isFinancingSkipped ? 4 : 3,
    [WorkflowStepName.SignOnline]: isFinancingSkipped ? 5 : 4,
    [WorkflowStepName.PickupAndDelivery]: isFinancingSkipped ? 5 : 4
  }

  const activeStepShift = Wz.activeStep !== null
    ? STEP_ORDER_SHIFT[Wz.activeStep.workTemplateStep] ?? 2
    : 0

  const activeStepIndex = isYourDetailsPage ? 0 : Wz.activeStep !== null ? Wz.activeStep.order - activeStepShift : -1

  const navigationSteps = useMemo(() => {
    const steps = Wz.steps.filter(step => {
      return step.workflowStepState !== WorkflowStateName.Inactive || isFinancingCalculator
    })

    return isYourDetailsPage ? YourDetailsStepsArray : steps
  }, [Wz.steps, isFinancingCalculator, isYourDetailsPage])

  const isDealerCheckActive = Wz.activeStep?.workTemplateStep === WorkflowStepName.DealerCheck
  const isDealerCheckCompleted = Wz.isCompleted(WorkflowStepName.DealerCheck)
  const isDocumentsCompleted = Wz.isCompleted(WorkflowStepName.Documents)
  const isSignOnlinePending = Wz.isPending(WorkflowStepName.SignOnline)

  const isStepLocked = (navigationStepName: string, workflowTemplateStep: string): boolean => {
    if (isYourDetailsPage) {
      return false
    }

    if (isDealerCheckActive || isDealerCheckCompleted) {
      const isPaymentRelated = workflowTemplateStep === WorkflowStepName.PickupAndDelivery
      const isDocumentsInProgress = (navigationStepName === NavigationStep.Documents) && isSignOnlinePending

      const isAnyPaymentMade = Wz.getPaymentDetails().some(p => isPaidLocal(p.transactionTypeId))

      /**
       * if document is in progress we return step locked = false as we are on a document step
       * besides if step is payment related it is locked only if any payment was made
       */
      return !((isPaymentRelated && !isAnyPaymentMade) || isDocumentsInProgress)
    }

    return false
  }

  const getRoutePathname = (navigationStepName: string, workflowTemplateStep: string): string => {
    if (navigationStepName === NavigationStep.Review) {
      return isDealerCheckCompleted ? Routes.DealerCheckCompleted : Routes.DealerCheck
    }

    if (navigationStepName === NavigationStep.Documents) {
      return isDocumentsCompleted && isSignOnlinePending ? Routes.SignOnline : Routes.Documents
    }

    if (navigationStepName === NavigationStep.Checkout) {
      const paymentDetails = Wz.getPaymentDetails()
      const paymentsStep = Wz.getStepByName(WorkflowStepName.Payments)

      /**
       * on the checkout we still can have some steps not finished before the payment steps
       */
      if ((Wz.activeStep != null) && (paymentsStep != null) && (Wz.activeStep.order < paymentsStep.order)) {
        return `${getAbsolutePath(Wz.activeStep?.workTemplateStep as WorkflowStepName)}`
      }

      /**
       * deposit payments should not be tracked as those completed because they are made outside the customer flow
       */
      const successfulPayments = paymentDetails?.filter(
        item => item.transactionTypeId !== TransactionTypeId.Deposit &&
          [PaymentStatusId.Completed, PaymentStatusId.Processing].includes(item.paymentStatusId)
      )

      if (Boolean(successfulPayments?.length) ||
        isPaidLocal(TransactionTypeId.ACH) ||
        isPaidLocal(TransactionTypeId.Card) ||
        isPaidLocal(TransactionTypeId.Cryptocurrency)) {
        return Routes.SplitPayment
      }
    }

    return `${getAbsolutePath(workflowTemplateStep)}`
  }

  const handleClick = (e: MouseEvent<HTMLAnchorElement>): void => {
    if (isDealerCheckActive) e.preventDefault()
    toggleDrawer(false)
  }

  const getDataTestId = (workTemplateStep: string): string | null => {
    switch (workTemplateStep) {
      case WorkflowStepName.PersonalDetails:
        return 'pnl-your-details'
      case WorkflowStepName.TradeIn:
        return 'pnl-trade-in'
      case WorkflowStepName.Financing:
        return 'pnl-cash-financing'
      case WorkflowStepName.ServiceAndProtection:
        return 'pnl-coverage'
      case WorkflowStepName.DealerCheck:
        return 'pnl-deal-check'
      case WorkflowStepName.Documents:
        return 'pnl-documents'
      case WorkflowStepName.PickupAndDelivery:
        return 'pnl-check-out'
      default:
        return null
    }
  }

  const isStepCompleted = (step: WorkflowStep): boolean => {
    if (isYourDetailsPage) {
      return false
    }

    return step.workflowStepState === WorkflowStateName.Completed ||
      step.workflowStepState === WorkflowStateName.Skipped ||
      step.workTemplateStep === Wz.activeStep?.workTemplateStep
  }

  const getNavigationStep = (step: WorkflowStepName): NavigationStep | null => {
    return NAVIGATION_STEPS[step] ?? null
  }

  return {
    activeStep: Wz.activeStep,
    navigationSteps,
    activeStepIndex,
    handleClick,
    getRoutePathname,
    isStepLocked,
    getDataTestId,
    isStepCompleted,
    getNavigationStep
  }
}

export default useWorkflowStepper
