import { type FC, useEffect } from 'react'
import { cnx, disableBodyScroll, enableBodyScroll } from '@carfluent/common'

import closeIcon from 'website/assets/close_white_24.svg'
import { type VideoPlayerDialogProps } from './types'
import CLASS_NAME from './styles'

const VideoPlayerDialog: FC<VideoPlayerDialogProps> = ({
  isOpen = false,
  onClose,
  posterUrl,
  videoUrl
}) => {
  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }
  }, [isOpen])

  // ========================================== //

  if (!isOpen) {
    return null
  }

  return (
    <div className={cnx(CLASS_NAME, isOpen && 'visible')} role='dialog'>
      <div className='video-player-modal-content'>
        <img
          className='btn-close'
          onClick={onClose}
          src={closeIcon}
          alt='close icon'
        />

        <div className='video-player-container'>
          <video className='video-player' controls playsInline poster={posterUrl ?? undefined}>
            <source src={videoUrl ?? undefined} />
          </video>
        </div>
      </div>
    </div>
  )
}

export default VideoPlayerDialog
