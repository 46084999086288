import { type FC } from 'react'

import {
  type GeneratedFilterProps,
  isGeneratedListFilter,
  isGeneratedRangeFilter,
  BODY_STYLE_MAP
} from 'website/configs'
import DropdownFilter from './renderers/DropdownFilter'
import ListFilter from './renderers/ListFilter'
import RangeFilter from './renderers/RangeFilter'
import TileFilter from './renderers/TileFilter'

const Filter: FC<GeneratedFilterProps> = (props) => {
  if (isGeneratedListFilter(props)) {
    switch (props.type) {
      case 'singleDropdown':
        return <DropdownFilter {...props} />
      case 'multiDropdown':
        return <DropdownFilter {...props} isMultiselect />
      case 'tile':
        return (
          <TileFilter
            {...props}
            nameToImgMap={props.id === 'bodyStyle' ? BODY_STYLE_MAP : undefined}
          />
        )
      default:
        return <ListFilter {...props} />
    }
  }

  if (isGeneratedRangeFilter(props)) {
    return <RangeFilter {...props} thousandSeparator={props.id === 'year' ? null : ','} />
  }

  return null
}

export default Filter
