import { type FC } from 'react'
import { cnx, Button } from '@carfluent/common'

import IconSVG from 'website/components/icons'
import Textarea from 'website/components/Textarea'
import ReCaptcha from 'components/common/ReCaptcha'

import RequestCall from '../RequestCall'
import TypingIndicator from '../TypingIndicator'
import Message, { MessageProps } from '../Message'
import { useChat } from './hook'
import CLASS_NAME from './styles'

export interface ChatProps {
  className?: string
  onClose: () => void
  threadId: string | null
  isOpened: boolean
  defaultMessages: MessageProps[] | null
}

const Chat: FC<ChatProps> = ({
  className,
  onClose,
  threadId,
  isOpened,
  defaultMessages
}) => {
  const {
    setIsRequestCallOpened,
    isRequestCallOpened,
    isSending,
    isDisabledSend,
    isFirstTyping,
    messages,
    value,
    setValue,
    onKeyDown,
    onSend,
    onSubmitRequestCall,
    refChatContent,
    refChatContainer,
    refRecaptcha
  } = useChat({ threadId, isOpened, defaultMessages })

  return (
    <div className={cnx(CLASS_NAME, className)} ref={refChatContainer}>
      <div onClick={onClose} className='chat-header'>
        Chat with dealer

        <IconSVG.ArrowDown />
      </div>

      <Button
        variant='outlined'
        onClick={() => setIsRequestCallOpened(true)}
        className='request-call-button'
      >
        REQUEST A CALL
      </Button>

      <div className='chat-content' ref={refChatContent}>
        {(isSending || isFirstTyping) && <TypingIndicator />}

        {messages.map((message) => {
          return (
            <Message
              key={message.id}
              {...message}
            />
          )
        })}
      </div>

      <div className='chat-textarea-wrapper'>
        <Textarea
          value={value}
          onChange={(_, value) => setValue(value)}
          placeholder='Type your message'
          onKeyDown={onKeyDown}
        />

        <div className={cnx('chat-send-icon', isDisabledSend && 'disabled')} onClick={onSend}>
          <IconSVG.Send />
        </div>
      </div>

      <RequestCall
        isOpened={isRequestCallOpened}
        onClose={() => setIsRequestCallOpened(false)}
        onSubmit={onSubmitRequestCall}
      />

      <ReCaptcha
        badge='bottomleft'
        ref={refRecaptcha}
        size='invisible'
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA ?? ''}
      />
    </div>
  )
}

export default Chat
