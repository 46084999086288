import { css } from '@emotion/css'

export default css(`
  width: 100%;
  display: flex;
  position: relative;
  
  &.cf-active {
    textarea {
      padding-top: 20px;
    }

    .cf-label {
      padding-left: 16px;
      padding-top: 8px;
      background: #F3F3F3;
      width: calc(100% - 20px);
      border-radius: 12px;
      top: 0px;
      left: 0px;
      font-size: 12px;
      color: rgba(33, 33, 33, 0.8);
    }
  }
  
  .cf-label {
    position: absolute;
    top: 16px;
    left: 16px;
    transition: all .2s;
  }
`)
