const setPropsFromTarget = (base: KeyVal, target: KeyVal): KeyVal => {
  const targetProps = Object.keys(target)

  for (const prop of targetProps) {
    if (prop in base) {
      base[prop] = target[prop]
    }
  }

  return base
}

export default setPropsFromTarget
