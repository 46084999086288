import type { FC, ChangeEvent, KeyboardEvent } from 'react'
import { Button, UI, Modal } from '@carfluent/common'

import { loginLabelText, registerLabelText } from './LabelText'
import type { SendCodeModalProps } from './types'
import useSendCodeModal from './hook'

import CLASS_NAME from './styles'

const { InputField } = UI

const SendCodeModal: FC<SendCodeModalProps> = (props) => {
  const { isModalOpen } = props

  const {
    isLogin,
    isResendActive,
    isInvalidCode,
    code,
    remainingTime,
    phoneNumber,
    inputRefs,
    onCodeChange,
    onInputKeyDown,
    onCodePaste,
    onResendCode,
    onCloseModal
  } = useSendCodeModal(props)

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onCloseModal}
      closeByBackdropClick={false}
      title='Security code'
      className={CLASS_NAME}
    >

      {isLogin ? loginLabelText(phoneNumber) : registerLabelText(phoneNumber)}

      <div className='cf-code-input-wrapper'>
        {code.map((value, index) => (
          <InputField
            id={`code-number-${index}`}
            key={index}
            inputRef={inputRefs.current[index]}
            type='tel'
            maxLength={1}
            value={value}
            placeholder=' '
            autoFocus={index === 0}
            autoComplete='off'
            inputProps={{
              onPaste: onCodePaste
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onCodeChange(e, index)}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => onInputKeyDown(e, index)}
          />
        ))}
      </div>

      {isInvalidCode && <p className='cf-error'>Incorrect SMS code.</p>}

      {isResendActive
        ? (
          <Button
            className='cf-btn-resend-code'
            onClick={onResendCode}
            variant='text'
          >
            Resend code
          </Button>
          )
        : (
          <Button
            className='cf-btn-resend-code-with-timer'
            onClick={() => {}}
            isDisabled
            variant='text'
          >
            {`Resend code (${remainingTime} s)`}
          </Button>
          )}
    </Modal>
  )
}

export default SendCodeModal
