import { useCallback, useState, useMemo } from 'react'
import pDebounce from 'p-debounce'
import useLoadGoogleMapScript from 'hooks/useLoadGoogleMapScript'
import client from './client'
import { fullAddress } from './utils'
import { FullAddressParts } from './types'

interface SearchOptions {
  input: string
  types?: string[]
  componentRestrictions?: google.maps.places.ComponentRestrictions
}

interface UsePlacesAutocompleteReturn {
  placesAutocompleteService: google.maps.places.AutocompleteService | null
  placePredictions: FullAddressParts[]
  getPlacePredictions: (opts: SearchOptions) => Promise<FullAddressParts[]>
  isPlacePredictionsLoading: boolean
}

type SearchPlacesDetailsFunc = (opts: SearchOptions) => Promise<FullAddressParts[]>

const defaultCountry = 'us'
const defaultTypes = ['address']
const defaultRestrictions = { country: defaultCountry }

const geocodingCache: Record<string, FullAddressParts | null> = {}

const usePlacesAutocomplete = (
  types = defaultTypes,
  debounce = 300,
  language = ''
): UsePlacesAutocompleteReturn => {
  const [placePredictions, setPlacePredictions] = useState<FullAddressParts[]>([])
  const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] = useState(false)

  const { placesAutocompleteService } = useLoadGoogleMapScript(language)

  const searchPlacesDetails: SearchPlacesDetailsFunc = useMemo(() => {
    return pDebounce(async (opts: SearchOptions) => {
      let result: Array<FullAddressParts | null> = []

      try {
        if (placesAutocompleteService != null && opts.input !== '') {
          const predictions = (await placesAutocompleteService.getPlacePredictions({
            componentRestrictions: defaultRestrictions,
            types,
            ...opts
          })).predictions ?? []

          const detailedResults: Array<FullAddressParts | null> = await Promise.all(predictions.map(async p => {
            try {
              if (geocodingCache[p.place_id] == null) {
                geocodingCache[p.place_id] = await client.geocodeByPlaceId(p.place_id)
              }
              return geocodingCache[p.place_id]
            } catch (e) {
              return null
            }
          }))

          /**
           * sometimes google search returns duplicates in predictions
           * they can be filtered out when we receive details of each prediction
           */
          const resultSet = new Set<string>()

          result = detailedResults.reduce((acc: FullAddressParts[], curr) => {
            /**
             * county is optional that is why it is not checked
             */
            if (curr != null && Boolean(curr.city) && Boolean(curr.zipCode) && Boolean(curr.state) && Boolean(curr.address)) {
              const addressStr = fullAddress(curr)

              if (!resultSet.has(addressStr)) {
                acc.push(curr)
                resultSet.add(addressStr)
              }
            }

            return acc
          }, [])
        }
      } catch { /** silent error catching */ }

      return result as FullAddressParts[]
    }, debounce)
  }, [placesAutocompleteService, debounce, types])

  const getPlacePredictions = useCallback(
    async (opts: SearchOptions): Promise<FullAddressParts[]> => {
      setIsPlacePredictionsLoading(true)
      const res = (await searchPlacesDetails(opts)) ?? []
      setIsPlacePredictionsLoading(false)
      setPlacePredictions(res)
      return res
    }, [searchPlacesDetails])

  return {
    placesAutocompleteService,
    placePredictions,
    isPlacePredictionsLoading,
    getPlacePredictions
  }
}

export default usePlacesAutocomplete
