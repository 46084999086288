import { css } from '@emotion/css'
import { Responsive } from 'constants/constants'

export default css(`
  &&.cf-modal-container {

    .cf-modal-content-scroll-wrapper {
      padding: 0;
    }

    .cf-modal-title {
      color: var(--titleMainColor);
      @media screen and (max-width: ${Responsive.MobileMaxWidth}px){
        border-bottom: 1px solid rgba(0, 0, 0, 0.24);
      }

      .cf-icon-button {
        background-color: #FFFFFF;
      }
    }

    .cf-modal-content {
      display: flex;
      flex-direction: column;
      min-width: 334px;
      max-width: 480px; 
      padding: 40px 20px 0 20px;
      gap: 24px;
      
      @media screen and (min-width: ${Responsive.MobileMaxWidth}px) {
        margin: 40px 40px 0;
        padding: 0;
      }

      @media screen and (min-width: ${Responsive.WideMobileScreen}px) {
        min-width: 480px;
      }
    }

    .cf-step-text {
      display: flex;
      flex-direction: column;
      gap: 0 4px;
      margin: 0;
      color: #101010;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;

      @media screen and (min-width: ${Responsive.WideMobileScreen}px) {
        flex-direction: row;
      }
    }

    .cf-support-team-number {
      color: var(--mainColor);
      height: 24px;
      margin: 0;
    }
  }
`)
