import type { FC } from 'react'
import { BannerProps, SupportedComponents } from 'website/components/types'
import ExtendedTypography from 'website/components/_base/ExtendedTypography'
import FillerComponent from 'website/components/Filler'
import ExtendedImage from 'website/components/ExtendedImage'
import CLASS_NAME from './styles'

const Banner: FC<BannerProps> = ({ Text, Image, Filler }) => {
  return (
    <div className={SupportedComponents.Banner}>
      <div className={CLASS_NAME}>
        <ExtendedTypography {...Text.config} className='cf-banner-text'>
          {Text.value}
        </ExtendedTypography>
        {
          Filler != null && (
            <FillerComponent {...Filler} />
          )
        }
        <ExtendedImage {...Image} />
      </div>
    </div>
  )
}

export default Banner
