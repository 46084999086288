import { css } from '@emotion/css'

export default css(`
  background: #fff;
  padding: 40px 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media(min-width: 768px) {
    padding: 40px max(40px, calc((100vw - 1360px) / 2)) 16px;
  }

  .cf-customer-reviews-text {
    margin-bottom: 40px;
  }

  .cf-customer-reviews {
    min-height: 250px;
    position: relative;

    .g-with-opacity {
      opacity: 0;
    }
    
    .g-loading-wrapper {
      position: absolute;
      top: 0;
      left: 0
      background: white;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
`)
