import type { FC } from 'react'
import { IconProps } from 'website/components/types'

const ArrowDown: FC<IconProps> = ({ fill = 'white', width = 24, height = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.59 8.29688L12 12.8769L7.41 8.29688L6 9.70687L12 15.7069L18 9.70687L16.59 8.29688Z"
        fill={fill}
      />
    </svg>
  )
}

export default ArrowDown
