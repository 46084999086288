import { useEffect, useRef, useState, type FC } from 'react'
import { cnx } from '@carfluent/common'
import SvgIcon from 'website/components/_base/SvgIcon'
import { getCssVars } from 'website/configs'

export interface FilterSectionProps {
  label?: string
  className?: string
  isCollapsible?: boolean
}

const { filtersTransitionDuration = '150' } = getCssVars()

/**
 * there is a discrepancy between scrollHeight and height auto
 * for dropdown filters for some reason. 5px is a difference.
 * We subtract to avoid animatin jumps.
 */
const SCROLL_HEIGHT_FIX = 5

const FilterSection: FC<FilterSectionProps> = ({
  isCollapsible = false,
  children,
  className,
  label
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean | undefined>(undefined)
  const refEl = useRef<HTMLDivElement>(null)

  const onToggle = (): void => {
    if (isCollapsible) {
      setIsExpanded((prevExpanded = false) => !prevExpanded)
    }
  }

  useEffect(() => {
    const wrapper = refEl.current

    /**
     * if isExpanded is not set yet, do nothing
     */
    if (wrapper == null || isExpanded == null) {
      return
    }

    /**
     * height animation works only on specific values
     * that is why we set content height to real value from scrollHeight
     * on either way.
     */

    /**
     * expand flow: from 0px to real height -> wait for animation
     * to finish and then set height to 'auto' because content may
     * dynamicly change its height.
     */

    /**
     * collapse flow: from auto to real height ->
     * wait 1 frame to make sure height change is applied.
     * -> and only then set height to 0px
     */

    const height = wrapper.scrollHeight - SCROLL_HEIGHT_FIX
    requestAnimationFrame(() => {
      wrapper.style.height = `${height}px`
    })

    const onExpandTransitionEnd = (): void => {
      wrapper.style.height = 'auto'
    }

    let tmt: NodeJS.Timeout | null = null

    if (isExpanded) {
      tmt = setTimeout(onExpandTransitionEnd, Number(filtersTransitionDuration))
    } else {
      requestAnimationFrame(() => {
        wrapper.style.height = '0px'
      })
    }

    return (): void => {
      if (tmt != null) clearTimeout(tmt)
    }
  }, [isExpanded])

  return (
    <div
      className={cnx(
        'cf-filter-section',
        className,
        isCollapsible && 'is-collapsible',
        isCollapsible && isExpanded && 'is-expanded'
      )}
    >
      <div className='cf-filter-header' onClick={onToggle}>
        <p>{label ?? ''}</p>
        {
          isCollapsible && (
            <SvgIcon type='arrowDown' />
          )
        }
      </div>
      <div className='cf-filter-content' ref={refEl}>
        {children}
      </div>
    </div>
  )
}

export default FilterSection
