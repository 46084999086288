import type { FormValidation } from '@carfluent/common'
import { string } from 'website/utils/validation/presets'

export interface FormValues {
  vin: string
}

export const DEFAULT_FORM_VALUES: FormValues = {
  vin: ''
}

const createValidationRules = (): FormValidation<FormValues> => {
  return {
    vin: string
  }
}

const validationRules = createValidationRules()

export default validationRules
