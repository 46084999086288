import storage from './storage'
import { WORKFLOW_ID, WORKFLOW_VERSION } from 'website/constants/localStorageKeys'

export function setWorkflowId (id: number): void {
  storage.set(WORKFLOW_ID, id)
}

export function getWorkflowId (): number | null {
  const raw = storage.getUnparsed(WORKFLOW_ID)
  return (raw != null && !isNaN(parseFloat(raw)))
    ? parseFloat(raw)
    : null
}

export function removeWorkflowId (): void {
  storage.remove(WORKFLOW_ID)
}

export function setWorkflowVersion (version: string): void {
  storage.set(WORKFLOW_VERSION, version)
}

export function getWorkflowVersion (): string | null {
  return storage.get(WORKFLOW_VERSION)
}

export function removeWorkflowVersion (): void {
  storage.remove(WORKFLOW_VERSION)
}
