export default {
  UnsubscribedPage: {
    default: {
      root: `
        .UnsubscribedPageHeader {
          height: 156px;
          min-width: 600px;
        }

        h1 {
          font-weight: 500;
          margin-bottom: 16px;
        }
        
        .UnsubscribedSubTitle {
          .ExtendedTypography {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
          }
        }

        @media screen and (max-width: 768px) {
          .UnsubscribedPageHeader {
            margin-top: 44px;
            height: auto;
            min-width: 100%;
            > div:first-child {
              height: auto;
            }
          }
        }
      `
    }
  }
}
