import { type FC } from 'react'
import { IconProps } from 'website/components/types'

const ArrowRightIcon: FC<IconProps> = ({
  className,
  fill = '#C99B86'
}) => {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 12L10 18L8.59 16.59L13.17 12L8.59 7.41L10 6L16 12Z'
        fill={fill}
      />
    </svg>
  )
}

export default ArrowRightIcon
