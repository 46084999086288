import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  checkboxWithError: {
    '& .MuiIconButton-label': {
      border: '1px solid #B00020'
    }
  },
  rootFormControlLabel: {
    paddingLeft: 5,
    marginTop: 0,
    marginRight: 0
  },
  labelFormControlLabel: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.15,
    color: '#101010',
    marginLeft: 4
  },
  colorSecondary: {
    color: '#000000!important'
  }
})

export default useStyles
