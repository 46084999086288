import { ServiceType } from 'website/api/types'

export const ModalTexts = {
  [ServiceType.AskQuestion]: {
    title: 'Ask',
    intro: 'Enter your contact information and one of our representatives will get back to you with more information.',
    submitBtn: 'SEND REQUEST'
  },
  [ServiceType.TestDrive]: {
    title: 'Schedule a Test Drive',
    intro: 'Please fill out your information and when you would like to schedule a viewing of this vehicle.',
    submitBtn: 'SEND REQUEST'
  },
  [ServiceType.Financing]: {
    title: 'Get pre-qualified',
    intro: 'By answering a few questions, we can help you determine how much financing you may qualify for.',
    submitBtn: 'GET PRE-QUALIFIED'
  },
  [ServiceType.CarOffer]: {
    title: 'Get my offer',
    intro: 'Leave your contact details and one of our assistant will help you to get an offer and appraisal of your car. ',
    submitBtn: 'GET MY OFFER'
  }
}
