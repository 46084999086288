import { type FC } from 'react'
import { Button, cnx } from '@carfluent/common'
import { FiltersDrawerTogglerProps, SupportedComponents } from 'website/components/types'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import SvgIcon from './_base/SvgIcon'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import useMediaQueryByBreakpoints from 'website/hooks/useMediaQueryByBreakpoints'

const useSharedState = SharedStateHook<Store.VisibilityState>(StoreBranches.FiltersDrawerVisibility)

const FiltersDrawerToggler: FC<FiltersDrawerTogglerProps> = ({
  breakpoints,
  nameInLayout = SupportedComponents.FiltersDrawerToggler,
  startIcon = 'filters',
  text
}) => {
  const [, setDrawerState] = useSharedState(defaultInstance(StoreBranches.FiltersDrawerVisibility))
  const componentStyles = useComponentStyles(nameInLayout)

  const { isMatchingBreakpoints } = useMediaQueryByBreakpoints(breakpoints)

  if (isMatchingBreakpoints !== true) {
    return null
  }

  const onToggle = (): void => {
    setDrawerState(state => ({ isOpen: !state.isOpen }))
  }

  return (
    <Button
      className={cnx(nameInLayout, componentStyles.root)}
      onClick={onToggle}
      startAdornment={<SvgIcon type={startIcon} />}
      variant='text'
    >
      {text}
    </Button>
  )
}

export default FiltersDrawerToggler
