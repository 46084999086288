import { css } from '@emotion/css'

export default css(`
  background: #212121 !important;
  border-radius: 4px !important;
  padding: 4px 12px;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
  max-width: 280px;
`)
