import type { FC } from 'react'
import { IconProps } from 'website/components/types'

const SendIcon: FC<IconProps> = ({ fill = '#20C39E', width = 24, height = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_92337_42236)">
        <path
          d="M3.4 20.4005L20.85 12.9205C21.66 12.5705 21.66 11.4305 20.85 11.0805L3.4 3.60051C2.74 3.31051 2.01 3.80051 2.01 4.51051L2 9.12051C2 9.62051 2.37 10.0505 2.87 10.1105L17 12.0005L2.87 13.8805C2.37 13.9505 2 14.3805 2 14.8805L2.01 19.4905C2.01 20.2005 2.74 20.6905 3.4 20.4005Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_92337_42236">
          <rect width={width} height={height} fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default SendIcon
