import { type FC, type KeyboardEvent, type ChangeEvent, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { cnx } from '@carfluent/common'
import type { InputBaseComponentProps } from '@carfluent/common/dist/UI/Input/types'

import { updateEntryInQs } from 'website/utils/qsfilters'
import { type SearchBarProps, SupportedComponents } from 'website/components/types'
import Button from 'website/components/Button'
import Input from 'website/components/Input'

import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { WebsiteRoutes } from 'website/routing/constants'
import SvgIcon from 'website/components/_base/SvgIcon'

const SEARCH_INPUT_PROPS: InputBaseComponentProps = { inputMode: 'search' }

const SearchBar: FC<SearchBarProps> = ({
  nameInLayout,
  variant,
  template,
  layout,
  componentProps,
  className,
  shouldResetOnSearch = false,
  onSearch: _onSearch,
  shouldShowClearButton = false
}) => {
  const navigate = useNavigate()

  const componentStylesCls = useComponentStyles(SupportedComponents.SearchBar, variant)
  const templateCls = useLayoutStyles({ template, layout })
  const [inputText, setInputText] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onSearch = (): void => {
    navigate(WebsiteRoutes.Inventory)
    updateEntryInQs('text', inputText)

    if (shouldResetOnSearch) {
      setInputText('')
      inputRef.current?.blur()
    }
  
    _onSearch?.(inputText)
  }

  const onKeyPress = (evt: KeyboardEvent<HTMLInputElement>): void => {
    if (evt.key === 'Enter') {
      onSearch()
    }
  }
  
  const isEndAdornmentExist = inputText.trim() !== '' && shouldShowClearButton

  return (
    <div
      className={cnx(
        nameInLayout ?? SupportedComponents.SearchBar,
        componentStylesCls.root,
        className
      )}
    >
      <div className={cnx(componentStylesCls.content, templateCls)}>
        <Input
          {...componentProps.Input}
          value={inputText}
          className='g-input-search'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setInputText(e.target.value)
          }}
          onKeyUp={onKeyPress}
          startAdornment={<SvgIcon onClick={onSearch} type='search' />}
          endAdornment={(
            isEndAdornmentExist
              ? <SvgIcon className='close-btn' onClick={() => setInputText('')} type='close' />
              : null
          )}
          maxLength={100}
          inputRef={inputRef}
          inputProps={SEARCH_INPUT_PROPS}
        />

        <Button
          {...componentProps.Button}
          onClick={onSearch}
        />
      </div>
    </div>
  )
}

export default SearchBar
