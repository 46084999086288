import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

export const PhoneIcon: FC<IconProps> = ({
  fill = '#000',
  width = 20,
  height = 20
}) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_93674_189206)'>
      <path
        d='M10.1299 5.40213C12.0477 5.64336 13.5493 7.14499 13.7905 9.06274C13.8267 9.3703 14.086 9.59344 14.3876 9.59344C14.4117 9.59344 14.4358 9.59344 14.4599 9.58741C14.7916 9.54519 15.0268 9.24366 14.9846 8.91197C14.677 6.44543 12.7412 4.50959 10.2746 4.20203C9.94296 4.16584 9.64143 4.40104 9.60525 4.73273C9.56303 5.06441 9.79823 5.36595 10.1299 5.40213ZM10.3591 6.6746C10.0395 6.59017 9.70777 6.78315 9.62334 7.1088C9.53891 7.43446 9.73189 7.76012 10.0575 7.84455C10.6908 8.00737 11.1853 8.50189 11.3541 9.14114C11.4265 9.41252 11.6677 9.59344 11.9391 9.59344C11.9874 9.59344 12.0416 9.58741 12.0899 9.57534C12.4095 9.49091 12.6025 9.15923 12.5241 8.8396C12.2406 7.77218 11.4144 6.94598 10.3591 6.6746ZM13.9594 11.5594L12.4276 11.3845C12.0597 11.3423 11.6979 11.469 11.4386 11.7283L10.3289 12.8379C8.62225 11.9695 7.22314 10.5764 6.35472 8.86373L7.47039 7.74805C7.72971 7.48874 7.85635 7.1269 7.81414 6.75903L7.63925 5.2393C7.56688 4.63021 7.05428 4.17188 6.43915 4.17188H5.39585C4.71438 4.17188 4.1475 4.73876 4.18972 5.42022C4.50934 10.5704 8.62828 14.6833 13.7724 15.0029C14.4539 15.0451 15.0208 14.4783 15.0208 13.7968V12.7535C15.0268 12.1444 14.5685 11.6318 13.9594 11.5594Z'
        fill={fill}
        fillOpacity='0.38'
      />
    </g>

    <defs>
      <clipPath id='clip0_93674_189206'>
        <rect width={width} height={height} fill='white'/>
      </clipPath>
    </defs>
  </svg>
)

export default PhoneIcon
