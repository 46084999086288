import { type FC } from 'react'
import capitalize from 'lodash-es/capitalize'
import type { GeneratedFilterProps } from 'website/configs'
import { Item, type OptionRendererConfig } from '@carfluent/common/dist/UI/Dropdown/types'
import getName from 'website/utils/getName'
import { Dropdown2 } from '@carfluent/common'
import Checkbox from 'website/components/_base/Checkbox'
import { POPOVER_STYLES } from 'website/components/VehiclesFilter/styles'

const renderMultiselectOption = (item: API.ListFilterItem, { isSelected }: OptionRendererConfig): JSX.Element => {
  return (
    <div className='cf-list-filter-item-label'>
      <Checkbox value={isSelected} />
      <span>{getName(item.name)}</span>
      <span className='cf-list-filter-item-count'>{item.count}</span>
    </div>
  )
}

const renderOption = (item: API.ListFilterItem): JSX.Element => {
  return (
    <div className='cf-list-filter-item-label'>
      <span>{getName(item.name)}</span>
      <span className='cf-list-filter-item-count'>{item.count}</span>
    </div>
  )
}

const DisplayPlaceholder: FC<{ placeholder: string }> = ({ placeholder }) => (
  <div className='cf-dropdown-filter-placeholder'>{placeholder}</div>
)

const isOptionDisabled = (item: Item<API.ListFilterItem>): boolean => {
  return item.value.count === 0
}

const DropdownFilter: FC<GeneratedFilterProps<API.ListFilter>> = ({
  applied,
  config,
  disableSelectAll = true,
  disabled = false,
  id,
  isMultiselect = false,
  listSeparator = ';',
  onChange: _onChange,
  placeholder: _placeholder,
  type
}) => {
  const onChange = (_id: string, val: API.ListFilterItem | API.ListFilterItem[] | null): void => {
    if (Array.isArray(val)) {
      const newVal = val.map(v => getName(v.name)).join(listSeparator)
      _onChange?.(_id, newVal, type)
    } else {
      const name = val == null ? null : getName(val.name)
      _onChange?.(_id, name ?? '', type, name != null)
    }
  }

  const selectedValues = config.items.filter(item => {
    const name = getName(item.name)
    return applied?.items.some(v => v.name === name) ?? false
  })

  const placeholder = _placeholder ?? `Select ${capitalize(id)}`
  /**
   * DD-NOTE: multiselect does not support search for now
   * also in select mode placeholder is not populated to Display element
   */
  const mode = isMultiselect ? 'select' : 'search'
  const startAdornment = (mode === 'select' && selectedValues.length === 0)
    ? <DisplayPlaceholder placeholder={placeholder} />
    : undefined

  return (
    <div className='cf-dropdown-filter'>
      <Dropdown2
        id={id}
        disabled={disabled}
        disableSelectAll={disableSelectAll}
        popoverClassName={POPOVER_STYLES}
        isMultiselect={isMultiselect}
        isOptionDisabled={isOptionDisabled}
        mode={mode}
        onChange={onChange}
        options={config.items}
        placeholder={placeholder}
        renderOption={isMultiselect ? renderMultiselectOption : renderOption}
        startAdornment={startAdornment}
        value={(isMultiselect ? selectedValues : selectedValues[0]) ?? null}
      />
    </div>
  )
}

export default DropdownFilter
