import { FC } from 'react'
import SectionRowItem from '../shared/section_row_item'
import CollapsedSection from '../shared/collapsed_section'
import { formatCurrency } from 'utils/format_number'
import { SummarySectionClasses } from '../types'

interface TradeInSectionProps {
  tradeInCredit: number | null
  tradeInPayoff: number | null
  classes?: SummarySectionClasses
}

const TradeInSection: FC<TradeInSectionProps> = (props) => {
  const { tradeInCredit, tradeInPayoff, classes } = props

  const negativeTradeInAmount = tradeInCredit !== null ? -Math.abs(tradeInCredit) : tradeInCredit

  const totalTradeInSum = Number(tradeInCredit) * -1 + Number(tradeInPayoff)
  const isTotalTradeInSumNegative = totalTradeInSum < 0
  const hasCollapsedContent = Boolean(totalTradeInSum)

  return (
    <CollapsedSection
      label='Net Trade In value'
      value={formatCurrency(totalTradeInSum, '-', isTotalTradeInSumNegative)}
      hasCollapsedContent={hasCollapsedContent}
      classes={classes}
    >
      <SectionRowItem
        label='Trade In credit'
        value={formatCurrency(negativeTradeInAmount ?? 0, '-', true)}
        hasLeftSpace
        classes={classes}
      />
      <SectionRowItem
        label='Payoff'
        value={formatCurrency(tradeInPayoff ?? 0)}
        hasLeftSpace
        classes={classes}
      />
    </CollapsedSection>
  )
}

export default TradeInSection
