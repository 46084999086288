import type { FC } from 'react'
import arrowTopIcon from 'assets/arrow_top.svg'
import arrowBottomIcon from 'assets/arrow_bottom.svg'

interface CollapsedArrowIconProps {
  isCollapseOpened: boolean
  className?: string
}

const CollapsedArrowIcon: FC<CollapsedArrowIconProps> = (props) => {
  const { isCollapseOpened, className = '' } = props

  return (
    <img
      src={isCollapseOpened ? arrowTopIcon : arrowBottomIcon}
      className={className}
      alt='Arrow'
    />
  )
}

export default CollapsedArrowIcon