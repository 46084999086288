import queryString from 'query-string'
import { LAYOUT_CONTENT_CLASS } from 'components/layout/constants'

export { touchify } from '@carfluent/common'

export const isNullOrEmpty = (value: string | null): boolean => {
  return value == null || value === ''
}

export const setSpaceBeforeCamelCase = (value: string | null): string => {
  return value != null
    ? value.replace(/([A-Z])/g, ' $1')
    : ''
}

export const scrollToError = (): void => {
  const elements = document.querySelectorAll('.Mui-error, .cf-form-field-error, .with-error')
  const firstElement = Array.from(elements).find(element => element instanceof HTMLElement)

  firstElement?.scrollIntoView({ block: 'center' }) // "smooth" does not work here
}

export const scrollToContentTop = (): void => {
  const el = document.querySelector(`.${LAYOUT_CONTENT_CLASS}`)
  el?.scrollTo(0, 0)
}

export const isFalsy = (value: any): boolean => {
  const bool = Boolean(value)
  return !bool
}

export const isTruthy = (value: any): boolean => {
  return Boolean(value)
}

export const getSearchParams = (): Record<string, any> => {
  const decodedSearch = decodeURIComponent(window.location.search ?? '')

  return queryString.parse(decodedSearch, { decode: false })
}

export const getAccessTokenFromSearch = (): string => {
  const { accessToken } = getSearchParams()

  return accessToken
}
