import { css } from'@emotion/css'

export default css`
  .bubble {
    width: 64px;
    height: 40px;
    background-color: #E9F2FA;
    border-radius: 12px;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .dot {
    width: 9px;
    height: 9px;
    background-color: #000;
    border-radius: 50%;
    animation: updownblink 1s infinite both;
  }

  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes updownblink {
    0%, 100% {
      transform: translateY(0);
      opacity: .12;
    }
    25% {
      opacity: .08;
    }
    50% {
      transform: translateY(-8px);
      opacity: .12;
    }
    75% {
      opacity: .08;
    }
  }
`
