/**
 * If dealerId is not set in .env, then this is a multirooftop build
 */
const isMultirooftop = (): boolean => {
  const dealerId = process.env.REACT_APP_DEALERSHIP_ID
  return dealerId == null || dealerId === ''
}

export const isMainHeadquarters = (): boolean => {
  return isMultirooftop() && process.env.REACT_APP_HEADQUARTERS_ID === '1'
}

export default isMultirooftop
