import { css } from '@emotion/css'

export default css`
  width: 100%;
  height: 100%;
  position: fixed;
  background: white;
  display: flex;
  flex-direction: column;
 
  @media screen and (min-width: 1024px) {
    width: 375px;
    height: 540px;
    right: 20px;
    bottom: 20px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.20);
  }
  
  .chat-header {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #20C39E;
    color: white;
    padding: 0 16px;
    cursor: pointer;
    
    @media screen and (min-width: 1024px) {
      position: relative;
      z-index: 4;
    }
  }
  
  .chat-content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    padding: 16px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
  }
  
  .chat-content::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .chat-textarea-wrapper {
    position: relative;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;

    textarea {
      padding-right: 56px;
      background: #fff;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.5px;
      font-family: Roboto;

      &::placeholder {
        color: rgba(33, 33, 33, 0.4);
      }
    }
    
    .chat-send-icon {
      height: 56px;
      width: 48px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      position: absolute;
      box-sizing: border-box;
      top: 0;
      right: 0;
      cursor: pointer;
      
      &.disabled {
        opacity: .3;
        cursor: not-allowed;
      }
    }
  }
  
  .request-call-button {
    &.cf-button-root.cf-button-variant-outlined.cf-button-color-primary {
      height: 48px;
      width: 100%;
      background: white;
      border-radius: 0;
      border: 1px solid rgba(0, 0, 0, 0.08);
      
      .cf-button-content {
        font-weight: 500;
      }
    }
  }
`
