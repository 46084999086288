import { useCallback, useEffect, useState } from 'react'
import { useForm } from '@carfluent/common'
import axios from 'axios'

import apiProvider from 'website/api/apiProvider'
import { onlyNumbers } from 'utils/format_number'
import { getSalesPhone } from 'website/utils/sales'
import SharedStateHook, { StoreBranches } from 'website/store'

import type { LoginFlowStep, LoginFormData, UseLoginFlowReturn } from './types'
import validationRules from './validation'
import useLoginTracker from './useLoginTracker'
import { getErrorMessage, ERROR_MESSAGE } from './utils'

const getDefaultFormData = (): LoginFormData => ({
  phoneNumber: ''
})

const DEFAULT_FORM_DATA = getDefaultFormData()
const useSharedDealership = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

export const useLoginFlow = (): UseLoginFlowReturn => {
  const { isLoginFlow, onClose: _onClose } = useLoginTracker()
  const [dealerInfo] = useSharedDealership('dealership')
  const [errorPhoneNumber, setErrorPhoneNumber] = useState<string>('')
  const [loginStep, setLoginStep] = useState<LoginFlowStep | null>(isLoginFlow ? 'login' : null)
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const submitAction = useCallback(async (values: LoginFormData) => {
    setErrorPhoneNumber('')

    try {
      const phoneNumber = onlyNumbers(values.phoneNumber)
      setPhoneNumber(phoneNumber)
      await apiProvider.identity.sendVerificationCode({ phoneNumber })

      setLoginStep('code')
    } catch (err: any) {
      const msg = axios.isAxiosError(err) ? getErrorMessage(err.message) : ERROR_MESSAGE
      setErrorPhoneNumber(msg)
    }
  }, [])

  const form = useForm<LoginFormData>({
    baseValues: DEFAULT_FORM_DATA,
    validationRules,
    submitAction
  })

  const { setFieldError, resetForm } = form

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClose = useCallback(() => {
    resetForm()
    _onClose()
  }, [_onClose, resetForm])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (errorPhoneNumber !== '') {
      setFieldError('phoneNumber', errorPhoneNumber)
    }
  }, [errorPhoneNumber, setFieldError])

  useEffect(() => {
    if (!isLoginFlow) {
      setLoginStep(null)
    } else {
      setLoginStep('login')
    }
  }, [isLoginFlow])

  // ========================================== //

  return {
    ...form,
    loginStep,
    setLoginStep,
    supportPhoneNumber: getSalesPhone(dealerInfo),
    phoneNumber,
    onClose,
    isLoginFlow
  }
}

export default useLoginFlow
