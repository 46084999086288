import { makeStyles } from '@material-ui/core/styles'

const flexBaseSettings = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
} as const

export const useStyles = makeStyles({
  clickable: {
    cursor: 'pointer'
  },
  collapseContainer: {
    ...flexBaseSettings,
    cursor: 'pointer'
  },
  row: flexBaseSettings,
  leftSpace: {
    ...flexBaseSettings,
    paddingLeft: '15px'
  },
  text: {
    letterSpacing: 0.5,
    alignItems: 'center',
    color: '#101010'
  },
  iconWrapper: {
    margin: '0 0 1px 10px'
  },
  collapseContent: {
    width: '100%'
  },
  defaultGap: {
    gap: '10px',
    display: 'grid'
  }
})
