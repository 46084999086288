import { useMemo, type FC } from 'react'
import { useParams } from 'react-router-dom'

import Banner from 'website/components/Banner'
import { type BannerProps } from 'website/components/types'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'

import dealership1Banner from 'website/assets/dealership1-banner.png'
import dealership2Banner from 'website/assets/dealership2-banner.png'
import dealership3Banner from 'website/assets/dealership3-banner.png'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const images = [dealership1Banner, dealership2Banner, dealership3Banner]

const DealershipLocationBanner: FC<BannerProps> = ({ Text, Image, ...rest }) => {
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const { locationId } = useParams()
  const [image, dealership] = useMemo(() => {
    const dealershipIndex = dealerInfo.dealerships.findIndex(el => el.id === Number(locationId))
    const image = images[dealershipIndex % images.length]

    return [image, dealerInfo.dealerships[dealershipIndex]]
  }, [locationId, dealerInfo])

  return (
    <Banner
      Text={{
        ...Text,
        value: dealership?.city ?? ''
      }}
      Image={{
        ...Image,
        src: image
      }}
      {...rest}
    />
  )
}

export default DealershipLocationBanner
