import { applyValidators, omitNotNumbers } from '@carfluent/common'
import type { ValidationRule } from '@carfluent/common/dist/hooks'

import {
  Errors,
  ErrorsMsg,
  BIRTHDATE_RANGE_START,
  BIRTHDATE_RANGE_END,
  MAX_AMOUNT,
  MIN_AMOUNT,
  MIN_SSN_AMOUNT
} from 'website/utils/validation/constants'
import type { AddressData, FullAddressParts, FullAddressValidation } from 'components/AddressAutocomplete/types'
import { ValidationLength } from 'constants/validation/constants'

import {
  exactLength,
  greaterThanOrEqual,
  isEmailValid,
  isNameValid,
  isRequired,
  isValidDateOrEmpty,
  lessThanOrEqual,
  greaterThanOrEqualDateForStingDate,
  lessThanOrEqualDateForStingDate,
  addressData as addressDataUtil,
  isDateRequired,
  isConditionExist,
  ruleRequired
} from '../rules'
import { ConditionData } from 'website/components/types'
import { isStringNotEmpty } from 'website/utils/isStringEmpty'

const requiredValidators = [
  isRequired(Errors.Empty)
]

const firstNameValidators = [
  ...requiredValidators,
  isNameValid(Errors.OnlyLatin),
  greaterThanOrEqual(ErrorsMsg.minName, ValidationLength.NAME_MIN),
  lessThanOrEqual(Errors.LongFirstName, ValidationLength.FIRST_NAME_MAX)
]

const lastNameValidators = [
  ...requiredValidators,
  isNameValid(Errors.OnlyLatin),
  greaterThanOrEqual(ErrorsMsg.minName, ValidationLength.NAME_MIN),
  lessThanOrEqual(Errors.LongLastName, ValidationLength.LAST_NAME_MAX)
]

const emailValidators = [
  isRequired(Errors.Empty),
  greaterThanOrEqual(Errors.ShortEmail, ValidationLength.EMAIL_SHORT),
  lessThanOrEqual(Errors.LongEmail, ValidationLength.EMAIL_MAX),
  isEmailValid(Errors.Email)
]

const phoneNumberValidators = [
  isRequired(Errors.Empty),
  exactLength(ErrorsMsg.phoneNumber, ValidationLength.PHONE_NUMBER)
]

export const dateTimeValidators = [
  isValidDateOrEmpty(Errors.InvalidDate)
]

export const birthDateValidators = [
  isDateRequired(Errors.InvalidDate),
  greaterThanOrEqualDateForStingDate(ErrorsMsg.birthdayMin, BIRTHDATE_RANGE_END),
  lessThanOrEqualDateForStingDate(ErrorsMsg.birthdayMax, BIRTHDATE_RANGE_START)
]

export const addressDataValidators = [
  addressDataUtil(Errors.InvalidAddress)
]

export const numberValidators = [
  isRequired(Errors.Empty)
]

export const zipCodeValidators = [
  isRequired(Errors.Empty),
  greaterThanOrEqual(Errors.ZipCode, ValidationLength.ZIP_CODE)
]

export const amountValidators = [
  ...numberValidators,
  greaterThanOrEqual(ErrorsMsg.minAmount, MIN_AMOUNT),
  lessThanOrEqual(ErrorsMsg.maxAmount, MAX_AMOUNT)
]

export const conditionValidators = [
  isConditionExist(Errors.InvalidCondition)
]

export const conditionWithSubConditionValidators = [
  isConditionExist(Errors.InvalidCondition, true)
]

export const aptValidators = [
  lessThanOrEqual(ErrorsMsg.maxAmount, ValidationLength.APT_MAX)
]

export const ssnValidators = [
  ...numberValidators,
  greaterThanOrEqual(Errors.SsnNumber, MIN_SSN_AMOUNT)
]

export const ssn = (val?: string) => applyValidators(
  val, ssnValidators
)

export const number = (val?: string): string | null => applyValidators(
  val, numberValidators
)

export const email = (val: string): string | null => applyValidators(
  val, emailValidators
)

export const firstName = (val?: string): string | null => applyValidators(
  val, firstNameValidators
)

export const lastName = (val?: string): string | null => applyValidators(
  val, lastNameValidators
)

export const phoneNumber = (val?: string): string | null => applyValidators(
  omitNotNumbers(val), phoneNumberValidators
)

export const dateTime = (val?: string): string | null => applyValidators(
  val, dateTimeValidators
)

export const birthDate = (val?: string): string | null => applyValidators(
  val, birthDateValidators
)

export const addressData = (val?: FullAddressParts): string | null => applyValidators(
  val, addressDataValidators
)

export const condition = (val?: ConditionData): string | null => applyValidators(
  val, conditionValidators
)

export const conditionWithSubCondition = (val?: ConditionData): string | null => applyValidators(
  val, conditionWithSubConditionValidators
)

export const string = (val?: string): string | null => applyValidators(
  val, requiredValidators
)

export const amount = (val?: number): string | null => applyValidators(
  val, amountValidators
)

export const apt = (val?: string): string | null => applyValidators(
  val, aptValidators
)

export const zipCode = (val: string): string | null => applyValidators(
  val, zipCodeValidators
)

export const requiredField = ruleRequired(Errors.Empty)

const addressDataFullValidators = <T>(isRequired = true): Array<ValidationRule<T, FullAddressValidation>> => ([
  (val: AddressData | null): FullAddressValidation | null => {
    const { city, zipCode, state, address } = (val ?? {})

    const hasCity = isStringNotEmpty(city)
    const hasZipCode = isStringNotEmpty(zipCode)
    const hasState = isStringNotEmpty(state)
    const hasAddress = isStringNotEmpty(address)

    const isFull = hasCity && hasZipCode && hasState && hasAddress
    const isPartial = hasCity || hasZipCode || hasState || hasAddress

    const isValid = isRequired ? isFull : (isFull || !isPartial)
    return isValid
      ? {
          city: null,
          zipCode: null,
          state: null,
          address: null
        }
      : {
          city: hasCity ? null : Errors.Empty,
          zipCode: hasZipCode ? null : Errors.Empty,
          state: hasState ? null : Errors.Empty,
          address: hasAddress ? null : Errors.Empty
        }
  }
])

export const addressDataFull = <T>(
  isRequired = true
) => (val?: AddressData | null): FullAddressValidation | null =>
    applyValidators<T, FullAddressValidation>(val, addressDataFullValidators<T>(isRequired))
