import { AvailableTemplates } from '../../types'
import mainPageTemplate from './mainPageTemplate.json'
import aboutUsPageTemplate from './aboutUsPageTemplate.json'
import assetsForMainPage from './assets/mainPage'
import assetsForAboutUsPage from './assets/aboutUsPage'

export const aboutUsPromosForMainPage = {
  name: AvailableTemplates.AboutUsPromosForMainPageTemplate,
  template: mainPageTemplate,
  assets: assetsForMainPage
}

export const aboutUsPromosForAboutUsPage = {
  name: AvailableTemplates.AboutUsPromosForAboutUsPageTemplate,
  template: aboutUsPageTemplate,
  assets: assetsForAboutUsPage
}
