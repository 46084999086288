import replacePlaceholders from './replacePlaceholders'

export type TranslateFn = (str: string) => string

const getTranslator = (dict: KeyVal): TranslateFn => {
  const recursiveTranslate = (str: string, passes = 0): string => {
    const res = replacePlaceholders(dict, str)

    if (res.includes('{{')) {
      return passes > 1 ? res : recursiveTranslate(res, passes + 1)
    }

    return res
  }

  return recursiveTranslate
}

export default getTranslator
