import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  sidebar: {
    maxWidth: 290,
    paddingTop: 60,
    paddingBottom: 35,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 60px)',

    [theme.breakpoints.down('md')]: {
      minWidth: '100vw',
      paddingLeft: '24px',
      alignItems: 'flex-start',
      marginTop: 1,
      paddingTop: 16,
      overflow: 'auto'
    }
  },

  carInfo: {
    display: 'flex',

    [theme.breakpoints.down(993)]: {
      display: 'none'
    }
  }
}))
