import { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UI, cn, formatInteger } from '@carfluent/common'

import { WorkflowStepName } from 'api/types'
import { getAbsolutePath } from 'routing/constants'
import type { GetStartedProps } from 'website/components/types'
import AuthCTX from 'store/auth'

import CLASS_NAME from './styles'

const { Button } = UI

interface LocationState {
  modelTrim: string
  salePrice: number
  vehicleStateYearMake: string
  mainImageUrl: string
  odometer: string
  dealerId: string
  vin?: string | null
  vehicleId: number
}

const getLocationState = (vehicle: GetStartedProps['vehicle'], dealerId: string): Partial<LocationState> => {
  if (vehicle == null) {
    return { dealerId }
  }

  return {
    modelTrim: `${vehicle.model ?? ''} ${vehicle.trim ?? ''}`,
    salePrice: vehicle.salePrice ?? 0,
    vehicleStateYearMake: `${vehicle.year ?? ''} ${vehicle.make ?? ''}`,
    mainImageUrl: vehicle.mainImageUrl ?? '',
    odometer: `${formatInteger(vehicle.mileage ?? 0, { emptyValues: '0' })} miles`,
    dealerId,
    vehicleId: vehicle.id,
    vin: vehicle.vin
  }
}

const GetStartedButton: FC<GetStartedProps> = ({
  nameInLayout = 'GetStartedButton',
  dealerId,
  className,
  vehicle
}) => {
  const navigate = useNavigate()
  const { isAuthorized } = useContext(AuthCTX)

  const onGetStartedClick = async (): Promise<void> => {
    if (isAuthorized) {
      navigate(
        getAbsolutePath(WorkflowStepName.PersonalDetails),
        { state: { shouldInitWorkflow: true, ...getLocationState(vehicle, dealerId) } }
      )
    } else {
      navigate(
        getAbsolutePath(WorkflowStepName.YourDetails),
        { state: getLocationState(vehicle, dealerId) }
      )
    }
  }

  return (
    <div className={cn(nameInLayout, className, CLASS_NAME)}>
      <Button
        className='cf-get-started-button'
        onClick={onGetStartedClick}
      >
        Get Started
      </Button>
    </div>
  )
}

export default GetStartedButton
