import { css } from '@emotion/css'

export default css(`
  grid-area: DealerInfoFragment;

  a {
    display: flex;
  }

  .phone-block, .address-block {
    text-decoration: none;
    color: #000;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
`)
