import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import { type GeneratedFilterProps } from 'website/configs'
import getName from 'website/utils/getName'

const TileFilter: FC<GeneratedFilterProps<API.ListFilter>> = ({
  applied,
  config,
  id,
  nameToImgMap,
  onChange: _onChange,
  type
}) => {
  const onClick = (itemToChange: API.ListFilterItem, isRemoveFromList: boolean): void => {
    const name = getName(itemToChange.name)
    _onChange?.(id, name, type, isRemoveFromList)
  }

  return (
    <div className='cf-tile-filter'>
      {config.items
        .map(item => {
          const name = getName(item.name)
          const isSelected = applied?.items.some(v => v.name === name) ?? false
          const imgSrc = nameToImgMap?.get(name)
          const isDisabled = item.count == null || item.count === 0

          return (
            <div
              key={name}
              className={cnx(
                'cf-tile-filter-item',
                isSelected && 'is-selected',
                isDisabled && 'is-disabled'
              )}
              {...(!isDisabled && { onClick: () => onClick(item, isSelected) })}
            >
              <span className='cf-tile-item-name'>{name}</span>
              {
                imgSrc != null && <img src={imgSrc} alt={name} />
              }
              <span className='cf-tile-item-count'>{item.count ?? 0}</span>
            </div>
          )
        })}
    </div>
  )
}

export default TileFilter
