import { css } from '@emotion/css'
import { Responsive } from 'website/constants'

export default css(`
  --player-border-size: 80px;
  --player-border-size-mobile: 16px;
  --player-border-top-size-mobile: 56px;

  position: fixed;
  inset: 0px;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  overflow: hidden;

  .video-player-modal-content {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    backdrop-filter: blur(7px);
    background: rgba(0, 0, 0, 0.8);
    transition-timing-function: ease-in;
    padding: var(--player-border-size);
     
    @media screen and (max-width: ${Responsive.TabletGallery}px) {
      padding: var(--player-border-size-mobile);
      padding-top: var(--player-border-top-size-mobile);
    }
  }
  
  .btn-close {
    position: absolute;
    top: calc(var(--player-border-size) / 2);
    right: calc(var(--player-border-size) / 2);
    cursor: pointer;
    z-index: 100;
    
    @media screen and (max-width: ${Responsive.TabletGallery}px) {
      top: var(--player-border-size-mobile);
      right: var(--player-border-size-mobile);
    }
  }
  
  .video-player-container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-player {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    z-index: 9999;
    
    &:fullscreen {
      z-index: 9999 !important;
    }
  }
`)
