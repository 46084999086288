const deepEqual = (a: unknown, b: unknown): boolean => {
  /**
   * handles same reference, same primitive value,
   * null-null, undefined-undefined cases
   */
  if (a === b) return true

  // If a and b are of different types, they are not equal
  if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
    return false
  }

  const keysA = Object.keys(a) as Array<keyof typeof a>
  const keysB = Object.keys(b) as Array<keyof typeof b>

  if (keysA.length !== keysB.length) {
    return false
  }

  for (const key of keysA) {
    if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
      return false
    }
  }

  return true
}

export default deepEqual
