import { JsonTemplateData } from 'website/siteGenerator/types'
import setPropsFromTarget from './setPropsFromTarget'
import replacePlaceholders from './replacePlaceholders'
import getTranslator from './getTranslator'

/**
 * Replaces template variables by real values (from assets)
 * and adds processed templates to the templates cache.
 */
const fillTemplateVariables = <T extends string>(
  templates: Array<JsonTemplateData<T>>,
  parsedTemplatesCache: Record<string, string>,
  i18nDict: KeyVal
): void => {
  const translate = getTranslator(i18nDict)

  for (const { template, assets, name } of templates) {
    /**
     * Resolves sub-templates variables.
     * Some "assets" contains not resources, but a name of template.
     * So we fill such assets by a corresponding templates' content.
     */
    const mergedAssets = setPropsFromTarget(assets, parsedTemplatesCache)

    /**
     * As we replace placeholders we cache parsed templates.
     * this way we reuse them in other templates.
     */
    parsedTemplatesCache[name] = replacePlaceholders(mergedAssets, template)
  }

  /**
   * Resolves i18n variables.
   * We only need to translate root template, since at this moment it already
   * contains the whole site config.
   */
  if ('rootTemplate' in parsedTemplatesCache) {
    parsedTemplatesCache.rootTemplate = translate(parsedTemplatesCache.rootTemplate)
  }
}

export default fillTemplateVariables
