import { type FC } from 'react'

import { type CoverageSummary } from 'api/types/coverage.types'
import { formatCurrency } from 'utils/format_number'
import { COVERAGE_LABELS } from 'constants/names'

import SectionRowItem from '../shared/section_row_item'
import CollapsedSection from '../shared/collapsed_section'
import { SummarySectionClasses } from '../types'

interface CoveragesSectionProps {
  coverageDetails: CoverageSummary[]
  classes?: SummarySectionClasses
}

const CoveragesSection: FC<CoveragesSectionProps> = (props) => {
  const { coverageDetails, classes } = props

  const totalCoverageSum = coverageDetails.reduce((a, b) => a + b.dealerRetailPrice, 0)

  const hasCollapsedContent = Boolean(totalCoverageSum)

  return (
    <CollapsedSection
      label='Coverage'
      value={formatCurrency(totalCoverageSum)}
      hasCollapsedContent={hasCollapsedContent}
      classes={classes}
    >
      {coverageDetails.map(coverage => {
        return (
          <SectionRowItem
            key={coverage.productType}
            label={COVERAGE_LABELS[coverage.productType] ?? '-'}
            value={formatCurrency(coverage.dealerRetailPrice)}
            hasLeftSpace
            classes={classes}
          />
        )
      })}
    </CollapsedSection>
  )
}

export default CoveragesSection
