import type { FC } from 'react'
import { useCallback, useEffect } from 'react'
import { cn, useModal, Modal } from '@carfluent/common'

import type { ConditionData, ConditionProps, ConditionSubOptionProps } from 'website/components/types'
import { SupportedComponents } from 'website/components/types'
import alertIcon from 'website/assets/alert_icon.svg'
import alertBlackIcon from 'website/assets/alert_icon_black.svg'

import Option from './components/Option'
import SubOptions from './components/SubOptions'
import uncheckedIcon from 'website/assets/rect_checkbox_icon_unchecked.svg'
import checkedIcon from 'website/assets/rect_checkbox_icon_checked.svg'

import { preloadImages } from './utils'
import CLASS_NAME, { MODAL_CLASS_NAME } from './styles'

const isEven = (value: number): boolean => value / 2 === 0

const Condition: FC<ConditionProps> = ({
  subOptionsType = 'checkbox',
  nameInLayout = SupportedComponents.Condition,
  title,
  value: conditionData,
  options,
  onChange = (_id: string, _value: ConditionData) => {},
  id,
  error,
  touched
}) => {
  const {
    isModalOpen: isConditionModalOpen,
    onOpenModal: onConditionOpenModal,
    onCloseModal: onConditionCloseModal
  } = useModal()
  const { value, subOptionValue } = conditionData ?? {}

  const onClick = useCallback((value: number, subOptions?: ConditionSubOptionProps[]) => {
    const subOptionValue = subOptions?.[0].value

    onChange(id, {
      value,
      subOptionValue: (subOptionValue != null && subOptionsType !== 'checkbox') ? [subOptionValue] : []
    })
  }, [onChange])

  const onSubOptionChange = useCallback((value: number) => {
    if (subOptionsType === 'checkbox') {
      const newSubOptionValue = [...(subOptionValue ?? [])]

      const isExist = subOptionValue?.includes(value)

      if (isExist === true) {
        const index = newSubOptionValue.findIndex((val) => val === value)
        newSubOptionValue.splice(index, 1)

        onChange(id, {
          value: conditionData?.value ?? null,
          subOptionValue: newSubOptionValue
        })

        return
      }

      newSubOptionValue.push(value)
      onChange(id, {
        value: conditionData?.value ?? null,
        subOptionValue: newSubOptionValue
      })

      return
    }

    onChange(id, {
      value: conditionData?.value ?? null,
      subOptionValue: [value]
    })
  }, [conditionData, subOptionsType, onChange])

  useEffect(() => {
    void preloadImages([checkedIcon, uncheckedIcon])
  }, [])

  const selectedOption = options.find(({ value: val }) => val === value)
  const subOptions = selectedOption?.subOptions ?? []

  const isErrorExist = error != null && typeof error === 'string' && touched === true
  const isConditionDescriptionExist = options.some(({ description }) => description != null)
  const optionClassName = isEven(options.length) ? 'cf-even' : 'cf-odd'

  return (
    <div className={cn(nameInLayout, CLASS_NAME, 'g-tradein-condition')}>
      <div className='cf-title-wrapper'>
        <div className='cf-condition-title'>
          <p>
            {title}

            {isConditionDescriptionExist && (
              <img
                onClick={onConditionOpenModal}
                className='cf-black-alert-icon'
                src={alertBlackIcon}
                alt='alert'
              />
            )}
          </p>
        </div>

        {isErrorExist && (
          <div className='cf-error-wrapper'>
            <img src={alertIcon} alt='error' />

            {error}
          </div>
        )}
      </div>

      <div className='cf-condition-button-wrapper'>
        {options.map((option) => (
          <Option
            {...option}
            className={optionClassName}
            isActive={value === option.value}
            key={option.value}
            onClick={onClick}
          />
        ))}
      </div>
      {subOptions.length > 0 && (
        <SubOptions
          value={subOptionValue}
          onChange={onSubOptionChange}
          options={subOptions}
          type={subOptionsType}
        />
      )}

      {isConditionDescriptionExist && (
        <Modal
          className={MODAL_CLASS_NAME}
          isOpen={isConditionModalOpen}
          onClose={onConditionCloseModal}
          title='Car condition'
        >
          {options.map(({ title, description, value }) => (
            <div className='cf-condition-block' key={value}>
              <p className='cf-name'>{title}</p>
              <p>{description}</p>
            </div>
          ))}
        </Modal>
      )}
    </div>
  )
}

export default Condition
