import { COLORS } from 'website/configs'

const VEHICLE_COLOR_MAP: Record<typeof COLORS[number], string> = {
  Beige: '#eed9c4',
  Black: '#000000',
  Blue: '#6A8BF4',
  Brown: '#A78276',
  Burgundy: '#A64B44',
  Gold: '#C99B86',
  Gray: '#727272',
  Green: '#84D089',
  Orange: '#EE9764',
  Purple: '#B362F4',
  Red: '#D06057',
  Silver: '#DBDBDB',
  Tan: '#C1A288',
  Teal: '#80CABA',
  White: '#EDEDED',
  Yellow: '#F6DD77',
  Other: 'transparent'
}

export default VEHICLE_COLOR_MAP
