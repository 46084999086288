import { RefObject, useEffect, useRef } from 'react'
import intersectionObserver from 'website/services/intersectionObserver'

interface UseWithIntersectionObserverReturn<T extends HTMLElement> {
  elRef: RefObject<T>
}

const useWithIntersectionObserver = <T extends HTMLElement>(
  key?: string,
  handler?: ((key: string) => void) | null,
  lazyLoad = true,
  markAsPending = false
): UseWithIntersectionObserverReturn<T> => {
  const elRef: RefObject<T> = useRef(null)

  useEffect(() => {
    if (!intersectionObserver.isSupported() || !lazyLoad || key == null) {
      return
    }

    if (markAsPending) {
      intersectionObserver.markAsPending(key)
    }

    intersectionObserver.observe(key, handler, elRef.current)

    return () => {
      intersectionObserver.unobserve(key, elRef.current)
    }
  }, [key, handler, markAsPending, lazyLoad])

  return {
    elRef
  }
}

export default useWithIntersectionObserver
