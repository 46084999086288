import { FC, useMemo } from 'react'

import Schedule from 'website/components/Schedule'
import Map from 'website/components/Map'
import { type DealershipLocationInfoProps, SupportedComponents } from 'website/components/types'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import { useNavigate, useParams } from 'react-router-dom'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import holdingLens from 'website/assets/holding-lens.png'
import DealerInfoFragment from 'website/components/_base/DealerInfoFragment'
import Button from 'website/components/Button'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const DealershipLocationInfo: FC<DealershipLocationInfoProps> = ({
  componentProps,
  phoneConfig,
  addressConfig
}) => {
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const { locationId } = useParams()
  const componentStyles = useComponentStyles(SupportedComponents.DealershipLocationInfo)
  const dealership = useMemo(() => {
    return dealerInfo.dealerships.find(el => el.id === Number(locationId))
  }, [locationId, dealerInfo])

  const navigation = useNavigate()
  const onRedirect = (): void => {
    const to = dealerInfo.dealerships.length > 1 ? `/inventory?dealerId=${dealership?.id ?? ''}` : '/inventory'
    navigation(to)
  }

  return (
    <div className={componentStyles.root}>
      <div className={componentStyles.content}>
        <div className='description-content'>
          <div>
            <h3>Best Prices & Selection</h3>

            <p>
              Searching for reliable used cars in {dealership?.city}? {dealership?.dealerName} is your trusted used car dealer, offering top-quality pre-owned vehicles including SUVs, sedans, trucks, and luxury cars. We provide competitive prices, flexible financing options, and expert support. Explore the best deals on used cars in {dealership?.city}—visit us or browse our inventory online now!
            </p>
          </div>

          <img src={holdingLens} alt='lens' />
        </div>

        {
          dealership != null
            ? (
              <>
                <div className='dealerInfoContainer'>
                  <DealerInfoFragment
                    phone={dealership.phone}
                    addressData={{
                      city: dealership.city,
                      state: dealership.state,
                      zipCode: dealership.zipCode,
                      address: dealership.address1
                    }}
                    phoneConfig={phoneConfig}
                    addressConfig={addressConfig}
                  />

                  <Button
                    className='shop-cars-button'
                    text={`Shop cars at ${dealership?.city ?? ''}`}
                    onClick={onRedirect}
                  />
                </div>

                <Schedule
                  {...componentProps.Schedule}
                  hours={dealership?.dealerBusinessHours}
                />

                <Map
                  {...componentProps.Map}
                  addressData={{
                    address: dealership.address1,
                    city: dealership.city,
                    state: dealership.state,
                    zipCode: dealership.zipCode
                  }}
                />
              </>
              )
            : null
        }
      </div>
    </div>
  )
}

export default DealershipLocationInfo
