import { FC, ChangeEvent, FocusEvent, useCallback, ReactNode } from 'react'
import { FormControl, FormControlLabel, Checkbox as MUICheckbox } from '@material-ui/core'
import { UITools } from '@carfluent/common'

import IconUnchecked from 'website/components/icons/unchecked'
import IconChecked from 'website/components/icons/checked'
import useStyles from './styles'

const { cn } = UITools

export interface CheckboxProps {
  label?: ReactNode
  value?: boolean
  showError?: boolean
  classes?: { control?: string, label?: string }
  disabled?: boolean
  dataTestId?: string
  onBlur?: (evt: FocusEvent<HTMLButtonElement>) => void
  onChange?: (checked: boolean, evt: ChangeEvent<HTMLInputElement>) => void
  icon?: ReactNode
  checkedIcon?: ReactNode
}

const Checkbox: FC<CheckboxProps> = ({
  label,
  value = false,
  showError = false,
  classes,
  dataTestId,
  onChange,
  icon,
  checkedIcon,
  ...otherProps
}) => {
  const styles = useStyles()

  const handleOnChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    onChange?.(evt.target.checked, evt)
  }, [onChange])

  return (
    <FormControl
      fullWidth
      className={cn(classes?.control, showError ? 'Mui-error' : null)}
    >
      <FormControlLabel
        classes={{
          root: styles.rootFormControlLabel,
          label: styles.labelFormControlLabel
        }}
        control={
          <MUICheckbox
            {...otherProps}
            data-test-id={dataTestId}
            checked={value}
            onChange={handleOnChange}
            icon={icon ?? <IconUnchecked stroke='var(--checkboxUncheckedStroke)' />}
            checkedIcon={checkedIcon ?? <IconChecked fill='var(--checkboxCheckedFill)' />}
            classes={{
              root: cn(styles.checkboxRoot, showError ? styles.checkboxWithError : null),
              colorSecondary: styles.colorSecondary
            }}
          />
        }
        label={label}
      />
    </FormControl>
  )
}

export default Checkbox
