import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'

class SpinnerStore {
  private _loading: number = 0

  get loading (): boolean {
    return this._loading > 0
  }

  startSpinner = (): void => {
    this._loading++
  }

  stopSpinner = (): void => {
    this._loading = Math.max(this._loading - 1, 0)
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const SpinnerInstance = new SpinnerStore()
export const SpinnerCTX = createContext(SpinnerInstance)

export default SpinnerCTX
