import { css, cx } from '@emotion/css'
import { convertColorToHex } from 'website/styles/utils'
import { TypographyVariant } from 'website/components/types'
import { theme } from 'website/configs'

interface EllipsisConfig {
  showEllipsis: boolean
}

interface HoverableConfig {
  isHoverable: boolean
}

interface VariantConfig {
  variant?: TypographyVariant
}

interface TextStyleConfig {
  color?: string
  fontWeight?: number
  fontSize?: string
  textAlign?: string
  lineHeight?: number | string
}

interface TypographyConfig extends EllipsisConfig, HoverableConfig, VariantConfig, TextStyleConfig {}

export const createStyleClass = (
  {
    variant, isHoverable, color = '#000',
    textAlign, showEllipsis, fontWeight, fontSize = '16px', lineHeight
  }: TypographyConfig
): string => {
  const themedVariant = variant != null
    ? cx(css(theme.typography.default), css(theme.typography[variant]))
    : css(theme.typography.default)

  const ownCss = css(`
  margin: 0;

${showEllipsis
  ? `
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
`
  : ''
}
${isHoverable
  ? `
'&:hover' {
  cursor: pointer;
}
& > a, & > a:visited {
  text-decoration: ${theme.link['text-decoration'] ?? 'none'};
  color: inherit;
}
`
  : ''
}

${textAlign != null ? `text-align: ${textAlign};` : ''}

color: ${convertColorToHex(color)};
${fontWeight != null ? `font-weight: ${fontWeight};` : ''}
${lineHeight != null ? `line-height: ${lineHeight};` : ''}
${fontSize != null ? `font-size: ${fontSize};` : ''}
`)

  return cx(themedVariant, ownCss)
}
