import { WORKFLOW_URL } from 'constants/urls'
import { getDataForRequest, getDataForRequestWithFreshRowVersion } from 'utils/coverage'
import {
  type CoverageRequestDto,
  type PaymentCalculatorDetailsDto
} from 'api/types/DTOs'

import { WrapperRequest } from './wrapper.api'
import type {
  WorkflowInitData,
  WorkflowTradeInData,
  PersonalDetailsPayloadWithId,
  Workflow,
  WorkflowPreview,
  CheckoutDetails,
  PersonalDetailsPayload
} from './types'
import type { CoApplicantCreateData, FinancingCalculatorDetails } from './types/financing.types'
import type { PaginationOptions, PaginatedResult } from './types/utility.types'
import type { AddDocumentsPayload, AddDriverLicenseDocumentsPayload } from './types/files'
import type { Summary } from './types/summary.types'

interface DeleteDocumentsData {
  documentsIds: number[]
}

const getFloatDigitPartOrUndefined = (val?: string): string | undefined => {
  const digits = val?.replace(/[^0-9.]/g, '')
  return (digits !== '' && digits != null) ? digits : undefined
}

class WorkflowApi extends WrapperRequest {
  async init ({ dealerId, vin, imageUrl, price, odometer, vehicleId }: WorkflowInitData): Promise<number> {
    const priceStr = getFloatDigitPartOrUndefined(price)
    const odometerStr = getFloatDigitPartOrUndefined(odometer)

    return await this.post<number>(WORKFLOW_URL, {
      dealerId,
      vin,
      imageUrl,
      // any undefined is stripped from payload during request in the request handler
      vehicleId,
      price: isNaN(Number(priceStr)) ? undefined : Number(priceStr),
      odometer: isNaN(Number(odometerStr)) ? undefined : parseInt(odometerStr ?? '0')
    })
  }

  async cancel (): Promise<Workflow> {
    return await this.patch<Workflow>('/api/v1/Workflow/cancel')
  }

  async info (): Promise<Workflow> {
    return await this.get<Workflow>('/api/v1/Workflow')
  }

  // TODO
  // Check if we need this,  we are not using it
  async states (): Promise<void> {
    return await this.get('/api/v1/Workflow/states')
  }

  async templateSteps (): Promise<void> {
    return await this.get('/api/v1/Workflow/template-steps')
  }

  async tradeIn (data?: WorkflowTradeInData): Promise<void> {
    return await this.post('/api/v1/Workflow/trade-in', data)
  }

  async createPersonalDetails (data: PersonalDetailsPayload): Promise<number> {
    return await this.post<number>('/api/v1/Workflow/personal-details', data)
  }

  async summaryDetails (): Promise<Summary> {
    return await this.get<Summary>('/api/v1/Workflow/Summary')
  }

  async summaryDealDetails (id: string | number): Promise<Summary> {
    return await this.get<Summary>(`/api/v1/Workflow/${id}/dealSummary`, { getDataForRequest })
  }

  async updatePersonalDetails (data: PersonalDetailsPayloadWithId): Promise<number> {
    return await this.put('/api/v1/Customers/personal-details', data)
  }

  async coApplicant (data: CoApplicantCreateData): Promise<number> {
    return await this.post<number>('/api/v1/Workflow/coapplicant-financing', data)
  }

  async confirmDealerCheckCompleted (): Promise<void> {
    return await this.post('/api/v1/Workflow/dealer-check-completed/confirm')
  }

  async submitFinancingCalculator (data: FinancingCalculatorDetails): Promise<number> {
    return await this.post<number>('/api/v1/Workflow/financing-calculator', data)
  }

  async skipFinancing (): Promise<void> {
    return await this.put('/api/v1/Workflow/financing/skip')
  }

  async paperworkWorkflow (): Promise<void> {
    return await this.post('/api/v1/Workflow/paperwork')
  }

  async paperworkSkip (): Promise<void> {
    return await this.post('/api/v1/Workflow/paperwork/skip')
  }

  async addDocuments (data: AddDocumentsPayload): Promise<number[]> {
    return await this.post('/api/v1/Workflow/documents', data)
  }

  async deleteDocuments (data: DeleteDocumentsData, workflowId?: number): Promise<void> {
    const url = workflowId != null
      ? `/api/v1/Workflow/${workflowId}/documents`
      : '/api/v1/Workflow/documents'

    return await this.delete(url, data)
  }

  async addDriverLicense (payload: AddDriverLicenseDocumentsPayload): Promise<number[]> {
    return await this.post('/api/v1/Workflow/driver-license', payload)
  }

  async submitDelivery (data: CheckoutDetails): Promise<void> {
    return await this.post('/api/v1/Workflow/delivery', data)
  }

  async submitCheckout (): Promise<void> {
    return await this.post('/api/v1/Workflow/payment-Summary')
  }

  async paymentsWorkflow (data: any) {
    return await this.post('/api/v1/Workflow/payments', data)
  }

  async placeOrder () {
    return await this.post('/api/v1/Workflow/place-order')
  }

  async getWorkflowById (id: number) {
    return await this.get<Workflow | null>(`/api/v1/Workflow/${id}`, { getDataForRequest })
  }

  async getPreviewsWorkflow (data: PaginationOptions): Promise<PaginatedResult<WorkflowPreview[]>> {
    return await this.post('/api/v1/Customers/workflow/previews/search', data)
  }

  async submitCoverages (data: CoverageRequestDto, rowVersion: string | null = null): Promise<void> {
    return await this.post('/api/v1/Workflow/coverage', {
      getDataForRequest: rowVersion != null
        ? getDataForRequestWithFreshRowVersion(rowVersion)
        : getDataForRequest,
      ...data
    })
  }

  async getPaymentShareDetails (token: string): Promise<PaymentCalculatorDetailsDto> {
    return await this.get(`/api/v1/Workflow/payment-calculator/${token}`)
  }
}

export const WorkflowApiProvider = new WorkflowApi()
export default WorkflowApiProvider
