const options = {
  root: null, // Page as root
  rootMargin: '0px',
  threshold: [0, 0.1]
}

export type IntersectionHandler = ((key: string, entry?: IntersectionObserverEntry) => void) | null

export interface HandlerMap {
  [key: string]: IntersectionHandler
}

export interface IntersectionObserverType {
  observe: (key: string, handler?: IntersectionHandler, el?: HTMLElement | null) => void
  unobserve: (key: string, el?: HTMLElement | null) => void
  isSupported: () => boolean
  handleIntersection: (entries: IntersectionObserverEntry[]) => void
  currentIntersections: Set<string>
  pendingToBeObserved: Set<string>
  markAsPending: (key: string) => void
}

const isSupported = (): boolean => {
  if (!('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
    return false
  }
  return true
}

let intersectionHandlersMap: HandlerMap = {}
const currentIntersections = new Set<string>()

/**
 * used for instant tracking of mounted nodes which are going to be tracked
 * because there is a lag when node is mounted and observer starts tracking it
 * So this is a workaround not to introduce timeouts in different places.
 *
 * After listeners starts tracking position it should be cleaned up
 */
const pendingToBeObserved = new Set<string>()

const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
  entries.forEach(entry => {
    const key = entry.target.getAttribute('data-key')

    if (key == null) {
      return
    }

    if (entry.isIntersecting) {
      currentIntersections.add(key)
      intersectionHandlersMap[key]?.(key, entry)
    } else {
      currentIntersections.delete(key)
    }

    pendingToBeObserved.delete(key)
  })
}

const removeHandlerFromMap = (key: string): void => {
  const { [key]: match, ...otherHandlers } = intersectionHandlersMap
  intersectionHandlersMap = otherHandlers
}

const addHandlerToMap = (key: string, handler: IntersectionHandler): void => {
  if (intersectionHandlersMap[key] == null) {
    intersectionHandlersMap[key] = handler
  }
}

const instance = isSupported()
  ? new IntersectionObserver(handleIntersection, options)
  : null

const unobserve = (key: string, el?: HTMLElement | null): void => {
  if (el != null) {
    instance?.unobserve(el)
    removeHandlerFromMap(key)
  }
}

const observe = (key: string, handler?: IntersectionHandler, el?: HTMLElement | null): void => {
  if (intersectionHandlersMap[key] == null && el != null) {
    instance?.observe(el)

    /**
     * it is a normal case if we need just to track intersections for some centralized management outside
     */
    if (handler != null) {
      addHandlerToMap(key, handler)
    }
  }
}

const markAsPending = (key: string): void => {
  /**
   * we mark as pending only if observer is supported because it also involves some extra management in its listeners
   */
  if (isSupported()) {
    pendingToBeObserved.add(key)
  }
}

const intersectionObserver: IntersectionObserverType = {
  observe,
  unobserve,
  isSupported,
  handleIntersection,
  currentIntersections,
  pendingToBeObserved,
  markAsPending
}

export default intersectionObserver
