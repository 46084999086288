import { Summary } from 'api/types/summary.types'

export const DEFAULT_SUMMARY: Summary = {
  amountFinanced: null,
  annualPercentageRate: null,
  bodyStyle: null,
  calcAmountFinanced: null,
  calcDownPayment: null,
  calcMonthlyPayment: null,
  calcTerm: null,
  cashPriceIncludingSalesTax: null,
  ceramicId: null,
  ceramicName: null,
  ceramicPrice: null,
  coPurchaserAddress: null,
  coPurchaserApt: null,
  coPurchaserCity: null,
  coPurchaserCountry: null,
  coPurchaserDateOfBirth: null,
  coPurchaserEmail: null,
  coPurchaserFirstName: null,
  coPurchaserLastName: null,
  coPurchaserLicenseNumber: null,
  coPurchaserName: null,
  coPurchaserPhone: null,
  coPurchaserState: null,
  coPurchaserZipCode: null,
  color: null,
  dealerAddress: '',
  dealerCity: '',
  dealerEmail: '',
  dealerId: null,
  dealerName: '',
  dealerPhone: '',
  dealerRepresentativeEmail: null,
  dealerRepresentativeFirstName: null,
  dealerRepresentativeId: null,
  dealerRepresentativeLastName: null,
  dealerRepresentativeName: null,
  dealerState: '',
  dealerZipCode: '',
  deposit: null,
  documentFee: null,
  downPayment: null,
  financeCharge: null,
  firstPaymentDate: '',
  fundedAmount: null,
  gapId: null,
  gapName: null,
  gapPrice: null,
  gapProviderName: null,
  inServiceDate: null,
  interestRate: null,
  isCreditApplicationSubmitted: false,
  lender: '',
  make: '',
  maxCardPaymentAmount: null,
  minFinancedAmount: null,
  model: '',
  modelYear: null,
  monthlyPayment: null,
  monthlyPaymentDueDate: '',
  odometer: null,
  other1: null,
  other1Description: '',
  other2: null,
  other2Description: null,
  other3: null,
  other3Description: null,
  pickupDeliveryDate: '',
  purchasePrice: null,
  purchaserAddress: '',
  purchaserApt: null,
  purchaserCity: '',
  purchaserCountry: '',
  purchaserDateOfBirth: '',
  purchaserEmail: '',
  purchaserFirstName: '',
  purchaserLastName: '',
  purchaserLicenseNumber: '',
  purchaserName: '',
  purchaserPhone: '',
  purchaserState: '',
  purchaserZipCode: '',
  registrationTax: null,
  saleDate: '',
  salesTax: null,
  salesTaxAmount: null,
  stockNumber: '',
  term: null,
  theftId: null,
  theftName: null,
  theftPrice: null,
  tireAndWheelId: null,
  tireAndWheelName: null,
  tireAndWheelPrice: null,
  totalAmount: null,
  totalAmountDueCashOrFinanced: null,
  totalDown: null,
  totalOtherCharges: null,
  totalPayments: null,
  totalPrice: null,
  totalSalePrice: null,
  tradeInAmount: null,
  tradeInCredit: null,
  totalPriceLessDeposit: null,
  tradeInLessPayoff: null,
  tradeInMake: '',
  tradeInModel: '',
  tradeInOdometer: null,
  tradeInPayoff: null,
  tradeInTrim: '',
  tradeInVin: '',
  tradeInYear: null,
  transportationCost: null,
  trim: '',
  unpaidBalanceOfCashPrice: null,
  vin: '',
  warrantyDeductibleAmount: null,
  warrantyId: null,
  warrantyName: null,
  warrantyPrice: null,
  warrantyProviderName: null,
  warrantyTermMiles: null,
  warrantyTermMonths: null,
  workflowId: null,
  dealFees: {
    buyerTag: 0,
    carDelivery: 0,
    dealerHandlingFee: 0,
    dealerInventoryTax: 0,
    emissions: 0,
    inspectionFee: 0,
    other1: 0,
    other2: 0,
    other3: 0,
    other1Description: null,
    other2Description: null,
    other3Description: null,
    plateTransferFee: 0,
    registrationFee: 0,
    titleFee: 0
  }
}
