import { css } from '@emotion/css'

export default css`
  
  .image-list-empty {
    width: 100%;
  }
  
  @media screen and (min-width: 767px) {
    .ComponentRoot-content {
      padding: 24px 40px 0!important;
    }
  }

  @media screen and (min-width: 768px) {
    .ComponentRoot-content {
      padding: 0!important;
    }
  }
  
  .no-photo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .empty-img-subtitle {
    position: absolute;
    top: calc(50% + 30px);
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #101010;

    span {
      font-size: 12px;
      font-weight: 400;
      color: #101010;
    }
  }
`

