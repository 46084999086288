import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { POPULAR_CARS_MAP } from 'website/configs'
import { WebsiteRoutes } from 'website/routing/constants'

import { type PopularCarsPanelProps } from '../types'

const PopularCarsPanel: FC<PopularCarsPanelProps> = ({ nameInLayout }) => {
  const navigate = useNavigate()

  const onMakeSelect = (make: string): void => {
    navigate(`${WebsiteRoutes.Inventory}?make=${make}`)
  }

  return (
    <div className={nameInLayout}>
      <div className='component-container'>
        <h3 className='popular-cars-section-header'>Popular cars</h3>
        <div className='makes-container'>
          {
            Array.from(POPULAR_CARS_MAP.keys()).map((key) => (
              <div
                key={key}
                className='makes-item'
                onClick={() => onMakeSelect(key)}
              >
                <img src={POPULAR_CARS_MAP.get(key)} alt={key} />
                <p className='makes-title'>{key}</p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default PopularCarsPanel
