export default {
  VehiclesFilterChips: {
    default: {
      root: `
        .cf-filter-chips {
          height: auto !important;
          width: auto !important;
          display: flex;
          flex-wrap: wrap;
          margin-top: -8px;

          button {
            margin-top: 8px;
            border: 1px solid #DBDBDB;
            border-radius: 8px;
            padding: 8px;
            height: 32px;
          }

          .cf-chip {
            background: var(--mainColor);
            color: #fff;
            padding: 6px;
            font-size: 14px;
            white-space: nowrap;
            width: max-content;
            height: 32px;
            border-radius: 8px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-right: 8px;
            margin-top: 8px;

            :hover {
              cursor: pointer;
            }

            svg {
              margin-left: 8px;
              width: 11px;
              height: 11px;
  
              path {
                fill: #fff;
              }
            }
          }
        }
      `
    }
  }
}
