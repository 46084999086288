import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

export const LocationIcon: FC<IconProps> = ({
  fill = '#000',
  width = 20,
  height = 20
}) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.0003 3.05469C7.31277 3.05469 5.13916 5.2283 5.13916 7.9158C5.13916 11.5616 10.0003 16.9436 10.0003 16.9436C10.0003 16.9436 14.8614 11.5616 14.8614 7.9158C14.8614 5.2283 12.6878 3.05469 10.0003 3.05469ZM10.0003 9.65191C9.04194 9.65191 8.26416 8.87413 8.26416 7.9158C8.26416 6.95747 9.04194 6.17969 10.0003 6.17969C10.9586 6.17969 11.7364 6.95747 11.7364 7.9158C11.7364 8.87413 10.9586 9.65191 10.0003 9.65191Z'
      fill={fill}
      fillOpacity='0.38'
    />
  </svg>
)

export default LocationIcon
