import { type FullAddressParts } from 'components/AddressAutocomplete/types'

interface VitalAddressParts {
  address: string
  city: string
  zipCode: string
  state: string
}

export const isAddressValid = (addressData: FullAddressParts | null): addressData is VitalAddressParts => {
  if (addressData == null) {
    return false
  }

  return addressData.address != null &&
    addressData.city != null &&
    addressData.zipCode != null &&
    addressData.state != null
}
