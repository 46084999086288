import { validation } from '@carfluent/common'

import type { FullAddressParts } from 'components/AddressAutocomplete/types'
import { containsTruthy } from 'utils/containsTruthy'
import isValid from 'date-fns/isValid'
import _isDate from 'date-fns/isDate'
import isEqual from 'date-fns/isEqual'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'

import { nameRegexp } from 'constants/regexp'
import type { ConditionData } from './types'

const { isEmailValid: _isEmailValid } = validation

export const isNameValid = (errorMessage: string) =>
  (val?: string): string | null => {
    const isValid = val == null ||
      val.length === 0 ||
      nameRegexp.test(val)

    return isValid ? null : errorMessage
  }

export const isEmailValid = (errorMessage: string) =>
  (val?: string): string | null => {
    const isValid = val == null || val.length === 0 || _isEmailValid(val)
    return isValid ? null : errorMessage
  }

export const isRequired = (errorMessage: string) => (val?: any): string | null => {
  const isValid = containsTruthy(val)
  return isValid ? null : errorMessage
}

export const isRequiredOrNot = (isRequired: boolean = true, errorMessage: string) =>
  (val?: any): string | null => {
    if (!isRequired && (val == null || val.length === 0)) {
      return null
    }

    const isValid = containsTruthy(val)
    return isValid ? null : errorMessage
  }

export const greaterThanOrEqual = (errorMessage: string, min: number) =>
  (val: number | string | null): string | null => {
    const isValid = val != null &&
      (typeof val === 'string'
        ? val.length >= min
        : val >= min)

    return isValid ? null : errorMessage
  }

export const lessThanOrEqual = (errorMessage: string, max: number) =>
  (val: number | string | null): string | null => {
    const isValid = val != null &&
      (typeof val === 'string'
        ? val.length <= max
        : val <= max)

    return isValid ? null : errorMessage
  }

export const exactLength = (errorMessage: string, len: number) =>
  (val: string | null): string | null => {
    const isValid = val != null && val.length === len
    return isValid ? null : errorMessage
  }

export function isDate (date: any): date is Date {
  return _isDate(date)
}

export function isValidDate (date: any): date is Date {
  return isDate(date) && isValid(date)
}

export const isDateRequired = (errorMessage: string) => (val: string | null) => {
  return isValidDate(val) ? null : errorMessage
}

export const isValidDateOrEmpty = (errorMessage: string) => (val?: Date | null): string | null => {
  if (val == null) {
    return null
  }

  const isValid = isValidDate(val)

  return isValid ? null : errorMessage
}

export const greaterThanOrEqualDateForStingDate = (errorMessage: string, min: Date) =>
  (val: string | null): string | null => {
    const isValid = val == null || (isEqual(new Date(val), new Date(min)) || isAfter(new Date(val), new Date(min)))

    return isValid ? null : errorMessage
  }

export const lessThanOrEqualDateForStingDate = (errorMessage: string, max: Date) =>
  (val: string | null): string | null => {
    const isValid = val == null || (isEqual(new Date(val), max) || isBefore(new Date(val), new Date(max)))

    return isValid ? null : errorMessage
  }

export const addressData = (errorMessage: string, isRequired = true) =>
  (val: FullAddressParts | null): string | null => {
    const { city, zipCode, state, address } = (val ?? {})
    const isFull = Boolean(city) && Boolean(zipCode) && Boolean(state) && Boolean(address)
    const isPartiall = Boolean(city) || Boolean(zipCode) || Boolean(state) || Boolean(address)

    const isValid = isRequired ? isFull : (isFull || !isPartiall)
    return isValid ? null : errorMessage
  }

export const isConditionExist = (errorMessage: string, isSubConditionRequired = false) => (val?: ConditionData | null) => {
  const isSubOptionInvalid = isSubConditionRequired && (val?.subOptionValue == null || val?.subOptionValue?.length === 0)

  if (val == null || val.value == null || isSubOptionInvalid) {
    return errorMessage
  }

  return null
}
