import type { FormValidation } from '@carfluent/common'

import {
  phoneNumber,
  email,
  firstName,
  lastName,
  zipCode
} from 'website/utils/validation/presets'

export interface FormValues {
  firstName: string
  lastName: string
  phoneNumber: string
  zipCode: string
  email: string
  contactYou: string
}

const createValidationRules = (): FormValidation<FormValues> => {
  return {
    firstName,
    lastName,
    phoneNumber,
    email,
    zipCode
  }
}

const validationRules = createValidationRules()

export default validationRules
