import type { FC } from 'react'

import CheckboxDeprecated from 'website/components/CheckboxDeprecated'
import RadioLabelControl from 'website/components/RadioLabelControl'

import type { ConditionSubOptionProps } from 'website/components/types'
import uncheckedIcon from 'website/assets/rect_checkbox_icon_unchecked.svg'
import checkedIcon from 'website/assets/rect_checkbox_icon_checked.svg'

import CLASS_NAME from './styles'

interface SubOptionsProps {
  type: 'radio' | 'checkbox'
  value?: number[] | null
  options: ConditionSubOptionProps[]
  onChange: (value: number) => void
}

const SubOptions: FC<SubOptionsProps> = ({
  type,
  value: _value,
  onChange: _onChange,
  options
}) => {
  const value = _value ?? []

  const onChange = (id: number) => () => _onChange(id)

  return (
    <div className={CLASS_NAME}>
      {options.map((option) => type === 'checkbox'
        ? (
          <CheckboxDeprecated
            classes={{
              control: 'cf-checkbox'
            }}
            key={option.value}
            value={value.includes(option.value)}
            label={option.title}
            onChange={onChange(option.value)}
            icon={<img src={uncheckedIcon} alt='unchecked' />}
            checkedIcon={<img src={checkedIcon} alt='checked' />}
          />
          )
        : (
          <RadioLabelControl
            key={option.value}
            label={option.title}
            checked={value.includes(option.value)}
            onChange={onChange(option.value)}
          />
          )
      )}
    </div>
  )
}

export default SubOptions
