import { HeaderProps } from 'website/components/types'

import headerCustomerAppFlowLayoutJson from './headerFlowLayout.json'
import headerCustomerAppAuthLayoutJson from './headerAuthLayout.json'
import headerCustomerAppOutsideFlowLayoutJson from './headerOutsideFlowLayout.json'
import headerYourDetailsPageJson from './headerYourDetailsPage.json'
import { parsedTemplatesCache } from '../../templates'

const headerDealLayouts = {
  flow: JSON.parse(JSON.stringify(headerCustomerAppFlowLayoutJson)),
  auth: JSON.parse(JSON.stringify(headerCustomerAppAuthLayoutJson)),
  outsideFlow: JSON.parse(JSON.stringify(headerCustomerAppOutsideFlowLayoutJson)),
  yourDetailsPage: headerYourDetailsPageJson
}

type HeaderDealAppType = keyof typeof headerDealLayouts
/**
 * When it is used all website assets should already be parsed
 * so it should be safe to parse them directly
 */
export default (type: HeaderDealAppType): HeaderProps => {
  return {
    ...JSON.parse(parsedTemplatesCache.headerTemplate),
    ...headerDealLayouts[type]
  }
}
