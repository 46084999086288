const textEllipsis = `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const CARD_HEIGHT_PX = '361px'

export const vehicleCardStyles = `
    height: ${CARD_HEIGHT_PX};
    border-radius: 12px;
    border: 1px solid #EBEBEB;
    overflow: hidden;
    background: white;
    box-sizing: border-box;
    max-height: ${CARD_HEIGHT_PX};

    :hover {
      box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.03), 0px 10px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 8px 5px rgba(0, 0, 0, 0.02);
    }
`

export default {
  VehicleCard: {
    default: {
      root: `
        ${vehicleCardStyles}

        .LazyImage {
          height: 200px;

          & img {
            object-fit: cover;
          }
        }

        .card-img-wrapper {
          position: relative;
        }

        .card-distance-info {
          position: absolute;
          padding: 8px;
          height: 16px;
          width: max-content;
          background: #212121CC;
          color: #fff;
          border-radius: 8px;
          bottom: 8px;
          left: 8px;
          display: flex;
          align-items: center;
          font-size: 14px;
          box-sizing: content-box;

          path {
            fill: #fff;
          }
        }
        
        .content-wrapper {
          padding: 16px;
          height: 161px;
          box-sizing: border-box;

          .car-info {
            display: grid;
            grid-template-areas: 'carInfoName carInfoName' 'carInfoTrim carInfoTrim' 'carInfoMiles carInfoEngine';
            grid-template-rows: auto minmax(20px, auto) auto;
            grid-template-columns: 1fr 1fr;
            row-gap: 4px;
            column-gap: 8px;
            padding-bottom: 8px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            
            .car-info-name {
              grid-area: carInfoName;
              font-weight: 500;
              line-height: 24px;
              ${textEllipsis}
            }
            
            .car-info-trim {
              grid-area: carInfoTrim;
              font-size: 14px;
              color: rgba(33, 33, 33, 0.80);
              line-height: 20px;
              margin-bottom: 4px;
              min-height: 20px;
              ${textEllipsis}
            }
            
            .car-info-miles {
              grid-area: carInfoMiles;
              padding: 0px;
              font-size: 14px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.80);
              ${textEllipsis}
            }
            
            .car-info-engine {
              grid-area: carInfoEngine;
              text-align: right;
              line-height: 20px;
              padding: 0px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.80);
              ${textEllipsis}
            }
          }
          
          .price-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            margin-top: 8px;

            > p {
              font-size: 24px;
              font-weight: 500;
              line-height: 36px;
            }

            > div, p {
              width: calc(50% - 8px)
            }

            .price-details {
              &.is-loading {
                display: flex;
                justify-content: flex-end;
              }
              
              .price-per-month {
                font-size: 14px;
                width: 100%;
                text-align: right;
                line-height: 20px;
                text-decoration: underline;
                position: relative;
                
                :hover > .prequalify-tooltip {
                  display: block;
                }
                
                .prequalify-tooltip {
                  :hover {
                    display: block;
                  }
                  
                  text-align: left;
                  display: none;
                  position: absolute;
                  max-width: 266px;
                  padding: 4px 12px;
                  border-radius: 4px;
                  background: #212121;
                  top: 0;
                  right: 0;
                  color: white;
                  transform: translate(0px, -100%);
                  width: max-content;
                  font-size: 14px;
                }
              }
              
              .cash-down {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.80);
                width: 100%;
                text-align: right;
                line-height: 16px;
              }
            }
          }
        }
        
        .card-link {
          text-decoration: none;
          color: #000;
          
          &.card-link-disabled {
            pointer-events: none;
          }

          &.card-link-placeholder {
            .LazyImage {
              background: #F6F9F8;
              display: flex;
              justify-content: center;
              align-items: center;
              
              img {
                width: 120px;
                height: 54px;
                margin-top: -20px;
              }
            }
            
            .empty-img-subtitle {
              position: absolute;
              top: calc(50% + 18px);
              left: 50%;
              transform: translate(-50%, 0);
              text-align: center;
              font-weight: 500;
              font-size: 14px;
              color: #101010;
              
              span {
                font-size: 12px;
                font-weight: 400;
                color: #101010;
              }
            }
          }
        }
      `
    }
  }
}
