import { type CoverageFeeSettingsDto, type DefaultAmountFeeSettingDto } from 'api/types'
import { type DealFees } from 'api/types/summary.types'

interface SettingProps extends DefaultAmountFeeSettingDto {
  amount: number
}

export interface FeesAndTaxesProps extends CoverageFeeSettingsDto {
  buyerTagSetting: SettingProps
  carDeliverySetting: SettingProps
  dealerHandlingFeeSetting: SettingProps
  dealerInventoryTaxSetting: SettingProps
  emissionsSetting: SettingProps
  inspectionFeeSetting: SettingProps
  plateTransferFeeSetting: SettingProps
  registrationFeeSetting: SettingProps
  titleFeeSetting: SettingProps
}

export const mergeTwoFeesInfo = (
  a: CoverageFeeSettingsDto,
  b: DealFees,
  dealerInventoryTaxAmount: number
): FeesAndTaxesProps => {
  return {
    ...a,
    buyerTagSetting: { ...a.buyerTagSetting, amount: b.buyerTag },
    carDeliverySetting: { ...a.carDeliverySetting, amount: b.carDelivery },
    dealerHandlingFeeSetting: { ...a.dealerHandlingFeeSetting, amount: b.dealerHandlingFee },
    dealerInventoryTaxSetting: { ...a.dealerInventoryTaxSetting, amount: dealerInventoryTaxAmount },
    emissionsSetting: { ...a.emissionsSetting, amount: b.emissions },
    inspectionFeeSetting: { ...a.inspectionFeeSetting, amount: b.inspectionFee },
    plateTransferFeeSetting: { ...a.plateTransferFeeSetting, amount: b.plateTransferFee },
    registrationFeeSetting: { ...a.registrationFeeSetting, amount: b.registrationFee },
    titleFeeSetting: { ...a.titleFeeSetting, amount: b.titleFee }
  }
}
