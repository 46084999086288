import { useEffect, useState } from 'react'
import { useRefUpdater } from '@carfluent/common'

import type { VehicleCardProps } from 'website/components/types'
import VehicleApiProvider from 'website/api/vehicles.api'
import useCustomSnackbar from 'hooks/useCustomSnackbar'

import { parseMonthlyPayments } from './parser'

type MonthlyPaymentVehicleType = (API.VehicleItem | VehicleCardProps)

interface UseMonthlyPaymentsProps<T extends MonthlyPaymentVehicleType> {
  vehicles: T[]
  isRefreshing?: boolean
}

export interface UseMonthlyPaymentsReturn {
  monthlyPayments: Record<number, API.VehicleMonthlyPayment>
  loadingIds: number[]
}


// This hook gets information about monthly payment for rendered vehicles according their ids
export function useMonthlyPayments <T extends MonthlyPaymentVehicleType>({
  vehicles,
  isRefreshing = false
}: UseMonthlyPaymentsProps<T>): UseMonthlyPaymentsReturn {
  const [monthlyPayments, setMonthlyPayments] = useState<Record<number, API.VehicleMonthlyPayment>>({})
  const [loadingIds, setLoadingIds] = useState<number[]>([])
  const monthlyPaymentRef = useRefUpdater(monthlyPayments)

  const { showAlert } = useCustomSnackbar()
  
  useEffect(() => {
    if (vehicles.length === 0) {
      return
    }
    
    if (isRefreshing) {
      setMonthlyPayments([])
      return
    }
    
    const runEffect = async () => {
      const vehicleIdsToUpdate = vehicles.reduce<number[]>((res, item) => {
        const _id = item.id as number
        const isExist = monthlyPaymentRef.current[_id] != null

        if (!isExist) {
          res.push(_id)
        }

        return res
      }, [])
      
      if (vehicleIdsToUpdate.length === 0) {
        return
      }
      
      try {
        setLoadingIds(vehicleIdsToUpdate)
        const payments = await VehicleApiProvider.getMonthlyPayments(vehicleIdsToUpdate)
        const newPayments = parseMonthlyPayments(payments)
  
        setMonthlyPayments((prev) => ({ ...prev,  ...newPayments }))
      } catch (e) {
        showAlert(e)
      } finally {
        setLoadingIds([])
      }
    }
    
    void runEffect()
  }, [vehicles, isRefreshing])

  return {
    monthlyPayments,
    loadingIds
  }
}
