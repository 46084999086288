import type {
  MutableRefObject, ChangeEvent, KeyboardEvent, ClipboardEvent, RefObject
} from 'react'

export enum FocusPos {
  First = 'first',
  Last = 'last',
  Next = 'next',
  Prev = 'prev'
}

export interface UseSendCodeModalProps {
  isLogin: boolean
  email?: string
  phoneNumber: string
  onCreatePersonalDetails?: () => Promise<void>
  onCloseModal: () => void
}

export interface SendCodeModalProps extends UseSendCodeModalProps {
  isModalOpen: boolean
}

export interface UseSendCodeModalReturn {
  isLogin: boolean
  isResendActive: boolean
  isInvalidCode: boolean
  code: string[]
  remainingTime: number
  phoneNumber: string
  inputRefs: MutableRefObject<Array<RefObject<HTMLInputElement>>>
  onCodeChange: (event: ChangeEvent<HTMLInputElement>, index: number) => void
  onInputKeyDown: (event: KeyboardEvent<HTMLInputElement>, index: number) => void
  onCodePaste: (event: ClipboardEvent<HTMLInputElement>) => void
  onResendCode: () => void
  onCloseModal: () => void
}
