import { WrapperRequest } from './wrapper.api'

class PaymentsApi extends WrapperRequest {
  url = process.env.REACT_APP_PAYMENTS

  async intentPayments () {
    return await this.post('/api/v1/Payments/intent')
  }

  async chargePayments (data: any) {
    return await this.post('/api/v1/Payments/charge', data)
  }

  /**
   * Requires data about selected payments (Card\ACH)
   * in the Workflow instance.
   */
  async linkPlaid () {
    return await this.post('/api/v1/Payments/link')
  }

  async stripeAccount () {
    return await this.get('/api/v1/Payments/account')
  }

  async cryptoCharge () {
    return await this.post('/api/v1/Payments/coinbase/charge')
  }
}

export const PaymentsApiProvider = new PaymentsApi()
