import { css } from '@emotion/css'

interface StylesReturn {
  gallery: string
  modalGallery: string
}

const createStyleClass = (): StylesReturn => {
  return {
    gallery: css(`
      --arrow-to-content-margin: 16px;
      --arrow-to-edge-margin: min(16px, 1.5vw);
      --gallery-big-image-padding: 16px;

      height: 100%;
      display: flex;
      flex-direction: column;

      & .full-image-section {
        width: 100%;
        padding-bottom: 0;
        height: calc(100% - var(--gallery-big-image-padding));
        max-height: var(--galleryNormalViewImgMaxHeightMobile);
        min-height: var(--galleryNormalViewImgMinHeight);
        box-sizing: border-box;

        .play-icon-img {
          display: block!important;
        }

        @media screen and (min-width: 600px) {
          min-height: var(--galleryNormalViewImgMediumHeight);
        }

        @media screen and (min-width: 768px) {
          padding-bottom: var(--gallery-big-image-padding);
          max-height: var(--galleryNormalViewImgMaxHeight);
          height: calc(100% - var(--galleryThumbnailHeight) - var(--gallery-big-image-padding));
        }
      }

      & .cf-thumbnails-section {
        height: var(--galleryThumbnailHeight);
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
        }

        .play-icon-img {
          display: none;
        }

        & .gallery-arrow {
          display: none;
        }

        & .gallery-container {
          margin: 0px;
        }

        @media screen and (min-width: 1025px) {
          & .gallery-arrow {
            display: flex;

            &.right {
              right: var(--arrow-to-edge-margin);
            }

            &.left {
              left: var(--arrow-to-edge-margin);
            }
          }

          & .gallery-container {
            margin: 0px calc(var(--arrow-to-content-margin) + var(--galleryArrowSize) + var(--arrow-to-edge-margin));
          }
        }
      }
    `),

    modalGallery: css(`
      --gallery-big-image-padding: 32px;
      --gallery-bottom-padding: 72px;
      --gallery-side-margin: 0px;
      --arrow-margin: 2.5%;

      visibility: hidden;
      position: fixed;
      inset: 0px;
      z-index: -10000;
      opacity: 0;

      &.visible {
        opacity: 1;
        visibility: visible;
        z-index: 10000;
        transition: opacity .7s;
      }

      & .gallery-modal-content {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        position: absolute;
        backdrop-filter: blur(7px);
        background: rgba(0,0,0, .8);
        transition-timing-function: ease-in;
      }
    }

    & .gallery-sections {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      margin: var(--galleryHeaderHeight) var(--gallery-side-margin) 0px;
      max-width: calc(100% - var(--gallery-side-margin) * 2);
      height: calc(100vh - var(--galleryHeaderHeight));

      & .full-image-section {
        width: 100%;
        height: 100%;
        margin: auto;
        padding-bottom: 0px;
        box-sizing: border-box;

        & .gallery-arrow {
          display: none;
          background: rgba(176,176,176, 0.6);

          &.left {
            left: calc(-1 * (var(--galleryArrowSize) + var(--arrow-margin)));
          }

          &.right {
            right: calc(-1 * (var(--galleryArrowSize) + var(--arrow-margin)));
          }
        }

        & .gallery-item {
          max-height: calc(100% - var(--galleryHeaderHeight));
        }
      }

      & .cf-thumbnails-section {
        height: var(--galleryThumbnailHeight);
        display: none;
      }

      @media screen and (min-width: 1025px) {
        margin: var(--galleryHeaderHeight) auto 0px;
        max-width: calc(100% - (var(--galleryArrowSize) + var(--arrow-margin) * 2) * 2);
        height: calc(100vh - var(--galleryHeaderHeight) - var(--gallery-bottom-padding));
        
        & .full-image-section {
          width: 100%;
          height: calc(100% - var(--galleryThumbnailHeight));
          padding-bottom: var(--gallery-big-image-padding);

          & .gallery-arrow {
            display: flex;
          }

          & .gallery-item {
            max-height: 100%;
          }
        }

        & .cf-thumbnails-section {
          display: block;
        }
      }
    `)
  }
}

export default createStyleClass
