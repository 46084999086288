import { type FC, useMemo } from 'react'
import { cnx, formatCurrency, formatInteger, Button } from '@carfluent/common'

import GetStartedButton from 'website/components/GetStartedButton'
import InfoIcon from 'website/components/icons/info'
import askIcon from 'website/assets/ask_icon.svg'

import type { VehicleBriefInfoProps } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { getTooltip, calculatePrequalify } from 'website/components/VehicleCard/parser'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import { useMonthlyPayments } from 'website/hooks/useMonthlyPayments'

const useSharedState = SharedStateHook<Store.PrequalifyState>(StoreBranches.Prequalify)
const DEFAULT_VEHICLES: API.VehicleItem[] = []

const VehicleBriefInfo: FC<VehicleBriefInfoProps> = (props) => {
  const {
    nameInLayout = 'VehicleBriefInfo',
    className,
    variant,
    vehicle,
    onClick,
    componentProps
  } = props
  const componentStylesCls = useComponentStyles(nameInLayout, variant)
  const [prequalify] = useSharedState(defaultInstance(StoreBranches.Prequalify))

  const vehicles = useMemo(() => {
    return vehicle != null ? [vehicle] : DEFAULT_VEHICLES
  }, [vehicle])

  const { monthlyPayments } = useMonthlyPayments({ vehicles })
  const monthlyPaymentDetails = vehicle?.id != null ? monthlyPayments[vehicle.id] : null

  if (vehicle == null || vehicle.dealerId == null || vehicle.vin == null) {
    return null
  }

  const parsedPrequalify = calculatePrequalify({
    ...prequalify,
    price: vehicle.salePrice ?? 0
  })

  const isPrequalify = parsedPrequalify != null

  const paymentInfo = parsedPrequalify ?? (
    monthlyPaymentDetails != null
      ? { monthlyPayment: monthlyPaymentDetails.monthlyPayment, cashDown: monthlyPaymentDetails.cashDown }
      : null
  )

  const tooltip = getTooltip({
    term: (isPrequalify ? prequalify.term : monthlyPaymentDetails?.term) ?? null,
    apr: (isPrequalify ? prequalify?.apr : monthlyPaymentDetails?.apr) ?? 0
  })

  const price = (vehicle.salePrice != null && vehicle.salePrice >= 1000)
    ? formatCurrency(vehicle.salePrice)
    : 'Call'

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root, className)}>
      <p className='brief-info-name'>
        {
          (vehicle.year != null && vehicle.make != null && vehicle.model != null)
            ? `${vehicle.year ?? ''} ${vehicle.make ?? ''} ${vehicle.model ?? ''}`
            : '-'
        } <br/>
        <span>{vehicle.trim != null ? vehicle.trim ?? '' : '-'}</span>
      </p>

      <div className='brief-info-price-mileage-wrapper'>
        <p className='brief-info-sale-price'>
          {price}
        </p>

        <p className='brief-info-mileage'>
          {formatInteger(vehicle.mileage, { emptyValues: '0' })} miles
        </p>
      </div>

      {paymentInfo != null && (
        <div className='payment-block-wrapper'>
          <div className='payment-block-title'>
            Monthly payment

            <span className='info-wrapper'>
              <InfoIcon/>

              {tooltip != null && (
                <div className='payment-tooltip'>
                  {tooltip}
                </div>
              )}
            </span>
          </div>

          <div className='payment-block-info'>
            <p>
              Est. {formatCurrency(paymentInfo.monthlyPayment)}/mo
            </p>

            <p className='cash-down'>
              Cash down {formatCurrency(paymentInfo.cashDown)}
            </p>
          </div>
        </div>
      )}

      <div className='brief-info-vin-stock-wrapper'>
        <p className='brief-info-vin'>
          VIN: {vehicle.vin}
        </p>

        <p className='brief-info-stock'>
          Stock: {vehicle.stock ?? '-'}
        </p>
      </div>

      <GetStartedButton
        nameInLayout='GetStartedButton'
        dealerId={vehicle.dealerId}
        vehicle={vehicle}
      />

      <div
        className='cn-button-container'
        data-vin={vehicle.vin}
        data-page-type='vdp'
        data-type='used'
      ></div>

      <Button
        variant='text'
        onClick={onClick}
        className='ask-button'
      >
        <img src={askIcon} alt='' />

        HAVE QUESTIONS? ASK US!
      </Button>
    </div>
  )
}

export default VehicleBriefInfo
