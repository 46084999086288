import { Days } from 'website/constants'
import { BusinessHourView, BusinessHours } from 'website/api/schedule.types'

export const getBusinessHours = (businessHours?: BusinessHours): BusinessHourView[] => {
  return businessHours != null
    ? [
        {
          day: Days.Monday,
          opening: businessHours.mondayOpeningTime,
          closing: businessHours.mondayClosingTime
        },
        {
          day: Days.Tuesday,
          opening: businessHours.tuesdayOpeningTime,
          closing: businessHours.tuesdayClosingTime
        }, {
          day: Days.Wednesday,
          opening: businessHours.wednesdayOpeningTime,
          closing: businessHours.wednesdayClosingTime
        }, {
          day: Days.Thursday,
          opening: businessHours.thursdayOpeningTime,
          closing: businessHours.thursdayClosingTime
        }, {
          day: Days.Friday,
          opening: businessHours.fridayOpeningTime,
          closing: businessHours.fridayClosingTime
        }, {
          day: Days.Saturday,
          opening: businessHours.saturdayOpeningTime,
          closing: businessHours.saturdayClosingTime
        }, {
          day: Days.Sunday,
          opening: businessHours.sundayOpeningTime,
          closing: businessHours.sundayClosingTime
        }]
    : []
}

export const formatHoursForView = (opening: string | null, closing: string | null): string => {
  if (opening == null || closing == null) {
    return 'Closed'
  }

  return `${opening.replace(/^0/, '').toLowerCase()} - ${closing.replace(/^0/, '').toLowerCase()}`
}
