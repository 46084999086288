export type DealerProducts = DealerProduct[]

export interface DealerProduct {
  id: number
  order: number
  isActive?: boolean
  promoted?: boolean
  productType: string
  productName: string
  packages: Package[]
}

export interface Package {
  packageName: string
  coverages: Coverage[]
}
export interface Coverage {
  id: number
  termMiles: string | null
  termMonths: string | null
  deductibles: Deductible[]
}

export interface CoverageSummary {
  productType: string
  dealerRetailPrice: number
}

export interface Deductible {
  id: number
  amount: number
  dealerRetailPrice: number
  description: string | null
}

export interface DeductibleInfoType extends Deductible {
  termMiles: string | null
  termMonths: string | null
}

export enum ProductType {
  ServiceContract = 'service contract',
  PowertrainService = 'powertrain service contract',
  ComprehensiveService = 'comprehensive service contract',
  DentProtection = 'dent protection',
  GapInsurance = 'gap insurance',
  WheelTire = 'wheel/tire',
  CeramicCoating = 'ceramic coating',
  AppearanceProtection = 'appearance protection',
  Theft = 'theft',
  TireAndWheel = 'tire and wheel'
}
