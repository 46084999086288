import { css } from '@emotion/css'

export default css(`
  .cf-input-container {
    height: 48px;

    .cf-input, .cf-input-adornment {
      padding-top: 12px;
      padding-bottom: 12px;
      height: 24px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .cf-start-adornment {
      margin-left: -8px;
    }
  }
`)
