import type { FC } from 'react'
import { cnx } from '@carfluent/common'
import CLASS_NAME from './styles'

interface SectionRowItemProps {
  label: string
  value: string
  hasLeftSpace?: boolean
  classes?: {
    label?: string
    value?: string
  }
}

const SectionRowItem: FC<SectionRowItemProps> = ({
  label, value, hasLeftSpace = false, classes
}) => {
  return (
    <div className={cnx(CLASS_NAME, hasLeftSpace && 'with-left-space')}>
      <p className={classes?.label ?? 'text'}>{label}</p>
      <p className={classes?.value ?? 'text'}>{value}</p>
    </div>
  )
}

export default SectionRowItem
