interface DeferredPromise {
  resolve?: () => void
  reject?: () => void
  promise?: Promise<void>
}

const defer = (): DeferredPromise => {
  const deferred: DeferredPromise = {}

  const promise = new Promise<void>((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })

  deferred.promise = promise
  return deferred
}

export default defer
