import { css } from '@emotion/css'

export default css(`
  /* Styling the label to look like a radio button */
  display: inline-block;
  cursor: pointer;
  padding-left: 30px; /* space for our custom radio button */
  position: relative;
  margin-right: 20px; /* space between the radio buttons */
  font-family: Arial, sans-serif;
  line-height: 24px;

  /* Outer circle */
  :before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #000;
    box-sizing: border-box;
  }

  /* Hide the default radio button visually but remain accessible */
  input[type='radio'] {
    opacity: 0;
    position: absolute;
    width: 0;
  }

  /* Inner circle */
  span:before {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    width: 10px;
    height: 10px;
    border: 7px solid #000;
    left: 0px;
    top: 0px;
    content: '';
    transform: scale(0);
    transition: transform 0.1s ease;
  }

  /* Show inner circle when checked */
  input[type='radio']:checked + span:before {
    transform: scale(1);
  }

  /* Adjust the label's text alignment */
  span {
    vertical-align: middle;
  }
`)
