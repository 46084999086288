import pDebounce from 'p-debounce'

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE ?? ''
const IP_API_KEY = process.env.REACT_APP_IPDATA_KEY ?? ''
const FETCH_DEBOUNCE_DELAY = 500

class GeoApi {
  /**
   * If you need more fields please check https://docs.ipdata.co/docs/geolocation
   * @returns {ip, city, country_name, postal, latitude, longitude}
   */
  getGeoByIp = pDebounce(async (): Promise<API.GeoData | null> => {
    try {
      const res = await fetch(`https://api.ipdata.co?api-key=${IP_API_KEY}&fields=ip,city,country_name,postal,latitude,longitude`)
      const json = await res.json()
      return {
        ip: json.ip,
        city: json.city,
        country: json.country_name,
        zipCode: json.postal,
        latitude: json.latitude,
        longitude: json.longitude
      }
    } catch (e) {
      console.error(e)
      return null
    }
  }, FETCH_DEBOUNCE_DELAY, { before: true })

  getGeoByZip = pDebounce(async (zipCode: string): Promise<API.GeoData | null> => {
    try {
      const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${GOOGLE_API_KEY}`)
      const json = await res.json()
      const addrParts: API.GoogleAddressParts[] = json.results[0]?.address_components
      const geometry = json.results[0]?.geometry

      if (addrParts == null) {
        return null
      }

      return {
        city: addrParts.find(p => p.types.includes('locality'))?.long_name ?? '',
        country: addrParts.find(p => p.types.includes('country'))?.long_name ?? '',
        zipCode: addrParts.find(p => p.types.includes('postal_code'))?.long_name ?? '',
        latitude: geometry?.location?.lat ?? null,
        longitude: geometry?.location?.lng ?? null
      }
    } catch (e) {
      console.error(e)
      return null
    }
  }, FETCH_DEBOUNCE_DELAY, { before: true })
}

const GeoApiProvider = new GeoApi()

export default GeoApiProvider
