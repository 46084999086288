import { css } from '@emotion/css'

export default css(`
  > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4FCFA;
    border-radius: 8px;
    height: 56px;
    padding: 16px;
    box-sizing: border-box;
    margin: 32px 0px 0px;

    @media (max-width: 600px) {
      display: block;
      height: auto;

      a {
        margin-top: 8px;
      }
    }

    a {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-column-gap: 12px;
      text-decoration: none;
      align-items: center;
      color: #101010;
      font-size: 16px;

      svg {
        width: 24px;
        height: 24px;
      }

      span.info {
        font-weight: 500;
      }
    }
    
    @media (max-width: 768px) {
      margin: 24px 0px 0px;
    }
  }
`)
