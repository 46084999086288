const loadImg = async (src: string) => {
  const img = new Image()
  img.src = src

  return await new Promise((res) => {
    img.onload = () => {
      res(src)
    }
  })
}

export const preloadImages = async (images: string[]) => {
  return await Promise.all(images.map((src) => loadImg(src)))
}
