import type { FormValidation } from '@carfluent/common'
import { phoneNumber } from 'constants/validation/presets/common'
import type { LoginFormData } from './types'

const createFormValidation = (): FormValidation<LoginFormData> => {
  return {
    phoneNumber: phoneNumber
  }
}

export default createFormValidation()
