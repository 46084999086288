export const APP_CLS_NAME = 'g-carfluent-purchase-flow'

export const DEALER_FILTER_KEY = 'dealerId'

export const FILTER_NAMES = [
  'year', 'make', 'model', 'trim', 'bodyStyle', DEALER_FILTER_KEY,
  'mileage', 'price', 'exteriorColor', 'interiorColor', 'transmission', 'drivetrain', 'fuelType'
] as const

export const FILTER_TYPES = [
  'list', 'singleDropdown', 'multiDropdown', 'range', 'text', 'color', 'tile'
] as const

export const SORT_ORDERS = ['Ascending', 'Descending'] as const

export const BODY_STYLES = [
  'Coupe', 'Crossover', 'Convertible', 'Hatchback', 'Minivan/Van', 'Pickup',
  'Sedan', 'SUV', 'Wagon', 'Other'
] as const

export const COLORS = [
  'Beige', 'Black', 'Blue', 'Brown', 'Burgundy', 'Gold', 'Gray', 'Green', 'Orange',
  'Purple', 'Red', 'Silver', 'Tan', 'Teal', 'White', 'Yellow', 'Other'
] as const

export const TRANSMISSIONS = ['Automatic', 'Manual', 'Other'] as const

export const DRIVETRAINS = [
  'All-wheel drive', 'Four-wheel drive',
  'Front-wheel drive', 'Rear-wheel drive'
] as const

export const FUELTYPES = [
  'CNG', 'Electric', 'Ethanol - FFV', 'Gas', 'Hybrid - Electric'
] as const

export const LIST_FILTER_KEYS = [
  'make', 'model', 'trim', 'bodyStyle', DEALER_FILTER_KEY,
  'exteriorColor', 'interiorColor', 'transmission', 'drivetrain', 'fuelType'
] as const

export const RANGE_FILTER_KEYS = ['year', 'mileage', 'price'] as const

export const SEARCH_FILTER_KEY = 'text'

export const MAKES = [
  'Toyota', 'Chevrolet', 'Jeep', 'Honda', 'Nissan', 'Tesla',
  'Hyundai', 'Ram', 'Mercedes-Benz', 'Ford', 'Subaru',
  'Kia', 'Mazda', 'BMW', 'Audi'
] as const
