import type { FormValidation } from '@carfluent/common'

import {
  condition,
  amount,
  mechanicalIssues,
  paymentAmount,
  totalRepairCosts,
  haveAirbagsDeployed,
  string
} from 'website/utils/validation/presets'
import type { FormValues } from './types'

const createValidationRules = (): FormValidation<FormValues> => {
  return {
    trim: string,
    mileage: amount,
    conditions: condition,
    vehicleCollision: condition,
    haveAirbagsDeployed,
    totalRepairCosts,
    mechanicalIssues,
    paymentType: condition,
    paymentAmount
  }
}

export default createValidationRules()
