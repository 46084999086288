import { type FC } from 'react'
import { cnx, FormInput, FormMaskedInput, FormDatePicker } from '@carfluent/common'

import Text from 'website/components/Text'
import Button from 'website/components/Button'
import AddressPicker from 'website/components/_base/AddressPicker'
import { type GetPrequalifiedFormProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { useGetPrequalifiedForm } from 'website/components/GetPrequalifiedForm/hook'
import { FormIds } from 'website/components/GetPrequalifiedForm/hook/constants'
import GetPrequalifiedDisclosure from 'website/components/GetPrequalifiedDisclosure'
import ReCaptcha from 'components/common/ReCaptcha'

const GetPrequalifiedForm: FC<GetPrequalifiedFormProps> = ({
  nameInLayout = SupportedComponents.GetPrequalifiedForm,
  variant,
  componentProps
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponents.GetPrequalifiedForm, variant)
  const {
    onBlur,
    onChange,
    onSubmit,
    errors,
    touched,
    values,
    onChangeDate,
    onChangeAddress,
    isLoading,
    refRecaptcha
  } = useGetPrequalifiedForm()

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root)}>
      <div className='form-container'>
        <Text
          className='cf-form-head cf-personal-info'
          {...componentProps.TextHeadPersonalInfo}
        />

        <div className='cf-form-container-personal'>
          <FormInput
            id={FormIds.FirstName}
            onBlur={onBlur}
            onChange={onChange}
            value={values.firstName}
            touched={touched.firstName}
            error={errors.firstName}
            className='form-field'
            label='First name'
          />

          <FormInput
            id={FormIds.LastName}
            onBlur={onBlur}
            onChange={onChange}
            value={values.lastName}
            touched={touched.lastName}
            error={errors.lastName}
            className='form-field'
            label='Last name'
          />

          <FormMaskedInput
            id={FormIds.PhoneNumber}
            onBlur={onBlur}
            onChange={onChange}
            value={values.phoneNumber}
            touched={touched.phoneNumber}
            error={errors.phoneNumber}
            mask='phone'
            className='form-field'
            label='Phone number'
          />

          <FormDatePicker
            id={FormIds.BirthDate}
            value={values.birthDate}
            touched={touched.birthDate}
            error={errors.birthDate}
            onChange={onChangeDate}
            onBlur={onBlur}
            className='form-field'
            label='Date of birth'
          />

          <FormInput
            id={FormIds.Email}
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'Spacebar') {
                e.preventDefault()
              }
            }}
            onBlur={onBlur}
            onChange={onChange}
            className='form-field cf-full-width'
            label='Email'
          />

          <AddressPicker
            id={FormIds.Address}
            value={values.addressData}
            label='Address'
            className='form-field cf-full-width Autocomplete'
            onBlur={onBlur}
            onChange={onChangeAddress}
            error={errors.addressData}
            touched={touched.addressData}
          />
        </div>

        <GetPrequalifiedDisclosure
          className='prequalify-form-reminder-reminder'
        />

        <ReCaptcha
          badge='bottomleft'
          ref={refRecaptcha}
          size='invisible'
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA ?? ''}
        />

        <Button
          {...componentProps.Button}
          onClick={onSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default GetPrequalifiedForm
