import type { FC } from 'react'
import { Modal } from '@carfluent/common'

import { formatPhoneNumber } from 'utils/constants'

import CLASS_NAME from './styles'

interface PhoneNumberRecoveryModalProps {
  supportPhoneNumber?: string
  isModalOpen: boolean
  onCloseModal: () => void
}

const PhoneNumberRecoveryModal: FC<PhoneNumberRecoveryModalProps> = ({
  supportPhoneNumber = '',
  isModalOpen,
  onCloseModal
}) => {
  const displayValue = formatPhoneNumber(supportPhoneNumber)

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onCloseModal}
      title='Phone number recovery'
      className={CLASS_NAME}
    >
      <span className='cf-step-text'>1. Contact the support team, give us a call <p className='cf-support-team-number'> {displayValue}</p> </span>
      <span className='cf-step-text'>2. Provide the support team with information such as your name, address, and account details.</span>
      <span className='cf-step-text'>3. Provide additional security information if requested. </span>
      <span className='cf-step-text'>4. Wait for the support team to verify your identity.</span>
    </Modal>
  )
}

export default PhoneNumberRecoveryModal
