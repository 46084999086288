import { type FC, useCallback } from 'react'
import { Button, cnx } from '@carfluent/common'

import { SupportedComponents } from 'website/components/types'
import type { TradeInDetailsFormPageProps } from 'website/components/types'
import TradeInDetailsForm from 'website/components/TradeInDetailsForm'

import { useTradeInFormPage } from './hook'
import CLASS_NAME from './styles'

const TradeInDetailsFormPage: FC<TradeInDetailsFormPageProps> = ({
  nameInLayout = SupportedComponents.TradeInDetailsFormPage,
  states
}) => {
  const {
    isLoading,
    baseValues,
    onUpdateTradeInVehicle,
    onActionResult,
    onSubmit,
    onBack,
    tradeInVehicleDefault
  } = useTradeInFormPage({ states })

  const renderActionBar = useCallback((onSubmit: () => Promise<void>) => {
    return (
      <div className='cf-action-bar'>
        <Button
          onClick={onSubmit}
          className='cf-continue-btn'
          isLoading={isLoading}
        >
          CONTINUE
        </Button>

        <Button
          onClick={onBack}
          className='cf-back-btn'
          variant='text'
        >
          Back
        </Button>
      </div>
    )
  }, [isLoading])

  return (
    <TradeInDetailsForm
      onValuesChanges={onUpdateTradeInVehicle}
      baseValues={baseValues}
      make={tradeInVehicleDefault.make}
      model={tradeInVehicleDefault.model}
      trim={tradeInVehicleDefault.trim}
      vin={tradeInVehicleDefault.vin}
      className={cnx(nameInLayout, CLASS_NAME)}
      onSubmit={onSubmit}
      onActionResult={onActionResult}
      renderActionBar={renderActionBar}
    />
  )
}

export default TradeInDetailsFormPage
