import type { TradeInVehiclePricePayload, TradeInVehicle } from 'website/api/types/tradeIn'
import { YesNoIds } from 'website/components/TradeInDetailsForm/hook/parser'
import type { FormValues } from 'website/components/TradeInDetailsForm/hook/types'
import { serializeTrim } from 'website/components/CarDetailsFields/hook/serializer'

export const serializeData = ({
  haveAirbagsDeployed,
  mechanicalIssues,
  paymentType,
  make,
  model,
  trim,
  vin,
  year,
  mileage,
  paymentAmount,
  totalRepairCosts,
  vehicleCollision,
  conditions
}: Omit<TradeInVehicle, 'trim'> & FormValues): TradeInVehiclePricePayload => {
  const mechanicalIssueType = (mechanicalIssues?.subOptionValue ?? []).reduce((s, n) => s + n, 0)
  const payment = (paymentType?.subOptionValue ?? []).reduce((s, n) => s + n, 0)
  return ({
    hasAnyAccidents: vehicleCollision?.value === YesNoIds.Yes,
    haveAirbagsDeployed: vehicleCollision?.value !== YesNoIds.Yes
      ? null
      : haveAirbagsDeployed?.value === YesNoIds.Yes,
    mileage: mileage ?? 0,
    totalRepairCosts: vehicleCollision?.value !== YesNoIds.Yes
      ? null
      : totalRepairCosts,
    paymentAmount: paymentType?.value === YesNoIds.Yes ? paymentAmount : null,
    vehicleConditionType: conditions?.value ?? null,
    anyMechanicalIssues: mechanicalIssues?.value === YesNoIds.Yes,
    mechanicalIssueType: mechanicalIssueType === 0 ? null : mechanicalIssueType,
    stillMakingPayments: paymentType?.value === YesNoIds.Yes,
    payment: payment === 0 ? null : payment,
    vehicleMake: make,
    vehicleModel: model,
    vehicleTrim: serializeTrim(trim?.name ?? null) ?? '',
    vehicleVin: vin === '' ? null : vin,
    vehicleYear: year
  })
}
