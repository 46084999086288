export enum WebsiteRoutes {
  Main = '/',
  Inventory = '/inventory',
  Vehicle = '/dealer/:dealerId/vehicle/:vehicleId',
  TradeIn = '/trade',
  NotFound = '/404',
  GetPrequalifiedFormError = '/financing/form/error',
  GetPrequalifiedForm = '/financing/form',
  Unsubscribed = '/unsubscribed'
}

export enum FinancingSubRoutes {
  SubIncome = 'ssn-income',
  Success = 'success',
  Error = 'error'
}

export enum TradeInSubRoutes {
  Car = 'car',
  Offer = 'offer',
  NoCarInfo = 'no-car-info',
  Details = 'car-details',
  UserInfo = 'user-info'
}

const Trade = (subRoute: TradeInSubRoutes) => `${WebsiteRoutes.TradeIn}/${subRoute}`

const Vehicle = (dealerId: string, id: string | number): string =>
  WebsiteRoutes.Vehicle.replace('/:vehicleId', `/${id}`).replace('/:dealerId', `/${dealerId}`)

const Unsubscribed = (): string => WebsiteRoutes.Unsubscribed

export default {
  Vehicle,
  Trade,
  Unsubscribed
}
