import { FC } from 'react'
import { Grid, Typography } from '@material-ui/core'
import CollapsedArrowIcon from 'components/icons/collapsed_arrow'
import { SummarySectionClasses } from 'components/summary_sections/types'
import SectionRowItem from '../section_row_item'
import { useStyles } from '../styles'

interface SectionCollapseHeaderProps{
  label: string
  value: string
  isCollapseOpened: boolean
  isAlwaysOpened: boolean
  isRightArrow: boolean
  handleClick: () => void
  hasCollapsedContent: boolean
  classes?: SummarySectionClasses
}

const SectionCollapseHeader: FC<SectionCollapseHeaderProps> = (props) => {
  const styles = useStyles()
  const {
    label,
    value,
    isCollapseOpened,
    isAlwaysOpened,
    isRightArrow,
    handleClick,
    hasCollapsedContent,
    classes
  } = props

  if (!hasCollapsedContent || isAlwaysOpened) {
    return (
      <SectionRowItem
        label={label}
        value={value}
        classes={classes}
      />
    )
  }

  const arrowEl = (
    <CollapsedArrowIcon
      isCollapseOpened={isCollapseOpened}
      className={styles.iconWrapper}
    />
  )

  return (
    <div className={styles.collapseContainer} onClick={handleClick}>
      <div className={styles.row}>
        <Typography className={classes?.label ?? styles.text}>
          {label}
          {!isRightArrow && arrowEl}
        </Typography>
      </div>

      <div>
        <Typography className={classes?.value ?? styles.text}>
          {value}
          {isRightArrow && arrowEl}
        </Typography>
      </div>
    </div>
  )
}

export default SectionCollapseHeader
