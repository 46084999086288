import type { ConditionData } from 'website/components/types'
import { YesNoIds } from 'website/components/TradeInDetailsForm/hook/parser'
import { FormValues } from 'website/components/TradeInDetailsForm/hook/types'
import { FormIds } from 'website/components/TradeInDetailsForm/hook/constant'

import { amount, condition, conditionWithSubCondition } from './common'

export const mechanicalIssues = (val?: ConditionData): string | null => {
  return Number(val?.value) !== YesNoIds.No ? conditionWithSubCondition(val) : condition(val)
}

export const paymentAmount = (val?: number, values?: FormValues): string | null => {
  if (values?.[FormIds.PaymentType]?.value === YesNoIds.Yes) {
    return amount(val)
  }

  return null
}

export const totalRepairCosts = (val?: number, values?: FormValues): string | null => {
  if (values?.[FormIds.VehicleCollision]?.value === YesNoIds.Yes) {
    return amount(val)
  }

  return null
}

export const haveAirbagsDeployed = (val?: ConditionData, values?: FormValues): string | null => {
  if (values?.[FormIds.VehicleCollision]?.value === YesNoIds.Yes) {
    return condition(val)
  }

  return null
}
