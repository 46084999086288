import type { FC } from 'react'
import { Link } from 'react-router-dom'
import { UITools } from '@carfluent/common'
import ExtendedTypography from 'website/components/_base/ExtendedTypography'

import { isTextConfigCommon, SupportedComponents, TextProps } from 'website/components/types'
import formatString from 'website/utils/formatString'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { type FlexTemplate } from 'website/siteGenerator/types'

const { cn } = UITools

const defaultTemplate: FlexTemplate = {
  name: 'flex-row-start',
  props: { rowGap: '8px' },
  fullOffset: true
}

const Text: FC<TextProps> = ({
  nameInLayout,
  className,
  template,
  layout,
  value,
  label,
  config,
  icon,
  onClick,
  linkPath,
  format,
  rootRef,
  ...otherProps
}) => {
  const _template = template ?? (
    label == null
      ? { name: 'flex-full-row' }
      : defaultTemplate
  )

  const templateCls = useLayoutStyles({ template: _template, layout })
  const isHoverable = linkPath != null

  const isConfigCommon = isTextConfigCommon(config)

  return (
    <div
      ref={rootRef}
      className={cn(nameInLayout ?? SupportedComponents.Text, className)}
      {...otherProps}
    >
      <div className={templateCls}>
        {label != null && (
          <ExtendedTypography {...(isConfigCommon ? config : config?.label)}>
            {label}&nbsp;
          </ExtendedTypography>
        )}

        {icon != null ? <img src={icon} alt='icon' /> : null}

        <ExtendedTypography {...(isConfigCommon ? config : config?.value)} isHoverable={isHoverable}>
          {
            linkPath != null
              ? (
                  linkPath?.includes('http')
                    ? <a href={linkPath} target='_blank' rel='noopener noreferrer'>{value}</a>
                    : <Link onClick={onClick} to={linkPath}>{value}</Link>
                )
              : formatString(value?.toString() ?? '', format)
          }
        </ExtendedTypography>
      </div>
    </div>
  )
}

export default Text
