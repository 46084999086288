import type { FC } from 'react'
import { cnx, formatCurrency, formatNumber, Loader } from '@carfluent/common'
import { Link } from 'react-router-dom'

import LazyImage from 'website/components/LazyImage'

import SvgIcon from 'website/components/_base/SvgIcon'
import CalcWebsiteRoutes, { WebsiteRoutes } from 'website/routing/constants'
import { VehicleCardProps, SupportedComponents } from 'website/components/types'
import { calculatePrequalify, getTooltip } from 'website/components/VehicleCard/parser'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const DEFAULT_PREQUALIFY = {
  downPayment: null,
  apr: 0,
  term: null
}

const MILES_FORMAT_CONFIG = { mantissa: 0 }

const VehicleCard: FC<VehicleCardProps> = ({
  openVehicleOnClick = true,
  vehicle,
  variant,
  prequalify = DEFAULT_PREQUALIFY,
  emptyImage,
  monthlyPaymentDetails,
  isMonthlyPaymentLoading = false
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponents.VehicleCard, variant)

  if (vehicle == null) {
    return null
  }

  const parsedPrequalify = calculatePrequalify({
    ...prequalify,
    price: vehicle.salePrice
  })

  const isPrequalify = parsedPrequalify != null

  const tooltip = getTooltip({
    term: (isPrequalify ? prequalify?.term : monthlyPaymentDetails?.term) ?? null,
    apr: (isPrequalify ? prequalify?.apr : monthlyPaymentDetails?.apr) ?? 0
  })

  const paymentInfo = parsedPrequalify ?? (
    monthlyPaymentDetails != null
      ? { monthlyPayment: monthlyPaymentDetails.monthlyPayment, cashDown: monthlyPaymentDetails.cashDown }
      : null
  )

  const isDisabledLink = vehicle.id == null || !openVehicleOnClick
  const to = isDisabledLink
    ? WebsiteRoutes.NotFound
    : CalcWebsiteRoutes.Vehicle(vehicle.dealerId, vehicle.id)

  const imgSrc = vehicle.media?.thumbnail ?? vehicle.mainImageUrl ?? emptyImage
  const price = (vehicle.salePrice != null && vehicle.salePrice >= 1000)
    ? formatCurrency(vehicle.salePrice)
    : 'Call'

  const engineStr = (vehicle.engine != null || vehicle.fuelType != null)
    ? `${vehicle.engine ?? ''}${vehicle.fuelType ?? ''}`
    : 'Engine: -'

  const isPlaceholderImg = imgSrc === emptyImage

  return (
    <div className={componentStylesCls.root}>
      <Link
        className={cnx(
          'card-link',
          isDisabledLink && 'card-link-disabled',
          isPlaceholderImg && 'card-link-placeholder'
        )}
        to={to}
      >
        <div className='card-img-wrapper'>
          <LazyImage src={imgSrc} lazyLoad={false} />
          {isPlaceholderImg && (
            <div className='empty-img-subtitle'>
              Preparing for a close up <br />

              <span>Photos coming soon</span>
            </div>
          )}

          {
            (vehicle.dealershipCity != null || vehicle.distanceToDealership != null) && (
              <div className='card-distance-info'>
                <SvgIcon type='map' />
                <span>{vehicle.dealershipCity ?? 'som adress'}</span>
                {
                  vehicle.distanceToDealership != null && (
                    <span>&nbsp;({vehicle.distanceToDealership.toFixed(1)} mi away)</span>
                  )
                }
              </div>
            )
          }
        </div>

        <div className='content-wrapper'>
          <div className='car-info'>
            <p className='car-info-name'>{vehicle.year} {vehicle.make} {vehicle.model}</p>
            <p className='car-info-trim'>{vehicle.trim ?? '-'}</p>
            <p className='car-info-miles'>{formatNumber(vehicle.mileage ?? 0, MILES_FORMAT_CONFIG)} miles</p>
            <p className='car-info-engine'>{engineStr}</p>
          </div>

          <div className='price-info'>
            <p>{price}</p>
            <div className={cnx('price-details', isMonthlyPaymentLoading && 'is-loading')}>
              {isMonthlyPaymentLoading && (
                <Loader color='dark' />
              )}

              {
                paymentInfo != null && !isMonthlyPaymentLoading && (
                  <>
                    <p className='price-per-month'>
                      Est. {formatCurrency(paymentInfo.monthlyPayment)}/mo

                      {tooltip != null && (
                        <div className='prequalify-tooltip'>
                          {tooltip}
                        </div>
                      )}
                    </p>
                    <p className='cash-down'>
                      Cash down {formatCurrency(paymentInfo.cashDown)}
                    </p>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default VehicleCard
