export default {
  Socials: {
    default: {
      root: `
        display: flex;
        flex-direction: column;

        .cf-socials-title {
          font-weight: 700;
          font-size: 16px;
          margin-bottom: 16px;
        }
      `,
      content: `
        display: flex;
        flex-wrap: wrap;

        a {
          margin-right: 8px;
        }

        a:last-child {
          margin-right: 0px;
        }
      `
    }
  }
}
