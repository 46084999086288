import type { FormValidation } from '@carfluent/common'

import {
  firstName,
  lastName,
  dateTime,
  customerEmail,
  customerPhoneNumber
} from 'website/utils/validation/presets'

import type { FormValues } from './serializer'

export const createFormValidation = (isServiceType: boolean): FormValidation<FormValues> => {
  return {
    customerFirstName: firstName,
    customerLastName: lastName,
    customerEmail,
    customerPhoneNumber,
    ...(isServiceType ? { dateTime } : {})
  }
}
