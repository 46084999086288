import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import ComponentRoot from 'website/components/_base/ComponentRoot'
import Gallery from 'website/components/_base/Gallery'

import { GalleryVehicleViewProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import CLASS_NAME from './styles'

const GalleryVehicleView: FC<GalleryVehicleViewProps> = ({
  galleryMedia,
  media,
  template,
  layout,
  nameInLayout = SupportedComponents.GalleryVehicleView,
  variant,
  className,
  emptyListImage,
  noPhotoClassName
}) => {
  const componentStylesCls = useComponentStyles(nameInLayout, variant)
  const templateCls = useLayoutStyles({ template, layout })

  return (
    <ComponentRoot
      nameInLayout={nameInLayout}
      classes={{
        root: cnx(componentStylesCls.root, CLASS_NAME, className, templateCls),
        content: componentStylesCls.content
      }}
    >
      {
        (galleryMedia.length > 0 || media?.spinEmbedUrl != null)
          ? (<Gallery galleryMedia={galleryMedia} media={media} />)
          : (
            <div className={cnx('no-photo', noPhotoClassName)}>
              <img className='image-list-empty' alt='No photos placeholder' src={emptyListImage} />
              <div className='empty-img-subtitle'>
                Preparing for a close up <br />

                <span>Photos coming soon</span>
              </div>
            </div>
            )
      }
    </ComponentRoot>
  )
}

export default GalleryVehicleView
