import { Dealer } from 'api/types'
import { Days } from 'constants/names'

export interface BusinessHourView {
  day: Days
  opening: string | null
  closing: string | null
}

export const getBusinessHours = (dealer?: Dealer): BusinessHourView[] => {
  return dealer != null
    ? [{
        day: Days.Monday,
        opening: dealer.dealerBusinessHours?.mondayOpeningTime,
        closing: dealer.dealerBusinessHours?.mondayClosingTime
      },
      {
        day: Days.Tuesday,
        opening: dealer.dealerBusinessHours?.tuesdayOpeningTime,
        closing: dealer.dealerBusinessHours?.tuesdayClosingTime
      }, {
        day: Days.Wednesday,
        opening: dealer.dealerBusinessHours?.wednesdayOpeningTime,
        closing: dealer.dealerBusinessHours?.wednesdayClosingTime
      }, {
        day: Days.Thursday,
        opening: dealer.dealerBusinessHours?.thursdayOpeningTime,
        closing: dealer.dealerBusinessHours?.thursdayClosingTime
      }, {
        day: Days.Friday,
        opening: dealer.dealerBusinessHours?.fridayOpeningTime,
        closing: dealer.dealerBusinessHours?.fridayClosingTime
      }, {
        day: Days.Saturday,
        opening: dealer.dealerBusinessHours?.saturdayOpeningTime,
        closing: dealer.dealerBusinessHours?.saturdayClosingTime
      }, {
        day: Days.Sunday,
        opening: dealer.dealerBusinessHours?.sundayOpeningTime,
        closing: dealer.dealerBusinessHours?.sundayClosingTime
      }]
    : []
}

export const formatHoursForView = (opening: string | null, closing: string | null): string => {
  if (opening == null || closing == null) {
    return 'Closed'
  }

  return `${opening.replace(/^0/, '').toLowerCase()} - ${closing.replace(/^0/, '').toLowerCase()}`
}
