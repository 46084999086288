import { type FC } from 'react'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { SupportedComponentNames } from 'website/components/types'
import VehicleCardSkeleton from 'website/components/_base/VehicleCardSkeleton'

interface LoadingScreenProps {
  pageSize: number
}

const LoadingScreen: FC<LoadingScreenProps> = ({ pageSize }) => {
  const styles = useComponentStyles(SupportedComponentNames.VehicleCardSkeleton, 'default')

  return (
    <div className='vehicles-loading-screen'>
      {Array.from({ length: pageSize }).map((_, i) =>
        <VehicleCardSkeleton className={styles.root} key={i} />)}
    </div>
  )
}

export default LoadingScreen
