import React from 'react'
import { FormikValues } from 'formik'
import { observer } from 'mobx-react-lite'

import FormCTX, { FormCTXProps } from './form.context'

export interface FormProps<V extends FormikValues> extends FormCTXProps<V> {
  children: React.ReactNode
  className?: string
}

export type FormPartProps<V extends FormikValues> = Omit<FormProps<V>, 'children' | 'onSubmit'>

export function Form<V extends FormikValues = FormikValues> (props: FormProps<V>): JSX.Element {
  const {
    children, className,
    ...contextProps
  } = props

  const contextConfig = {
    ...contextProps
  }

  return (
    <form className={className}>
      <FormCTX.Provider value={contextConfig}>
        {children}
      </FormCTX.Provider>
    </form>
  )
}

export default observer(Form)
