import { css } from '@emotion/css'
import { Responsive } from 'constants/constants'

export default css(`
  &&.cf-modal-container {

    .cf-modal-content-scroll-wrapper {
      padding: 0;
    }

    .cf-modal-title {
      color: var(--titleMainColor);

      @media screen and (max-width: ${Responsive.MobileMaxWidth}px){
        border-bottom: 1px solid rgba(0, 0, 0, 0.24);
      }

      .cf-icon-button {
        background-color: #FFFFFF;
      }
    }

    .cf-modal-content {
      display: flex;
      flex-direction: column;
      min-width: 334px;
      padding: 40px 20px 0 20px;

      @media screen and (min-width: ${Responsive.MobileMaxWidth}px) {
        margin: 40px 40px 0;
        padding: 0;
      }

      @media screen and (min-width: ${Responsive.WideMobileScreen}px) {
        min-width: 290px;
      }
    }

    .cf-label-text {
      max-width: 296px;
      color: var(--textMainColor);
      font-family: Roboto;
      line-height: 20px;
      font-size: 14px;
      letter-spacing: 0.25px;
    }

    .cf-code-input-wrapper {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;

      .cf-input-container {
        width: 56px;
        height: 56px;
        padding: 0px;
        border: none;
        outline: none;

        input {
          text-align: center;
        }
      }
    }

    .cf-btn-resend-code, .cf-btn-resend-code-with-timer {
      align-self: flex-start;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }
`)
