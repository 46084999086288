import { css } from '@emotion/css'

export const BLANC = '#fff'
export const STROKE = 'rgba(0, 0, 0, 0.3)'
export const FILLED = '#FFCA28'

export default css(`
  display: flex;
  flex-direction: column;

  .stars {
    display: grid;
    grid-template-columns: repeat(5, 41px);
    grid-column-gap: 12px;

    label {
      cursor: pointer;
      font-size: 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        margin: 0px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      svg {
        width: 41px;
        height: 41px;

        g path {
          transition: fill 0.3s ease;
        }
      }
    }
  }
`)
