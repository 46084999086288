import { useEffect, useRef, type FC } from 'react'
import { Button, cnx, disableBodyScroll, enableBodyScroll } from '@carfluent/common'
import CLASS_NAME from './styles'

interface DrawerProps {
  className?: string
  isOpen?: boolean
  onClose: () => void
}

// DD-TODO:
// make its style global
// move to a base components and reuse its parts for nav drawer

const Drawer: FC<DrawerProps> = ({
  className,
  children,
  isOpen = false,
  onClose
}) => {
  const drawerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll()
    }

    return () => {
      enableBodyScroll()
    }
  }, [isOpen])

  return (
    <div ref={drawerRef} className={cnx(CLASS_NAME, className, isOpen && 'is-open', 'cf-filters-drawer')}>
      {children}
      <div className='cf-filters-drawer-footer-btn'>
        <Button
          onClick={onClose}
        >
          SHOW RESULTS
        </Button>
      </div>

      <div
        className='cf-filters-drawer-overlay'
        onClick={(e) => {
          e.stopPropagation()
          onClose()
        }}
        onTouchStart={(e) => {
          e.stopPropagation()
          onClose()
        }}
      />
    </div>
  )
}

export default Drawer
