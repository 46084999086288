export const ERROR_MESSAGE = 'No customer with this phone number'

export const ApiErrors = {
  NotFound: 'User not found',
  MaxAttempts: 'Max send attempts reached'
}

export const getErrorMessage = (apiMsg: string): string => {
  if (apiMsg.startsWith(ApiErrors.MaxAttempts)) {
    return apiMsg
  }

  return ERROR_MESSAGE
}
