import successFinancingFormImage from 'website/assets/success_financing_form.svg'
import financingAlarm from 'website/assets/financing_alarm.svg'
import financingCash from 'website/assets/financing_cash.svg'

const assets: Record<string, string> = {
  successFinancingFormImage,
  financingAlarm,
  financingCash,
}

export default assets