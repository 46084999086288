import checkEllipse from 'website/assets/check_ellipse.svg'
import noImpactImage from 'website/assets/no_impact_icon.svg'
import personalDataImage from 'website/assets/personal_data.svg'
import personalizationImage from 'website/assets/personalization_image.svg'

const assets: Record<string, string> = {
  checkEllipse,
  noImpactImage,
  personalDataImage,
  personalizationImage
}

export default assets