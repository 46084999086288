import type { FC } from 'react'
import { formatCurrency, formatPercentages } from 'utils/format_number'
import SectionRowItem from 'components/summary_sections/shared/section_row_item'

interface FinancingSectionProps {
  downPayment: number | null
  amountFinanced: number | null
  interestRate: number | null
  term: number | null
  lender: string | null
  monthlyPayment: number | null
  hasFinancingSection: boolean
}

const FinancingSection: FC<FinancingSectionProps> = (props) => {
  const {
    downPayment,
    amountFinanced,
    interestRate,
    term,
    lender,
    monthlyPayment,
    hasFinancingSection
  } = props

  if (!hasFinancingSection) {
    return null
  }

  return (
    <div className='bill-of-sale-financing-wrapper bill-of-sale-content'>
      <SectionRowItem
        label='Term'
        value={`${term ?? 0} months`}
      />
      <SectionRowItem
        label='Lender'
        value={lender ?? ''}
      />
      <SectionRowItem
        label='Interest rate'
        value={formatPercentages(interestRate ?? 0)}
      />
      <SectionRowItem
        label='Down payment'
        value={formatCurrency(downPayment ?? 0, '$0')}
      />
      <SectionRowItem
        label='Total financed'
        value={formatCurrency(amountFinanced ?? 0)}
      />

      <hr />

      <SectionRowItem
        classes={{ label: 'bold-label', value: 'bold-value' }}
        label='Monthly payment'
        value={`${formatCurrency(monthlyPayment ?? 0)}/mo`}
      />
    </div>
  )
}

export default FinancingSection
