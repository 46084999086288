import { type FC } from 'react'

export const CarfaxIcon: FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='121' height='24' viewBox='0 0 121 24' fill='none'>
      <path fillRule='evenodd' clipRule='evenodd' d='M115.88 21.0034C115.88 21.9622 115.081 22.7214 114.121 22.7214H2.75555C1.77585 22.7214 0.996094 21.9423 0.996094 21.0034V2.94455C0.996094 1.98567 1.79585 1.22656 2.75555 1.22656H114.121C115.101 1.22656 115.88 2.00565 115.88 2.94455V21.0034Z' fill='white' />
      <path fillRule='evenodd' clipRule='evenodd' d='M2.7393 0.726562C1.49969 0.726562 0.5 1.72539 0.5 2.92399V20.9828C0.5 22.2014 1.49969 23.1802 2.7393 23.1802H114.105C115.344 23.1802 116.364 22.1814 116.364 20.9828V2.92399C116.364 1.70541 115.364 0.726562 114.105 0.726562H2.7393ZM1.4597 21.0028V2.94396C1.4597 2.26476 2.03952 1.70542 2.7393 1.70542H114.105C114.824 1.70542 115.384 2.26476 115.384 2.94396V21.0028C115.384 21.682 114.805 22.2413 114.105 22.2413H2.7393C2.03952 22.2413 1.4597 21.682 1.4597 21.0028Z' fill='#101010' />
      <path d='M114.225 2.80469H96.75V21.0832H114.225V2.80469Z' fill='white' />
      <path d='M96.5078 21.3283H114.482V2.57031H96.5078V21.3283ZM114.002 20.8489H97.0077V3.04975H114.002V20.8489Z' fill='#101010' />
      <path d='M39.0063 2.82812H21.5117V21.1067H39.0063V2.82812Z' fill='#101010' />
      <path d='M21.2773 21.3361H39.2518V2.57812H21.2773V21.3361ZM38.7719 20.8567H21.7772V3.05756H38.7719V20.8567Z' fill='#101010' />
      <path d='M57.7602 2.82812H40.2656V21.1067H57.7602V2.82812Z' fill='#101010' />
      <path d='M40.0273 21.3361H58.0018V2.57812H40.0273V21.3361ZM57.5219 20.8567H40.5272V3.05756H57.5219V20.8567Z' fill='#101010' />
      <path d='M76.6152 2.82812H59.1406V21.1067H76.6152V2.82812Z' fill='#101010' />
      <path d='M58.9023 21.3361H76.8768V2.57812H58.9023V21.3361ZM76.3769 20.8567H59.3822V3.05756H76.3769V20.8567Z' fill='#101010' />
      <path d='M95.5102 2.82812H78.0156V21.1067H95.5102V2.82812Z' fill='#101010' />
      <path d='M77.7734 21.3361H95.7479V2.57812H77.7734V21.3361ZM95.248 20.8567H78.2533V3.05756H95.248V20.8567Z' fill='#101010' />
      <path d='M20.1308 2.82812H2.65625V21.1067H20.1308V2.82812Z' fill='#101010' />
      <path d='M2.41406 21.3361H20.3885V2.57812H2.41406V21.3361ZM19.8886 20.8567H2.89391V3.05756H19.8886V20.8567Z' fill='#101010' />
      <path d='M11.8159 18.5464C8.09704 18.5464 6.09766 15.7896 6.09766 12.074C6.09766 8.0387 8.45692 5.60156 11.8359 5.60156C14.9149 5.60156 16.1345 6.92002 16.9943 9.29723L13.8353 10.5358C13.3954 9.37713 12.9555 8.638 11.8159 8.638C10.3563 8.638 9.73653 10.0963 9.73653 12.074C9.73653 13.9917 10.3363 15.5299 11.8559 15.5299C12.9555 15.5299 13.4154 14.9306 14.0952 13.752L17.0343 15.3102C16.1745 16.9682 14.775 18.5464 11.8159 18.5464Z' fill='white' />
      <path d='M31.3538 13.2664H29.2745L29.5144 12.4474C29.9542 10.8493 30.1542 10.1501 30.3141 9.31107C30.4741 10.1501 30.674 10.8293 31.1139 12.4474L31.3538 13.2664ZM36.6322 18.2206L32.0736 5.69531H28.5547L23.9961 18.2206H27.7149L28.3947 16.0032H32.1536L32.8334 18.2206H36.6322Z' fill='white' />
      <path d='M50.7648 9.91729C50.7648 10.8162 50.325 11.3156 49.1853 11.3156H47.146V8.57886H49.2453C50.345 8.57886 50.7648 9.1382 50.7648 9.91729ZM54.4837 18.2675L51.9645 13.4931C53.344 12.8738 54.2238 11.7152 54.2238 9.81741C54.2238 6.8409 52.2644 5.74219 49.3253 5.74219H43.707V18.2675H47.126V14.0524H48.5255L50.5649 18.2875H54.4837V18.2675Z' fill='white' />
      <path d='M66.3613 8.69872V10.4367H70.3V13.3732H66.3613V18.2675H62.9023V5.74219H72.8393V8.69872H66.3613Z' fill='white' />
      <path d='M87.6776 13.3524H85.5982L85.8381 12.5333C86.278 10.9352 86.4779 10.236 86.6379 9.39701C86.7978 10.236 86.9978 10.9152 87.4376 12.5333L87.6776 13.3524ZM92.9559 18.3066L88.3973 5.78125H84.8984L80.3398 18.3066H84.0587L84.7385 16.0892H88.4973L89.1771 18.3066H92.9559Z' fill='white' />
      <path d='M107.243 21.2611L106.683 20.4021C106.184 19.623 105.704 18.784 105.364 18.1048C105.044 18.764 104.544 19.623 104.024 20.4021L103.404 21.2611H99.1457L103.264 15.5478L98.9258 9.375H103.344L104.104 10.5936C104.584 11.3727 105.124 12.2117 105.424 12.8709C105.744 12.2117 106.264 11.3727 106.763 10.5936L107.523 9.375H111.842L107.503 15.4679L111.562 21.2411H107.243V21.2611Z' fill='#101010' />
      <path d='M120.42 2.49053C120.42 2.94999 120.26 3.34952 119.94 3.66915C119.62 3.98877 119.24 4.14858 118.78 4.14858C118.32 4.14858 117.94 3.98877 117.62 3.66915C117.301 3.34952 117.141 2.94999 117.141 2.49053C117.141 2.01109 117.301 1.61156 117.62 1.29194C117.94 0.972311 118.32 0.8125 118.78 0.8125C119.24 0.8125 119.62 0.972311 119.94 1.29194C120.26 1.63154 120.42 2.03107 120.42 2.49053ZM120.16 2.49053C120.16 2.11098 120.02 1.77137 119.76 1.4917C119.5 1.21203 119.18 1.07219 118.8 1.07219C118.42 1.07219 118.08 1.21203 117.8 1.4917C117.521 1.77137 117.401 2.11098 117.401 2.51051C117.401 2.91004 117.54 3.22967 117.8 3.50934C118.08 3.78901 118.4 3.92884 118.78 3.92884C119.16 3.92884 119.48 3.78901 119.74 3.50934C120.02 3.22967 120.16 2.89006 120.16 2.49053ZM119.7 2.05104C119.7 2.23083 119.64 2.39065 119.5 2.49053C119.38 2.59041 119.22 2.65034 119.06 2.65034L119.66 3.4494H119.28L118.68 2.65034H118.34V3.4494H118.04V1.47172H119C119.22 1.47172 119.38 1.53165 119.5 1.63153C119.66 1.75139 119.7 1.89123 119.7 2.05104ZM119.4 2.05104C119.4 1.85128 119.24 1.7514 118.94 1.7514H118.36V2.39065H118.96C119.26 2.39065 119.4 2.29076 119.4 2.05104Z' fill='#101010' />
      <path d='M117.605 1.28579C117.285 1.62539 117.125 2.02492 117.125 2.50436C117.125 2.96382 117.285 3.38333 117.625 3.70296C117.945 4.02258 118.345 4.20237 118.804 4.20237C119.264 4.20237 119.664 4.04256 119.984 3.70296C120.304 3.36335 120.464 2.96382 120.464 2.50436C120.464 2.02492 120.304 1.62539 119.984 1.30577C119.664 0.966165 119.264 0.806352 118.804 0.806352C118.325 0.786376 117.925 0.946187 117.605 1.28579ZM117.665 3.64302C117.345 3.3234 117.185 2.94384 117.185 2.48438C117.185 2.02492 117.345 1.62539 117.645 1.30577C117.965 0.986141 118.345 0.826324 118.784 0.826324C119.224 0.826324 119.604 0.986141 119.924 1.30577C120.244 1.62539 120.384 2.02492 120.384 2.46441C120.384 2.92387 120.224 3.30342 119.924 3.62304C119.604 3.94267 119.224 4.10249 118.784 4.10249C118.365 4.12246 117.965 3.96265 117.665 3.64302Z' fill='#101010' />
      <path d='M117.803 1.46292C117.523 1.7426 117.383 2.10218 117.383 2.50171C117.383 2.90124 117.523 3.24084 117.803 3.52051C118.083 3.80018 118.422 3.94002 118.802 3.94002C119.182 3.94002 119.522 3.80019 119.782 3.50054C120.062 3.22087 120.202 2.86129 120.202 2.48173C120.202 2.0822 120.062 1.7426 119.782 1.46292C119.502 1.18325 119.182 1.02344 118.802 1.02344C118.422 1.02344 118.083 1.16328 117.803 1.46292ZM117.843 3.48056C117.583 3.20089 117.443 2.88126 117.443 2.50171C117.443 2.12215 117.583 1.78255 117.843 1.50288C118.103 1.22321 118.442 1.08337 118.802 1.08337C119.162 1.08337 119.462 1.22321 119.742 1.50288C120.002 1.78255 120.142 2.10218 120.142 2.48173C120.142 2.86129 120.002 3.20089 119.742 3.48056C119.482 3.76023 119.162 3.90007 118.802 3.90007C118.422 3.88009 118.103 3.74025 117.843 3.48056Z' fill='#101010' />
      <path d='M118.047 1.4375V3.47511H118.407V2.67605H118.727L119.306 3.45513L119.706 3.47511H119.766C119.766 3.47511 119.207 2.73598 119.167 2.67605C119.326 2.65607 119.466 2.61612 119.586 2.51623C119.726 2.39637 119.786 2.23656 119.786 2.05677C119.786 1.87699 119.726 1.73715 119.606 1.61729C119.486 1.49743 119.306 1.4375 119.087 1.4375H118.047ZM119.047 1.49743C119.247 1.49743 119.406 1.55736 119.526 1.65724C119.626 1.75713 119.686 1.89696 119.686 2.05677C119.686 2.23656 119.626 2.3764 119.506 2.47628C119.386 2.57617 119.247 2.63609 119.087 2.63609H119.027C119.027 2.63609 119.566 3.35525 119.626 3.43516H119.306L118.727 2.65607L118.367 2.63609H118.347V3.43516H118.107V1.51741H119.047V1.49743Z' fill='#101010' />
      <path d='M118.344 1.71875V2.41793H118.964C119.263 2.41793 119.423 2.29807 119.423 2.03838C119.423 1.91852 119.383 1.83861 119.303 1.79866C119.223 1.73873 119.104 1.71875 118.944 1.71875H118.344ZM118.944 1.77868C119.084 1.77868 119.203 1.79866 119.263 1.83861C119.323 1.87856 119.363 1.95847 119.363 2.03838C119.363 2.25812 119.223 2.358 118.964 2.358H118.384V1.77868H118.944Z' fill='#101010' />
    </svg>
  )
}
