import { FC } from 'react'
import CollapsedSection from '../shared/collapsed_section'
import SectionRowItem from '../shared/section_row_item'
import { formatCurrency } from 'utils/format_number'
import { SummarySectionClasses } from '../types'

interface MiscellaneousSectionProps {
  other1: number | null
  other1Description: string | null
  other2: number | null
  other2Description: string | null
  other3: number | null
  other3Description: string | null
  classes?: SummarySectionClasses
}

const MiscellaneousSection: FC<MiscellaneousSectionProps> = (props) => {
  const {
    other1,
    other1Description,
    other2,
    other2Description,
    other3,
    other3Description,
    classes
  } = props

  const totalMiscellaneousSum = Number(other1) + Number(other2) + Number(other3)
  const hasOther1 = Boolean(other1) && Boolean(other1Description)
  const hasOther2 = Boolean(other2) && Boolean(other2Description)
  const hasOther3 = Boolean(other3) && Boolean(other3Description)

  const hasCollapsedContent = Boolean(totalMiscellaneousSum)

  if (!hasCollapsedContent) {
    return null
  }

  return (
    <CollapsedSection
      label='Miscellaneous'
      value={formatCurrency(totalMiscellaneousSum)}
      hasCollapsedContent={hasCollapsedContent}
      classes={classes}
    >
      {hasOther1 && (
        <SectionRowItem
          label={other1Description ?? ''}
          value={formatCurrency(other1 ?? 0)}
          hasLeftSpace
          classes={classes}
        />
      )}
      {hasOther2 && (
        <SectionRowItem
          label={other2Description ?? ''}
          value={formatCurrency(other2 ?? 0)}
          hasLeftSpace
          classes={classes}
        />
      )}
      {hasOther3 && (
        <SectionRowItem
          label={other3Description ?? ''}
          value={formatCurrency(other3 ?? 0)}
          hasLeftSpace
          classes={classes}
        />
      )}
    </CollapsedSection>
  )
}

export default MiscellaneousSection
