import type { FC } from 'react'
import { useRef, useMemo } from 'react'
import { Popover, useModal } from '@carfluent/common'

import Text from 'website/components/Text'

import type { TooltipTextProps } from 'website/components/types'
import { createUseStyles } from './styles'

const TooltipText: FC<TooltipTextProps> = ({
  Tooltip: {
    maxWidth,
    Popover: PopoverProps,
    Text: TooltipTextProps
  },
  ...TextProps
}) => {
  const anchorEl = useRef<HTMLDivElement>(null)
  const { onCloseModal, onOpenModal, isModalOpen } = useModal()
  const popoverCls = useMemo(() => createUseStyles(maxWidth), [maxWidth])

  return (
    <>
      <Text
        {...TextProps}
        onMouseEnter={onOpenModal}
        onMouseLeave={onCloseModal}
        rootRef={anchorEl}
      />
      <Popover
        open={isModalOpen}
        anchorEl={anchorEl.current}
        className={popoverCls}
        {...PopoverProps}
      >
        <Text {...TooltipTextProps}/>
      </Popover>
    </>
  )
}

export default TooltipText