import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import { SupportedComponents, VehiclesFilterChipsProps } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import Button from 'website/components/Button'
import { removeQueryStringFilters, updateEntryInQs, updateListEntryInQs } from 'website/utils/qsfilters'
import { getAppliedFiltersNum } from 'website/utils/apiFilters'
import FilterChip from './FilterChip'
import { DEALER_FILTER_KEY } from 'website/configs'
import { type FilterName, VEHICLE_FILTERS_TYPE_MAP } from 'website/configs'

const useFiltersState = SharedStateHook<Store.VehiclesFilterState>(StoreBranches.VehiclesFilter)
const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const VehiclesFilterChips: FC<VehiclesFilterChipsProps> = ({
  nameInLayout = SupportedComponents.VehiclesFilterChips,
  states
}) => {
  const [filters] = useFiltersState(states.filters)
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const { appliedFilters } = filters

  const componentStyles = useComponentStyles(nameInLayout)

  const appliedFiltesNum = getAppliedFiltersNum(appliedFilters)
  
  const getDealerIdName = (dealerId: string): string => {
    const dealer = dealerInfo.dealerships.find(({ id }) => Number(dealerId) === id)
    
    if (dealer == null) {
      return dealerId
    }
    
    return dealer.dealerName
  }

  const onDelete = (id: FilterName, filter: string): void => {
    const type = VEHICLE_FILTERS_TYPE_MAP.get(id)

    if (type === 'range' || type === 'text' || type === 'singleDropdown') {
      updateEntryInQs(id, null)
    } else {
      updateListEntryInQs(id, filter, true)
    }

    if (id === 'make') {
      updateEntryInQs('model', null)
      updateEntryInQs('trim', null)
    }

    if (id === 'model') {
      updateEntryInQs('trim', null)
    }
  }

  if (appliedFiltesNum === 0) {
    return null
  }

  return (
    <div className={cnx(nameInLayout, componentStyles.root)}>
      {appliedFiltesNum > 0 && (
        <div className='cf-filter-chips'>
          {Object.entries(appliedFilters).map(([key, val]) => {
            const filterName = key as FilterName
            return (
              <FilterChip
                filterName={filterName}
                key={key}
                filter={val}
                onDelete={onDelete}
                getName={filterName === DEALER_FILTER_KEY ? getDealerIdName : undefined}
              />
            )
          })}

          <Button
            variant='text'
            onClick={() => removeQueryStringFilters()}
            text='Clear All'
          />
        </div>
      )}
    </div>
  )
}

export default VehiclesFilterChips
