import { css } from '@emotion/css'
import { Responsive } from 'constants/constants'

export default css(`
  &.cf-modal-container {

    .cf-modal-content-scroll-wrapper { padding: 0; }

    .cf-modal-root {
      @media screen and (max-width: ${Responsive.MobileMaxWidth}px) {
        width: 100%;

        .ModalIntro {
          display: none;
        }
      }
    }
  }
`)
