export default {
  InventoryPage: {
    default: {
      root: `
        .FiltersTopBar {
          background: #fff;
          border-radius: 12px;
          
          .g-input-search {
            .cf-input-container:not(.with-error):not(.disabled) {
              border-color: #DBDBDB;
              
              :hover {
                border-color: var(--mainColor);
              }
            }
          }
        }

        .VehicleInfiniteScroll .infinite-scroll-component {
          overflow: hidden !important;
        }

        @media (max-width: 1072px) {
          .FiltersTopBar, .VehiclesFilterChips, .VehicleInfiniteScroll {
            padding: 16px 40px;
          }
        }

        @media (max-width: 700px) {
          .FiltersTopBar, .VehiclesFilterChips, .VehicleInfiniteScroll {
            padding: 8px 16px;
          }
        }
      `
    }
  }
}
