import storage from './storage'
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'website/constants/localStorageKeys'

/**
 * From MDN
 * The StorageEvent is fired whenever a change is made to the Storage object.
 * This won't work on the same page that is making the changes -
 * it is really a way for other pages on the domain using the storage to sync any changes that are made.
 *
 * That is why we trigger events manually to make it more reliable to listen to the events where needed.
 */

export function setAccessToken (token: string): void {
  storage.set(ACCESS_TOKEN, token)
}

export function getAccessToken (): string {
  return storage.get(ACCESS_TOKEN) ?? ''
}

export function removeAccessToken (): void {
  storage.remove(ACCESS_TOKEN)
}

export function getRefreshToken (): string {
  return storage.get(REFRESH_TOKEN) ?? ''
}

export function setRefreshToken (token: string): void {
  storage.set(REFRESH_TOKEN, token)
}

export function removeRefreshToken (): void {
  storage.remove(REFRESH_TOKEN)
}
