import { type FC, useEffect, useRef, useState } from 'react'
import { Button, cnx, PopoverV2, useModal, type PopoverPropsV2 } from '@carfluent/common'

import LS from 'services/storage.service'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import isMultirooftop from 'website/utils/isMultirooftop'
import LocationMarkerIcon from 'website/components/icons/locationMarker'
import { type ZipCodeLocationProps, SupportedComponents } from 'website/components/types'
import useMediaQueryByBreakpoints from 'website/hooks/useMediaQueryByBreakpoints'
import { setZipCodeLocationInLS } from 'website/services/storage/zipCodeLocation'
import { isStringEmpty } from 'website/utils/isStringEmpty'

import ZipCodeLocationModal from '../ZipCodeLocationUpdateModal'
import { MAIN_CLASS, POPOVER_CLASS, POPPER_WIDTH } from './styles'
import { SHOULD_ZIP_CODE_POPOVER_OPEN } from 'website/constants/localStorageKeys'

const POPOVER_OPTIONS: PopoverPropsV2['options'] = {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-POPPER_WIDTH / 2, 0]
      }
    }
  ]
}

const isOneDealership = !isMultirooftop()

/**
 * DD-TODO:
 * refactor ZipCodeLocation display and effect logic.
 * It should be controlled from parent component.
 */

const useZipCodeLocationState = SharedStateHook<Store.ZipCodeLocationState>(StoreBranches.ZipCodeLocation)

const ZipCodeLocation: FC<ZipCodeLocationProps> = ({
  breakpoints,
  nameInLayout = SupportedComponents.ZipCodeLocation
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const refAnchorEl = useRef<HTMLDivElement | null>(null)
  const [{ zipCode }] = useZipCodeLocationState(
    defaultInstance(StoreBranches.ZipCodeLocation), setZipCodeLocationInLS
  )

  const { isModalOpen, onOpenModal, onCloseModal } = useModal()
  const { isMatchingBreakpoints } = useMediaQueryByBreakpoints(breakpoints)

  useEffect(() => {
    if (LS.get<boolean>(SHOULD_ZIP_CODE_POPOVER_OPEN) === true || isOneDealership) {
      return
    }

    LS.set(SHOULD_ZIP_CODE_POPOVER_OPEN, true)

    setIsPopoverOpen(true)
  }, [])

  const zipCodeMsg = isStringEmpty(zipCode) ? 'Enter your zip' : zipCode
  const onPopoverClose = (): void => { setIsPopoverOpen(false) }

  /**
   * we should leave it in grid layouts not to break anything
   */
  if (isOneDealership) {
    return <></>
  }

  return (
    <div className={cnx(nameInLayout, MAIN_CLASS)}>
      {/* we can not put onClick on a parent div because click events from modal and popover are captured there */}
      <div
        onClick={() => {
          if (!isPopoverOpen) {
            onOpenModal()
          }
        }}
        ref={refAnchorEl}
      >
        <LocationMarkerIcon />
        <p className='zipcode-label'>Your location</p>
        <p className='zipcode-message'>{zipCodeMsg}</p>
      </div>

      <PopoverV2
        className={POPOVER_CLASS}
        options={POPOVER_OPTIONS}
        isOpen={isMatchingBreakpoints === true && isPopoverOpen}
        onClose={onPopoverClose}
        anchorEl={refAnchorEl}
      >
        <>
          <p>Do you want to use this location?</p>
          <p>{zipCode}</p>
          <Button
            onClick={() => {
              onPopoverClose()
              onOpenModal()
            }}
            variant='outlined'
          >
            NO
          </Button>
          <Button onClick={onPopoverClose}>
            YES
          </Button>
        </>
      </PopoverV2>

      <ZipCodeLocationModal
        isOpen={isModalOpen}
        nameInLayout={undefined}
        onClose={onCloseModal}
      />
    </div>
  )
}

export default ZipCodeLocation
