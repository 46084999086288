import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'
import { TokenResponse } from 'api/types/user.types'
import LS from 'services/storage.service/storage'
import {
  getAccessToken,
  setAccessToken,
  setRefreshToken
} from 'services/storage.service/tokens'
import { checkTokenStatus, TokenValidationResult, TokenValidationStatus } from 'utils/authHelpers'
import {
  ACCESS_TOKEN,
  ACH_VALUE,
  CASH_VALUE,
  CLOUD_ACCESS_TOKEN,
  CHECK,
  CHECKOUT,
  CRYPTOCURRENCY_VALUE,
  HAS_DELIVERY,
  REFRESH_TOKEN,
  WORKFLOW_ID,
  WORKFLOW_VERSION,
  SHOULD_ZIP_CODE_POPOVER_OPEN
} from 'website/constants/localStorageKeys'

class Auth {
  accessToken: string
  isCleanQS: boolean = false

  get authResult (): TokenValidationResult {
    return checkTokenStatus(this.accessToken)
  }

  get isAuthorized (): boolean {
    return this.authResult.status === TokenValidationStatus.VALID
  }

  setAuth = async (data: TokenResponse): Promise<void> => {
    /**
     * we set access token even if token mismatches by roles
     * because we nee to allow logic of redirects based by user roles
     */
    if (data != null && checkTokenStatus(data.access_token).status !== TokenValidationStatus.INVALID_TOKEN) {
      setAccessToken(data.access_token)
      this.accessToken = data.access_token
      setRefreshToken(data.refresh_token)
    }
  }

  logout = (isCleanQS: boolean = false): void => {
    LS.remove(ACCESS_TOKEN)
    LS.remove(REFRESH_TOKEN)
    LS.remove(WORKFLOW_ID)
    LS.remove(WORKFLOW_VERSION)

    /**
     * Auxiliary keys created and used during the user flow
     */
    LS.remove(CHECK)
    LS.remove(CLOUD_ACCESS_TOKEN)
    LS.remove(HAS_DELIVERY)
    LS.remove(CHECKOUT)
    LS.remove(ACH_VALUE)
    LS.remove(CASH_VALUE)
    LS.remove(CRYPTOCURRENCY_VALUE)
    LS.remove(SHOULD_ZIP_CODE_POPOVER_OPEN)

    this.accessToken = ''
    this.isCleanQS = isCleanQS
  }

  constructor () {
    this.accessToken = getAccessToken()
    makeAutoObservable(this)
  }
}

export const AuthInstance = new Auth()
export const AuthCTX = createContext(AuthInstance)
export default AuthCTX
