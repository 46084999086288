import { getCssVars } from 'website/configs'
import { onlyDigits } from 'website/utils/onlyDigits'

export type AnimateCallback = ((callback: FrameRequestCallback) => number) & ((callback: FrameRequestCallback) => number)

export const getItemsGap = (itemWidth: string): number => {
  return itemWidth === '100%'
    ? 0
    : Number(getCssVars().galleryThumbnailGap.replace(/\D/g, ''))
}

export const calcItemWidth = (itemWidth: string, width: number): number => {
  const isPercentValue = itemWidth.includes('%')
  const itemWidthNum = onlyDigits(itemWidth)
  const itemsGap = getItemsGap(itemWidth)

  return isPercentValue
    ? Math.min(width, Math.ceil(width * itemWidthNum / 100) + itemsGap)
    : itemWidthNum + itemsGap
}

export const translateX = (num: number): string => `translateX(${num}px)`

export const bouncedAnimate = (
  animate: AnimateCallback,
  el: HTMLDivElement,
  val: number,
  bounceOffset: number,
  bounceDir: 1 | -1,
  isBounced: boolean,
  bounceDelay: number
): void => {
  animate(() => {
    if (isBounced) {
      el.style.transform = translateX(bounceDir * (val - bounceOffset))

      const t = setTimeout(() => {
        animate(() => { el.style.transform = translateX(val) })
        clearTimeout(t)
      }, bounceDelay)
    } else {
      el.style.transform = translateX(val)
    }
  })
}
