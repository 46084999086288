import type { FC } from 'react'
import { IconProps } from 'website/components/types'

const CheckedIcon: FC<IconProps> = ({ fill }) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='16' height='16' rx='4' fill={fill} />
      <path d='M3.59375 8.30878L4.90895 6.99358L8.41695 10.5016L7.10095 11.8168L3.59375 8.30878ZM7.10095 9.18558L11.4866 4.80078L12.8018 6.11598L8.41695 10.5016L7.10095 9.18478V9.18558Z' fill='white' />
    </svg>
  )
}

export default CheckedIcon
