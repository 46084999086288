import type { DetailedHTMLProps, TextareaHTMLAttributes } from 'react'
import { cn } from '@carfluent/common'

import WithLabel from './components/WithLabel'
import { useTextarea } from './hook'
import CLASS_NAME from './styles'

const MAX_ROWS = 3
const MIN_ROWS = 1

export type TextareaBaseProps = Omit<DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, 'value' | 'onChange'>

interface TextareaProps extends TextareaBaseProps {
  classNameRootLabel?: string
  minRows?: number
  maxRows?: number
  error?: string | null
  value?: string | null
  label?: string
  onChange?: (id: string, value: string) => void
  additionalHeight?: number
}

function Textarea ({
  value,
  classNameRootLabel = '',
  className = '',
  minRows = MIN_ROWS,
  maxRows = MAX_ROWS,
  onKeyUp,
  onKeyDown,
  onFocus,
  onBlur,
  label,
  placeholder,
  onChange,
  additionalHeight,
  id,
  ...otherProps
}: TextareaProps): JSX.Element {
  const {
    isScrollBarHidden,
    isActiveLabel,
    ...textareaProps
  } = useTextarea({
    minRows,
    maxRows,
    value: value ?? '',
    onKeyUp,
    onKeyDown,
    onFocus,
    onBlur,
    label,
    id,
    additionalHeight,
    onChange
  })

  return (
    <WithLabel
      label={label}
      className={classNameRootLabel}
      isActive={isActiveLabel}
    >
      <textarea
        id={id}
        className={cn(
          CLASS_NAME,
          isScrollBarHidden ? 'cf-without-scrollbar' : '',
          className
        )}
        placeholder={label == null ? placeholder : undefined}
        {...otherProps}
        {...textareaProps}
      />
    </WithLabel>
  )
}

export default Textarea
