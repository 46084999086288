import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  listName: {
    width: '100%'
  },

  listItem: {
    /**
     * given to support text-overflow = ellipsis on the nested components
     */
    minWidth: 0
  }
})
