import type { Vehicle } from 'api/types/workflow.types'
import capitalize from 'lodash/capitalize'
import { formatInteger } from 'utils/format_number'

import type { VehicleInfo } from './car_info.hook'

import car from 'assets/placeholder_car.svg'

const getVehicleInfo = (carInfoFromItsPage: VehicleInfo, workflowVehicle?: Vehicle | null): VehicleInfo => {
  const carImg = carInfoFromItsPage.mainImageUrl !== '' ? carInfoFromItsPage.mainImageUrl : car

  if (workflowVehicle == null) {
    return {
      modelTrim: carInfoFromItsPage.modelTrim,
      odometer: getOdometerValue(carInfoFromItsPage.odometer),
      vehicleStateYearMake: carInfoFromItsPage.vehicleStateYearMake,
      mainImageUrl: carImg
    }
  }

  const {
    imageUrl,
    modelYear,
    make,
    model,
    odometer,
    trim = ''
  } = workflowVehicle

  const modelTrim = trim ?? carInfoFromItsPage.modelTrim
  const odometerValue = getOdometerValue(odometer)
  const mainImageUrl = imageUrl ?? carImg
  const vehicleStateYearMake =
    modelYear != null
      ? `${modelYear} ${capitalizeWords(make)} ${capitalizeWords(model)}`
      : carInfoFromItsPage.vehicleStateYearMake

  return {
    modelTrim,
    odometer: odometerValue,
    vehicleStateYearMake,
    mainImageUrl
  }
}

export default getVehicleInfo

function getOdometerValue (odometer?: string | number): string {
  return odometer != null ? `${formatInteger(odometer)} miles` : ''
}

function capitalizeWords (value?: string | null): string {
  return value != null ? value.split(' ').map(capitalize).join(' ') : ''
}
