import type { FC } from 'react'

import { IconProps } from 'website/components/types'

const InfoIcon: FC<IconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <g clipPath="url(#clip0_6261_64800)">
        <path d="M9.66992 5.83659H11.3366V7.50325H9.66992V5.83659ZM9.66992 9.16992H11.3366V14.1699H9.66992V9.16992ZM10.5033 1.66992C5.90326 1.66992 2.16992 5.40326 2.16992 10.0033C2.16992 14.6033 5.90326 18.3366 10.5033 18.3366C15.1033 18.3366 18.8366 14.6033 18.8366 10.0033C18.8366 5.40326 15.1033 1.66992 10.5033 1.66992ZM10.5033 16.6699C6.82826 16.6699 3.83659 13.6783 3.83659 10.0033C3.83659 6.32826 6.82826 3.33659 10.5033 3.33659C14.1783 3.33659 17.1699 6.32826 17.1699 10.0033C17.1699 13.6783 14.1783 16.6699 10.5033 16.6699Z" fill="#101010"/>
      </g>
      
      <defs>
        <clipPath id="clip0_6261_64800">
        <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default InfoIcon
