import { css } from '@emotion/css'

export default css(`
  --offset: max(40px, calc((100vw - 1360px) / 2));

  overflow-x: hidden;

  .component-container {
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;

    h3 {
      padding: 0px 40px;

      @media (max-width: 375px) {
        padding: 0 16px;
      }
    }

    .body-style-container {
      display: grid;
      grid-template-columns: repeat(10, 121.6px);
      gap: 16px;
      padding: 0px 40px;
      overflow-x: auto;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .body-style-item {
        height: 120px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0px 4px 12px 0px #0000000A;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        img {
          max-width: 100px;
        }

        :hover {
          cursor: pointer;
        }

        p {
          margin-bottom: 16px;
        }
      }

      @media (max-width: 375px) {
        padding: 0 16px;
      }
    }

    @media (min-width: 768px) {
      grid-row-gap: 32px;

      h3, .body-style-container {
        padding: 0px var(--offset);
      }
    }
  }
`)
