import { useCallback, useMemo, useRef } from 'react'
import type { FC, FocusEvent, ReactNode } from 'react'
import { UI } from '@carfluent/common'
import identity from 'lodash-es/identity'

import Input from 'components/input'

import { containsTruthy } from 'utils/containsTruthy'
import usePlacesAutocomplete from './hook'
import { FullAddressParts } from './types'
import { emptyAddress, fullAddress } from './utils'

const { Autocomplete } = UI

export interface AddressAutocompleteProps extends Partial<{
  label: string
  disabled: boolean
  renderInput: (params: UI.AutocompleteRenderInputParams) => ReactNode
  onChange: (addr: FullAddressParts | null) => void
  onBlur: (evt: FocusEvent<HTMLDivElement>) => void
  error: boolean | string | null
  showDropDownIcon: boolean
  dataTestId: string
}> {
  value: FullAddressParts | null
  id: string
}

const AddressAutocomplete: FC<AddressAutocompleteProps> = ({
  id,
  value,
  disabled,
  onChange: _onChange,
  onBlur: _onBlur,
  renderInput,
  label = 'Address',
  showDropDownIcon = true,
  error,
  ...otherProps
}) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesAutocomplete()

  /**
   * See details here:
   * https://dev.azure.com/carfluent/CarFluent/_workitems/edit/6974
   *
   * TL;DR:
   * - don't select first option by onBlur if this onBlur is caused by onChange
   * - don't select first option by onBlur if there was no input from keyboard
   */
  const refWasInputChanged = useRef(false)
  const refIsJustSelected = useRef(false)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  /**
   * See details here:
   * https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/280/Address-Fields
   */
  const onBlur = useCallback((evt: FocusEvent<HTMLDivElement>) => {
    if ((placePredictions.length > 0) && !refIsJustSelected.current && refWasInputChanged.current) {
      _onChange?.(placePredictions[0])
    }

    refWasInputChanged.current = false
    refIsJustSelected.current = false
    _onBlur?.(evt)
  }, [_onBlur, _onChange, placePredictions])

  const onChange = useCallback((_, value: FullAddressParts | null) => {
    refWasInputChanged.current = false
    refIsJustSelected.current = true
    _onChange?.(value)
  }, [_onChange])

  const defaultRenderInput = useMemo(() => (params: UI.AutocompleteRenderInputParams): JSX.Element => {
    const err = containsTruthy(error)
    return <Input label={label} name={id} variant='filled' {...params} error={err} />
  }, [label, error, id])

  const getOptionLabel = useCallback((option: FullAddressParts): string => {
    return fullAddress(option)
  }, [])

  const getOptionSelected = useCallback((option: FullAddressParts, value?: FullAddressParts): boolean => {
    return fullAddress(option) === fullAddress(value)
  }, [])

  const onInputChange = useCallback(async (_evt, value) => {
    refWasInputChanged.current = true
    await getPlacePredictions({ input: value })
  }, [getPlacePredictions])

  const renderOption = useCallback((option: FullAddressParts): ReactNode => {
    return <span>{fullAddress(option)}</span>
  }, [])

  // ========================================== //

  return (
    <Autocomplete
      {...otherProps}
      id={id}
      disabled={disabled}
      filterOptions={identity}
      getOptionLabel={getOptionLabel} // required to show all place predictions
      getOptionSelected={getOptionSelected}
      loading={isPlacePredictionsLoading}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={onInputChange}
      renderOption={renderOption}
      options={placePredictions}
      renderInput={renderInput ?? defaultRenderInput}
      value={value ?? emptyAddress}
      {...(!showDropDownIcon ? { popupIcon: null } : undefined)}
    />
  )
}

export default AddressAutocomplete
