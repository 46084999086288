export interface GetVehicleVideoUrlProps {
  fileVersion?: string
  fileId?: number | null
}

export const getVehicleVideoUrl = ({
  fileId,
  fileVersion = ''
}: GetVehicleVideoUrlProps): string => {
  const linkToEnv = process.env.REACT_APP_FILES ?? ''

  return `${linkToEnv}/api/v1/Download/vehicle-videos/${fileVersion}/${fileId ?? ''}`
}
