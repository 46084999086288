import type { Nullable } from 'types'

import type { BusinessDetailsDto } from './DTOs'
import { DocumentDetails } from './files'
import type {
  ApplicantFinancingDetails,
  CoApplicantFinancingDetails,
  FinanceApplicationLenderDecisionDetails,
  FinancingCalculatorDetails
} from './financing.types'

export const enum WorkflowTypeId {
  Personal = 1,
  Business = 2,
  Wholesale = 3
}

export enum WorkflowStateName {
  Pending = 'Pending',
  Completed = 'Completed',
  Skipped = 'Skipped',
  Deleted = 'Deleted',
  Canceled = 'Canceled',
  Inactive = 'Inactive'
}

export type WorkflowState =
  WorkflowStateName.Pending |
  WorkflowStateName.Canceled |
  WorkflowStateName.Completed |
  WorkflowStateName.Deleted

export enum VehicleStateName {
  Active = 'Active',
  Inactive = 'Inactive',
  Sold = 'Sold',
  Deleted = 'Deleted'
}

export type WorkflowStepState =
  | 'Pending'
  | 'Completed'
  | 'Skipped'
  | 'Failed'
  | 'Canceled'
  | 'Inactive'

export enum WorkflowStepName {
  Initial = 'Initial',
  YourDetails = 'YourDetails',
  PersonalDetails = 'PersonalDetails',
  TradeIn = 'TradeIn',
  Financing = 'Financing',
  ServiceAndProtection = 'ServiceAndProtection',
  CreditApplication = 'CreditApplication',
  DealerCheck = 'DealerCheck',
  DealerCheckCompleted = 'DealerCheckCompleted',
  Documents = 'Documents',
  SignOnline = 'SignOnline',
  PickupAndDelivery = 'PickupAndDelivery',
  Payments = 'Payments',
  PaymentSummary = 'PaymentSummary'
}

export enum DealFinancingTypeId {
  Financing = 1,
  Cash,
}

export interface DefaultAmountFeeSettingDto {
  labelName: string | null
  isEnabled: boolean
}
export interface CoverageFeeSettingsDto {
  buyerTagSetting: DefaultAmountFeeSettingDto
  carDeliverySetting: DefaultAmountFeeSettingDto
  dealerHandlingFeeSetting: DefaultAmountFeeSettingDto
  dealerInventoryTaxSetting: DefaultAmountFeeSettingDto
  emissionsSetting: DefaultAmountFeeSettingDto
  inspectionFeeSetting: DefaultAmountFeeSettingDto
  plateTransferFeeSetting: DefaultAmountFeeSettingDto
  registrationFeeSetting: DefaultAmountFeeSettingDto
  titleFeeSetting: DefaultAmountFeeSettingDto
  serviceContractTaxable: boolean
  gapTaxable: boolean
  appearanceProtectionTaxable: boolean
  tireAndWheelTaxable: boolean
  theftTaxable: boolean
}

export interface Workflow {
  id: number
  rowVersion: string
  dealer: Dealer
  vehicle?: Vehicle | null
  workflowState: WorkflowState
  workflowStateId: number
  workflowTypeId: number
  workflowSteps: WorkflowStep[]
  isGabiEmailSended: boolean
  dealFinanceTypeId: DealFinancingTypeId | null
  coverageFeeSettings: CoverageFeeSettingsDto
  financeInfo: {
    dealerInventoryTaxAmount: number | null
    resultSalesTaxAmount: number | null
    resultSalesTaxPercent: number | null
  }
  dealFees: {
    buyerTag: number
    carDelivery: number
    dealerHandlingFee: number
    dealerInventoryTax: number
    emissions: number
    inspectionFee: number
    other1: number
    other2: number
    other3: number
    other1Description: string
    other2Description: string
    other3Description: string
    plateTransferFee: number
    registrationFee: number
    titleFee: number
  }
  /**
   * Steps-specific data.
   */
  personalDetails: PersonalDetails | null
  tradeInDetails: TradeInDetails | null
  financingCalculatorDetails: FinancingCalculatorDetails | null
  applicantFinancingDetails: ApplicantFinancingDetails | null
  coApplicantFinancingDetails: CoApplicantFinancingDetails | null
  financeApplicationLenderDecisionDetails: FinanceApplicationLenderDecisionDetails | null
  coverageDetails: CoverageDetails[]
  paymentDetails: PaymentDetails[]
  documents: DocumentDetails[]
  checkoutDetails: CheckoutDetails | null
  businessDetails: BusinessDetailsDto | null
}

export interface Dealer {
  id: number
  dealerName: string
  address1: string
  address2: string | null
  city: string
  email: string
  leadCmsEmail: string
  logoUrl: string
  phone: string
  state: string
  subscriptionTypeName: string
  termsUrl: string
  zipCode: string
  dealerFeeMarkupSettings: {
    id: number
    dealerCoverageViewTemplate: null | {
      id: number
      name: string
      description: string
      imageUrl: string
    }
    dealerCoverageViewTemplateId: number
    documentFee: number
    excellentInterestRate: number
    goodInterestRate: number
    averageInterestRate: number
    belowAverageInterestRate: number
    maxCardPaymentAmount: number
    registrationFee: number
  }
  dealerBusinessHours: {
    mondayOpeningTime: string | null
    mondayClosingTime: string | null
    tuesdayOpeningTime: string | null
    tuesdayClosingTime: string | null
    wednesdayOpeningTime: string | null
    wednesdayClosingTime: string | null
    thursdayOpeningTime: string | null
    thursdayClosingTime: string | null
    fridayOpeningTime: string | null
    fridayClosingTime: string | null
    saturdayOpeningTime: string | null
    saturdayClosingTime: string | null
    sundayOpeningTime: string | null
    sundayClosingTime: string | null
  }
}

export interface Vehicle {
  vehicleId: number
  workflowId: number
  model: string
  vin: string | null
  make: string | null
  listPrice: number
  modelYear: number
  odometer: number
  imageUrl: string | null
  trim: string | null
  vehicleState: VehicleStateName | null // AS-NOTE: can be null, if vehicle not from inventory
}

export enum PaymentStatusId {
  Pending,
  Processing,
  Completed,
  Failed = 6
}

export enum TransactionTypeId {
  Card = 1,
  ACH,
  Cash,
  Cryptocurrency,
  Deposit
}

export interface TradeInDetails {
  vehicleVin: string
  mileage: number
  payment: string
  tradeInVehicleProviderType: string
  price: number
  anyMechanicalIssues: boolean | null
  hasAnyAccidents: boolean | null
  haveAirbagsDeployed: boolean | null
  mechanicalIssueType: boolean | null
  paymentAmount: number | null
  stillMakingPayments: boolean | null
  totalRepairCosts: number | null
  vehicleMake: string | null
  vehicleModel: string | null
  vehicleTrim: string | null
  vehicleYear: number | null
}

export interface PaymentDetails {
  transactionTypeId: TransactionTypeId
  amount: number
  amountPayed: number
  paymentStatusId: PaymentStatusId
}

export interface CoverageDetails {
  id: number
  workflowStepId: number
  deductibleAmount: number
  deductibleId: number
  productName: string
  providerName: string | null
  fullProductName: string
  coverageName: string
  deductibleDescription: string | null
  dealerRetailPrice: number
  termMiles: string | null
  termMonths: string | null
  productType: string
  dealCoverageDefaultId: number
}

export interface WorkflowStep {
  order: number
  templateStepId: number
  workTemplateStep: string
  workflowStepState: WorkflowStepState
  workflowStepStateId: number
}

export interface WorkflowInitData {
  dealerId: number | string
  vin: string
  vehicleId?: string | number
  dealId?: string
  imageUrl?: string
  price?: string
  odometer?: string
}

export interface WorkflowTradeInData {
  mileage: number
  hasAnyAccidents: boolean
  anyMechanicalIssues: boolean
  stillMakingPayments: boolean
}

export interface PersonalDetailsPayload {
  email: string
  firstName: string
  lastName: string
  birthDate: string
  phoneNumber: string
  address: string
  city: string
  state: string
  zipCode: string
  apt: string

  // AZ-NOTE: these props are in Swagger, but currently we are not sending them (don't know, why).
  // county: string | null
  // secondaryAddress: string | null
}

export type PersonalDetailsPayloadWithId = PersonalDetailsPayload & { id: number }

export type PersonalDetails = Nullable<PersonalDetailsPayload> & {
  id: number
  county: string | null
  socialSecurityNumber: string | null
}

export interface WorkflowPreview {
  workflowId: number
  vehicleMake: string
  vehicleModel: string
  vehicleModelYear?: string
  vehicleTrim: string
  vehicleOdometer?: number
  vehiclePrice?: number
  vehicleImageUrl: string
  workflowState: WorkflowState
}

export interface CheckoutDetails {
  id: number
  workflowStepId: number
  hasDelivery: boolean | null
  pickupDeliveryDate: Date | null
  deliveryAddress: string | null
  deliveryState: string | null
  deliveryCity: string | null
  deliveryZipCode: string | null
}

export interface DataForRequest {
  workflowId: number | null
  workflowVersion: string | null
  accessToken: string
  isAllArgsFromParams: boolean
}
