import { type FC, useState, useEffect } from 'react'
import { Slider } from '@material-ui/core'
import { NumberInput } from '@carfluent/common'
import { type GeneratedFilterProps } from 'website/configs'
import useStyles from './styles'

export interface ChangePayload {
  from?: number
  to?: number
}

const RangeFilter: FC<GeneratedFilterProps<API.RangeFilter>> = ({
  config,
  id,
  min = 0,
  max = 0,
  onChange: _onChange,
  startAdornment,
  step = 1,
  thousandSeparator,
  type
}) => {
  const styles = useStyles()

  const { from, to } = config

  const [fromNum, setFromNum] = useState(min)
  const [toNum, setToNum] = useState(max)

  const onChange = (range: string): void => {
    _onChange(id, range, type)
  }

  /**
   * we use local state to show immediate changes in UI.
   * that is why we need to sync outside changes to local state.
   */
  useEffect(() => {
    setFromNum(from ?? min ?? 0)
    setToNum(to ?? max ?? 0)
  }, [min, max, from, to])

  return (
    <div className='cf-range-filter'>
      <div className='cf-range-filter-inputs'>
        <NumberInput
          endAdornment={null}
          id={`${id}-from`}
          placeholder='From'
          value={fromNum}
          min={min}
          max={toNum}
          startAdornment={startAdornment}
          thousandSeparator={thousandSeparator}
          onChange={(_field, val: number) => {
            setFromNum(val)
            onChange(`${val}-${toNum}`)
          }}
        />

        <NumberInput
          endAdornment={null}
          id={`${id}-to`}
          placeholder='To'
          value={toNum}
          min={fromNum}
          max={max}
          startAdornment={startAdornment}
          thousandSeparator={thousandSeparator}
          onChange={(_field, val: number) => {
            setToNum(val)
            onChange(`${fromNum}-${val}`)
          }}
        />
      </div>

      <div>
        <Slider
          aria-labelledby='range-slider'
          classes={{
            root: styles.sliderRoot,
            track: styles.sliderTrack,
            rail: styles.sliderRail,
            thumb: styles.sliderThumb
          }}
          min={min}
          max={max}
          step={step}
          onChange={(_e, value) => {
            if (!Array.isArray(value)) {
              return
            }

            const from = value[0]
            const to = value[1]

            setFromNum(from)
            setToNum(to)
            onChange(`${from}-${to}`)
          }}
          value={[fromNum, toNum]}
          valueLabelDisplay='off'
        />
      </div>
    </div>
  )
}

export default RangeFilter
