import { css } from '@emotion/css'

const flexBaseSettings = `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export default css(`
  ${flexBaseSettings}

  .text {
    letter-spacing: 0.5;
    align-items: center;
    color: #101010;
  }

  &.with-left-space {
    padding-left: 15px;
  }
`)
