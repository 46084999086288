import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import lock from 'assets/lock_route.svg'
import QontoStepIcon from './QontoStepIcon'
import useWorkflowStepper from './hook'
import { useStyles } from './styles'
import { WorkflowStepName } from 'api/types'

interface WorkflowStepperProps {
  toggleDrawer: (isOpen?: boolean) => void
}

const WorkflowStepper: FC<WorkflowStepperProps> = ({ toggleDrawer }) => {
  const styles = useStyles()
  const {
    activeStepIndex,
    navigationSteps,
    handleClick,
    getRoutePathname,
    isStepLocked,
    getDataTestId,
    isStepCompleted: _isStepCompleted,
    getNavigationStep
  } = useWorkflowStepper({ toggleDrawer })

  return (
    <div>
      <Stepper
        classes={{ root: styles.rootStepper }}
        activeStep={activeStepIndex}
      >
        {navigationSteps.map((step) => {
          const title = getNavigationStep(step.workTemplateStep as WorkflowStepName)

          if (title === null) {
            return null
          }

          const isStepCompleted = _isStepCompleted(step)

          return (
            <Step
              data-test-id={getDataTestId(step.workTemplateStep)}
              classes={{ root: styles.rootStep }}
              key={step.workTemplateStep}
            >
              <StepLabel
                classes={{
                  label: styles.rootStepLabel,
                  iconContainer: styles.iconContainerStep
                }}
                StepIconComponent={QontoStepIcon}
              >
                <NavLink
                  style={() => (isStepCompleted ? { fontWeight: 500 } : {})}
                  to={getRoutePathname(title, step.workTemplateStep)}
                  className={`${styles.link} ${!isStepCompleted ? styles.disabledLink : ''}`}
                  onClick={handleClick}
                >
                  {title} {isStepLocked(title, step.workTemplateStep) && <img src={lock} alt='Locked' />}
                </NavLink>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}

export default observer(WorkflowStepper)
