import { css } from '@emotion/css'

const ICON_SIZE = 24

export default css(`
  input:hover ~ .cf-icon {
    background: #ebf1f5;
  }

  input:hover ~ .cf-checked-icon {
    background: #000;
  }
   
  input:disabled ~ .cf-icon {
    box-shadow: none;
    background: rgba(206,217,224,.5);
  }
   
  .cf-icon {
    border-radius: 50%;
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    box-shadow: inset 0 0 0 1px #212121, inset 0 -1px 0 #212121;
    background: #FFFFFF;
  }
  
  .cf-checked-icon {
    background: #000;
    
    &:before {
      display: block;
      width: ${ICON_SIZE}px;
      height: ${ICON_SIZE}px;
      background-image: radial-gradient(#fff,#fff 28%,transparent 32%);
      content: "";
    }
  }
`)
