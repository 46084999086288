export default {
  FooterInfo: {
    default: {
      content: `
        display: flex;
        justify-content: space-between;

        .DealerInfoFragment {
          margin-right: 16px;
          font-size: 16px;

          .phone-block {
            margin: 0px 24px 8px 0px;

            .phone-value {
              font-weight: 700;
            }
          }

          @media (min-width: 1200px) {
            p {
              display: inline-block;
            }
          }
        }

        .ExtendedImage {
          width: 160px;
          margin-left: auto;

          @media (max-width: 768px) {
            align-self: flex-end;
            margin-bottom: 4px;
          }
        }
      `
    }
  }
}
