import prequalifiedIcon from 'website/assets/prequalified.svg'
import carIcon from 'website/assets/car.svg'
import lenderIcon from 'website/assets/lender.svg'
import carImage2 from 'website/assets/carImage2.png'
import noCarsIcon from 'website/assets/no-cars.svg'
import emptyImage from 'website/assets/ds_empty_card.svg'

const assets: Record<string, string> = {
  prequalifiedIcon,
  carIcon,
  noCarsIcon,
  carImage2,
  lenderIcon,
  emptyImage
}

export default assets
