import { FC, useCallback } from 'react'

import Button from 'website/components/Button'

import type { GetPrequalifiedButtonProps } from 'website/components/types'

const GetPrequalifiedButton: FC<GetPrequalifiedButtonProps> = ({
  variant,
  variantProps,
  text,
  onClick
}) => {
  const handleOnClick = useCallback(() => {
    onClick?.()
  }, [onClick])

  return (
    <Button
      nameInLayout='GetPrequalifiedButton'
      text={text ?? 'GET PRE-QUALIFIED'}
      variant={variant ?? 'search'}
      variantProps={variantProps}
      onClick={handleOnClick}
    />
  )
}

export default GetPrequalifiedButton
