import { useMemo } from 'react'
import { Dropdown2 } from '@carfluent/common'

import { VEHICLE_SORTING_OPTIONS, type VehicleSortingItem } from 'website/configs'
import { SupportedComponents, VehiclesSortingProps } from 'website/components/types'
import SharedStateHook, { StoreBranches } from 'website/store'
import SvgIcon from 'website/components/_base/SvgIcon'
import { updateMultipleEntriesInQs } from 'website/utils/qsfilters'

import { useComponentStyles } from 'website/styles/useComponentStyles'

const useSharedSorting = SharedStateHook<Store.VehiclesSortingState>(StoreBranches.VehiclesSorting)

export function VehiclesSorting ({
  label,
  mode = 'select',
  nameInLayout = SupportedComponents.VehiclesSorting,
  popoverClassName,
  states,
  startAdornmentIcon,
  variant
}: VehiclesSortingProps): JSX.Element {
  const componentStyles = useComponentStyles(nameInLayout, variant)
  const [sorting] = useSharedSorting(states.sorting)
  const { appliedSorting } = sorting

  const value = useMemo(() => VEHICLE_SORTING_OPTIONS.find(item => {
    return (
      (item.sortField === appliedSorting.sortField) && (item.sortOrder === appliedSorting.sortOrder)
    ) ?? null
  }), [appliedSorting])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onChange = (_id: string, value: VehicleSortingItem | null): void => {
    updateMultipleEntriesInQs({
      sortOrder: value?.sortOrder ?? null,
      sortField: value?.sortField ?? null
    })
  }

  // ========================================== //
  const startAdornment = startAdornmentIcon == null
    ? null
    : <SvgIcon type={startAdornmentIcon} />

  return (
    <div className={nameInLayout}>
      <div className={componentStyles.root}>
        <Dropdown2
          disableClearable
          id='vehicles-sorting'
          onChange={onChange}
          label={label}
          options={VEHICLE_SORTING_OPTIONS}
          popoverClassName={popoverClassName}
          renderOption={(item: VehicleSortingItem) => item.title}
          startAdornment={startAdornment}
          mode={mode}
          value={value}
        />
      </div>
    </div>
  )
}

export default VehiclesSorting
