import { type FC } from 'react'
import Checkbox from 'website/components/_base/Checkbox'
import getName from 'website/utils/getName'
import VEHICLE_COLOR_MAP from 'website/constants/vehicleColorMap'
import MulticolorImg from 'website/assets/multicolor.png'
import { cnx } from '@carfluent/common'

export interface FilterListItemProps {
  checked: boolean
  item: API.ListFilterItem
  isColorListItem?: boolean
  isDisabled?: boolean
  renderOptionName?: (name: string) => string
  onChange: (item: API.ListFilterItem, checked: boolean) => void
}

const mapVehicleColors = (color: string): string => {
  return VEHICLE_COLOR_MAP[color as keyof typeof VEHICLE_COLOR_MAP] ?? 'transparent'
}

const FilterListItem: FC<FilterListItemProps> = ({
  checked, item, onChange, renderOptionName, isColorListItem = false, isDisabled = false
}) => {
  const name = renderOptionName != null
    ? renderOptionName(getName(item.name))
    : getName(item.name)

  const label = (
    <div className='cf-list-filter-item-label'>
      <span>{name}</span>
      <span className='cf-list-filter-item-count'>{item.count}</span>
    </div>
  )

  return (
    <div
      className={cnx(
        'cf-list-filter-item',
        isColorListItem && 'is-color-list-item',
        isDisabled && 'is-disabled'
      )}
      {...(!isDisabled && { onClick: () => onChange(item, !checked) })}
    >
      <Checkbox
        value={checked}
        label={label}
      />
      {
        isColorListItem && (
          <span
            className='cf-list-filter-item-color'
            style={{
              backgroundColor: mapVehicleColors(item.name),
              border: `1px solid ${item.name === 'White' ? '#EDEDED' : 'transparent'}`
            }}
          >
            {
              item.name === 'Other' && <img src={MulticolorImg} alt='' />
            }
          </span>
        )
      }
    </div>
  )
}
export default FilterListItem
