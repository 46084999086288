import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import CLASS_NAME from './styles'

export interface RadioButtonProps {
  label: string
  className?: string
  value: boolean
  onChange: () => void
}

const RadioButton: FC<RadioButtonProps> = ({ label, className, value, onChange }) => (
  <label className={cnx(CLASS_NAME, className)}>
    <input
      type='radio'
      checked={value}
      onChange={onChange}
    />
    <span>{label}</span>
  </label>
)

export default RadioButton
