import type { FC } from 'react'
import { useState, useRef } from 'react'
import { Popover } from '@carfluent/common'

import alertIcon from 'website/assets/alert_icon_black.svg'

import POPOVER_CLASS_NAME from './styles'

interface DescriptionProps {
  content: string
}

const Description: FC<DescriptionProps> = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false)
  const iconRef = useRef<HTMLImageElement | null>(null)

  const onMouseEnter = () => setIsOpen(true)
  const onMouseLeave = () => setIsOpen(false)

  return (
    <>
      <img
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={iconRef}
        src={alertIcon}
        alt=""
      />
      <Popover
        open={isOpen}
        anchorEl={iconRef.current}
        className={POPOVER_CLASS_NAME}
      >
        {content}
      </Popover>
    </>
  )
}

export default Description
