import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import { useLocation } from 'react-router-dom'

import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import DrawerMenu from 'website/components/DrawerMenu'
import ExtendedImage from 'website/components/ExtendedImage'
import Login from 'website/components/Login'
import List from 'website/components/List'
import SearchBar from 'website/components/SearchBar'

import { HeaderProps, SupportedComponents } from 'website/components/types'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { isIndependentCoverageFlow, isIndependentPaymentShareFlow } from 'utils/urlHelpers'

import DealerLocationsPopover from '../_base/DealerLocationsPopover'

import CLASS_NAME from './styles'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const Header: FC<HeaderProps> = ({
  nameInLayout,
  template,
  layout,
  variant,
  variantProps,
  className,
  componentProps,
  useDealFlowNavigation = false
}) => {
  const { pathname } = useLocation()
  const componentStylesCls = useComponentStyles(SupportedComponents.Header, variant, variantProps)
  const templateCls = useLayoutStyles({ template, layout })

  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))

  const lastDealership = dealerInfo.dealerships[dealerInfo.dealerships.length - 1]

  const wrapperClass = cnx(
    nameInLayout ?? SupportedComponents.Header,
    CLASS_NAME,
    componentStylesCls.root,
    className
  )

  const isHiddenHeader = isIndependentCoverageFlow(pathname) ||
    isIndependentPaymentShareFlow(pathname)

  if (isHiddenHeader) {
    return null
  }

  return (
    <div className={wrapperClass}>
      <div className={cnx(componentStylesCls.content, templateCls)}>
        <DrawerMenu
          {...componentProps.DrawerMenu}
          useDealFlowNavigation={useDealFlowNavigation}
        />

        {!useDealFlowNavigation && (
          <SearchBar
            {...componentProps.SearchBar}
            className='header-search-bar'
            shouldResetOnSearch
          />
        )}

        {
          lastDealership != null && (
            <ExtendedImage
              {...componentProps.ExtendedImage}
              src={lastDealership.logoUrl ?? componentProps.ExtendedImage.src}
            />
          )
        }
        <List {...componentProps.List} />

        {
         !useDealFlowNavigation &&
           <DealerLocationsPopover
             className='locations-popover'
           />
        }

        <Login
          {...componentProps.Login}
          useDealFlowNavigation={useDealFlowNavigation}
        />
      </div>
    </div>
  )
}

export default Header
