export default {
  VehiclesSorting: {
    default: {
      root: `
        .cf-end-adornment {
          display: none;
        }

        .cf-select-display-container {
          border: none;
          background: transparent;
          padding-left: 0px;
          min-width: 160px;
          height: auto;

          .cf-start-adornment svg path {
            fill: var(--mainColor);
          }

          .cf-display {
            padding-top: 16px;
            font-size: 14px;
            height: 16px;
          }

          .cf-display-label {
            color: #0000004D;
            margin-top: -2px;
          }
        }

        .with-label .cf-start-adornment {
          margin-left: -4px;
          padding: 0px;
          display: flex;
          align-self: center;
          visibility: visible;
          position: relative;
        }
      `
    },
    filtersDrawer: {
      root: `
        .cf-end-adornment {
          display: block;
          padding: 0px;
        }

        .cf-select-display-container {
          border: 1px solid #DBDBDB;
          padding: 12px 16px;
          height: 48px;

          :hover {
            border: 1px solid var(--mainColor);
          }

          input, .cf-display, .cf-end-adornment {
            padding: 0px;
          }
        }
      `
    }
  }
}
