import type { FC } from 'react'

import { cnx, BackToTop } from '@carfluent/common'
import { SupportedComponents, VehicleInfiniteScrollProps } from 'website/components/types'
import VehicleCard from 'website/components/VehicleCard'
import InfiniteScroll from 'website/components/_base/InfiniteScroll'

import useVehicleInfiniteScroll, { PAGE_SIZE } from './hook'
import NoVehiclesView from './components/NoVehiclesView'
import LoadingScreen from './components/LoadingScreen'

import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import CLASS_NAME, { BACK_TO_TOP_CLASS_NAME } from './styles'

const Loader = <LoadingScreen pageSize={PAGE_SIZE} />

const VehicleInfiniteScroll: FC<VehicleInfiniteScrollProps> = (props) => {
  const { template, layout, nameInLayout = SupportedComponents.VehicleInfiniteScroll } = props
  const templateCls = useLayoutStyles({ template, layout })

  const {
    fetchVehicles,
    vehicles: vehicleCards,
    hasMoreVehicles,
    isLoading,
    monthlyPayments,
    loadingIds,
    additionalClassName
  } = useVehicleInfiniteScroll(props)

  return (
    <div className={nameInLayout}>
      {
        (vehicleCards.length === 0 && !isLoading) && (
          <NoVehiclesView {...props.componentProps.NoVehiclesView} />
        )
      }

      <InfiniteScroll
        className={CLASS_NAME}
        dataLength={vehicleCards.length}
        next={fetchVehicles}
        hasMore={hasMoreVehicles}
        isLoading={isLoading}
        loader={Loader}
      >
        <div className={cnx(templateCls, vehicleCards.length > 0 && 'list-with-content')}>
          {vehicleCards.map((card) => (
            <VehicleCard
              key={card.id}
              {...card}
              monthlyPaymentDetails={monthlyPayments[card.id as number]}
              isMonthlyPaymentLoading={loadingIds.includes(card.id as number)}
            />)
          )}
        </div>
      </InfiniteScroll>
      <BackToTop className={cnx(BACK_TO_TOP_CLASS_NAME, additionalClassName)} />
    </div>
  )
}

export default VehicleInfiniteScroll
