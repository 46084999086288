export interface DealerProductDeductibleDto {
  id: number
  amount: number
  dealerCost: number
  dealerRetailPrice: number
  description: string | null
}

export interface DealerProductCoverageDto {
  id: number | string
  termMiles: string | null
  termMonths: string | null
  deductibles: DealerProductDeductibleDto[]
}

export interface DealerProductPackageDto {
  packageName: string
  coverages: DealerProductCoverageDto[]
}

export interface DealerProductCoverageTemplateDto {
  id: CoverageTemplateTypeIds
  name: string
  imageUrl: string
  imageUrlSmall: string
  description: string
  isSelected: boolean
}

export interface DealerProductCoveragePackageSettingsDto {
  packageName: string
  packageTypeId: CoveragePackageTypeIds
  productTypeIds: CoverageProductTypeIds[]
}

export interface DealerProductDto {
  id: number
  isActive: boolean
  order?: number | null
  productName: string
  productType: string
  packages: DealerProductPackageDto[]
  promoted?: boolean
}

export enum CoveragePackageTypeIds {
  Platinum = 1,
  Gold = 2,
  Basic = 3
}

export enum CoverageProductTypeIds {
  ServiceContract = 1,
  GapInsurance,
  AppearanceProtection,
  TireAndWheel,
  Theft
}

export enum CoverageTemplateTypeIds {
  LargeBlocks = 1,
  ColumnsView,
  ListView,
  Package,
}

export interface DealerCoverageDto {
  id: number
  termMiles: number
  termMonths: number
  coverageName: string
  productTypeId: number
}

export interface DealerProviderCoverageDto {
  id: number
  name: string
  coverages: DealerCoverageDto[]
}

export interface DealerDefaultCoverageDto {
  id: number
  cost: number
  termMiles: number
  termMonths: number
  totalPrice: number
  providerId: number
  coverageName: string
  productTypeId: CoverageProductTypeIds
  deductibleAmount: number
  productCoverageId: number | null
}

export interface DealerDefaultCoverageRequestDto {
  coverageDefaults: DealerDefaultCoverageDto[]
}

export interface CoverageProductTypesDto {
  id: number
  name: string
}

export interface SettingsPackageType {
  name: string
  packageTypeId: number
}

export interface SettingsPackageTypesDto {
  packages: SettingsPackageType[]
}
