import { FormikValues, FormikErrors } from 'formik'
import get from 'lodash-es/get'
import { useContext, useMemo } from 'react'
import { FormCTX, FormCTXProps } from './form.context'

type PossibleFormError = string | string[] | FormikErrors<any> | Array<FormikErrors<any>>

export interface FormField<V = any> {
  id: string
  value?: V
  error?: string
  errors?: PossibleFormError
  touched?: any // AZ-TODO-IMPR: remove, when all forms will be properly typed
}

export interface UseFieldReturn<V> {
  value: V
  error: string
  touched: boolean
  showError: boolean
}

function getPossiblyResolvedError (id: string, errors: PossibleFormError): string {
  if (Array.isArray(errors)) {
    return typeof errors[0] === 'string'
      ? errors[0] ?? ''
      : get(errors[0], id, '') as string
  }

  return typeof errors === 'string'
    ? errors
    : get(errors, id, '') as string
}

export function useField<V = any> (props: FormField<V>): UseFieldReturn<V> {
  const { id } = props
  const { values, errors, touched } = useContext(FormCTX) ?? {}
  const resolvedValue = props.value === undefined ? get(values, id) : props.value

  const resolvedError = props.error !== undefined
    ? props.error
    : (props.errors !== undefined)
        ? getPossiblyResolvedError(id, props.errors)
        : get(errors, id, '') as string

  const resolvedTouched = props.touched !== undefined
    ? Boolean(props.touched)
    : get(touched, id, false)

  const showError = Boolean(resolvedError) && Boolean(resolvedTouched)

  return useMemo(() => ({
    value: resolvedValue as V,
    error: resolvedError ?? '',
    touched: Boolean(resolvedTouched),
    showError
  }), [
    resolvedValue,
    resolvedError,
    resolvedTouched,
    showError
  ])
}

export function useForm<V extends FormikValues = FormikValues> (): FormCTXProps<V> {
  return useContext<FormCTXProps<V>>(FormCTX)
}
