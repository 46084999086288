import { FC, useState } from 'react'
import { Collapse } from '@material-ui/core'

import { SummarySectionClasses } from 'components/summary_sections/types'
import SectionCollapseHeader from '../section_collapsed_header'
import { useStyles } from '../styles'

interface CollapsedSectionProps {
  label: string
  value: string
  hasCollapsedContent?: boolean
  isAlwaysOpened?: boolean
  isRightArrow?: boolean
  isWrapped?: boolean
  classes?: SummarySectionClasses & { root?: string }
  unmountOnExit?: boolean
}

const CollapsedSection: FC<CollapsedSectionProps> = ({
  label,
  value,
  hasCollapsedContent = true,
  isAlwaysOpened = false,
  isRightArrow = false,
  isWrapped = false,
  classes,
  children,
  unmountOnExit = true
}) => {
  const [isCollapseOpened, setCollapseOpened] = useState(isAlwaysOpened)
  const styles = useStyles()

  const handleToggleCollapse = (): void => {
    if (hasCollapsedContent && !isAlwaysOpened) {
      setCollapseOpened(!isCollapseOpened)
    }
  }

  const elem = (
    <>
      <SectionCollapseHeader
        label={label}
        value={value}
        isCollapseOpened={isCollapseOpened}
        isAlwaysOpened={isAlwaysOpened}
        isRightArrow={isRightArrow}
        handleClick={handleToggleCollapse}
        hasCollapsedContent={hasCollapsedContent}
        classes={classes}
      />

      <Collapse
        in={isCollapseOpened}
        timeout='auto'
        unmountOnExit={unmountOnExit}
        className={styles.collapseContent}
      >
        <div className={classes?.content ?? styles.defaultGap}>
          {children}
        </div>
      </Collapse>
    </>
  )

  if (isWrapped) {
    return (
      <div className={classes?.root}>
        {elem}
      </div>
    )
  }

  return elem
}

export default CollapsedSection
