import { ProductType } from 'api/types/coverage.types'

export enum CreditScore {
  Excellent = 'Excellent',
  Good = 'Good',
  Average = 'Average',
  BelowAverage = 'Below average'
}

export enum Days {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum Notifications {
  DealApproved = 'DealApproved',
}

export enum NodeEnvironment {
  Development = 'development',
  Production = 'production',
  Test = 'test'
}

export const COVERAGE_LABELS: Record<string, string> = {
  [ProductType.ServiceContract]: 'Service Contract',
  [ProductType.CeramicCoating]: 'Ceramic Coating',
  [ProductType.GapInsurance]: 'Gap',
  [ProductType.AppearanceProtection]: 'Appearance Protection',
  [ProductType.TireAndWheel]: 'Tire and Wheel',
  [ProductType.Theft]: 'Theft'
}
