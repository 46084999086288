import { css } from '@emotion/css'

export default css(`
  outline: none;
  width: 100%;
  resize: none;
  padding: 16px;
  line-height: 24px;
  font-size: 16px;
  border: none;
  background: #F3F3F3;
  border-radius: 12px;
  height: 56px;
  transition: height .2s;
  box-sizing: border-box;
  
  &.cf-without-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  &::placeholder {
    color: rgba(33, 33, 33, 0.8);
  }
`)
