import type { FC } from 'react'
import type { RadioProps } from '@material-ui/core'
import { Radio as MUIRadio } from '@material-ui/core'
import { cn } from '@carfluent/common'

import CLASS_NAME from './styles'

const Radio: FC<RadioProps> = (props) => (
  <MUIRadio
    {...props}
    className={CLASS_NAME}
    color='default'
    checkedIcon={<span className={cn('cf-icon', 'cf-checked-icon')} />}
    icon={<span className={'cf-icon'} />}
  />
)

export default Radio
