import type{ FC } from 'react'
import { observer } from 'mobx-react-lite'

import BillOfSale from 'components/dialogs/bil_of_sale'

import { useCarInfo } from './car_info.hook'

import CLASS_NAME from './styles'

const CarInfo: FC = () => {
  const {
    vehicleInfo,
    toggleSummaryBillVisibility,
    summaryBillVisible,
    priceBlockData
  } = useCarInfo()

  const {
    modelTrim, odometer, mainImageUrl,
    vehicleStateYearMake
  } = vehicleInfo

  return (
    <div className={CLASS_NAME}>
      <div className='cf-car-img-block'>
        <img src={mainImageUrl} alt='car' className='cf-car-img' />
      </div>

      <div className='info-container'>
        <div className='cf-btn-collapse'>
          {vehicleStateYearMake}
        </div>

        <p className='cf-title'>
          {modelTrim} <br />
          {odometer}
          <button className='cf-more-less-btn' onClick={toggleSummaryBillVisibility}>
            More
          </button>
        </p>

        <div className='cf-total'>
          <span>{priceBlockData.title}</span>
          <span>{priceBlockData.value}</span>
        </div>

      </div>

      <BillOfSale
        open={summaryBillVisible}
        onClose={toggleSummaryBillVisibility}
      />
    </div>
  )
}

export default observer(CarInfo)
