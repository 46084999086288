import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UITools } from '@carfluent/common'

import { Routes } from 'routing/constants'
import LS from 'services/storage.service/storage'
import { AuthInstance } from 'store/auth'
import { ACCESS_TOKEN } from 'website/constants/localStorageKeys'
import { LoginProps, SupportedComponents } from 'website/components/types'
import Button from 'website/components/Button'
import {
  isYourDetailsPage,
  isIndependentCoverageFlow as _isIndependentCoverageFlow,
  isIndependentPaymentShareFlow as _isIndependentPaymentShareFlow
} from 'utils/urlHelpers'

import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const { cn } = UITools

const Login: FC<LoginProps> = ({
  nameInLayout,
  template,
  layout,
  className,
  buttonVariant,
  onClick,
  useDealFlowNavigation
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponents.Login)
  const templateCls = useLayoutStyles({ template, layout })

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const isIndependentYourDetailsPage = isYourDetailsPage(pathname)

  // need this consts for auto login with params from admin panel
  const isIndependentCoverageFlow = _isIndependentCoverageFlow(pathname)
  const isIndependentPaymentShare = _isIndependentPaymentShareFlow(pathname)
  /**
   * used for backward compatibility, remove it when we will remove old flow
   */
  const isOldSignupOrLoginPage = pathname === '/deal/signup' || pathname === '/deal/login'

  const [isAuthed, setIsAuthed] = useState(LS.get(ACCESS_TOKEN) != null)

  useEffect(() => {
    const lsListener = (_e: any): void => {
      const hasToken = LS.get(ACCESS_TOKEN) != null
      setIsAuthed(hasToken)
    }

    window.addEventListener('storage', lsListener)

    return () => window.removeEventListener('storage', lsListener)
  }, [])

  /**
   * we need to track whether user in on flow and auth state
   * if user logouts from another opened window we need to redirect him to home page with #login hash
   */
  useEffect(() => {
    const isSpecialPage = isIndependentYourDetailsPage ||
      isOldSignupOrLoginPage ||
      isIndependentCoverageFlow ||
      isIndependentPaymentShare

    if (isSpecialPage) {
      return
    }

    if (isAuthed && window.location.hash === '#login') {
      window.location.hash = ''
      navigate(Routes.MultipleDeals, { replace: true })
    }

    if (!isAuthed && useDealFlowNavigation === true) {
      navigate('/', { replace: true })
      window.location.hash = '#login'
    }
  }, [
    isAuthed, isIndependentYourDetailsPage, isIndependentCoverageFlow, navigate,
    isOldSignupOrLoginPage, useDealFlowNavigation
  ])

  const handleAction = useCallback((): void => {
    if (isAuthed) {
      /**
       * We use instance directly only for logout here and leave all local storage listening logic
       * because this allows us not to observe Mobx store here.
       */
      AuthInstance.logout(true)
      if (useDealFlowNavigation === true) {
        navigate('/', { replace: true })
      }
    } else {
      window.location.hash = '#login'
    }

    onClick?.()
  }, [isAuthed, navigate, onClick, useDealFlowNavigation])

  return (
    <div className={cn(nameInLayout ?? SupportedComponents.Login, componentStylesCls.root, className)}>
      <div className={cn(componentStylesCls.content, templateCls)}>
        {
          isAuthed
            ? <Button variant={buttonVariant} buttonVariant={buttonVariant} text='Log out' onClick={handleAction} />
            : <Button variant={buttonVariant} buttonVariant={buttonVariant} text='Log in' onClick={handleAction} />
        }
      </div>
    </div>
  )
}

export default Login
