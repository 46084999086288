import { type FC, type ReactNode, useState } from 'react'
import { cnx } from '@carfluent/common'
import { type SvgIconProps } from 'website/components/types'
import SvgIcon from 'website/components/_base/SvgIcon'

export interface CheckboxProps {
  className?: string
  checkedIcon?: SvgIconProps['type']
  label?: ReactNode
  onChange?: (checked: boolean) => void
  value?: boolean
  defaultValue?: boolean
}

const Checkbox: FC<CheckboxProps> = ({
  className,
  checkedIcon = 'checked',
  label,
  value,
  defaultValue,
  onChange
}) => {
  const isControlled = value !== undefined
  /**
   * we duplicate value into local state so that we could see changes immediately.
   * So-called optimistic update.
   */
  const [checked, setChecked] = useState(value ?? defaultValue ?? false)

  const onClick = (): void => {
    if (isControlled) {
      onChange?.(!value)
    } else {
      setChecked(prevChecked => {
        // Schedule the onChange callback to run after the state update
        // to avoid potential warnings with state updates during render.
        setTimeout(() => onChange?.(!prevChecked), 0)
        return !prevChecked
      })
    }
  }

  /**
   * if controlled, use value from props
   */
  const resolvedValue = isControlled ? value : checked

  return (
    <div className={cnx('g-checkbox', resolvedValue && 'is-checked', className)} onClick={onClick}>
      <div className='checkmark'>
        <SvgIcon type={checkedIcon} />
      </div>
      {label}
    </div>
  )
}

export default Checkbox
