import { type FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import isEqual from 'lodash-es/isEqual'
import { FormNumberInput, useForm, cnx } from '@carfluent/common'

import Button from 'website/components/Button'

import { WebsiteRoutes } from 'website/routing/constants'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { SsnYearIncomeFormProps, SupportedComponents } from 'website/components/types'

import { DEFAULT_PREQUALIFIED_FORM_VALUES } from 'website/constants'

import { type FormValuesIncomeSSN, useSubmitGetPrequalifiedFormAction } from './useSubmitGetPrequalifiedFormAction'
import validationRules from './validator'

const baseValues = {
  lastDigits: null,
  yearIncome: null
}

const SsnYearIncomeForm: FC<SsnYearIncomeFormProps> = ({
  variant,
  nameInLayout = SupportedComponents.SsnYearIncomeForm
}) => {
  const navigate = useNavigate()
  const componentStylesCls = useComponentStyles(SupportedComponents.SsnYearIncomeForm, variant)

  const {
    submitAction,
    onActionResult,
    prequalifiedFormValues
  } = useSubmitGetPrequalifiedFormAction({
    errorNavigationRoute: WebsiteRoutes.GetPrequalifiedFormError,
    shouldResetValuesOnError: true
  })

  const {
    values,
    errors,
    touched,
    isSubmitting,
    onBlur,
    onChange,
    onSubmit
  } = useForm<FormValuesIncomeSSN>({
    submitAction,
    onActionResult,
    validationRules,
    baseValues
  })

  useEffect(() => {
    const isDefaultValues = isEqual(DEFAULT_PREQUALIFIED_FORM_VALUES, prequalifiedFormValues)

    if (isDefaultValues) {
      navigate(WebsiteRoutes.GetPrequalifiedForm)
    }
  }, [prequalifiedFormValues, navigate])

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root)}>
      <h3>Add your social security and year income</h3>

      <p>Your data is secure. Your credit score will not be affected.</p>

      <div className='ssn-year-input-wrapper'>
        <FormNumberInput
          id='lastDigits'
          maxLength={4}
          onBlur={onBlur}
          onChange={onChange}
          value={values.lastDigits}
          touched={touched.lastDigits}
          error={errors.lastDigits}
          thousandSeparator={null}
          className='form-field'
          label='SSN last 4 digits'
        />

        <FormNumberInput
          id='yearIncome'
          onBlur={onBlur}
          onChange={onChange}
          value={values.yearIncome}
          touched={touched.yearIncome}
          error={errors.yearIncome}
          className='form-field'
          label='Year income'
        />
      </div>

      <Button
        text='Get Pre-qualified'
        onClick={onSubmit}
        variant='search'
        isLoading={isSubmitting}
      />
    </div>
  )
}

export default SsnYearIncomeForm
