import { css } from '@emotion/css'

export default css(`
  .list-with-content {
    margin-bottom: 16px;
  }

  .vehicles-loading-screen {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    grid-gap: 16px;
    grid-column: 1 / -1;
    grid-row: 1;
  }
`)

export const BACK_TO_TOP_CLASS_NAME = css(`
  &.with-additional-indentation {
    right: 24px;
    bottom: 95px;
  }
`)
