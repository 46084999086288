export const DEFAULT_CONDITION_DATA = {
  value: null,
  subOptionValue: null
}

export enum FormIds {
  Mileage = 'mileage',
  Conditions = 'conditions',
  VehicleCollision = 'vehicleCollision',
  VehicleAirbags = 'haveAirbagsDeployed',
  RepairCost = 'totalRepairCosts',
  MechanicalIssues = 'mechanicalIssues',
  PaymentType = 'paymentType',
  Amount = 'paymentAmount',
  Trim = 'trim'
}

export enum PaymentIds {
  Finance = 1,
  Lease
}

export enum PaymentNames {
  Finance = 'Finance',
  Lease = 'Lease'
}

export enum VehicleConditionIds {
  Excellent = 1,
  VeryGood,
  Good,
  Fair
}

export enum VehicleConditionNames {
  Excellent = 'Excellent',
  VeryGood = 'Very Good',
  Good = 'Good',
  Fair = 'Fair'
}

export enum MechanicalIssuesNames {
  EngineIssues = 'Engine Issues',
  TransmissionIssues = 'Transmission issues',
  DashboardWarningLights = 'Dashboard Warning Lights'
}

export enum MechanicalIssuesIds {
  EngineIssues = 1,
  TransmissionIssues = 2,
  DashboardWarningLights = 4
}

export const mechanicalIssuesMapper = {
  [MechanicalIssuesIds.EngineIssues]: MechanicalIssuesNames.EngineIssues,
  [MechanicalIssuesIds.TransmissionIssues]: MechanicalIssuesNames.TransmissionIssues,
  [MechanicalIssuesIds.DashboardWarningLights]: MechanicalIssuesNames.DashboardWarningLights,
}

export const vehicleConditionMapper = {
  [VehicleConditionIds.Excellent]: VehicleConditionNames.Excellent,
  [VehicleConditionIds.VeryGood]: VehicleConditionNames.VeryGood,
  [VehicleConditionIds.Good]: VehicleConditionNames.Good,
  [VehicleConditionIds.Fair]: VehicleConditionNames.Fair
}
