import type { FC } from 'react'
import { useCallback } from 'react'
import { cn, UI } from '@carfluent/common'

import type { ConditionOptionProps } from 'website/components/types'

import Description from './components/Description'

const { Button } = UI

const Option: FC<ConditionOptionProps> = ({
  title,
  value,
  isActive = false,
  subOptions,
  onClick: _onClick,
  description,
  className = ''
}) => {
  const onClick = useCallback(() => {
    _onClick?.(value, subOptions)
  }, [value, _onClick])

  return (
    <Button
      variant='outlined'
      className={cn('cf-button g-tradein-condition-option', className, isActive ? 'cf-active' : '')}
      onClick={onClick}
    >
      {title.toUpperCase()}
      {description != null && <Description content={description} />}
    </Button>
  )
}

export default Option
