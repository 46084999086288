import { FC } from 'react'

import { ServiceType } from 'website/api/types'
import Button from 'website/components/Button'

import DirectionsCarIcon from './DirectionsCarIcon'
import HeadsetIcon from './HeadsetIcon'

export interface RequestHelpOrDriveLinkProps {
  mode: ServiceType
  onClick: () => void
}

const RequestHelpOrDriveLink: FC<RequestHelpOrDriveLinkProps> = ({ onClick, mode }) => {
  return (
    <div className='RequestHelpOrDriveLink'>
      {(mode === ServiceType.AskQuestion) ? <HeadsetIcon /> : <DirectionsCarIcon />}

      <Button
        buttonVariant='text'
        onClick={onClick}
        nameInLayout=''
        text={(mode === ServiceType.AskQuestion) ? 'Ask' : 'Drive'}
        variant='textRight'
      />
    </div>
  )
}

export default RequestHelpOrDriveLink
