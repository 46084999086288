import { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { type CoverageSummary } from 'api/types/coverage.types'
import { type Summary } from 'api/types/summary.types'
import WorkflowCTX from 'store/workflow'
import SummaryCTX from 'store/summary'
import { DEFAULT_SUMMARY } from 'store/mock'
import { formatCurrency } from 'utils/format_number'
import { isYourDetailsPage } from 'utils/urlHelpers'
import { getCoverageSummary } from 'utils/coverage'
import { mergeTwoFeesInfo, type FeesAndTaxesProps } from 'utils/mergeTwoFeesInfo'

interface UseBillOfSaleDialogReturn {
  coverageDetails: CoverageSummary[]
  hasFinancingSection: boolean
  feesAndTaxes: FeesAndTaxesProps | null
  isWholesale: boolean
  retailPrice: string
  summary: Summary
  totalPrice: string
  transportationCost: string
}

const useBillOfSaleDialog = (): UseBillOfSaleDialogReturn => {
  const location = useLocation()

  /**
   * AZ-TODO: discover, in what cases this `location.state` is fulfilled and add comment here
   */
  const { salePrice = '-' } = isYourDetailsPage(location.pathname) ? location.state : {}

  const { workflow, isWholesale } = useContext(WorkflowCTX)
  const { summaryDetails } = useContext(SummaryCTX)

  const shouldUseEmptyValues = isYourDetailsPage(location.pathname)

  const summary = useMemo(() =>
    shouldUseEmptyValues ? DEFAULT_SUMMARY : summaryDetails,
  [summaryDetails, shouldUseEmptyValues])

  const coverageDetails = getCoverageSummary(summary)
  const hasFinancingSection = Boolean(summary.monthlyPayment)
  const transportationCost = formatCurrency(summary.transportationCost ?? 0, '$0')

  const retailPrice: string = shouldUseEmptyValues
    ? formatCurrency(salePrice ?? 0)
    : formatCurrency(summary?.purchasePrice ?? 0)

  const totalPrice: string = shouldUseEmptyValues
    ? formatCurrency(salePrice ?? 0)
    : formatCurrency(summary?.totalPrice ?? 0)

  const feesAndTaxes: FeesAndTaxesProps | null = useMemo(() => {
    if (workflow?.coverageFeeSettings == null) {
      return null
    }

    return mergeTwoFeesInfo(
      workflow?.coverageFeeSettings,
      workflow.dealFees,
      Number(workflow?.financeInfo?.dealerInventoryTaxAmount)
    )
  }, [workflow])

  return {
    coverageDetails: coverageDetails.filter(x => Boolean(x.dealerRetailPrice)),
    feesAndTaxes: feesAndTaxes ?? null,
    hasFinancingSection,
    isWholesale,
    retailPrice,
    summary,
    totalPrice,
    transportationCost
  }
}

export default useBillOfSaleDialog
