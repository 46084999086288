import { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import type { DirectionsServiceProps } from '@react-google-maps/api'

export const DEFAULT_ZOOM = 13

export interface CommonProps {
  hideDirections?: boolean
  center: google.maps.LatLngLiteral
  destination: google.maps.LatLngLiteral | null
}

interface UseGenericMapReturns extends CommonProps {
  showDirections: boolean
  directionsConfig: DirectionsServiceProps['options'] | null
  directions: google.maps.DirectionsResult | null
  handleClearDirections: () => void
  handleInitMap: (map: google.maps.Map) => void
  handleDirectionsCalculated: (
    result: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus
  ) => void
}

export const useGenericMap = (props: CommonProps): UseGenericMapReturns => {
  const {
    center,
    destination,
    hideDirections = destination != null
  } = props

  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null)
  const map = useRef<google.maps.Map | null>(null)

  const directionsConfig = useMemo(() => {
    if (destination == null) {
      return null
    }

    return {
      origin: center,
      destination,
      travelMode: google.maps.TravelMode.DRIVING
    }
  }, [center, destination])

  const showDirections = !hideDirections && (directionsConfig?.destination != null)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const handleDirectionsCalculated = useCallback((
    result: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus
  ) => {
    if ((result !== null) && (status === 'OK')) {
      setDirections(result)
    }
  }, [])

  const handleClearDirections = useCallback(() => {
    setDirections(null)
  }, [])

  const handleInitMap = useCallback((_map) => {
    map.current = _map
  }, [])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    let zoomChangeTimeout: NodeJS.Timeout | undefined

    if (hideDirections || (directions == null)) {
      map.current?.panTo(center)

      zoomChangeTimeout = setTimeout(() => {
        map.current?.setZoom(DEFAULT_ZOOM)
      }, 500)
    }

    return () => clearTimeout(zoomChangeTimeout)
  }, [hideDirections, directions, center])

  // ========================================== //

  return {
    center,
    destination,
    hideDirections,
    showDirections,
    directionsConfig,
    directions,
    handleClearDirections,
    handleInitMap,
    handleDirectionsCalculated
  }
}
