import { atomFamily } from 'recoil'
import { FILTERS_CONFIG, VEHICLE_SORTING_OPTIONS } from 'website/configs'

import {
  DEFAULT_TRADE_IN_VEHICLE_VALUE,
  DEFAULT_TRADE_IN_DETAILS_FORM_VALUE,
  DEFAULT_ZIP_CODE_LOCATION_VALUE,
  DEFAULT_PREQUALIFY_VALUE,
  DEFAULT_DEALERSHIP_ID,
  DEFAULT_PREQUALIFIED_FORM_VALUES,
  DEFAULT_SETTINGS
} from 'website/constants'
import { getZipCodeLocationFromLS } from 'website/services/storage/zipCodeLocation'
import { getPrequalifyStateFromLS } from 'website/services/storage/prequalifyData'

export enum StoreBranches {
  FiltersDrawerVisibility = 'FiltersDrawerVisibility',
  VehiclesFilter = 'VehiclesFilter',
  VehiclesSorting = 'VehiclesSorting',
  Dealership = 'Dealership',
  Prequalify = 'Prequalify',
  TradeInVehicle = 'TradeInVehicle',
  TradeInDetailsVehicle = 'TradeInDetailsVehicle',
  ZipCodeLocation = 'ZipCodeLocation',
  ReviewDealershipId = 'ReviewDealershipId',
  PrequalifiedFormValues = 'PrequalifiedFormValues',
  Settings = 'Settings',
  BotEnabled = 'BotEnabled'
}

const botEnabledState = atomFamily<Store.BotEnabled, string>({
  key: StoreBranches.Dealership,
  default: {
    isEnabled: false,
    lastUpdateTs: 0
  }
})

const dealershipState = atomFamily<Store.Dealership, string>({
  key: StoreBranches.Dealership,
  default: {
    dealerships: [],
    lastUpdateTs: 0
  }
})

const filtersDrawerVisibilityState = atomFamily<Store.VisibilityState, string>({
  key: StoreBranches.FiltersDrawerVisibility,
  default: {
    isOpen: false,
    lastUpdateTs: 0
  }
})

const vehiclesFilterState = atomFamily<Store.VehiclesFilterState, string>({
  key: StoreBranches.VehiclesFilter,
  default: {
    allFilters: FILTERS_CONFIG,
    appliedFilters: {},
    isFilteringEnabled: false,
    lastUpdateTs: 0
  }
})

const vehiclesSortingState = atomFamily<Store.VehiclesSortingState, string>({
  key: StoreBranches.VehiclesSorting,
  default: {
    appliedSorting: {
      sortField: VEHICLE_SORTING_OPTIONS[0].sortField,
      sortOrder: VEHICLE_SORTING_OPTIONS[0].sortOrder
    },
    lastUpdateTs: 0
  }
})

const zipCodeLocationState = atomFamily<Store.ZipCodeLocationState, string>({
  key: StoreBranches.ZipCodeLocation,
  default: {
    ...DEFAULT_ZIP_CODE_LOCATION_VALUE,
    ...getZipCodeLocationFromLS(),
    lastUpdateTs: 0
  }
})

const prequalifiedState = atomFamily<Store.PrequalifyState, string>({
  key: StoreBranches.Prequalify,
  default: {
    ...DEFAULT_PREQUALIFY_VALUE,
    ...getPrequalifyStateFromLS(),
    lastUpdateTs: 0
  }
})

const tradeInVehicleState = atomFamily<Store.TradeInVehicleState, string>({
  key: StoreBranches.TradeInVehicle,
  default: {
    lastUpdateTs: 0,
    ...DEFAULT_TRADE_IN_VEHICLE_VALUE
  }
})

const tradeInDetailsVehicle = atomFamily<Store.TradeInDetailsVehicleState, string>({
  key: StoreBranches.TradeInVehicle,
  default: {
    lastUpdateTs: 0,
    ...DEFAULT_TRADE_IN_DETAILS_FORM_VALUE
  }
})

const reviewDealershipId = atomFamily<Store.ReviewDealership, string>({
  key: StoreBranches.ReviewDealershipId,
  default: {
    lastUpdateTs: 0,
    ...DEFAULT_DEALERSHIP_ID
  }
})

const prequalifiedFormValues = atomFamily<Store.PrequalifiedFormValues, string>({
  key: StoreBranches.PrequalifiedFormValues,
  default: {
    lastUpdateTs: 0,
    ...DEFAULT_PREQUALIFIED_FORM_VALUES
  }
})

const settings = atomFamily<Store.Settings, string>({
  key: StoreBranches.Settings,
  default: {
    lastUpdateTs: 0,
    settings: DEFAULT_SETTINGS
  }
})

export const StateAtoms = {
  [StoreBranches.FiltersDrawerVisibility]: filtersDrawerVisibilityState,
  [StoreBranches.VehiclesFilter]: vehiclesFilterState,
  [StoreBranches.VehiclesSorting]: vehiclesSortingState,
  [StoreBranches.Dealership]: dealershipState,
  [StoreBranches.Prequalify]: prequalifiedState,
  [StoreBranches.TradeInVehicle]: tradeInVehicleState,
  [StoreBranches.TradeInDetailsVehicle]: tradeInDetailsVehicle,
  [StoreBranches.ZipCodeLocation]: zipCodeLocationState,
  [StoreBranches.ReviewDealershipId]: reviewDealershipId,
  [StoreBranches.PrequalifiedFormValues]: prequalifiedFormValues,
  [StoreBranches.Settings]: settings,
  [StoreBranches.BotEnabled]: botEnabledState
} as const

export default StateAtoms
