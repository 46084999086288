import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import { createGoogleMapsLink, createPhoneLink } from 'website/utils/links'
import { fullAddressShort } from 'website/utils/googleMap'
import { VehicleDealershipInfoProps } from 'website/components/types'
import SvgIcon from 'website/components/_base/SvgIcon'
import { formatPhoneNumber } from 'utils/constants'
import CLASS_NAME from './styles'

const VehicleDealershipInfo: FC<VehicleDealershipInfoProps> = ({
  nameInLayout = 'VehicleDealershipInfo',
  dealership,
  className
}) => {
  const address = dealership == null
    ? null
    : fullAddressShort({
      address: dealership.address1,
      city: dealership.city,
      state: dealership.state,
      zipCode: dealership.zipCode
    })

  const mapUrl = address == null ? null : createGoogleMapsLink(address)
  const phoneUrl = dealership?.phone == null ? null : createPhoneLink(dealership.phone)

  return (
    <div className={cnx(nameInLayout, CLASS_NAME, className)}>
      <span>
        {
          (dealership?.city != null && dealership?.state != null) && (
            <a href={mapUrl ?? ''} rel='noreferrer' target='_blank'>
              <SvgIcon type='map' fill='#20C39E' />
              <span>
                Located at <span className='info'>{dealership.city}, {dealership.state}</span>
              </span>
            </a>
          )
        }
        {
          dealership?.phone != null && (
            <a href={phoneUrl ?? ''} target='_blank' rel='noopener noreferrer'>
              <SvgIcon type='phone' fill='#20C39E' />
              <span className='info'>{formatPhoneNumber(dealership.phone)}</span>
            </a>
          )
        }
      </span>
    </div>
  )
}

export default VehicleDealershipInfo
