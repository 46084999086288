import { FC } from 'react'
import ExtendedImage from 'website/components/ExtendedImage'
import Text from 'website/components/Text'
import { NoVehiclesViewProps } from 'website/components/types'
import { createStyleClass } from './styles'

const defaultText = 'No vehicles found in stock. Try clearing your filters.'

const NoVehiclesView: FC<NoVehiclesViewProps> = ({
  imgSrc,
  text = defaultText
}) => {
  const stylesRoot = createStyleClass()
  return (
    <div className={stylesRoot}>
      <div className='cf-no-vehicles-content'>
        <ExtendedImage
          src={imgSrc}
        />
        <Text
          value={text}
        />
      </div>
    </div>
  )
}

export default NoVehiclesView
