import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Method
} from 'axios'
import Qs from 'qs'

// import type { KeyVal } from 'types'
// import { bodyRefresh } from 'constants/store_helpers'
// import { WORKFLOW_URL } from 'constants/urls'
// import { GlobalUIInstance } from 'store/global_ui'
//
// import {
//   getRefreshToken,
//   getBaseUrlIdentity,
//   getWorkflowId,
//   getWorkflowVersion,
//   setWorkflowId,
//   setWorkflowVersion
// } from 'services/storage.service'

// import { AuthInstance } from 'store/auth'
// import { Workflow, DataForRequest } from './types'
// import { TokenResponse } from './types/user.types'

const CToken = axios.CancelToken
const previousRequests: KeyVal = {}

const baseURL = process.env.REACT_APP_CUSTOMERSCORE ?? ''

const DEFAULT_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'Carfluent-Client': 'CarFluent.Web'
}

// const ACCESS_TOKEN_URL = `${getBaseUrlIdentity()}/connect/token`

// const isWorkflowRequest = (url?: string): boolean => {
//   if (url == null) {
//     return false
//   }
//
//   return /^\/api\/v1\/Workflow(\/[\d]+)?$/.test(url)
// }

// const isWorkflowInitRequest = (method: string, url: string): boolean => {
//   return (method.toLowerCase() === 'post') && (url === WORKFLOW_URL)
// }

// export const getDefaultDataForRequest = (): DataForRequest => {
//   return {
//     workflowId: getWorkflowId(),
//     workflowVersion: getWorkflowVersion(),
//     accessToken: AuthInstance.accessToken,
//     isAllArgsFromParams: false
//   }
// }

export class WrapperRequest {
  post = async <T = any>(url: string, params = {}, cancelPrevious = false): Promise<T> =>
    await WrapperRequest.makeRequest<T>('post', url, params, cancelPrevious)

  put = async <T = any>(url: string, params = {}, cancelPrevious = false): Promise<T> =>
    await WrapperRequest.makeRequest<T>('put', url, params, cancelPrevious)

  patch = async <T = any>(url: string, params = {}, cancelPrevious = false): Promise<T> =>
    await WrapperRequest.makeRequest<T>('patch', url, params, cancelPrevious)

  get = async <T = any>(url: string, params = {}, cancelPrevious = false): Promise<T> =>
    await WrapperRequest.makeRequest<T>('get', url, params, cancelPrevious)

  delete = async <T = any>(url: string, params = {}, cancelPrevious = false): Promise<T> =>
    await WrapperRequest.makeRequest<T>('delete', url, params, cancelPrevious)

  static async makeRequest<T>(
    method: Method,
    url: string,
    parameters: KeyVal,
    cancelPrevious: boolean
  ): Promise<T> {
    const previousUrl = previousRequests[url]
    const hasPreviousUrl = Boolean(previousUrl)

    // let getRequestData = getDefaultDataForRequest

    // const { getDataForRequest, ...restParams } = parameters
    // const hasDataForRequestFromParams = Boolean(getDataForRequest)

    // if (hasDataForRequestFromParams) {
    // getRequestData = getDataForRequest
    // parameters = restParams
    // }

    // const {
    //   workflowId,
    //   workflowVersion,
    //   accessToken,
    //   isAllArgsFromParams
    // } = getRequestData()
    const isUpdatingMethod = method === 'post' || method === 'patch' || method === 'put'

    if (cancelPrevious && hasPreviousUrl) {
      previousUrl.cancel()
    }

    let data
    let params = {}
    let customHeaders = {}

    if (isUpdatingMethod) {
      data = parameters
    } else if (method === 'get') {
      params = parameters
    }

    if (parameters.data != null) {
      data = parameters.data
    } else {
      data = parameters
    }

    if (parameters.headers != null) {
      customHeaders = parameters.headers
    }

    const instance = axios.create({ baseURL })
    const config: AxiosRequestConfig = {
      method,
      baseURL,
      url,
      headers: {
        ...DEFAULT_HEADERS,
        ...customHeaders
        // Authorization: getAuthHeader(accessToken)
      },
      data,
      params,
      cancelToken: new CToken((c) => {
        const hasPreviousUrl = Boolean(previousRequests[url])

        if (!hasPreviousUrl) {
          previousRequests[url] = {}
        }

        previousRequests[url].cancel = c
      }),
      paramsSerializer (params) {
        return Qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }

    // if ((workflowVersion !== null) && isUpdatingMethod && !isWorkflowInitRequest(method, url)) {
    //   config.headers.WorkflowId = workflowId
    //   config.headers.WorkflowVersion = workflowVersion
    // }

    instance.interceptors.response.use(
      (response: AxiosResponse<T>) => {
        const data = response.data
        const hasData = Boolean(data)
        const result = hasData ? data : response
        // const isGet = config.method === 'get'

        // if (isWorkflowRequest(config.url) && isGet && !isAllArgsFromParams) {
        //   const workflow = result as unknown as Workflow
        //
        //   setWorkflowId(workflow.id)
        //   setWorkflowVersion(workflow.rowVersion)
        // }

        return result
      },

      async (error: AxiosError) => {
        if (error.response === undefined) {
          return await Promise.reject(error)
        }

        // const {
        //   status,
        //   // config
        // } = error.response
        // const isAuthError = status === 401
        // const isForbiddenError = status === 403
        // const isConflict = status === 409
        // const isTokenRestorationRequest = config.url === ACCESS_TOKEN_URL

        /**
         * In case of authorization error just logout immediately
         */
        // if (isForbiddenError) {
        //   AuthInstance.logout()
        //   return
        // }

        /**
         * In case of authentication error, try to restore access token
         * from refresh token and re-send original failed request.
         */
        // if (isAuthError && !isTokenRestorationRequest) {
        //   const refreshToken = getRefreshToken()
        //   const data = bodyRefresh(refreshToken)
        //   const authConfig = { ...config }
        //
        //   authConfig.headers = {
        //     'Content-Type': 'application/x-www-form-urlencoded'
        //   }
        //
        //   try {
        //     const response: TokenResponse = await instance
        //       .post(ACCESS_TOKEN_URL, data, authConfig)
        //
        //     void AuthInstance.setAuth(response)
        //     config.headers.Authorization = getAuthHeader(response.access_token)
        //     return instance.request(config)
        //   } catch (err) {
        //     AuthInstance.logout()
        //     return
        //   }
        // }

        // if (isConflict) {
        //   GlobalUIInstance.showDealWasUpdated()
        // }

        return await Promise.reject(error)
      }
    )

    return instance.request<T>(config)
  }
}

// export function getAuthHeader (token: string): string {
//   return `Bearer ${token}`
// }
