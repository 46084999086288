import { RefObject, useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { type OnActionResultHandler, type DeepRequired, isOk } from '@carfluent/common'
import ReCAPTCHA from 'react-google-recaptcha'

import GeoApiProvider from 'website/api/geo.api'
import { isAddressValid } from 'website/utils/addressGuards'
import isRecaptchaSuccess from 'website/utils/validation/isRecaptchaSuccess'
import { serializeFinancingData, serializeLeadData } from 'website/components/GetPrequalifiedForm/hook/serializer'
import apiProvider from 'website/api/apiProvider'
import { DEFAULT_PREQUALIFIED_FORM_VALUES } from 'website/constants'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import { setPrequalifyStateInLS } from 'website/services/storage/prequalifyData'
import { FinancingSubRoutes } from 'website/routing/constants'
import { type PrequalifiedFormData } from '.'

const usePrequalifyState = SharedStateHook<Store.PrequalifyState>(StoreBranches.Prequalify)
const usePrequalifiedFormValuesState = SharedStateHook<Store.PrequalifiedFormValues>(StoreBranches.PrequalifiedFormValues)
const DEFAULT_TERM = 60

interface UseSubmitActionProps {
  errorNavigationRoute?: string
  shouldResetValuesOnError?: boolean
  shouldCreateLead?: boolean
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ErrTouchShortcuts = {
  addressData: true
}

export interface FormValuesIncomeSSN {
  lastDigits: number | null
  yearIncome: number | null
}

interface UseSubmitActionResult<T> {
  prequalifiedFormValues: PrequalifiedFormData
  baseValues: PrequalifiedFormData
  submitAction: (values: DeepRequired<T>) => (Promise<void> | Promise<T>)
  onActionResult: OnActionResultHandler<T, ErrTouchShortcuts>
  refRecaptcha: RefObject<ReCAPTCHA>
}

type BaseUseSubmitActionType = PrequalifiedFormData | FormValuesIncomeSSN

export const useSubmitGetPrequalifiedFormAction = <T extends BaseUseSubmitActionType>({
  errorNavigationRoute = FinancingSubRoutes.Error,
  shouldResetValuesOnError = false,
  shouldCreateLead = false
}: UseSubmitActionProps = {}): UseSubmitActionResult<T> => {
  const navigate = useNavigate()
  const [, setSuccessFormData] = usePrequalifyState(
    defaultInstance(StoreBranches.Prequalify),
    setPrequalifyStateInLS
  )
  const [
    { lastUpdateTs: _, ...prequalifiedFormValues },
    setPrequalifiedFormValues
  ] = usePrequalifiedFormValuesState(
    defaultInstance(StoreBranches.PrequalifiedFormValues)
  )

  const refPrequalifiedValuesDefault = useRef(prequalifiedFormValues)
  const refPrequalifiedValues = useRef(prequalifiedFormValues)
  const refRecaptcha = useRef<ReCAPTCHA>(null)

  const submitAction = useCallback(async (values: DeepRequired<T>) => {
    const mergedValues = { ...refPrequalifiedValues.current, ...values }

    if (!isAddressValid(mergedValues.addressData)) {
      return
    }

    const isValidRecaptcha = await isRecaptchaSuccess(refRecaptcha)
    if (!isValidRecaptcha) {
      return
    }

    const { latitude, longitude } = await GeoApiProvider.getGeoByZip(mergedValues.addressData.zipCode) ?? {}
    const serializePayload = { ...mergedValues, locationLatitude: latitude, locationLongitude: longitude }

    if (shouldCreateLead) {
      const leadPayload = await serializeLeadData(serializePayload)

      if (leadPayload == null) {
        return
      }

      await apiProvider.crm.createLead(leadPayload)
    }

    const prequalifyPayload = serializeFinancingData(serializePayload)

    if (prequalifyPayload == null) {
      return
    }

    setPrequalifiedFormValues({
      ...mergedValues,
      lastUpdateTs: Date.now()
    })

    const res = await apiProvider.dealer.getPrequalified(prequalifyPayload)

    setSuccessFormData({
      ...res,
      term: res.term == null || res.term === '' ? DEFAULT_TERM : Number(res.term),
      lastUpdateTs: Date.now()
    })
  }, [shouldCreateLead])

  const onActionResult = useCallback((result) => {
    if (isOk(result)) {
      navigate(FinancingSubRoutes.Success)
      setPrequalifiedFormValues({
        ...DEFAULT_PREQUALIFIED_FORM_VALUES,
        lastUpdateTs: Date.now()
      })
    } else {
      navigate(errorNavigationRoute)
      if (shouldResetValuesOnError) {
        setPrequalifiedFormValues({
          ...DEFAULT_PREQUALIFIED_FORM_VALUES,
          lastUpdateTs: Date.now()
        })
      }
    }
  }, [navigate, errorNavigationRoute])

  useEffect(() => {
    refPrequalifiedValues.current = prequalifiedFormValues
  }, [prequalifiedFormValues])

  return {
    onActionResult,
    submitAction,
    prequalifiedFormValues,
    baseValues: refPrequalifiedValuesDefault.current,
    refRecaptcha
  }
}
