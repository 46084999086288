import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  checkboxWithError: {
    '& .MuiIconButton-label': {
      border: '1px solid #B00020'
    }
  },
  rootFormControlLabel: {
    boxSizing: 'border-box',
    width: '100%',
    padding: 0,
    margin: 0
  },
  labelFormControlLabel: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.15,
    color: '#101010',
    padding: '0',
    margin: '0',
    paddingLeft: '8px',
    userSelect: 'none'
  },
  checkboxRoot: {
    padding: 0,
    margin: 0
  },
  colorSecondary: {
    color: 'var(--mainColor) !important'
  }
})

export default useStyles
