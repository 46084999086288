import { createContext } from 'react'
import { action, observable, makeObservable } from 'mobx'

import {
  WorkflowStep,
  Workflow,
  WorkflowStepState
} from 'api/types/workflow.types'
import { WorkflowInstance } from 'store/workflow'

export interface WorkflowData {
  workflow: Workflow | null
  activeStep: WorkflowStep | null
}

class WizardStore {
  workflow: Workflow | null = null
  steps: WorkflowStep[] = []
  activeStep: WorkflowStep | null = null

  getWorkflow = async (): Promise<WorkflowData> => {
    await WorkflowInstance.getWorkflow()
    this.setGetters()

    return {
      workflow: this.workflow,
      activeStep: this.activeStep
    }
  }

  setGetters = (): void => {
    this.workflow = WorkflowInstance.workflow
    this.steps = WorkflowInstance.steps
    this.activeStep = WorkflowInstance.activeStep
  }

  loadFreshWorkflowById = async (dealId: number): Promise<WorkflowData> => {
    await WorkflowInstance.getWorkflow(dealId)
    this.setGetters()

    return {
      workflow: this.workflow,
      activeStep: this.activeStep
    }
  }

  getFreshWorkflow = async (): Promise<Workflow | null> => {
    await WorkflowInstance.getWorkflow()
    this.setGetters()

    return this.workflow
  }

  getStepByName = (stepId: string): WorkflowStep | null => WorkflowInstance.getStepByName(stepId)
  getStepByUrl = (url: string): WorkflowStep | null => WorkflowInstance.getStepByUrl(url)
  getStepState = (step: WorkflowStep | string): WorkflowStepState | null => WorkflowInstance.getStepState(step)
  getStepIdx = (step?: WorkflowStep | string): number => WorkflowInstance.getStepIdx(step)
  isPending = (step: WorkflowStep | string): boolean => WorkflowInstance.isPending(step)
  isCompleted = (step: WorkflowStep | string): boolean => WorkflowInstance.isCompleted(step)
  isSkipped = (step: WorkflowStep | string): boolean => WorkflowInstance.isSkipped(step)
  isInactive = (step: WorkflowStep | string): boolean => WorkflowInstance.isInactive(step)
  hasDelivery = (): boolean => WorkflowInstance.hasDelivery

  getPersonalDetails = () => WorkflowInstance.personalDetails
  getTradeInDetails = () => WorkflowInstance.tradeInDetails
  getCreditCalculatorDetails = () => WorkflowInstance.creditCalculatorDetails
  getApplicantDetails = () => WorkflowInstance.applicantDetails
  getCoApplicantDetails = () => WorkflowInstance.coApplicantDetails
  getCoApplicantFinancingDetails = () => WorkflowInstance.coApplicantFinancingDetails
  getLenderDecisionDetails = () => WorkflowInstance.lenderDecisionDetails
  getDocuments = () => WorkflowInstance.documents
  getCheckoutDetails = () => WorkflowInstance.checkoutDetails
  getPaymentDetails = () => WorkflowInstance.paymentDetails
  getCoverageDetails = () => WorkflowInstance.coverageDetails
  isWholesale = (): boolean => WorkflowInstance.isWholesale

  constructor () {
    makeObservable(this, {
      workflow: observable,
      steps: observable,
      activeStep: observable,
      loadFreshWorkflowById: action,
      getFreshWorkflow: action,
      setGetters: action
    })
  }
}

export const WizardInstance = new WizardStore()
export const StoreCTX = createContext(WizardInstance)

export default StoreCTX
