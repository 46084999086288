import { type FC, useState, useEffect } from 'react'
import GenericMap from 'components/generic_map'
import { geocoding } from 'website/services/geocoding'
import { MapProps, SupportedComponents } from 'website/components/types'
import { fullAddressShort } from 'website/utils/googleMap'

const GoogleMap: FC<MapProps> = ({ addressData }) => {
  const [center, setCenter] = useState<google.maps.LatLngLiteral | undefined>()
  const address = fullAddressShort(addressData)

  useEffect(() => {
    const action = async (): Promise<void> => {
      try {
        const { latLng } = await geocoding.geocodeByAddress(address) ?? {}
        setCenter(latLng)
      } catch (err) {
        console.error(err)
      }
    }

    void action()
  }, [address])

  return (
    <div className={SupportedComponents.Map}>
      {
        center != null && (
          <GenericMap
            center={center}
            destination={null}
          />
        )
      }
    </div>
  )
}

export default GoogleMap
