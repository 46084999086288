import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { cnx } from '@carfluent/common'

import { BODY_STYLE_MAP } from 'website/configs'
import { WebsiteRoutes } from 'website/routing/constants'
import { updateListEntryInQs } from 'website/utils/qsfilters'

import { type CarBodyStylePanelProps, SupportedComponents } from '../types'

import CLASS_NAME from './styles'

const CarBodyStylePanel: FC<CarBodyStylePanelProps> = ({
  nameInLayout = SupportedComponents.CarBodyStylePanel
}) => {
  const navigate = useNavigate()

  const onBodyStyleChange = (bodyStyleName: string): void => {
    navigate(WebsiteRoutes.Inventory)
    updateListEntryInQs('bodyStyle', bodyStyleName)
  }

  return (
    <div className={cnx(nameInLayout, CLASS_NAME)}>
      <div className='component-container'>
        <h3>Shop by body style</h3>
        <div className='body-style-container'>
          {
            Array.from(BODY_STYLE_MAP.keys()).map((key) => (
              <div
                key={key}
                className='body-style-item'
                onClick={() => onBodyStyleChange(key)}
              >
                <p className='small-text'>{key}</p>
                <img src={BODY_STYLE_MAP.get(key)} alt={key} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default CarBodyStylePanel
