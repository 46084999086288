import { css } from '@emotion/css'
import { convertColorToHex } from 'website/styles/utils'
export interface StylesConfig {
  color?: string
  borderRadius?: string
  border?: string
  width?: string
  height?: string
  minHeight?: string
}

export const createStyleClass = ({
  color = '#000',
  borderRadius = '0px',
  border = 'none',
  width = '100%',
  height = '100%',
  minHeight = '1px'
}: StylesConfig): string => {
  return css(`
    background-color: ${convertColorToHex(color)};
    border-radius: ${borderRadius};
    border: ${border};
    width: ${width};
    height: ${height};
    min-height: ${minHeight};
`)
}
