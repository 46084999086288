import type { FC } from 'react'
import { cnx, Modal as LibModal } from '@carfluent/common'

import { ModalProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const Modal: FC<ModalProps> = ({
  nameInLayout = SupportedComponents.Modal,
  variant,
  intro,
  title,
  isOpen,
  onClose,
  children,
  className
}) => {
  const componentStylesCls = useComponentStyles(nameInLayout, variant)

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root)}>
      <LibModal
        title={title}
        isOpen={isOpen}
        className={cnx(componentStylesCls.content, className)}
        onClose={onClose}
      >
        <p className='ModalIntro'>{intro}</p>

        {children}
      </LibModal>
    </div>
  )
}

export default Modal
