import { css } from '@emotion/css'

export default css(`
  // TODO: fix padding issue in the Common Lib for all Modals
  && {
    .cf-modal-content-scroll-wrapper {
      padding: 0px;
    }
  }

  .cf-modal-content {
    p {
      margin: 0px;
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .location-message {
      font-weight: 500;
      letter-spacing: 0.4px;
    }

    .cf-input-container {
      margin: 24px 0px 48px;
    }

    button {
      margin: 0px auto;
      width: 100%;

      &.cf-button-variant-text {
        text-decoration: none;
      }

      &:first-of-type {
        margin-bottom: 24px;
      }
    }
  }
`)
