import { uniqueId } from 'lodash-es'

import type { MessageProps } from 'website/components/Messenger/components/Message'

export enum BotMessageRole {
  User,
  AI
}

export const parseMessages = (messages: API.WebChatMessage[]): MessageProps[] => {
  return messages.map(({ botMessageRoleId, content }) => ({
    id: uniqueId(),
    isReceived: botMessageRoleId === BotMessageRole.AI,
    isSending: false,
    message: content,
  }))
}