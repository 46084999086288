import type { Lead } from 'website/api/types/leads'

import type { FormValues } from './types'

const LEAD_SOURCE_ID = 37

export const serializeData = ({
  firstName,
  lastName,
  phoneNumber
}: FormValues): Lead => {
  return {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    phoneNumber: phoneNumber ?? '',
    email: null,
    address: null,
    city: null,
    state: null,
    zipCode: null,
    assignedUserId: null,
    leadSourceId: LEAD_SOURCE_ID,
    leadTemperatureId: 1,
    comments: null,
    vehiclesIds: null,
    vehicleId: null
  }
}
