import { css } from '@emotion/css'

export const createUseStyles = (maxWidth?: number) => css(`
  padding: 4px 12px;
  background: #212121 !important;
  border-radius: 4px;
  color: white;
  ${maxWidth != null ? `max-width: ${maxWidth}px;` : ''}
  
  .Text {
    p {
      color: #ffffff;
    }
  }
`)
