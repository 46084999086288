import { type FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { cn, formatCurrency } from '@carfluent/common'

import Button from 'website/components/Button'

import Routes, { TradeInSubRoutes, WebsiteRoutes } from 'website/routing/constants'
import { SupportedComponents, TradeInCarProps } from 'website/components/types'
import SharedStateHook, { StoreBranches } from 'website/store'
import tradeInCarImg from 'website/assets/car_placeholder.svg'
import formatString from 'website/utils/formatString'
import { DEFAULT_TRADE_IN_DETAILS_FORM_VALUE, DEFAULT_TRADE_IN_VEHICLE_VALUE } from 'website/constants'
import { getSalesPhone } from 'website/utils/sales'
import { isStringEmpty } from 'website/utils/isStringEmpty'

import CLASS_NAME from './styles'

const PHONE_FORMAT = '(###)###-####'

const useSharedState = SharedStateHook<Store.TradeInVehicleState>(StoreBranches.TradeInVehicle)
const useSharedTradeInDetailsState = SharedStateHook<Store.TradeInDetailsVehicleState>(StoreBranches.TradeInDetailsVehicle)
const useSharedDealership = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const TradeInCar: FC<TradeInCarProps> = ({
  nameInLayout = SupportedComponents.TradeInCar,
  states,
  isActionBarExist = true,
  isTitleExist = true,
  isPriceBarExist = false,
  isMobileTitleHidden = false,
  isSearchCarExist = false
}) => {
  const navigate = useNavigate()

  const [{
    year,
    make,
    model,
    trim,
    vin,
    price
  }, setTradeInVehicle] = useSharedState(states.tradeInVehicle)
  const [, setTradeInDetails] = useSharedTradeInDetailsState(states.tradeInDetailsVehicle)
  const [dealerInfo] = useSharedDealership('dealership')
  const formattedPhone = formatString(getSalesPhone(dealerInfo)?.toString(), PHONE_FORMAT)

  const onContinue = (): void => {
    navigate(Routes.Trade(TradeInSubRoutes.Details))
  }

  const onCleanVehicleData = (): void => {
    setTradeInVehicle({
      ...DEFAULT_TRADE_IN_VEHICLE_VALUE,
      lastUpdateTs: Date.now()
    })
    setTradeInDetails({
      ...DEFAULT_TRADE_IN_DETAILS_FORM_VALUE,
      lastUpdateTs: Date.now()
    })
  }

  const onEdit = (): void => {
    navigate(WebsiteRoutes.TradeIn)
    onCleanVehicleData()
  }

  const onGoSearch = (): void => {
    navigate(WebsiteRoutes.Inventory)
    onCleanVehicleData()
  }

  useEffect(() => {
    if (isStringEmpty(make) && isStringEmpty(model)) {
      navigate(WebsiteRoutes.TradeIn)
    }
  }, [make, model, navigate])

  return (
    <div className={cn(nameInLayout, CLASS_NAME)}>
      {isTitleExist && (
        <h3
          className={cn('cf-title', isMobileTitleHidden ? 'cf-trade-in-car-title' : '')}
        >
          Your Car for Trade in
        </h3>
      )}

      <div className='cf-content-wrapper'>
        <div className='cf-car-info'>
          <div className='cf-car-name-wrapper'>
            <h4 className='cf-car-name'>{`${year ?? 0} ${make} ${model}`}</h4>

            <p className='cf-car-spec'>{`Trim: ${trim === '' ? '-' : trim}`}</p>
            {vin != null && vin !== '' && (
              <p className='cf-car-spec'>{`VIN: ${vin}`}</p>
            )}
          </div>

          <div className='cf-car-placeholder'>
            <img src={tradeInCarImg} alt='car' />
          </div>
        </div>

        {
          isPriceBarExist && (
            (price != null && price !== 0)
              ? (
                <div className='cf-price-bar'>
                  <h1 className='g-h1-thin'>{`${formatCurrency(Math.round(price))}*`}</h1>

                  <p className='additional-note'>We’ll contact you shortly to collect your documents and finalise the deal</p>
                </div>
                )
              : (
                <div className='cf-estimate-car-wrapper'>
                  <div className='cf-title'>
                    <div className='cf-main-title'>
                      <h1 className='g-h1-thin'>Unable to estimate car*</h1>
                    </div>

                    <p className='additional-note'>We’ll contact you shortly to collect your documents and finalise the deal</p>
                  </div>
                </div>
                )
          )
        }

        {isSearchCarExist && (
          <div className='cf-search-action-bar'>
            <Button
              onClick={onGoSearch}
              variant='search'
              text='Search cars'
            />

            <p>Check your Inventory and find your new car.</p>
          </div>
        )}

        {isActionBarExist && (
          <div className='cf-action-bar'>
            <Button
              onClick={onContinue}
              text='CONTINUE'
            />

            <Button
              onClick={onEdit}
              className='cf-outlined-btn'
              variant='outlined'
              text='EDIT VEHICLE'
            />
          </div>
        )}
      </div>

      {isSearchCarExist && (
        <>
          <hr />

          <p className='g-helper-text'>
            {`* The appraisal is an estimate and requires a dealership inspection. If this trade appraisal is not what you were expecting, please contact us at ${formattedPhone}`}
          </p>
        </>
      )}
    </div>
  )
}

export default TradeInCar
