import { WrapperRequest } from './wrapper.api'
import type { DictionaryItem, ListResponse } from './types'

class AppraisalApi extends WrapperRequest {
  url = process.env.REACT_APP_APPRAISAL ?? ''

  getMakes = async (): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Hierarchy/makes`)
  }

  getModels = async (makeId: number): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Hierarchy/makes/${makeId}/models`)
  }

  getTrims = async (modelId: number): Promise<ListResponse<DictionaryItem>> => {
    return await this.get(`${this.url}/api/v1/Hierarchy/models/${modelId}/trims`)
  }
}

export const AppraisalAPIProvider = new AppraisalApi()
export default AppraisalAPIProvider
