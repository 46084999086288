import { omitNotNumbers, serializers, parsers } from '@carfluent/common'
import { ServiceType } from 'website/api/types'
import { Lead } from 'website/api/types/leads'

import { format } from 'date-fns'

export type ReplacedProps =
  | 'dealerId'
  | 'serviceType'
  | 'vin'
  | 'vehicleInfo'
  | 'dateTime'
  | 'customerEmail'
  | 'customerPhoneNumber'
  | 'customerComments'

export type FormValues = Omit<API.VehicleRequest, ReplacedProps> & {
  dateTime: Date | null
  customerComments: string
  customerEmail: string
  customerPhoneNumber: string
}

export const DEFAULT_FORM_VALUES: FormValues = {
  customerFirstName: '',
  customerLastName: '',
  customerEmail: '',
  customerPhoneNumber: '',
  customerComments: '',
  dateTime: null
}

export interface SerializePayload extends FormValues {
  dealerId: string
  vin: string | null
  vehicleInfo?: string | null
  serviceType: ServiceType
}

const getStringOrNull = (value?: string | null): string | null => {
  return (value != null && value.length > 0) ? value : null
}

export const serialize = (payload: SerializePayload): API.VehicleRequest => {
  const { dealerId, serviceType, vin, vehicleInfo, ...formValues } = payload
  return {
    dealerId,
    dateTime: serializers.serializeDateTime(formValues.dateTime) ?? null,
    customerFirstName: formValues.customerFirstName,
    customerLastName: formValues.customerLastName,
    customerEmail: getStringOrNull(formValues.customerEmail),
    customerComments: getStringOrNull(formValues.customerComments),
    customerPhoneNumber: getStringOrNull(omitNotNumbers(formValues.customerPhoneNumber)),
    serviceType,
    vin: getStringOrNull(vin),
    vehicleInfo: getStringOrNull(vehicleInfo)
  }
}

enum LeadSourceIds {
  Ask = 23,
  TestDrive,
  Financing = 21
}

const LEAD_SOURCE_IDS = {
  [ServiceType.AskQuestion]: LeadSourceIds.Ask,
  [ServiceType.TestDrive]: LeadSourceIds.TestDrive,
  [ServiceType.Financing]: LeadSourceIds.Financing
}

const HOT_TEMPERATURE_ID = 1

interface LeadDataPayload extends Omit<SerializePayload, 'vehicleInfo' | 'vin' | 'serviceType'> {
  serviceType: ServiceType.AskQuestion | ServiceType.TestDrive
  vehicleId?: number
}

const { parseDateStringUTC } = parsers
const { serializeDateTimeUTC } = serializers

const DATE_PART = 'MM/dd/yy'
const FORMAT_DATE = `${DATE_PART} hh:mm aa`
const EMPTY_DATE_VALUE = null

export const getDate = (date: string): string | null => {
  const parsedDate = parseDateStringUTC(date)

  return parsedDate != null
    ? format(parsedDate, FORMAT_DATE) ?? EMPTY_DATE_VALUE
    : EMPTY_DATE_VALUE
}

export const serializeLeadData = ({
  serviceType,
  dealerId,
  vehicleId,
  customerEmail,
  customerPhoneNumber,
  customerFirstName,
  customerLastName,
  customerComments,
  dateTime
}: LeadDataPayload): Lead => {
  const phoneNumber = omitNotNumbers(customerPhoneNumber)
  const serializedDateTime = serializeDateTimeUTC(dateTime)

  const baseData = {
    dealerId,
    firstName: customerFirstName,
    lastName: customerLastName,
    phoneNumber: phoneNumber === '' ? null : phoneNumber,
    email: customerEmail === '' ? null : customerEmail,
    leadSourceId: LEAD_SOURCE_IDS[serviceType],
    leadTemperatureId: HOT_TEMPERATURE_ID,
    comments: customerComments,
    address: null,
    city: null,
    state: null,
    zipCode: null,
    birthDate: null,
    assignedUserId: null,
    vehicleId: vehicleId ?? null,
    vehiclesIds: vehicleId != null ? [vehicleId] : null
  }

  return serializedDateTime != null
    ? {
        ...baseData,
        additionalData: {
          DateTime: serializedDateTime
        }
      }
    : baseData
}

export default serialize
