import { FC, useMemo } from 'react'
import closeIcon from 'website/assets/close_white.svg' // TODO: use close_white_24.svg
import createStyleClass from './styles'

interface HeaderProps {
  counter: string
  onClose: () => void
}

const Header: FC<HeaderProps> = ({ counter, onClose }) => {
  const rootCls = useMemo(createStyleClass, [])

  return (
    <div className={rootCls}>
      <p className='cf-header-counter'>{counter}</p>
      <img
        onClick={onClose}
        src={closeIcon}
        alt='close icon'
      />
    </div>
  )
}

export default Header
