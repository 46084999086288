export const containsTruthy = (value: any): boolean => {
  if (Array.isArray(value)) {
    return value.some(containsTruthy)
  }

  if ((typeof value === 'object') && (value !== null)) {
    return Object.values(value).some(containsTruthy)
  }

  return Boolean(value)
}
