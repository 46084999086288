import { useCallback, useEffect, type FC } from 'react'
import pDebounce from 'p-debounce'

import { type FilterRangeKey, type ActiveFilters } from 'website/configs'
import { SupportedComponentNames, type InventoryPageProps } from 'website/components/types'
import DynamicComponent from 'website/components/DynamicComponent'
import isMultirooftop from 'website/utils/isMultirooftop'
import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import { getQueryStringFilters } from 'website/utils/qsfilters'
import apiProvider from 'website/api/apiProvider'
import { mergeFiltersWithAppliedRanges } from 'website/utils/apiFilters'
import ZipCodeLocationModal from '../ZipCodeLocationUpdateModal'
import { useLocation } from 'react-router-dom'

const _isMultirooftop = isMultirooftop()

/**
 * DD-TODO:
 * - do not load vechicles until qs is processed (add some prop to store
 * and update it after initial qs processing and then use it in VehiclesList)
 * - pass controller to Inventory page and any component
 * working with filters, sorting. We need factory to instantiate it.
 * We need to hide this QS work under it to abstract logic for these
 * components. Or in other case if we are gonna get rid of QS we will
 * need to rewrite all these components again.
 * But reading should still be done from Recoil store.
 * (This way we'd follow CQRS pattern)
 * - decrease number of places where we use isGeneratedListFilter, isGeneratedRangeFilter
 * - improve rendering performance of Inventory page ->
 * (Vehicle card Image rendering is slow because it uses mainImageUrl
 * instead of thumbnailUrl. But thumbnails have size 200x200 and the card
 * has more space. so they would be distorted if we use them. DISCUSS WITH BE)
 *
 */

/**
 * AZ-NOTE:
 *
 * Some information regarding filters (used here and throughout the app):
 * - `allFilters`     - all possible filters, loaded from API on mount
 * - `appliedFilters` - currently applied filters (mirrors User's inputs, actually)
 * - `activeFilters`  - filters, loaded from API but with applied input (restrictions) from UI.
 *      Usually, this is a subset of the initial `allFilters`.
 *      But it can include some uniq elements, if any car was added between initial and current load.
 *      So, in future we should consider extending `allFilters` by any new elements from `activeFilters`.
 *
 *      WARNING: `activeFilters` and `appliedFilters` are not equal! They are for different purposes!
 *
 * *************************************************************
 * DD-NOTE:
 *
 *  Flow:
 * - All filters are loaded on mount.
 * - Initial appliedFilers are set from qs
 *
 * (DD-TODO:
 * update these notes after qs updates
 * are abstracted to FilterCommandsController)
 * ).
 *
 * - After each User's action qs is updated and custom event is dispatched
 * (listening to qs changes is not reliable in the router we use)
 * - On this event `appliedFilters` are taken from the qs and set in Store
 * after which it is passed to `onFiltersSortingChange`
 * which gets new `active filters` from BE.
 * - These new `active filters` are used to
 * update `count` property in all lists' items in `allFilters`.
 * - Any other part of the app may listen to changes in Store and act accordingly.
 *
 * - Conclusion: only InventoryPage can write to the Store.
 * It does initially and after custom events handling.
 * All other components may listen to the Store but can update filters only
 * after user action and only through qs.
 */

const API_CALL_DELAY = 1000

const calculateFilters = async (filters: API.FiltersRequest): Promise<Omit<API.FiltersResponse, FilterRangeKey>> => {
  /**
   * we intentionally ignore any range filters here because
   * applying those from backend would cause inconsistent UI (unexpected slider filter changes)
   */
  const { year, price, mileage, ...otherFilters } = await apiProvider.vehicles.calculateFilters({ ...filters })

  return otherFilters
}

const calculateFiltersDebounced = pDebounce(calculateFilters, API_CALL_DELAY)

const useFiltersState = SharedStateHook<Store.VehiclesFilterState>(StoreBranches.VehiclesFilter)
const useSortingState = SharedStateHook<Store.VehiclesSortingState>(StoreBranches.VehiclesSorting)

const InventoryPage: FC<InventoryPageProps> = (props) => {
  const [, setFiltersState] = useFiltersState(props.states?.filters ?? defaultInstance(StoreBranches.VehiclesFilter))
  const [, setSharedSorting] = useSortingState(props.states?.sorting ?? defaultInstance(StoreBranches.VehiclesSorting))
  const location = useLocation()

  const setAppliedFiltersAndSorting = useCallback((isInitial: boolean = false): {filters: ActiveFilters} => {
    const { filters, sorting } = getQueryStringFilters()

    /**
     * we set initial filtering state only once here - at first setup
     */
    setFiltersState((state) => ({
      ...state,
      isFilteringEnabled: isInitial || state.isFilteringEnabled,
      appliedFilters: filters
    }))

    setSharedSorting((state) => ({
      ...state,
      appliedSorting: sorting
    }))

    return { filters }
  }, [setFiltersState, setSharedSorting])

  const onFiltersSortingChange = useCallback(async (): Promise<void> => {
    const { filters } = setAppliedFiltersAndSorting()

    try {
      const filterResponse = await calculateFiltersDebounced(filters)

      setFiltersState((state) => {
        const activeFilters = mergeFiltersWithAppliedRanges(
          state.allFilters, state.appliedFilters, filterResponse
        )

        return {
          ...state,
          allFilters: activeFilters
        }
      })
    } catch (e) {
      console.error('Error fetching facets:', e)
    }
  }, [setAppliedFiltersAndSorting, setFiltersState])

  useEffect(() => {
    const fetchAvailableFacets = async (): Promise<void> => {
      const { filters } = setAppliedFiltersAndSorting()
      const filtersResponse = await calculateFilters(filters)

      setFiltersState((state) => {
        const activeFilters = mergeFiltersWithAppliedRanges(
          state.allFilters, state.appliedFilters, filtersResponse
        )

        return {
          ...state,
          allFilters: activeFilters
        }
      })
    }

    void fetchAvailableFacets()
  }, [])

  useEffect(() => {
    const handler = (): void => {
      void onFiltersSortingChange()
    }

    setAppliedFiltersAndSorting(true)

    window.addEventListener('vehicleSearchChanged', handler)

    return () => {
      window.removeEventListener('vehicleSearchChanged', handler)
    }
  }, [onFiltersSortingChange, setAppliedFiltersAndSorting])

  /**
   * we listen location change because it might be changed without
   * custom event just by navigation
   */
  useEffect(() => {
    const { filters } = getQueryStringFilters()
    setFiltersState((state) => ({
      ...state,
      appliedFilters: filters
    }))
  }, [location, setFiltersState])

  return (
    <>
      <DynamicComponent {...props} nameInLayout={SupportedComponentNames.InventoryPage} />
      {
        _isMultirooftop && (
          <ZipCodeLocationModal breakpoints={props.zipCodeLocationModalBreakpoints} />
        )
      }
    </>
  )
}

export default InventoryPage
