import { css } from '@emotion/css'

export default css(`
  width: 100%;
  height: 100%;

  img, > div {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`)
