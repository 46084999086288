import { css } from '@emotion/css'

export const createStyleClass = (): string => {
  return css(`
    & {
      .form-controls {
        grid-area: FormControls;
        width: 100%;
        display: flex;

        & > div { flex: 1; }
      }

      .form-container {
        grid-area: Form;
        width: 100%;
        position: relative;
        height: min-content;
        
        .form-field { max-height: 56px; }
      }
    }
  `)
}
