import type { Condition } from 'website/api/types/tradeIn'
import type { ConditionOptionProps } from 'website/components/types'

import { camelCaseRegexp } from 'website/utils/regexp'

export enum YesNo {
  Yes = 'Yes',
  No = 'No'
}

export enum YesNoIds {
  Yes = 1,
  No
}

export function parseConditions <T> (conditions: Condition[]): T[] {
  return conditions.map(({ name, id, description }) => {
    return {
      title: name.replace(camelCaseRegexp, '$1 '),
      value: id,
      ...(description != null ? { description } : {})
    } as unknown as T
  })
}

export const parseConditionsWithSubOptions = (mechanicalIssues: Condition[]): ConditionOptionProps[] => {
  return [
    {
      title: YesNo.Yes,
      value: YesNoIds.Yes,
      subOptions: parseConditions(mechanicalIssues)
    },
    {
      title: YesNo.No,
      value: YesNoIds.No
    }
  ]
}
