import { css } from '@emotion/css'

// DD-NOTE: temporary -> breakpoints should be refactored separately for the whole system and configs
const MOBILE = 599

export default css(`
  @media (max-width: ${MOBILE}px) {
    && .cf-modal-content {
      border-radius: 12px;
      padding: 0px 16px 0px;
    }

    &&.cf-modal-root {
      min-height: unset;
      height: calc(100vh - 70px);
    }
  }

  && .cf-modal-title h3 {
    font-weight: 500;
  }

  .bill-of-sale-content {
    gap: 10px;
    display: grid;
  }

  .bill-of-sale-financing-wrapper {
    margin: 40px -40px 0px;
    width: calc(100% + 80px);
    padding: 40px;
    background: #F3F3F3;
    borderRadius: 16px 16px 12px 12px;
  }

  .bold-label {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.15px;
    align-items: center;
    color: #101010;
  }

  .bold-value {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    align-items: center;
    color: #101010;
  }

  hr {
    margin: 8px 0px;
    border-color: #0000001F;;
    width: 100%;
  }
`)
