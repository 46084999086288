import type { FC } from 'react'
import { cnx } from '@carfluent/common'
import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import { FooterInfoProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'

import ExtendedImage from 'website/components/ExtendedImage'
import DealerInfoFragment from 'website/components/_base/DealerInfoFragment'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const FooterInfo: FC<FooterInfoProps> = ({
  footerImg,
  phoneConfig,
  addressConfig,
  nameInLayout = SupportedComponents.FooterInfo,
  variant,
  className
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponents.FooterInfo, variant)
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))

  // we render parts only if there is only one dealership
  // or else it is a multi dealership

  const dealership = dealerInfo.dealerships.length === 1
    ? dealerInfo.dealerships[0]
    : null

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root, className)}>
      <div className={componentStylesCls.content}>
        {
          dealership != null && (
            <DealerInfoFragment
              phone={dealership.phone}
              addressData={{
                address: dealership.address1,
                city: dealership.city,
                state: dealership.state,
                zipCode: dealership.zipCode
              }}
              phoneConfig={phoneConfig}
              addressConfig={addressConfig}
            />
          )
        }
        {
          footerImg != null && (
            <ExtendedImage src={footerImg} />
          )
        }
      </div>
    </div>
  )
}

export default FooterInfo
