import axios from 'axios'

import { GOOGLE_API_KEY } from 'website/constants/index'
import { addressParts, FullAddressParts } from 'website/utils/googleMap'

const getPlaceIdGeocodeUrl = (placeId: string): string =>
  `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLE_API_KEY}`

const getAddressGeocodeUrl = (address: string = ''): string =>
  `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_API_KEY}`

export const geocoding = (() => {
  const client = axios.create()
  return {
    geocodeByPlaceId: async (placeId: string): Promise<FullAddressParts | null> => {
      const addr = (await client.get(getPlaceIdGeocodeUrl(placeId))).data.results[0]
      return addressParts(addr)
    },
    geocodeByAddress: async (
      address?: string
    ): Promise<FullAddressParts | null> => {
      const res = (await client.get(getAddressGeocodeUrl(address))).data.results[0]
      return addressParts(res)
    }
  }
})()
