import type { FC } from 'react'

import { FillerProps, SupportedComponents } from 'website/components/types'
import { createStyleClass } from './styles'

const Filler: FC<FillerProps> = (props) => {
  /**
   * This component is fully dependent on parent's width.
   * We can not make it behave correctly all the time because of this.
   * css grid sets every component's minWidth to 0 for responsiveness, for example.
   * That is why we need style with minWidth set directly.
   */
  return (
    <div
      className={props.nameInLayout ?? SupportedComponents.Filler}
      style={props.minWidth != null ? { minWidth: props.minWidth } : {}}
    >
      <div className={createStyleClass(props)} />
    </div>
  )
}

export default Filler
