import { forwardRef, LegacyRef } from 'react'
import ReCAPTCHA, { type ReCAPTCHAProps } from 'react-google-recaptcha'

import isReCaptchaDisabled from 'utils/isReCaptchaDisabled'

const ReCaptcha = forwardRef<ReCAPTCHA, ReCAPTCHAProps>((
  props,
  ref
) => {
  if (isReCaptchaDisabled()) {
    return null
  }

  return (
    <ReCAPTCHA {...props} ref={ref as LegacyRef<ReCAPTCHA>} />
  )
})

export default ReCaptcha
