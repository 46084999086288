import { css } from '@emotion/css'

export default css(`
  background: #fff;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .cf-banner-text, .Filler {
    z-index: 1;
    margin-bottom: 24px;
    min-width: clamp(300px, 50%, 600px);
  }

  .cf-banner-text {
    @media screen and (max-width: 768px) {
      font-size: 34px;
    }
  }

  .ExtendedImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`)
