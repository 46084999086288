import { useContext } from 'react'
import axios from 'axios'
import { useSnackbar } from '@carfluent/common'
import type { UseSnackbarReturnType } from '@carfluent/common/dist/UI'

import AlertCTX from 'store/alert'

const NON_DISPLAYABLE_ERRORS = new Set([401, 403])

const isDisplyayableData = (data: any): boolean => {
  if (typeof data === 'string') {
    return true
  }

  return axios.isAxiosError(data) &&
    (data.response?.status != null && !NON_DISPLAYABLE_ERRORS.has(data.response?.status)) &&
    !axios.isCancel(data)
}

const useCustomSnackbar = (): UseSnackbarReturnType => {
  const { removeAlert } = useContext(AlertCTX)

  return useSnackbar({
    isDisplyayableData,
    onClose: removeAlert
  })
}

export default useCustomSnackbar
