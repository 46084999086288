import intersection from 'lodash-es/intersection'

export const ZIP_CODE_TYPE = 'postal_code'
export const CITY_TYPES = ['locality', 'city', 'postal_town', 'sublocality_level_1', 'sublocality']
export const STATE_TYPE = 'administrative_area_level_1'
export const COUNTY_TYPE = 'administrative_area_level_2'

export interface FullAddressParts {
  addressLong?: string | null
  address?: string | null
  city?: string | null
  zipCode?: string | null
  state?: string | null
  county?: string | null
  streetNumber?: string | null
  latLng?: google.maps.LatLngLiteral
}

export const getAddressInfo = (value: google.maps.GeocoderResult, type: string, isShort: boolean = true): string => {
  const res = value.address_components.find(item => item.types[0] === type)

  if (res == null) {
    return ''
  }

  return isShort ? res?.short_name : res?.long_name
}

export function getPostalCode (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => item.types.includes(ZIP_CODE_TYPE))

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export function getCity (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => intersection(item.types, CITY_TYPES).length > 0)

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export function getState (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => Boolean(item.types.includes(STATE_TYPE)) && (item.short_name.length === 2))

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export function getCounty (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => item.types.includes(COUNTY_TYPE))

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export const fullAddress = (addr?: FullAddressParts | null): string => {
  if (addr == null) {
    return ''
  }
  const { addressLong, address, city, state, zipCode } = addr
  const prefix = [addressLong ?? address, city, state, zipCode].filter(Boolean).join(', ')
  return prefix.length > 0 ? `${prefix}, USA` : ''
}

export const fullAddressShort = (addr?: FullAddressParts | null): string => {
  if (addr == null) {
    return ''
  }
  const { addressLong, address, city, state, zipCode } = addr
  const prefix = [addressLong ?? address, city, state].filter(Boolean).join(', ')
  return prefix.length > 0 ? `${prefix}${zipCode != null ? ` ${zipCode}` : ''}` : ''
}

export const addressParts = (
  place: google.maps.GeocoderResult | null
): FullAddressParts | null => {
  if (place == null) {
    return null
  }

  const addressParts = place?.address_components?.slice(0, 2) ?? []
  const addressLong = addressParts.map(item => item.long_name).join(' ') ?? null
  const addressShort = addressParts.map(item => item.short_name).join(' ') ?? null

  try {
    return {
      addressLong,
      address: addressShort,
      city: getCity(place),
      state: getState(place),
      zipCode: getPostalCode(place),
      county: getCounty(place),
      streetNumber: getAddressInfo(place, 'street_number'),
      /**
       * built-in function toJSON or lat or lng are not working as expected
       */
      latLng: JSON.parse(JSON.stringify(place.geometry.location))
    }
  } catch (r) {
    return null
  }
}
