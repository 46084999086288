import axios from 'axios'
import { FullAddressParts } from './types'
import { addressParts, fullAddress } from './utils'
import { GOOGLE_API_KEY } from './constants'

const getPlaceIdGeocodeUrl = (placeId: string): string =>
  `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLE_API_KEY}`

const getAddressGeocodeUrl = (address: string = ''): string =>
  `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_API_KEY}`

const client = (() => {
  const client = axios.create()
  return {
    geocodeByPlaceId: async (placeId: string): Promise<FullAddressParts | null> => {
      const addr = (await client.get(getPlaceIdGeocodeUrl(placeId))).data.results[0]
      return addressParts(addr)
    },
    geocodeByAddress: async (
      address?: string, city?: string, zipCode?: string, state?: string
    ): Promise<FullAddressParts | null> => {
      const addr = fullAddress({ address, city, zipCode, state })
      const res = (await client.get(getAddressGeocodeUrl(addr))).data.results[0]
      return addressParts(res)
    }
  }
})()

export default client
