import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    position: 'relative'
  },

  content: {
    whiteSpace: 'nowrap',
    transition: 'transform 0.5s',
    position: 'relative',
    height: '100%'
  },

  item: {
    display: 'inline-flex',
    '&, & > div, & > div > div': {
      flex: 1,
      height: '100%',
      width: '100%'
    }
  }
})
