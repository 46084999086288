import { css } from '@emotion/css'

export default css(`
  position: relative;

  .dealer-info-header {
    grid-area: Title;
  }

  .DealerInfoSwitcher {
    grid-area: DealerInfoSwitcher;
  }

  .cf-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`)
