import { createContext } from 'react'
import { FormikValues } from 'formik'
import type { UseFormikReturnType } from 'types'

export type FormCTXProps<V extends FormikValues = FormikValues> =
  UseFormikReturnType<V>

/**
 * Do not remove this `any` type.
 * It will be overrided by type provided to useForm/useField hooks.
 * See more details here:
 * https://github.com/formium/formik/blob/b9cc2536a1edb9f2d69c4cd20ecf4fa0f8059ade/packages/formik/src/FormikContext.tsx#L5
 */
export const FormCTX = createContext<FormCTXProps<any>>(undefined as any)

export default FormCTX
