import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

export const EmailIcon: FC<IconProps> = ({
  fill = '#000',
  width = 20,
  height = 20
}) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_93674_189214)'>
      <path
        d='M15.3359 5H4.66927C3.93594 5 3.33594 5.6 3.33594 6.33333V14.3333C3.33594 15.0667 3.93594 15.6667 4.66927 15.6667H15.3359C16.0693 15.6667 16.6693 15.0667 16.6693 14.3333V6.33333C16.6693 5.6 16.0693 5 15.3359 5ZM15.0693 7.83333L10.7093 10.56C10.2759 10.8333 9.72927 10.8333 9.29594 10.56L4.93594 7.83333C4.76927 7.72667 4.66927 7.54667 4.66927 7.35333C4.66927 6.90667 5.15594 6.64 5.53594 6.87333L10.0026 9.66667L14.4693 6.87333C14.8493 6.64 15.3359 6.90667 15.3359 7.35333C15.3359 7.54667 15.2359 7.72667 15.0693 7.83333Z'
        fill={fill}
        fillOpacity='0.38'
      />
    </g>

    <defs>
      <clipPath id='clip0_93674_189214'>
        <rect width='20' height='20' fill='white'/>
      </clipPath>
    </defs>
  </svg>
)

export default EmailIcon
