import type { FC } from 'react'
import { cn } from '@carfluent/common'

import CLASS_NAME from './styles'

interface WithLabelProps {
  label?: string
  className?: string
  isActive: boolean
}

const WithLabel: FC<WithLabelProps> = ({ children, label, className, isActive }) => {
  if (label != null) {
    return (
      <div className={cn(CLASS_NAME, className, isActive ? 'cf-active' : '')}>
        <span className='cf-label'>
          {label}
        </span>

        {children}
      </div>
    )
  }

  return <>{children}</>
}

export default WithLabel
