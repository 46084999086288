/* eslint-disable no-template-curly-in-string */

import { FormikErrors, yupToFormErrors } from 'formik'
import isAfter from 'date-fns/isAfter'
import isDateFn from 'date-fns/isDate'
import parseISO from 'date-fns/parseISO'
import get from 'lodash-es/get'
import * as Yup from 'yup'
import { TestContext } from 'yup'
import { AnyObject } from 'yup/es/types'
import { toJS } from 'mobx'
import { utils, validation } from '@carfluent/common'
import { FullAddressParts } from 'components/AddressAutocomplete/types'

import {
  BIRTHDATE_RANGE_START,
  MAX_YEARS_OLD,
  BIRTHDATE_RANGE_END,
  MIN_YEARS_OLD,
  ValidationLength
} from 'constants/validation/constants'
import type { KeyVal } from 'types'
import { aptRegex } from './regex_helper'
import { TestReturn } from '../types'
import { isFalsy } from './common'

export const { isEmailValid } = validation

const { isAnyLowerCase, isAnyUpperCase } = utils.text

export const MAX_RENT = 99999

export const lowerAndUpperCaseRegex = /(?=.*[a-z])(?=.*[A-Z]).*/

const isEmpty = (val?: string): boolean => val == null || val === ''

export const isValidPhone = (value: string = ''): boolean => Boolean(value.replace(/[^0-9]/g, '').match(/\d{10}/g))

export const isNotEmpty = (value: string = ''): boolean => Boolean(value?.trim().length)

export const isWithoutSymbols = (val?: string): boolean => !(((val?.match(/[&/\\#,+!()$~%.'":;*^№?<>{}@]/g)) != null) || ((val?.match(/[0-9]/g)) != null))

export const isProperName = (val?: string): boolean => Boolean(val?.match(/^[a-zA-Z]+[a-zA-Z\s-]*$/))

export const isApt = (val?: string | null): boolean => {
  return aptRegex.test(val ?? '')
}

/**
 * Right now this function allows more then one space between words.
 * It's strange, but legal (at least, according to our requirements).
 */
export const isPersonName = (val: string = ''): boolean => {
  const re = /^(\s)*[A-Za-z'.]+((-|(\s)*)?[A-Za-z'.]+)*(\s)*$/
  return re.test(val)
}

export const isNumbersOnly = (val?: string): boolean => Boolean(val?.match(/^\d+$/g))

export const isWithoutSymbolsAndSpaces = (val?: string): boolean => isWithoutSymbols(val) && ((val?.match(/\s/g)) == null)

export const isNotSimpleNumber = (val?: string): boolean => val != null ? val.length > 0 && ''.padEnd(val.length, val[0]) !== val : false

const driverLicenseRegex = new RegExp(`^[A-Za-z0-9- ]{${ValidationLength.DRIVER_LINCENSE_MIN},}$`)

export const isDriverLicenseNumber = (value?: string): boolean => driverLicenseRegex.test(value ?? '')

export const isDriverLicenseNumberOrEmpty = (value?: string): boolean => isEmpty(value ?? '') || isDriverLicenseNumber(value ?? '')

export function isDate (date: any): date is Date {
  return isDateFn(date)
}

export function isValidDate (date: any): boolean {
  return isDate(date) && !isNaN(date.getTime())
}

export function getValidDateOrNull (value: any): Date | null {
  return isValidDate(value) ? value : null
}

export const getDateOrNull = (value: any): Date | null => {
  return isDate(value) ? value : null
}

export const getValueOrEmpty = <T>(value: T): T | string => {
  return value != null ? value : ''
}

export const getStringOrEmpty = (value: any): string => (typeof value === 'string') ? value : ''

export function getValidator<T> (schema: Yup.AnySchema, showErrors = false, showData = false) {
  return (data: T): FormikErrors<T> => {
    try {
      schema.validateSync(data, { abortEarly: false })
      return {}
    } catch (err) {
      if (showErrors) {
        console.info(err)
        console.info(yupToFormErrors(err))
      }

      if (showData) {
        console.info('data :: ', toJS(data))
      }

      return yupToFormErrors(err)
    }
  }
}

// AZ-TODO-IMPR: move to centralized localization file
export const ErrorMsg = {
  required: 'Field is required',
  invalidFormat: 'Invalid format',
  onlyNumbers: 'Should contain only numbers',
  positive: 'Should be greater than zero',
  exactLength: 'Should contain ${length} characters',
  minLength: 'Should contain at least ${min} characters',
  maxLength: 'Should contain at most ${max} characters',
  exactNumberLength: 'Should contain ${length} numbers',
  minNumberLength: 'Should contain at least ${min} numbers',
  maxNumberLength: 'Should contain at most ${max} numbers',
  maxLengthShort: 'Max ${max} characters',
  nonSymbols: 'Should not contain symbols',
  validName: 'Should contain valid name',
  birthdayMin: `You need to be ${MIN_YEARS_OLD} years or older`,
  birthdayMax: `You need to be ${MAX_YEARS_OLD} years or younger`,
  timePassed: 'Selected time has already passed',
  invalidAddress: 'Address should have state, city, address and zip code',
  invalidApt: 'Field is invalid',
  invalidDriverLicense: 'Please enter valid driver license number'
}

export function getErrorMessages<T extends KeyVal> (msgs: T): KeyVal {
  return { ...ErrorMsg, ...msgs }
}

export function getLengthErrorMessage (msg: string, len: number): string {
  return msg
    .replace('${length}', `${len}`)
    .replace('${min}', `${len}`)
    .replace('${max}', `${len}`)
}

export const addressData = (): Yup.ObjectSchema<{}> => Yup.object()
  .transform((val: FullAddressParts | null) => val ?? {})
  .test('addressData', ErrorMsg.invalidAddress, ({ city, zipCode, state, address }: FullAddressParts) => {
    return Boolean(city) && Boolean(zipCode) && Boolean(state) && Boolean(address)
  })

export const isAfterFieldDate = (fieldId: string) => (value: Date | null | undefined, context: TestContext<AnyObject>): boolean => {
  if (value != null) {
    return isAfterDate(context.parent[fieldId])(value)
  }
  return true
}

export const isAfterDate = (dateToCompare: string | Date | null | undefined) => (value: Date | null | undefined): boolean => {
  if (value != null && dateToCompare != null) {
    const resolvedDate = isDate(dateToCompare)
      ? dateToCompare
      : parseISO(dateToCompare)
    return isAfter(value, resolvedDate)
  }
  return true
}

/**
 * with nullable we get proper date object
 * which does not break further date field validational pipelines.
 */
export const validDate = Yup.date()
  .transform(getDateOrNull)
  .nullable()
  .typeError(ErrorMsg.invalidFormat)

export const requiredDate = (): Yup.DateSchema<Date | undefined | null> => validDate.required(ErrorMsg.required)

export const zipCode = (): Yup.StringSchema => Yup.string()
  .transform(getValueOrEmpty)
  .required(ErrorMsg.required)
  .test('numbers', ErrorMsg.onlyNumbers, isNumbersOnly)
  .length(ValidationLength.ZIP_CODE, ErrorMsg.exactNumberLength)

export const city = (): Yup.StringSchema => Yup.string()
  .transform(getValueOrEmpty)
  .required(ErrorMsg.required)
  .test('symbols', ErrorMsg.nonSymbols, isProperName)
  .min(ValidationLength.NAME_MIN, ErrorMsg.minLength)
  .max(ValidationLength.CITY_MAX, ErrorMsg.maxLength)

export const stringField = (): Yup.StringSchema => Yup.string()
  .transform(getValueOrEmpty)

export const requiredString = (): Yup.StringSchema => stringField()
  .required(ErrorMsg.required)

export const firstName = (): Yup.StringSchema => requiredString()
  .test('symbols', ErrorMsg.nonSymbols, isPersonName)
  .min(ValidationLength.NAME_MIN, ErrorMsg.minLength)
  .max(ValidationLength.FIRST_NAME_MAX, ErrorMsg.maxLength)

export const lastName = (): Yup.StringSchema => requiredString()
  .test('symbols', ErrorMsg.nonSymbols, isPersonName)
  .min(ValidationLength.NAME_MIN, ErrorMsg.minLength)
  .max(ValidationLength.LAST_NAME_MAX, ErrorMsg.maxLength)

export const apt = (): Yup.StringSchema => stringField()
  .test('symbols', ErrorMsg.invalidApt, isApt)
  .max(ValidationLength.APT_MAX, ErrorMsg.maxLengthShort)

export const birthDate = (): Yup.DateSchema<Date | null | undefined> => requiredDate()
  .max(BIRTHDATE_RANGE_START, ErrorMsg.birthdayMin)
  .min(BIRTHDATE_RANGE_END, ErrorMsg.birthdayMax)

export const phoneNumber = (): Yup.StringSchema => Yup.string()
  .transform(getStringOrEmpty)
  .required(ErrorMsg.required)
  .length(ValidationLength.PHONE_NUMBER, ErrorMsg.exactNumberLength)
  .test('simple', 'Please enter valid phone number', isNotSimpleNumber)

export const state = (): Yup.StringSchema => Yup.string()
  .required(ErrorMsg.required)
  .test('symbols', ErrorMsg.nonSymbols, isProperName)

export const socialSecurityNumber = (): Yup.StringSchema => Yup.string()
  .required(ErrorMsg.required)
  .transform(getValueOrEmpty)
  .test('numbers', ErrorMsg.onlyNumbers, isNumbersOnly)
  .length(ValidationLength.SSN, ErrorMsg.exactNumberLength)

export const driverLicenseNumber = (): Yup.StringSchema => Yup.string()
  .transform(getValueOrEmpty)
  .test('license', 'Please enter valid driver license number', isDriverLicenseNumberOrEmpty)

/**
 * Don't use this rule for validation email in signup\login pages,
 * since it allows spaces before\after email.
 */
export const email = (validationLength: number = ValidationLength.EMAIL_MAX): Yup.StringSchema => Yup.string()
  .required(ErrorMsg.required)
  .transform(getValueOrEmpty)
  .transform((value: string) => (value.trim()))
  .max(validationLength, ErrorMsg.maxLength)
  .test('email', 'Please enter valid email', isEmailValid)

export const password = (): Yup.StringSchema => Yup.string()
  .required('Please add your Password')
  .min(ValidationLength.PASSWORD)
  .test('letter cases', 'Password must have upper & lower cases', (val) =>
    (val != null) && isAnyLowerCase(val) && isAnyUpperCase(val))

export const vinField = (): Yup.StringSchema => stringField()
  .optional()
  .max(ValidationLength.VIN_MAX, ErrorMsg.maxLength)

export const vehicleInfoField = (): Yup.StringSchema => stringField()
  .optional()
  .max(ValidationLength.VEHICLE_INFO_MAX, ErrorMsg.maxLength)

export const requiredDependentFields = (
  fields: string[],
  errorMessage: string = ErrorMsg.required
): () => TestReturn<string | undefined> =>
  (): TestReturn<string | undefined> => [
    'siblings',
    errorMessage,
    (value: any, ctx: Yup.TestContext): boolean => {
      const areSiblingsEmpty = fields
        .filter(item => !ctx.path.endsWith(item))
        .every(siblingId => isFalsy(get(ctx.parent, siblingId)))

      return areSiblingsEmpty || Boolean(value)
    }
  ]
