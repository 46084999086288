/**
 * returns phoneNumber with hidden two digits and two last digits
 * @param phoneNumber
 * @returns **XX
 */
const maskPhoneNumber = (phoneNumber: string): string => {
  const lastTwoDigits = phoneNumber.slice(-2)

  return `**${lastTwoDigits}`
}

export const loginLabelText = (phoneNumber: string): JSX.Element => {
  return (
    <label className='cf-label-text'>
      We sent you a 4 digit code
      <br />
      {`to phone number ending ${maskPhoneNumber(phoneNumber)}`}
    </label>
  )
}

export const registerLabelText = (phoneNumber: string): JSX.Element => {
  return (
    <label className='cf-label-text'>
      To protect your profile, we sent you a 4 digit
      <br />
      {`security code to phone number ending ${maskPhoneNumber(phoneNumber)}`}
    </label>
  )
}
