import { type FC } from 'react'
import { IconProps } from 'website/components/types'

const ArrowDownIcon: FC<IconProps> = ({
  className,
  fill = '#101010'
}) => {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 16L6 10L7.41 8.59L12 13.17L16.59 8.59L18 10L12 16Z'
        fill={fill}
      />
    </svg>
  )
}

export default ArrowDownIcon
