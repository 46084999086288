export default {
  DealershipLocations: {
    default: {
      root: `
        --offset: max(40px, calc((100vw - 1526px) / 2));

        overflow-x: hidden;

        .component-container {
          display: grid;
          grid-gap: 16px;
          position: relative;
          grid-template-columns: 1fr;
          grid-template-areas: "DealershipLocationsList";

          @media (min-width: 1200px) {
            grid-gap: 32px;
          }
        }
      `,

      content: `
        grid-area: DealershipLocationsList;
        position: relative;
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 40px var(--offset);
        max-width: calc(100vw - var(--offset) * 2);

        .location-card {
          width: 100%;
          display: flex;
          max-width: 100%;
          overflow: hidden;
          background: #fff;
          border-radius: 12px;
          flex-direction: column;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
          border: 1px solid rgba(235, 235, 235, 1);

          .location-link {
            flex: 1;
            padding: 20px;
            margin-top: 8px;
            text-align: center;
            border-radius: 12px;
            text-decoration: none;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 1);
            background: rgba(32, 195, 158, 1);

            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 1.25px;
            font-family: Roboto, sans-serif;
          }

          .location-info {
            padding: 16px;
            display: flex;
            flex-direction: column;

            > img {
              height: 273px;
            }

            > h6 {
              font-size: 20px;
              font-weight: 500;
              line-height: 24px;
              margin-bottom: 8px;
              letter-spacing: 0.15px;
              color: rgba(16, 16, 16, 1);

              span {
                color: rgba(32, 195, 158, 1);
              }
            }
          }

          .DealerInfoFragment {
            display: flex;
            flex-direction: column;

            .phone-value {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0.4px;
              color: rgba(16, 16, 16, 1);
            }

            .address-block {
              gap: 4px;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.15px;
              color: rgba(33, 33, 33, 0.8);
            }

            path {
              fill: rgba(32, 195, 158, 1)
            }

            a {
              margin-bottom: 8px;
            }
          }

          @media (min-width: 769px) {
            width: 496px;
          }
        }

        @media (max-width: 460px) {
          padding: 40px 16px;
          max-width: calc(100vw - 32px)
        }
      `
    }
  }
}
