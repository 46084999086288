import { useCallback, useState, type ChangeEvent, type FC } from 'react'
import debounce from 'lodash-es/debounce'
import { MaskedInput } from '@carfluent/common'
import { setZipCodeLocationInLS } from 'website/services/storage/zipCodeLocation'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import { zipCodeValidators } from 'website/utils/validation/presets/common'
import GeoApiProvider from 'website/api/geo.api'

const STORE_UPDATE_DEBOUNCE_DELAY = 1000
const INPUT_PROPS: { inputMode: 'numeric' } = { inputMode: 'numeric' }

interface ZipCodeInputProps {
  syncWithStore?: boolean
  onChange?: (value: string) => void
  value?: string
}

const useZipCodeLocationState = SharedStateHook<Store.ZipCodeLocationState>(StoreBranches.ZipCodeLocation)

const ZipCodeInput: FC<ZipCodeInputProps> = ({
  onChange: _onChange,
  syncWithStore = false,
  value
}) => {
  const [zipCode, setZipCode] = useState<string | null>(null)
  const [zipCodeLocationState, setZipCodeLocationState] = useZipCodeLocationState(
    defaultInstance(StoreBranches.ZipCodeLocation), setZipCodeLocationInLS
  )

  const setZipCodeLocationStateDebounced = useCallback(debounce(
    setZipCodeLocationState, STORE_UPDATE_DEBOUNCE_DELAY
  ), [setZipCodeLocationState])

  const onChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const value = e.target.value

    /**
     * we support controlled and uncontrolled syncable to store modes
     */
    if (_onChange != null) {
      _onChange(value)
    } else {
      setZipCode(value)
    }

    if (!syncWithStore) {
      return
    }

    /**
     * used to make it send request to update store only if it is valid already
     */
    if (zipCodeValidators.find(fn => fn(value) != null) != null) {
      return
    }
    
    const geo = await GeoApiProvider.getGeoByZip(value)

    setZipCodeLocationStateDebounced({
      ...zipCodeLocationState,
      ...geo,
      zipCode: value,
      lastUpdateTs: Date.now()
    })
  }

  const resolvedValue = value ?? zipCode ?? zipCodeLocationState.zipCode

  return (
    <MaskedInput
      id='zipCode'
      mask='zipCode'
      onChange={onChange}
      inputProps={INPUT_PROPS}
      placeholder='Your zip code'
      label={null}
      value={resolvedValue}
    />
  )
}

export default ZipCodeInput
