import { FC, useMemo } from 'react'
import { ExtendedTypographyProps, SupportedComponents } from 'website/components/types'
import tagMapper from './tagMapper'
import { createStyleClass } from './styles'

import { cn } from '@carfluent/common'

const ExtendedTypography: FC<ExtendedTypographyProps> = ({
  children,
  showEllipsis = false,
  isHoverable = false,
  nameInLayout = SupportedComponents.ExtendedTypography,
  variant,
  color,
  fontWeight,
  fontSize,
  textAlign,
  lineHeight,
  className,
  value
}) => {
  const cls = useMemo(() => {
    return createStyleClass({ variant, color, fontWeight, fontSize, textAlign, lineHeight, showEllipsis, isHoverable })
  }, [variant, color, fontWeight, lineHeight, textAlign, showEllipsis, isHoverable])

  const TextTag = tagMapper(variant)

  return (
    <TextTag className={cn(nameInLayout, cls, className)}>
      {value ?? children}
    </TextTag>
  )
}

export default ExtendedTypography
