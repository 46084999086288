import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  rootStepper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 80,

    '@media screen and (max-height: 860px)': {
      padding: '16px 25px'
    }
  },
  rootStep: {
    padding: '15px 25px',
    width: '100%',
    '&:hover': {
      background: '#FAFAFA',
      cursor: 'pointer'
    },

    '@media screen and (max-height: 860px)': {
      padding: '12px 0',

      '&:first-of-type': {
        paddingTop: 0
      },

      '&:last-of-type': {
        paddingBottom: 0
      }
    }
  },
  rootStepLabel: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.15,
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconContainerStep: {
    paddingRight: 16
  },
  link: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    textDecoration: 'none',
    fontFamily: 'Roboto, sans-serif',
    color: '#212121',
    fontWeight: 400,

    '& img': {
      marginLeft: 10
    }
  },
  disabledLink: {
    pointerEvents: 'none'
  }
})
