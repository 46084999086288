import { useState, useRef, useEffect, useCallback } from 'react'
import type { Dropdown2Props } from '@carfluent/common'

import { DictionaryItem } from 'website/api/types'
import AppraisalAPIProvider from 'website/api/appraisal.api'
import useAsyncEffect from 'hooks/useAsyncEffect'

import { parseTrims } from './parser'
import { DEFAULT_VALUES } from './constants'

export type IdKeys = 'make' | 'model' | 'trim'

export interface UseCarDetailsProps {
  values: Record<'make' | 'model', DictionaryItem | null> & { trim?: DictionaryItem | null }
  onChange: (id: string, value: unknown) => void
  setFieldTouched?: (id: string, value: boolean) => void
  ids: Record<'make' | 'model', string> & { trim?: string }
  isTrimHidden: boolean
}

export interface UseCarDetailsReturn extends Record<'makes' | 'models' | 'trims', DictionaryItem[]> {
  onChangeMake: Dropdown2Props<DictionaryItem, false>['onChange']
  onChangeModel: Dropdown2Props<DictionaryItem, false>['onChange']
  onChangeTrim: Dropdown2Props<DictionaryItem, false>['onChange']
}

export const useCarDetailsFields = ({
  values,
  onChange: _onChange,
  ids,
  setFieldTouched,
  isTrimHidden
}: UseCarDetailsProps): UseCarDetailsReturn => {
  const [makes, setMakes] = useState<DictionaryItem[]>([])
  const [models, setModels] = useState<DictionaryItem[]>([])
  const [trims, setTrims] = useState<DictionaryItem[]>([])
  const valuesRef = useRef<UseCarDetailsProps['values']>(DEFAULT_VALUES)
  const onChangeRef = useRef<(id: string, value: unknown) => void>(_onChange)
  const setFieldTouchRef = useRef<((id: string, value: boolean) => void) | undefined>(setFieldTouched)
  const modelsRef = useRef<DictionaryItem[]>([])
  const trimsRef = useRef<DictionaryItem[]>([])

  useEffect(() => {
    onChangeRef.current = _onChange
  }, [_onChange])

  useEffect(() => {
    setFieldTouchRef.current = setFieldTouched
  }, [setFieldTouched])

  useEffect(() => {
    modelsRef.current = models
  }, [models])

  useEffect(() => {
    trimsRef.current = trims
  }, [trims])

  useAsyncEffect(async () => {
    const { items } = await AppraisalAPIProvider.getMakes()
    setMakes(items)
    setModels([])
    setTrims([])
  }, [])

  const onChangeMake = useCallback((_, value) => {
    _onChange(ids.make, value)
  }, [_onChange, ids])

  const onChangeModel = useCallback((_, value) => {
    _onChange(ids.model, value)
  }, [_onChange, ids])

  const onChangeTrim = useCallback((_, value) => {
    _onChange(ids?.trim ?? '', value)
  }, [_onChange, ids])

  const onMakeChanged = useCallback(async (makeInOptions: DictionaryItem, isReset: boolean = false) => {
    const { items } = await AppraisalAPIProvider.getModels(makeInOptions.id)

    setModels(items)
    setTrims([])

    if (isReset) {
      await onChangeRef.current(ids.model, null)
      valuesRef.current.make = null

      if (!isTrimHidden) {
        await onChangeRef.current(ids.trim ?? '', null)
        valuesRef.current.trim = null
      }
    }
  }, [values.make?.id, isTrimHidden, ids])

  const onModelChanged = useCallback(async (makeInOptions: DictionaryItem, isReset: boolean = false) => {
    if ((values.model == null || modelsRef.current.length === 0) && !isTrimHidden) {
      await onChangeRef.current(ids.trim ?? '', null)
      return
    }

    const modelInOptions = modelsRef.current.find(({ id }) => id === values.model?.id)

    if (modelInOptions != null) {
      valuesRef.current.model = values.model
      if (!isTrimHidden) {
        const { items } = await AppraisalAPIProvider.getTrims(modelInOptions.id)
        setTrims(parseTrims(items))

        if (isReset) {
          await onChangeRef.current(ids.trim ?? '', null)
          valuesRef.current.trim = null
        }
      }
    } else {
      await onChangeRef.current(ids.model, null)
      valuesRef.current.model = null

      if (!isTrimHidden) {
        await onChangeRef.current(ids.trim ?? '', null)
        valuesRef.current.trim = null
      }
    }
  }, [values.model?.id, isTrimHidden])

  useAsyncEffect(async () => {
    const isPreventLogic = makes.length === 0

    if (isPreventLogic) {
      return
    }

    const makeInOptions = makes.find(({ name }) => name.trim() === values.make?.name.trim())

    if (makeInOptions == null) {
      valuesRef.current = { ...DEFAULT_VALUES }
      setModels([])

      onChangeRef.current(ids.make, null)
      onChangeRef.current(ids.model, null)
      if (!isTrimHidden) {
        onChangeRef.current(ids.trim ?? '', null)
      }
      return
    }

    const isMakeChanged = values.make?.id !== valuesRef.current.make?.id
    const isModelChanged = values.model?.id !== valuesRef.current.model?.id

    if (isMakeChanged) {
      await onMakeChanged(makeInOptions, !isModelChanged)
    }

    if (isModelChanged) {
      await onModelChanged(makeInOptions, !isMakeChanged)
    }

    await setFieldTouchRef.current?.(ids.model, false)
    if (!isTrimHidden) {
      await setFieldTouchRef.current?.(ids.trim ?? '', false)
    }
    valuesRef.current = values
  }, [values.make?.id, values.model?.id, onModelChanged, onMakeChanged, makes, isTrimHidden])

  return {
    makes,
    models,
    trims,
    onChangeMake,
    onChangeModel,
    onChangeTrim
  }
}
