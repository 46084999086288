import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  activeMark: {
    color: 'red'
  },
  completedBlock: {
    background: '#000',
    borderRadius: '50%',
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  activeBlock: {
    background: '#000',
    borderRadius: '50%',
    width: 20,
    height: 20
  }
})

export default useStyles
