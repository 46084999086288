export const ERROR_FAILED = 'Failed to send request. Please try again later.'

export enum FormIds {
  FirstName = 'firstName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  ZipCode = 'zipCode',
  Email = 'email',
  ContactYou = 'contactYou'
}

export const DEFAULT_FORM_VALUE = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  zipCode: '',
  email: '',
  contactYou: 'phone'
}
