export enum SupportedComponents {
  Header = 'Header',
  FooterInfo = 'FooterInfo',
  Login = 'Login',
  CarBodyStylePanel='CarBodyStylePanel',
  DrawerMenu = 'DrawerMenu',
  Carousel = 'Carousel',
  Filler = 'Filler',
  ExtendedImage = 'ExtendedImage',
  ExtendedTypography = 'ExtendedTypography',
  LazyImage = 'LazyImage',
  DynamicComponent = 'DynamicComponent',
  FiltersDrawerToggler = 'FiltersDrawerToggler',
  RouteOutlet = 'RouteOutlet',
  Socials = 'Socials',
  Schedule = 'Schedule',
  Map = 'Map',
  MainSearchSection='MainSearchSection',
  VehicleCard = 'VehicleCard',
  RecommendedVehicles = 'RecommendedVehicles',
  SearchBar = 'SearchBar',
  Button = 'Button',
  Input = 'Input',
  VehicleInfiniteScroll = 'VehicleInfiniteScroll',
  VehiclesFilter = 'VehiclesFilter',
  VehiclesFilterChips = 'VehiclesFilterChips',
  VehiclesSorting = 'VehiclesSorting',
  VehiclesSearch = 'VehiclesSearch',
  DealerInfo = 'DealerInfo',
  Text = 'Text',
  TooltipText = 'TooltipText',
  List = 'List',
  GalleryVehicleView = 'GalleryVehicleView',
  VehicleViewContent = 'VehicleViewContent',
  VehicleBriefInfo = 'VehicleBriefInfo',
  VehicleSpecsOverview = 'VehicleSpecsOverview',
  FeatureOptionList = 'FeatureOptionList',
  RequestHelpOrDrive = 'RequestHelpOrDrive',
  TextWithFiller = 'TextWithFiller',
  GetPrequalifiedComponent = 'GetPrequalifiedComponent',
  GetPrequalifiedRedirectComponent = 'GetPrequalifiedRedirectComponent',
  GetPrequalifiedForm = 'GetPrequalifiedForm',
  InfoCard = 'InfoCard',
  Modal = 'Modal',
  CarAppraisalForm = 'CarAppraisalForm',
  Banner = 'Banner',
  CustomerReviews = 'CustomerReviews',
  VinStickerButton = 'VinStickerButton',
  GetPrequalifiedSuccess = 'GetPrequalifiedSuccess',
  TradeInDetailsFormPage = 'TradeInDetailsFormPage',
  Condition = 'Condition',
  TradeInCar = 'TradeInCar',
  TradeInUserDetailsForm = 'TradeInUserDetailsForm',
  ZipCodeLocation = 'ZipCodeLocation',
  ZipCodeLocationModal = 'ZipCodeLocationModal',
  SsnYearIncomeForm = 'SsnYearIncomeForm',
  FeedbackForm = 'FeedbackForm',
  UnsubscribeButton = 'UnsubscribeButton',
  IncludedWithCarAds = 'IncludedWithCarAds',
  Messenger = 'Messenger',
  DealershipLocations = 'DealershipLocations',
  DealershipLocationBanner = 'DealershipLocationBanner',
  DealerLocationsMenu = 'DealerLocationsMenu',
  DealershipLocationInfo = 'DealershipLocationInfo',
  GetPrequalifiedDisclosure = 'GetPrequalifiedDisclosure',
  CarCategoryCard = 'CarCategoryCard',
  PopularCarsPanel = 'PopularCarsPanel',
  HomePageVideoPlayer = 'HomePageVideoPlayer'
}

export enum SupportedComponentNames {
  GeneralLayout = 'GeneralLayout',
  AboutUsPromos = 'AboutUsPromos',
  AboutUsPage = 'AboutUsPage',
  InventoryPage = 'InventoryPage',
  FeedbackPage = 'FeedbackPage',
  Footer = 'Footer',
  NotFound = 'NotFound',
  VehicleView = 'VehicleView',
  HowItWorks = 'HowItWorks',
  GetPrequalifiedCallToAction = 'GetPrequalifiedCallToAction',
  VehicleServiceRequestModalForm = 'VehicleServiceRequestModalForm',
  TradeSellIntro = 'TradeSellIntro',
  CallToActionInfo = 'CallToActionInfo',
  DealerLocationsPopover='DealerLocationsPopover',
  VehicleCardSkeleton = 'VehicleCardSkeleton',
  PrequalifiedFormSSNIncome = 'PrequalifiedFormSSNIncome',
  PrequalifiedFormError = 'PrequalifiedFormError',
  GetPrequalifiedFormPage = 'GetPrequalifiedFormPage',
  InfoLink = 'InfoLink',
  UnsubscribePage = 'UnsubscribePage',
  UnsubscribedPage = 'UnsubscribedPage',
  HomePage = 'HomePage'
}
