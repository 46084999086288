import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatCurrency } from '@carfluent/common'

import { WebsiteRoutes } from 'website/routing/constants'

import { type CarCategoryCardProps } from '../types'
import SvgIcon from '../_base/SvgIcon'

const CarCategoryCard: FC<CarCategoryCardProps> = ({ image, label, price }) => {
  const navigate = useNavigate()

  const onCarCategorySelect = (): void => {
    navigate(`${WebsiteRoutes.Inventory}?price=0-${price}`)
  }

  return (
    <div
      className='carCategoryCard'
      onClick={onCarCategorySelect}
      style={{
        backgroundImage: `url(${image})`
      }}
    >
      <div className='category-text'>
        <h3>{label}</h3>

        <p>{formatCurrency(price)}</p>
      </div>

      <span className='category-arrow-icon'><SvgIcon type='arrowRightAlt' /></span>
    </div>
  )
}

export default CarCategoryCard
