import { css } from '@emotion/css'

export default css(`
  background: #fff;
  
  .cf-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 16px;
    
    .cf-form-field-error {
      font-family: Roboto, sans-serif;
    }
    
    [class*=helper-text] {
      margin-top: 16px;
    }
    
    .cf-divider {
      height: 1px;
      background: rgba(0, 0, 0, 0.12);
      margin-bottom: 40px;
    }
    
    .cf-input-title {
      margin-bottom: 32px;
    }

    h2 {
      font-weight: 500;

      @media (min-width: 376px) {
        font-size: 20px;
      }
    }
  
    .cf-input-subtitle {
      margin-bottom: 16px;
    }
  }
  
  .cf-continue-button {
    margin-bottom: 40px;
  }
  
  .cf-back-button {
    width: 65px;
    margin: 0 auto;
  }
  
  .cf-input-wrapper {
    margin-bottom: 40px;
  }
  
  .Condition {
    width: 100%;
    margin-bottom: 40px;
    
    .cf-condition-title {
      p {
        margin: 0;
      }
    }
  }
  
  .cf-form-input {
    max-height: 56px;

    .cf-title-cost {
      p {
        font-size: 20px;
        font-weight: 500;
      }
    }
    
    .MuiFormControl-root {
      width: 100%;
      border-radius: 12px;
    }
  }
`)
