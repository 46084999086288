import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  sliderRoot: {
    margin: '0px 8px',
    maxWidth: 'calc(100% - 20px)'
  },
  sliderRail: {
    backgroundColor: '#E0E0E0',
    height: '4px'
  },
  sliderTrack: {
    backgroundColor: 'var(--mainColor)',
    height: '4px'
  },
  sliderThumb: {
    height: '20px',
    width: '20px',
    marginTop: '-8px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E4E4E7',
    '&::after': {
      display: 'none'
    },
    '&.Mui-focusVisible, &.MuiSlider-active, &:hover': {
      boxShadow: 'none'
    }
  }
})

export default useStyles
