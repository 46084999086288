export enum VehicleCondition {
  Excellent = 'Excellent',
  VeryGood = 'VeryGood',
  Good = 'Good',
  Fair = 'Fair'
}

export interface TradeInModelsType {
  skip: number
  take: number
  makeId: number
  year: number
}

export interface TradeInMakesType {
  skip: number
  take: number
  year: number
}

export interface TradeInYearsType {
  skip: number
  take: number
}

export interface TradeInModelYearsType {
  modelId: number
  yearId: number
  makeId: number
}

export interface TradeInTrimsType {
  skip: number
  take: number
  year: number
  modelId: number
  makeId: number
  modelYearId: number
}

export interface VehicleByVin {
  year?: number | null
  make?: string | null
  model?: string | null
  trim?: string | null
}

export interface CalculateVehicleValuePayload {
  vehicleMake: string | null
  vehicleModel: string | null
  vehicleTrim: string | null
  vehicleYear: number | null
  vehicleVin: string | null
  mileage: number
  hasAccidents: boolean
  zipCode: string
  vehicleCondition: VehicleCondition
}

export interface SearchVehicleCriteria {
  modelId?: number
  year?: number
  makeId?: number
  trimId?: number
}

export interface VehicleByCriteria {
  vehicleId: number
  vehicleName: string
  trimName: string
}

export interface VehicleValueInfo {
  value: number
  valueSource: string
}
