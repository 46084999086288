import type { FC } from 'react'
import {
  GoogleMap, Marker,
  DirectionsService, DirectionsRenderer
} from '@react-google-maps/api'

import useLoadGoogleMapScript from 'hooks/useLoadGoogleMapScript'
import { CommonProps, useGenericMap, DEFAULT_ZOOM } from './hook'

export interface MapProps extends CommonProps {
  classes?: {
    mapContainer: string
  }
  language?: string
}

const GenericMap: FC<MapProps> = (props) => {
  const { classes, language } = props
  const {
    center,
    directionsConfig,
    directions,
    handleClearDirections,
    handleInitMap,
    handleDirectionsCalculated
  } = useGenericMap(props)

  const { isGoogleScriptLoaded } = useLoadGoogleMapScript(language)

  if (!isGoogleScriptLoaded) {
    return null
  }

  return (
    <GoogleMap
      center={center}
      zoom={DEFAULT_ZOOM}
      mapContainerClassName={classes?.mapContainer}
      onLoad={handleInitMap}
    >
      {(directionsConfig != null) && (
        <DirectionsService
          options={directionsConfig}
          callback={handleDirectionsCalculated}
          onUnmount={handleClearDirections}
        />
      )}

      {(directions != null) && <DirectionsRenderer options={{ directions }} />}

      <Marker position={center} />
    </GoogleMap>
  )
}

export default GenericMap
