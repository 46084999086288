import { formatCurrency, formatInteger } from '@carfluent/common'

import type { Lead } from 'website/api/types/leads'
import { DEFAULT_API_DATA as DEFAULT_LEAD_API_DATA, TRADE_IN_SOURCE_ID } from 'website/components/GetPrequalifiedForm/hook/serializer'
import {
  VehicleConditionIds,
  vehicleConditionMapper,
  mechanicalIssuesMapper,
  MechanicalIssuesIds
} from 'website/components/TradeInDetailsForm/hook/constant'
import { YesNo, YesNoIds } from 'website/components/TradeInDetailsForm/hook/parser'
import { formatPhoneNumber } from 'utils/constants'

import type { FormValues } from './validator'
import { isStringEmpty } from 'website/utils/isStringEmpty'
import GeoApiProvider from 'website/api/geo.api'

interface SerializerDataProps extends FormValues, Store.TradeInDetailsVehicleState, Store.TradeInVehicleState {
  dealerId?: number | string | null
}

const DEFAULT_API_DATA = {
  address: null,
  city: null,
  state: null,
  ...DEFAULT_LEAD_API_DATA
}

const EMPTY_VALUE = 0

const getYesNo = (id: number | null = YesNoIds.No): string => {
  return id === YesNoIds.Yes
    ? YesNo.Yes
    : YesNo.No
}

export const serializeData = async ({
  firstName,
  lastName,
  dealerId,
  email,
  zipCode,
  phoneNumber,
  contactYou,
  mileage,
  conditions,
  vehicleCollision,
  haveAirbagsDeployed,
  totalRepairCosts,
  mechanicalIssues,
  paymentType,
  paymentAmount,
  make,
  model,
  year,
  vin,
  trim,
  price
}: SerializerDataProps): Promise<Lead | null> => {
  if (isStringEmpty(zipCode)) {
    return null
  }

  const { latitude, longitude } = await GeoApiProvider.getGeoByZip(zipCode) ?? {}
  const collision = getYesNo(vehicleCollision?.value)

  return {
    dealerId,
    firstName,
    lastName,
    phoneNumber,
    email,
    zipCode,
    locationLatitude: latitude,
    locationLongitude: longitude,
    ...DEFAULT_API_DATA,
    leadSourceId: TRADE_IN_SOURCE_ID,
    additionalData: {
      Name: `${firstName} ${lastName}`,
      Phone: formatPhoneNumber(phoneNumber),
      Mileage: `${formatInteger(mileage)} miles`,
      Email: email,
      ZipCode: zipCode,
      PreferableTypeOfContact: contactYou,
      VehicleCondition: vehicleConditionMapper[conditions?.value as VehicleConditionIds] ?? YesNo.No,
      Collision: collision,
      AirbagsDeployed: collision === YesNo.Yes
        ? getYesNo(haveAirbagsDeployed?.value)
        : YesNo.No,
      RepairsCost: collision === YesNo.Yes
        ? formatCurrency(
          (totalRepairCosts != null)
            ? totalRepairCosts
            : EMPTY_VALUE
        )
        : YesNo.No,
      MechanicalIssues: mechanicalIssues?.value === YesNoIds.Yes
        ? mechanicalIssues?.subOptionValue?.reduce((res: string[], item) => {
          if (mechanicalIssuesMapper[item as MechanicalIssuesIds] != null) {
            res.push(mechanicalIssuesMapper[item as MechanicalIssuesIds])
          }

          return res
        }, []).join(',')
        : YesNo.No,
      PaymentsOnYourVehicle: paymentType?.value === YesNoIds.Yes ? YesNo.Yes : YesNo.No,
      AmountStillOutstanding: paymentType?.value === YesNoIds.Yes
        ? formatCurrency(
          (paymentAmount != null)
            ? paymentAmount
            : EMPTY_VALUE
        )
        : null,
      Make: make,
      Model: model,
      Year: year?.toString(),
      Trim: trim,
      Date: new Date().toString(),
      ...(vin != null && vin !== '' ? { Vin: vin } : {}),
      ...(price != null && price !== 0 ? { TradeInValue: formatCurrency(price) } : {})
    }
  }
}
