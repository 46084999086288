import { createContext } from 'react'
import { action, observable, makeObservable } from 'mobx'

class GlobalUIStore {
  isDealWasUpdatedVisible: boolean = false

  showDealWasUpdated = (): void => {
    this.isDealWasUpdatedVisible = true
  }

  hideDealWasUpdated = (): void => {
    this.isDealWasUpdatedVisible = false
  }

  constructor () {
    makeObservable(this, {
      isDealWasUpdatedVisible: observable,
      showDealWasUpdated: action,
      hideDealWasUpdated: action
    })
  }
}

export const GlobalUIInstance = new GlobalUIStore()
export const GlobalUICTX = createContext(GlobalUIInstance)

export default GlobalUICTX
