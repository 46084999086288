import { DictionaryItem } from 'website/api/types'

export const NO_TRIM_VALUE = 'No trim'

export const parseTrim = (trim: string | null): string | null => {
  return trim?.trim() === '' ? NO_TRIM_VALUE : trim
}

export const parseTrims = (trims: DictionaryItem[]): DictionaryItem[] => {
  return trims.map((item) => item.name.trim() === ''
    ? { ...item, name: NO_TRIM_VALUE }
    : item)
}
