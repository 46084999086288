import { type RefObject } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import apiProvider from 'website/api/apiProvider'
import isReCaptchaDisabled from 'utils/isReCaptchaDisabled'

const isRecaptchaSuccess = async (refRecaptcha: RefObject<ReCAPTCHA>): Promise<boolean> => {
  if (isReCaptchaDisabled()) {
    return true
  }

  const token = await refRecaptcha.current?.executeAsync()
  if (token == null) {
    return false
  }

  const result = await apiProvider.identity.verifyRecaptcha({ token })
  return result.success
}

export default isRecaptchaSuccess
