import LS from 'services/storage.service/storage'

const PREQUALIFY_STATE = 'PREQUALIFY_STATE'

export const setPrequalifyStateInLS = (location: Store.PrequalifyState): void => {
  LS.set(PREQUALIFY_STATE, location)
}

export const getPrequalifyStateFromLS = (): Store.PrequalifyState | null => {
  return LS.get(PREQUALIFY_STATE)
}
