const INPUTS_GAP = 16

export default {
  PrequalifiedFormSSNIncome: {
    default: {
      root: `
        padding: 40px 16px 0;
        background: #fff;
        margin-bottom: 40px;
        
        > div {
          padding-top: 0;
        }
        
        .SsnYearIncomeForm {
          > * {
            margin-bottom: 32px;
          }
          
          > h3 {
            font-family: Roboto;
            font-size: 24px;
            margin-bottom: 16px;
          }
          
          .Button {
            margin-bottom: 0;
          }
          
          .ssn-year-input-wrapper {
            .form-field {
              width: 100%;
              :first-child {
                margin-bottom: 32px;
              }
            }
          }
          
          .MuiButton-root {
            max-height: 56px;
            height: 56px;
          }
          
          > .Button {
            .MuiButtonBase-root {
              background: var(--mainOutlinedButtonColor);
            }
          }
        }
        
        .ButtonGoShopWrapper {
          .MuiButton-root {
            background: #fff;
            color: #000;
            max-height: 56px;
            height: 56px;
            box-shadow: none;
            border: 1px solid #000;
            
            :hover {
              background: #FAFAFA;
              color: #000;
            }
          }
        }
        
        .ErrorHead {
          h1 {
            font-weight: 500;
          }
        }
        
        .ErrorSubHead {
          p {
            font-size: 14px;
          }
        }

        @media(min-width: 768px) {
          .SsnYearIncomeForm {
            max-width: 600px;
            width: 600px;
            
            .ssn-year-input-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              
              .form-field {
                width: calc(50% - ${INPUTS_GAP / 2}px);
                
                :first-child {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      `
    }
  }
}
