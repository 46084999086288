import { type FC } from 'react'
import { IconProps } from 'website/components/types'

const LocationIcon: FC<IconProps> = ({
  className,
  onClick,
  fill = "#F4FCFA"
}) => {
  return (
    <svg onClick={onClick} className={className} width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="40" rx="12" fill={fill}/>
      <g clipPath="url(#clip0_5675_111967)">
        <path d="M30.1253 19.9558C30.8313 19.2505 31.3443 18.3758 31.6154 17.4154C31.8865 16.455 31.9066 15.4411 31.6737 14.4708L33.7528 13.5791C33.8162 13.5519 33.8854 13.5409 33.9541 13.5471C34.0228 13.5532 34.0889 13.5763 34.1465 13.6143C34.2041 13.6523 34.2513 13.704 34.284 13.7648C34.3167 13.8255 34.3337 13.8935 34.3337 13.9625V25.8308L28.5003 28.3308L23.5003 25.8308L18.2478 28.0816C18.1844 28.1088 18.1153 28.1198 18.0465 28.1137C17.9778 28.1075 17.9117 28.0844 17.8541 28.0464C17.7966 28.0084 17.7493 27.9567 17.7167 27.896C17.684 27.8352 17.6669 27.7673 17.667 27.6983V15.8308L20.2745 14.7133C20.091 15.6513 20.1411 16.62 20.4202 17.5341C20.6994 18.4481 21.1991 19.2795 21.8753 19.955L26.0003 24.0808L30.1253 19.9558ZM28.947 18.7775L26.0003 21.7225L23.0537 18.7766C22.471 18.1939 22.0743 17.4514 21.9136 16.6432C21.753 15.835 21.8355 14.9973 22.1509 14.236C22.4663 13.4747 23.0004 12.824 23.6856 12.3662C24.3707 11.9084 25.1763 11.6641 26.0003 11.6641C26.8244 11.6641 27.6299 11.9084 28.3151 12.3662C29.0003 12.824 29.5343 13.4747 29.8497 14.236C30.1651 14.9973 30.2477 15.835 30.087 16.6432C29.9263 17.4514 29.5296 18.1939 28.947 18.7766V18.7775Z" fill="#20C39E"/>
      </g>
      <defs>
        <clipPath id="clip0_5675_111967">
          <rect width="20" height="20" fill="white" transform="translate(16 10)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default LocationIcon
