import type { ChangeEvent, FocusEvent } from 'react'
import capitalize from 'lodash-es/capitalize'
import kebabCase from 'lodash-es/kebabCase'
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core'
import { cnx } from '@carfluent/common'

import iconChecked from 'assets/check_case.svg'
import iconCheckbox from 'assets/case.svg'
import type { BaseFormField } from '../types'
import { useField } from '../Form'
import useStyles from './styles'

export interface FormCheckboxProps extends BaseFormField<boolean | null>, Partial<{
  label: React.ReactNode
  name: string
  classes: { control?: string, label?: string } | string
  dataTestId: string
  disabled: boolean
  onBlur: (evt: FocusEvent<HTMLButtonElement>) => void
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void
}> {}

function FormCheckbox (props: FormCheckboxProps): JSX.Element {
  const {
    id,
    label,
    name,
    classes,
    dataTestId,
    onChange,
    ...otherProps
  } = props

  const styles = useStyles()
  const { value, showError } = useField<boolean | null>(props)
  const rootClasses = typeof classes === 'string' ? classes : (classes?.control ?? '')

  return (
    <FormControl
      fullWidth
      className={cnx(rootClasses, showError && 'Mui-error')}
    >
      <FormControlLabel
        classes={{
          root: styles.rootFormControlLabel,
          label: styles.labelFormControlLabel
        }}
        control={
          <Checkbox
            data-test-id={dataTestId ?? kebabCase(id)}
            id={id}
            name={name ?? id}
            checked={value === true}
            error={showError}
            onChange={onChange}
            checkedIcon={<img width='24px' height='24px' alt='checkbox-icon-checked' src={iconChecked} />}
            icon={<img width='24px' height='24px' alt='checkbox-icon' src={iconCheckbox} />}
            classes={{
              root: cnx(showError && styles.checkboxWithError, 'checkbox'),
              colorSecondary: styles.colorSecondary
            }}
            {...otherProps}
          />
        }
        label={label ?? capitalize(id)}
      />
    </FormControl>
  )
}

export default FormCheckbox
