import { useCallback, useState } from 'react'

export interface UseModalReturn {
  isModalOpen: boolean
  onOpenModal: () => void
  onCloseModal: () => void
}

const useModal = (): UseModalReturn => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = useCallback(() => setIsModalOpen(true), [])
  const handleCloseModal = useCallback(() => setIsModalOpen(false), [])

  return {
    isModalOpen,
    onOpenModal: handleOpenModal,
    onCloseModal: handleCloseModal
  }
}

export default useModal
