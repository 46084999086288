import { css } from '@emotion/css'

export const POPPER_WIDTH = 245

export const MAIN_CLASS = css(`
  > div {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-column-gap: 8px;
    align-items: center;
    max-width: 124px;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }

    p {
      font-family: Roboto;
      font-weight: 400;
      margin: 0px;
    }

    svg {
      grid-row: 1 / 3;
      path {
        fill: var(--mainColor);
      }
    }

    .zipcode-label {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #0000004D;
    }

    .zipcode-message {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`)

export const POPOVER_CLASS = css(`
  max-width: ${POPPER_WIDTH}px;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0px 6px 30px 0px #00000008;

  &.cf-popover-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
    padding: 16px;
  }

  p {
    font-family: Roboto;
    font-weight: 400;
    margin: 0px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.18px;
    grid-column: 1 / 3;
    text-align: left;
  }

  button.cf-button-root {
    margin-top: 16px;
    padding: 16px;
    height: 48px;
    min-width: auto;
  }
`)
