import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import TruncateWithShowMore from "website/components/_base/TruncatedShowMoreButton";
import { useComponentStyles } from 'website/styles/useComponentStyles';
import { VehicleDescriptionProps } from 'website/components/types';

const VehicleDescription: FC<VehicleDescriptionProps> = ({
  nameInLayout = 'VehicleDescriptionContent',
  description,
  className,
  variant
}) => {
  const componentStylesCls = useComponentStyles(nameInLayout, variant)

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root, className)}>
      <TruncateWithShowMore lineClamp={3}>
        {description != null
          ? <div dangerouslySetInnerHTML={{ __html: description }} />
          : '-'}
      </TruncateWithShowMore>
    </div>
  )
}

export default VehicleDescription
