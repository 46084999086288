import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import { Link } from 'react-router-dom'

import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import { useComponentStyles } from 'website/styles/useComponentStyles'

import DealerInfoFragment from '../_base/DealerInfoFragment'
import { type DealershipLocationsProps, SupportedComponents } from '../types'

import dealership1 from 'website/assets/dealership1.png'
import dealership2 from 'website/assets/dealership2.png'
import dealership3 from 'website/assets/dealership3.png'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const images = [dealership1, dealership2, dealership3]

const DealershipLocations: FC<DealershipLocationsProps> = ({
  nameInLayout = SupportedComponents.RecommendedVehicles,
  variant,
  phoneConfig,
  addressConfig,
  className
}) => {
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const componentStylesCls = useComponentStyles(SupportedComponents.DealershipLocations, variant)

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root, className)}>
      <div className='component-container'>
        <div className={componentStylesCls.content}>
          {dealerInfo.dealerships.map((el, i) => {
            const image = images[i % images.length]

            return (
              <div className='location-card' key={el.id}>
                <img src={image} alt='dealership' />

                <div className='location-info'>
                  <h6>
                    {el.dealerName}&nbsp;

                    <span>
                      {el.city}
                    </span>
                  </h6>

                  <DealerInfoFragment
                    phone={el.phone}
                    addressData={{
                      city: el.city,
                      state: el.state,
                      zipCode: el.zipCode,
                      address: el.address1
                    }}
                    phoneConfig={phoneConfig}
                    addressConfig={addressConfig}
                  />

                  <Link
                    className={cnx(
                      'location-link'
                    )}
                    to={`/locations/${el.id}`}
                  >
                    {el.city}
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default DealershipLocations
