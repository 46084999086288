import prequalifiedIcon from 'website/assets/prequalified.svg'
import carIcon from 'website/assets/car.svg'
import lenderIcon from 'website/assets/lender.svg'
import carImage2 from 'website/assets/carImage2.png'

const assets: Record<string, string> = {
  prequalifiedIcon,
  carIcon,
  carImage2,
  lenderIcon
}

export default assets
