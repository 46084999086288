import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import StarIcon from '../icons/Star'
import CLASS_NAME, { BLANC, STROKE, FILLED } from './styles'

export interface StarRatingProps {
  subtitle: string
  className?: string
  value: number
  onChange: (rating: number) => void
}

const StarRating: FC<StarRatingProps> = ({ subtitle, className, value, onChange }) => {
  return (
    <div className={cnx(CLASS_NAME, className)}>
      <h3>{subtitle}</h3>
      <div className='stars'>
        {[...Array(5)].map((_, index) => {
          const ratingValue = index + 1
          return (
            <label key={ratingValue}>
              <input
                type='radio'
                name='rating'
                value={ratingValue}
                onClick={() => onChange(ratingValue)}
              />
              <StarIcon
                fill={value >= ratingValue ? FILLED : BLANC}
                stroke={value >= ratingValue ? FILLED : STROKE}
              />
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default StarRating
