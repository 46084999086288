export default {
  DealerLocationsMenu: {
    default: {
      content: `
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        line-height: 24px;
        position: relative;
        margin-bottom: -24px;
        color: rgba(16, 16, 16, 1);
        font-family: Roboto, sans-serif;

        &.location-item-container {
          margin-bottom: 0;
        }

        .locations-link {
          display: flex;
        }

        .arrow-icon {
          margin-left: 4px;
          transition: transform 0.3s ease;

          &.rotated {
            transform: rotate(180deg);
          }
        }

        .dropdown-menu {
          display: none;
          padding: 8px 0;
          min-width: 240px;
          position: absolute;
          list-style-type: none;
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 24px 38px 0px rgba(58, 71, 78, 0.1);
        }

        .hidden {
          display: none;
        }

        .dropdown-menu.visible {
          display: block;
        }

        .dropdown-menu li {
          padding: 5px 10px;
          cursor: pointer;
          padding: 12px 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.15px;

          :hover {
            background: rgba(243, 243, 243, 1);
          }
        }

        @media (max-width: 1269px) {
          .dropdown-menu {
            position: static;
            background: none;
            border: none;
            box-shadow: none;
            
            &:not(&.hidden) {
              display: block !important;
            }
          }

          .dropdown-menu li {
            padding-left: 20px;
          }
        }
      `
    }
  }
}
