import { type Variant } from '@material-ui/core/styles/createTypography'
import { type VariantProps } from 'website/siteGenerator/types'
import { SupportedStyledComponents } from 'website/types/components/index'

export const extendedTypographyVariants = [
  'default',
  'text1',
  'text2',
  'textMain',
  'textInfo',
  'header1',
  'header4',
  'h4Thin',
  'header1Thin',
  'subtitle1Thin',
  'smallText',
  'noCarInfoTitle'
] as const

export type ExtendedTypographyVariant = typeof extendedTypographyVariants[number]
export type TypographyVariant = Variant | ExtendedTypographyVariant

export const componentThemeStyleProps = ['root', 'content'] as const
export type ComponentThemeStyleProps = typeof componentThemeStyleProps[number]

interface EllipsisConfig {
  showEllipsis: boolean
}

interface HoverableConfig {
  isHoverable: boolean
}

interface VariantConfig {
  variant?: TypographyVariant
}

interface TextStyleConfig {
  color?: string
  fontWeight?: number
  lineHeight?: number | string
}

export interface TypographyConfig extends EllipsisConfig, HoverableConfig, VariantConfig, TextStyleConfig {}

export type ComponentVariant = string | 'default'
export interface TypographyCss {
  typography: Partial<{
    [t in TypographyVariant]: string
  }>
}

export interface ButtonTextConfig {
  color?: string
  textAlign?: 'left' | 'right' | 'center'
  textDecorationStyle?: 'underline' | 'overline' | 'line-through' | 'none'
  fontWeight?: number
}

export interface HeaderDealFlowConfig {
  isAuthPage?: boolean
  isOutsideFlowPage?: boolean
  isYourDetailsPage?: boolean
  isPaymentSharePage?: boolean
}

export type ComponentThemeCssStyles = Record<ComponentVariant, Partial<{
  [prop in ComponentThemeStyleProps]: string | ((props: VariantProps) => string)
}>>

export type ComponentThemeStyles = Partial<{
  [prop in ComponentThemeStyleProps]: string
}>

export type ComponentStylesFromTheme = Record<ComponentThemeStyleProps, string>

export type ThemeSupportedStyles = Partial<Record<SupportedStyledComponents, ComponentThemeCssStyles>>

export type CssTheme = TypographyCss & ThemeSupportedStyles & { link: Record<string, string> }

export type StyleDefinition<T> = T | { [key: string]: StyleDefinition<T> }

export type InfiniteRowConfig = Record<number | string, number>
