import type { FC } from 'react'
import { cnx } from '@carfluent/common'

interface VehicleCardSkeletonProps {
  className: string
}

const VehicleCardSkeleton: FC<VehicleCardSkeletonProps> = ({ className }) => {
  return (
    <div className={cnx(className, 'vehicle-card-skeleton')}>
      <div className='img-block cf-skeleton' />
      <div className='content-block'>
        <div className='text-block cf-skeleton' />
        <div className='text-block cf-skeleton' />
      </div>
      <div className='footer-block'>
        <div className='text-block cf-skeleton' />
        <div className='text-block cf-skeleton' />
      </div>
    </div>
  )
}

export default VehicleCardSkeleton
