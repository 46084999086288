import { useEffect, useRef, useState, useCallback } from 'react'
import { useForm, isOk, type OnActionResultHandler } from '@carfluent/common'

import { validationRules } from './validator'
import { serializeData } from './serializer'
import {
  type FormValues,
  type UseRequestCallProps,
  type UseRequestCallReturn
} from './types'

const ANIMATION_DELAY = 400

const DEFAULT_VALUES: FormValues = {
  firstName: null,
  isAgreementAccepted: true,
  lastName: null,
  phoneNumber: null
}

export const useRequestCall = ({
  onClose: _onClose,
  onSubmit: _onSubmit
}: UseRequestCallProps): UseRequestCallReturn => {
  const [isClosing, setIsClosing] =
    useState(false)

  const refTimeout = useRef<NodeJS.Timeout>()

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onClose = (): void => {
    setIsClosing(true)

    /**
     * Apply animation before closing.
     */
    refTimeout.current = setTimeout(() => {
      _onClose()
      setIsClosing(false)
    }, ANIMATION_DELAY)
  }

  const submitAction = useCallback(async (values) => {
    return values
  }, [])

  const onActionResult: OnActionResultHandler<FormValues> = useCallback(async (
    res,
    resetForm
  ) => {
    if (isOk(res)) {
      onClose()
      _onSubmit?.(serializeData(res.result))
      resetForm()
    }
  }, [onClose, _onSubmit])

  const {
    errors,
    isSubmitting,
    onBlur,
    onChange,
    onSubmit,
    resetForm,
    touched,
    values,
  } = useForm<FormValues>({
    baseValues: DEFAULT_VALUES,
    validationRules,
    submitAction,
    onActionResult
  })

  const onCancel = (): void => {
    onClose()
    resetForm()
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    return () => {
      clearTimeout(refTimeout.current)
    }
  }, [])

  // ========================================== //

  return {
    errors,
    isClosing,
    isSubmitting,
    onChange,
    onBlur,
    onCancel,
    onSubmit,
    touched,
    values
  }
}
