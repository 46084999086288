import pieceOfMind from 'website/assets/car_piece_of_mind.svg'
import bestQuality from 'website/assets/best_quality.svg'
import shoppingEasy from 'website/assets/easy_car_shopping.svg'

const assets: Record<string, string> = {
  pieceOfMind,
  bestQuality,
  shoppingEasy
}

export default assets
