import type { FC } from 'react'
import { IconProps } from 'website/components/types'

const ChatIcon: FC<IconProps> = ({ fill = 'white', width = 32, height = 32 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_92311_3858)">
        <path
          d="M26.668 3.66406H5.33464C3.86797 3.66406 2.6813 4.86406 2.6813 6.33073L2.66797 30.3307L8.0013 24.9974H26.668C28.1346 24.9974 29.3346 23.7974 29.3346 22.3307V6.33073C29.3346 4.86406 28.1346 3.66406 26.668 3.66406ZM8.0013 12.9974H24.0013V15.6641H8.0013V12.9974ZM18.668 19.6641H8.0013V16.9974H18.668V19.6641ZM24.0013 11.6641H8.0013V8.9974H24.0013V11.6641Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_92311_3858">
          <rect
            width={width}
            height={height}
            fill={fill}
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ChatIcon
