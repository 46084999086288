import { type FC } from 'react'
import { IconProps } from 'website/components/types'

const UpDownArrowsIcon: FC<IconProps> = ({
  className,
  fill = '#C99B86'
}) => {
  return (
    <svg className={className} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.33203 7.50964L8.33203 1.66797L6.66536 1.66797L6.66536 7.50964L4.16536 7.50963L7.4987 10.8346L10.832 7.50964L8.33203 7.50964ZM9.16536 12.493L11.6654 12.493L11.6654 18.3346L13.332 18.3346L13.332 12.493L15.832 12.493L12.4987 9.16797L9.16536 12.493Z'
        fill={fill}
      />
    </svg>
  )
}

export default UpDownArrowsIcon
