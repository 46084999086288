import { FC, useRef } from 'react'
import { cnx } from '@carfluent/common'

import Text from 'website/components/Text'
import Button from 'website/components/Button'
import VehicleCard from 'website/components/VehicleCard'

import { VehicleState } from 'website/constants'
import { RecommendedVehiclesProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { useRecommendedVehicles } from './hook'
import LoadingScreen from './components/LoadingScreen'
import ScrollHorizontalButtons from './components/ScrollHorizontalButtons'

// DD-TODO: rework when reworking card design
const SCROLL_STEP = 328

const RecommendedVehicles: FC<RecommendedVehiclesProps> = ({
  nameInLayout = SupportedComponents.RecommendedVehicles,
  variant,
  className,
  maxItemsNumber,
  componentProps
}) => {
  const refList = useRef<HTMLDivElement>(null)
  const componentStylesCls = useComponentStyles(SupportedComponents.RecommendedVehicles, variant)
  const {
    isLoading,
    vehicles,
    prequalify,
    monthlyPayments,
    loadingIds
  } = useRecommendedVehicles({ maxItemsNumber })
  const hasVehicles = !isLoading && vehicles.length > 0

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root, className)}>
      <div className='component-container'>
        {
          (hasVehicles && componentProps.Text != null) && (
            <Text {...componentProps.Text} />
          )
        }
        {
          (hasVehicles && componentProps.Button != null) && (
            <Button {...componentProps.Button} />
          )
        }
        <div className={componentStylesCls.content} ref={refList}>
          {isLoading && vehicles.length === 0 && <LoadingScreen />}

          {vehicles.map((vehicle) => {
            if (vehicle.vehicleState === VehicleState.Deleted) {
              return null
            }

            return (
              <VehicleCard
                {...componentProps.VehicleCard}
                key={vehicle.id}
                vehicle={vehicle}
                prequalify={prequalify}
                active={vehicle.vehicleState === VehicleState.Active}
                monthlyPaymentDetails={monthlyPayments[vehicle.id]}
                isMonthlyPaymentLoading={loadingIds.includes(vehicle.id)}
              />
            )
          })}
        </div>
        <ScrollHorizontalButtons
          isVisible={vehicles.length > 0}
          scrollStep={SCROLL_STEP}
          refList={refList}
        />
      </div>
    </div>
  )
}

export default RecommendedVehicles
