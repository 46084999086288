import { css } from '@emotion/css'

export default css(`

  .cf-car-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  .cf-car-img-block {
    width: 260px;
    height: 200px;
    margin: 0 15px 0 14px;

    @media screen and (max-height: 789px) {
      height: 150px;
    }
  }

  .info-container {
    padding: 0 24px;
  }
  
  .cf-total {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 3px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    > span {
      font-weight: 500;
      color: #101010;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      font-family: Roboto, sans-serif;
    }
  }
  
  .cf-btn-collapse {
    font-family: Roboto, sans-serif;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #101010;
    letter-spacing: 0.1px;
    margin-top: 18px;
    word-break: break-word;
  }
  
  .cf-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(33, 33, 33, 0.8);
    letter-spacing: 0.4px;
  }
  
  .cf-more-less-btn {
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    margin-bottom: -16px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #101010;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    padding: 8px 4px;
  }
`)
