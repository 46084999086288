import { useState, useCallback, useEffect } from 'react'

interface UseLoginTrackerReturn {
  isLoginFlow: boolean
  onClose: () => void
}

const useLoginTracker = (): UseLoginTrackerReturn => {
  const [isLoginFlow, setLoginFlow] = useState(false)

  const onClose = useCallback(() => {
    setLoginFlow(false)
    window.history.pushState(null, '', window.location.pathname)
  }, [])

  useEffect(() => {
    const hashChangeHandler = (): void => {
      setLoginFlow(window.location.hash === '#login')
    }

    window.addEventListener('hashchange', hashChangeHandler)
    hashChangeHandler()

    return () => {
      window.removeEventListener('hashchange', hashChangeHandler)
    }
  }, [])

  return {
    isLoginFlow,
    onClose
  }
}

export default useLoginTracker
