import { VehicleByVin } from 'api/types'
import { CloudAccessToken } from 'api/types/files'
import { CHECK, CLOUD_ACCESS_TOKEN, VEHICLE_INFO } from 'website/constants/localStorageKeys'

export function getVehicleInfoStorage (): VehicleByVin & { vin: string } {
  const vehicleInfo = localStorage.getItem(VEHICLE_INFO) ?? '{}'

  return JSON.parse(vehicleInfo)
}

export function setVehicleInfoStorage (vehicleInfo: VehicleByVin & { vin?: string | null }): void {
  localStorage.setItem(VEHICLE_INFO, JSON.stringify(vehicleInfo))
}

export function getCheckDateInfoStorage (): string | null {
  return localStorage.getItem(CHECK)
}

export function setCheckDateInfoStorage (): void {
  localStorage.setItem(CHECK, JSON.stringify(Date.now()))
}

export function getParsedCloudAccessToken (): CloudAccessToken | null {
  try {
    const cloudAccessToken = localStorage.getItem(CLOUD_ACCESS_TOKEN)
    if (cloudAccessToken == null) {
      return null
    }
    return JSON.parse(cloudAccessToken)
  } catch (e) {
    return null
  }
}
