import { omitNotNumbers } from '@carfluent/common'

import { FormValues } from 'website/hooks/vehicleRequestService/serializer'
import { email, phoneNumber } from 'website/utils/validation/presets'

export const customerEmail = (val: string, values?: FormValues): string | null => {
  const phone = omitNotNumbers((values?.customerPhoneNumber))
  const isPhoneNumberExist = phone.length > 0

  if (val === '' && isPhoneNumberExist) {
    return null
  }

  return email(val)
}

export const customerPhoneNumber = (val: string, values?: FormValues): string | null => {
  const isEmailExist = values?.customerEmail != null && values.customerEmail.length > 0
  const phone = omitNotNumbers(val)

  if (phone === '' && isEmailExist) {
    return null
  }

  return phoneNumber(phone)
}
