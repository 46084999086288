import { type FC } from 'react'
import type { GeneratedFilterProps } from 'website/configs'
import getName from 'website/utils/getName'

import ListItem from './ListFilterItem'

const ListFilter: FC<GeneratedFilterProps<API.ListFilter>> = ({
  applied,
  config,
  id,
  renderOptionName,
  onChange: _onChange,
  type
}) => {
  const onChange = (itemToChange: API.ListFilterItem, checked: boolean): void => {
    const name = getName(itemToChange.name)
    _onChange?.(id, name, type, !checked)
  }

  return (
    <div className='cf-list-filter'>
      {config.items
        .map(item => {
          const name = getName(item.name)
          const checked = applied?.items.some(v => v.name === name) ?? false
          const isDisabled = item.count == null || item.count === 0

          return (
            <ListItem
              key={name}
              item={item}
              isDisabled={isDisabled}
              isColorListItem={type === 'color'}
              renderOptionName={renderOptionName}
              checked={checked}
              onChange={onChange}
            />
          )
        })}
    </div>
  )
}

export default ListFilter
