import queryString from 'query-string'
import { formatCurrencyDecimal, formatCurrency, formatInteger } from '@carfluent/common'

import { type Summary } from 'api/types/summary.types'
import {
  CoverageSummary,
  ProductType
} from 'api/types/coverage.types'

import { DataForRequest } from 'api/types'
import { getDefaultDataForRequest } from 'api/wrapper.api'
import { CheckRedirectProps } from 'routing/utils'
import { Routes } from 'routing/constants'
import { CoverageProductTypeIds, type DealerDefaultCoverageDto } from 'api/types/DTOs/DealerProductDto'

import { getSearchParams } from './common'
import { isIndependentCoverageFlow } from './urlHelpers'

interface SearchDataForIndependentCoveragesReturn {
  accessToken?: string
  dealId?: number
  rowVersion?: string
}

export const getTotalSumTitle = (totalSum: number, approvedTerm: number, monthUnitLabel: string = 'mo'): string => {
  const sumText = formatCurrency(totalSum)
  return (approvedTerm != null && approvedTerm !== 0) ? `${sumText}/${monthUnitLabel}` : `${sumText}`
}

export const getMonthlyPayment = (
  approvedTerm?: number,
  dealerRetailPrice?: number
): number => {
  const argsAreValid = approvedTerm != null && approvedTerm !== 0 &&
    dealerRetailPrice != null && dealerRetailPrice !== 0

  return argsAreValid
    ? Math.round(dealerRetailPrice / approvedTerm)
    : 0
}

export const getCoverageSummary = (
  summary: Summary
): CoverageSummary[] => {
  const coverageDetails: CoverageSummary[] = [{
    productType: ProductType.ServiceContract,
    dealerRetailPrice: summary?.warrantyPrice ?? 0
  }, {
    productType: ProductType.AppearanceProtection,
    dealerRetailPrice: summary?.ceramicPrice ?? 0
  }, {
    productType: ProductType.GapInsurance,
    dealerRetailPrice: summary?.gapPrice ?? 0
  }, {
    productType: ProductType.TireAndWheel,
    dealerRetailPrice: summary?.tireAndWheelPrice ?? 0
  }, {
    productType: ProductType.Theft,
    dealerRetailPrice: summary?.theftPrice ?? 0
  }]

  return coverageDetails.filter(x => Boolean(x.dealerRetailPrice))
}

export const getSearchDataForIndependentCoverages = (): SearchDataForIndependentCoveragesReturn => {
  const { accessToken, dealId, rowVersion } = getSearchParams()

  return {
    accessToken,
    dealId,
    rowVersion
  }
}

export const checkCoverageRouteRedirect = ({ pathname }: CheckRedirectProps): string | null => {
  const { accessToken, dealId, rowVersion } = getSearchDataForIndependentCoverages()
  const isIndependentCoverageLogic = isIndependentCoverageFlow(pathname)
  const isSearchParamsOfIndependentCoverage = Boolean(accessToken != null && dealId && rowVersion)
  const shouldLogoutFromIndependentCoverage = !isSearchParamsOfIndependentCoverage || !isIndependentCoverageLogic

  return shouldLogoutFromIndependentCoverage ? Routes.Login : null
}

export const getDataForRequest = (pathname: string, _rowVersion: string | null = null): DataForRequest => {
  const isOnIndependentPage = isIndependentCoverageFlow(pathname)
  const { accessToken, rowVersion, dealId } = getSearchDataForIndependentCoverages()
  const _default = getDefaultDataForRequest()
  const mergedRowVersion = _rowVersion ?? rowVersion

  return {
    workflowId: (isOnIndependentPage && dealId != null) ? dealId : _default.workflowId,
    workflowVersion: (isOnIndependentPage && mergedRowVersion != null) ? mergedRowVersion : _default.workflowVersion,
    accessToken: (isOnIndependentPage && accessToken != null) ? accessToken : _default.accessToken,
    isAllArgsFromParams: isOnIndependentPage
  }
}

export const getDataForRequestWithFreshRowVersion = (rowVersion: string) => {
  return (pathname: string) => getDataForRequest(pathname, rowVersion)
}

export const getUpdatedSearchParams = (updatedRowVersion: string = ''): string => {
  const { dealId, accessToken, rowVersion } = getSearchDataForIndependentCoverages()

  return encodeURIComponent(queryString.stringify({
    dealId,
    accessToken,
    rowVersion: updatedRowVersion === '' ? rowVersion : updatedRowVersion
  }, { encode: false }))
}

export const getProductInfo = (coverage: DealerDefaultCoverageDto, termMonths: number | null, isPerMonth = true): string => {
  const paymentInfo = termMonths != null && termMonths !== 0 && isPerMonth
    ? formatCurrencyDecimal(coverage.totalPrice / termMonths, { postfix: '/mo' })
    : formatCurrencyDecimal(coverage.totalPrice)
  
  return `${
    paymentInfo
  }${
    coverage.termMonths !== 0 ? ` | ${coverage.termMonths} mo` : ''
  }${
    coverage.termMiles !== 0 ? ` | ${formatInteger(coverage.termMiles)} mi` : ''
  }${
    coverage.productTypeId === CoverageProductTypeIds.ServiceContract
      ? ` | ${formatCurrency(coverage.deductibleAmount)}`
      : ''
  }`
}
