import type { FC } from 'react'

import CLASS_NAME from './styles'

const TypingIndicator: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className="bubble">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  )
}

export default TypingIndicator
