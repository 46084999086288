import { useEffect, RefObject, useRef } from 'react'

const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void): void => {
  const refCallback = useRef(callback)

  useEffect(() => {
    refCallback.current = callback
  }, [callback])

  useEffect(() => {
    const onClick = (e: Event): void => {
      if (ref.current != null && !ref.current.contains(e.target as Node)) {
        refCallback.current()
      }
    }

    document.addEventListener('click', onClick)

    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [ref])
}

export default useClickOutside
