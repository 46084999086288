import type { FC } from 'react'
import { cnx } from '@carfluent/common'
import { formatHoursForView } from 'utils/businessHours'

import { getBusinessHours } from './utils'
import { SupportedComponents, ScheduleProps } from 'website/components/types'

import ExtendedTypography from 'website/components/_base/ExtendedTypography'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const Schedule: FC<ScheduleProps> = ({
  hours,
  nameInLayout = SupportedComponents.Schedule
}) => {
  const componentStyles = useComponentStyles(SupportedComponents.Schedule)
  const hoursToRender = getBusinessHours(hours)

  return (
    <div className={cnx(nameInLayout, componentStyles.root)}>
      <div className={componentStyles.content}>
        {
          hoursToRender.map(item => {
            return (
              <div key={item.day} className='schedule-item'>
                <ExtendedTypography>{item.day}</ExtendedTypography>
                <ExtendedTypography>
                  {formatHoursForView(item.opening, item.closing)}
                </ExtendedTypography>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Schedule
