import { useCallback } from 'react'
import { useForm } from '@carfluent/common'

import { DEFAULT_VALUES } from './constants'
import validationRules, { FormValues } from './validator'

export interface UseMakeModelYearFormProps {
  onSubmit: (values: FormValues) => void
}

export const useMakeModelYear = ({ onSubmit }: UseMakeModelYearFormProps) => {
  const submitAction = useCallback(async (values) => {
    await onSubmit(values)
  }, [onSubmit])

  const form = useForm<FormValues>({
    baseValues: DEFAULT_VALUES,
    validationRules,
    submitAction
  })

  const onChangeYear = useCallback((_, value) => {
    form.onChange('year', value)
  }, [form.onChange])

  return {
    ...form,
    onChangeYear
  }
}
