import { css } from '@emotion/css'

export const createStyles = (lineClamp: number): string => css`
  .content-wrapper {
    max-height: none;
    overflow: visible;

    &.truncated {
      display: -webkit-box;
      -webkit-line-clamp: ${lineClamp};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .show-more-less-block {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;

    button {
      font-size: 12px;
      letter-spacing: 0.4px;
      text-decoration: underline;
      text-underline-offset: 1px;
      padding-right: 0;
      padding-left: 0;
      background: none;
      outline: none;
      border: none;
    }
  }
`
