import type { FC } from 'react'

const DirectionsCarIcon: FC = () => {
  return (
    <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3 14V15C3 15.2833 2.90433 15.5207 2.713 15.712C2.521 15.904 2.28333 16 2 16H1C0.716667 16 0.479333 15.904 0.288 15.712C0.0960001 15.5207 0 15.2833 0 15V7L2.1 1C2.2 0.7 2.37933 0.458333 2.638 0.275C2.896 0.0916668 3.18333 0 3.5 0H14.5C14.8167 0 15.1043 0.0916668 15.363 0.275C15.621 0.458333 15.8 0.7 15.9 1L18 7V15C18 15.2833 17.904 15.5207 17.712 15.712C17.5207 15.904 17.2833 16 17 16H16C15.7167 16 15.4793 15.904 15.288 15.712C15.096 15.5207 15 15.2833 15 15V14H3ZM2.8 5H15.2L14.15 2H3.85L2.8 5ZM4.5 11C4.91667 11 5.27067 10.854 5.562 10.562C5.854 10.2707 6 9.91667 6 9.5C6 9.08333 5.854 8.72933 5.562 8.438C5.27067 8.146 4.91667 8 4.5 8C4.08333 8 3.72933 8.146 3.438 8.438C3.146 8.72933 3 9.08333 3 9.5C3 9.91667 3.146 10.2707 3.438 10.562C3.72933 10.854 4.08333 11 4.5 11ZM13.5 11C13.9167 11 14.2707 10.854 14.562 10.562C14.854 10.2707 15 9.91667 15 9.5C15 9.08333 14.854 8.72933 14.562 8.438C14.2707 8.146 13.9167 8 13.5 8C13.0833 8 12.7293 8.146 12.438 8.438C12.146 8.72933 12 9.08333 12 9.5C12 9.91667 12.146 10.2707 12.438 10.562C12.7293 10.854 13.0833 11 13.5 11ZM2 12H16V7H2V12Z' fill='#1C1B1F' />
    </svg>
  )
}

export default DirectionsCarIcon
