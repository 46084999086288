import { WrapperRequest } from './wrapper.api'

class VehiclesApi extends WrapperRequest {
  url = process.env.REACT_APP_INVENTORY ?? ''
  customersCoreUrl = process.env.REACT_APP_CUSTOMERSCORE ?? ''

  getVehicleByVin = async (vin: string): Promise<API.VehicleDetailsByVin> => {
    return await this.get(`${this.url}/api/v1/Inventory/vin/${vin}`)
  }

  async getVehicle (id: number | string, dealerId: string): Promise<API.VehicleItem> {
    return await this.get(`${this.url}/api/v1/Vehicles/${id}/external?dealerId=${dealerId}`)
  }

  async updatePageVisits (id: string): Promise<number> {
    return await this.post(`${this.url}/api/v1/Vehicles/${id}/view`)
  }

  async getMonthlyPayments (vehicleIds: number[]): Promise<API.VehicleMonthlyPayment[]> {
    return await this.post(`${this.customersCoreUrl}/api/v1/Vehicles/monthly-payments`, { vehicleIds })
  }
}

export const VehicleApiProvider = new VehiclesApi()
export default VehicleApiProvider
