import { UI, FormFieldHOCProps } from '@carfluent/common'

import AddressAutocomplete, { AddressAutocompleteProps } from 'components/AddressAutocomplete/AddressAutocomplete'
import { FullAddressParts } from 'components/AddressAutocomplete/types'

const { FormFieldHOC } = UI

export default FormFieldHOC<
Omit<FormFieldHOCProps, 'value'> & AddressAutocompleteProps,
FullAddressParts | null>(AddressAutocomplete)
