export default {
  Schedule: {
    default: {
      content: `
        display: grid;
        grid-row-gap: 8px;
        grid-template-rows: repeat(7, auto);

        min-width: 240px;
        max-width: 295px;
        
        /*
          DD-NOTE: need to override currently styles which are set
          by the layout system
        */
        > div:first-child {
          height: unset;
          width: unset;
        }

        @media(min-width: 768px) {
          max-width: 340px;
        }

        .schedule-item {
          display: flex;
          height: 24px;

          p {
            flex: 1;
          }
        }
      `
    }
  }
}
