/**
 * Objects here should be used as default Return objects before real payloads are received.
 * Using defaults makes safer and cleaner code throughout the app.
 *
 * NOTE: All important business logic checks should be done anyway as these are only placeholders.
 */

import type { PersonalDetails } from '../types'

export const GET_PERSONAL_DETAILS = (): PersonalDetails => ({
  id: 0,
  email: '',
  firstName: '',
  lastName: '',
  address: '',
  birthDate: '',
  city: '',
  state: '',
  zipCode: '',
  phoneNumber: '',
  apt: '',
  county: '',
  socialSecurityNumber: null
})
