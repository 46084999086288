import { TransactionTypeId, PaymentStatusId } from 'api/types'

export interface AccessInfo {
  publicToken: string
  accountId: string
}

export enum PayTypes {
  Deposit = 'Deposit',
  Card = 'Card',
  ACH = 'ACH',
  Cash = 'Cash',
  Cryptocurrency = 'Cryptocurrency'
}

export const PAY_TYPE_BY_METHOD_ID: Record<TransactionTypeId, PayTypes> = {
  [TransactionTypeId.ACH]: PayTypes.ACH,
  [TransactionTypeId.Card]: PayTypes.Card,
  [TransactionTypeId.Cash]: PayTypes.Cash,
  [TransactionTypeId.Cryptocurrency]: PayTypes.Cryptocurrency,
  [TransactionTypeId.Deposit]: PayTypes.Deposit
}

export interface PaymentDetail {
  amount: number
  transactionTypeId: TransactionTypeId
  paymentStatusId: PaymentStatusId
}

export interface PlaidData {
  linkToken: string
}
