import { AvailableTemplates } from '../../types'
import template from './template.json'
import assets from './assets'

const inventoryPageTemplate = {
  name: AvailableTemplates.InventoryPageTemplate,
  template,
  assets
}

export default inventoryPageTemplate
