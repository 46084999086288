import { KeyVal } from 'types'

const ls = localStorage

export function getUnparsed (name: string): string | null {
  return ls.getItem(name)
}

export function get<T = KeyVal> (name: string, parser?: TranspileFn): T | null {
  const rawValue = getUnparsed(name)

  if (rawValue === null) {
    return null
  }

  try {
    const resolvedValue = parser?.(rawValue) ?? rawValue
    return JSON.parse(resolvedValue) as T
  } catch (err) {
    return null
  }
}

export function set (name: string, value: unknown, serializer?: TranspileFn): void {
  try {
    const str = JSON.stringify(value)
    const resolvedValue = serializer?.(str) ?? str
    ls.setItem(name, resolvedValue)
    window.dispatchEvent(new Event('storage'))
  } catch (err) {
    console.error('LocalStorage is full')
  }
}

export function remove (name: string): void {
  ls.removeItem(name)
  window.dispatchEvent(new Event('storage'))
}

export function clear (): void {
  ls.clear()
  window.dispatchEvent(new Event('storage'))
}

export type TranspileFn = (src: string) => string

const LS = {
  getUnparsed,
  get,
  set,
  remove,
  clear
}

export default LS
