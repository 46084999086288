import type { FC } from 'react'
import { cnx } from '@carfluent/common'

import { type RequestHelpOrDriveProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import useModal from 'website/hooks/useModal'
import VehicleServiceRequestModalForm from 'website/components/_base/VehicleServiceRequestModalForm'

import Link from './components/Link'

const RequestHelpOrDrive: FC<RequestHelpOrDriveProps> = ({
  nameInLayout = SupportedComponents.RequestHelpOrDrive,
  variant,
  dealerId,
  vehicleId,
  serviceType,
  modalVariant,
  formVariant
}) => {
  const componentStylesCls = useComponentStyles(nameInLayout, variant)
  const modalProps = useModal()

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root)}>
      {/* eslint-disable-next-line */}
      <Link onClick={modalProps.onOpenModal} mode={serviceType} />

      <VehicleServiceRequestModalForm
        dealerId={dealerId}
        vehicleId={vehicleId}
        serviceType={serviceType}
        modalVariant={modalVariant}
        formVariant={formVariant}
        {...modalProps}
      />
    </div>
  )
}

export default RequestHelpOrDrive
