import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import pDebounce from 'p-debounce'

import isMultirooftop from 'website/utils/isMultirooftop'
import type { VehicleCardProps, VehicleInfiniteScrollProps } from 'website/components/types'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import apiProvider from 'website/api/apiProvider'
import deepEqual from 'website/utils/deepEqual'
import mergeVehicleCards from 'website/utils/mergeVehicleCards'
import {
  useMonthlyPayments,
  type UseMonthlyPaymentsReturn
} from 'website/hooks/useMonthlyPayments'

export const PAGE_SIZE = 36
const API_CALL_DELAY = 1000

const _isMultirooftop = isMultirooftop()

type UseVehicleInfiniteScrollProps = VehicleInfiniteScrollProps

interface UseVehicleInfiniteScrollReturn extends UseMonthlyPaymentsReturn {
  fetchVehicles: (skip?: number, isRefresh?: boolean) => Promise<void>
  vehicles: VehicleCardProps[]
  hasMoreVehicles: boolean
  isLoading: boolean
  additionalClassName: string
}

/**
 * applied filters might change fast, so we need to debounce API calls
 * and apply only the latest response.
 */
const loadVehiclesDebounced = pDebounce(
  async (payload: API.SearchVehiclesRequest): Promise<API.SearchVehiclesResponse> => {
    return await apiProvider.vehicles.getList(payload)
  }, API_CALL_DELAY)

const useFiltersState = SharedStateHook<Store.VehiclesFilterState>(StoreBranches.VehiclesFilter)
const useSortingState = SharedStateHook<Store.VehiclesSortingState>(StoreBranches.VehiclesSorting)
const usePrequalifyState = SharedStateHook<Store.PrequalifyState>(StoreBranches.Prequalify)
const useZipCodeState = SharedStateHook<Store.ZipCodeLocationState>(StoreBranches.ZipCodeLocation)
const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)
const useBotEnabled = SharedStateHook<Store.BotEnabled>(StoreBranches.BotEnabled)

export const useVehicleInfiniteScroll = (
  props: UseVehicleInfiniteScrollProps
): UseVehicleInfiniteScrollReturn => {
  const { states, componentProps } = props
  const [cards, setCards] = useState<VehicleCardProps[]>([])
  const [filters] = useFiltersState(states.filters)
  const [sorting] = useSortingState(states.sorting)
  const [prequalify] = usePrequalifyState(defaultInstance(StoreBranches.Prequalify))
  const [zipCodeLocation] = useZipCodeState(defaultInstance(StoreBranches.ZipCodeLocation))
  const [dealersInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const [isRefreshingMonthlyPayment, setIsRefreshingMonthlyPayment] = useState(false)
  const [{ isEnabled: isChatEnabled }] = useBotEnabled(defaultInstance(StoreBranches.BotEnabled))

  const { appliedFilters, isFilteringEnabled } = filters
  const { appliedSorting } = sorting

  const [isLoading, setLoading] = useState<boolean>(true)
  const [hasMoreVehicles, setHasMoreVehicles] = useState<boolean>(true)

  /**
   * we intentionally set isFilteringEnabled to false, because this way
   * component mount is also covered (transition from unset state to a set one)
   */
  const refIsFilteringEnabled = useRef(false)
  const refPrevAppliedFilters = useRef(appliedFilters)
  const refPrevAppliedSorting = useRef(appliedSorting)
  const refPrevZipCode = useRef(zipCodeLocation.zipCode)

  const additionalClassName = useMemo((): string => {
    if (isChatEnabled != null) {
      return 'with-additional-indentation'
    }

    return ''
  }, [isChatEnabled])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const loadVehicles = useCallback(async (payload: API.SearchVehiclesRequest, isRefresh: boolean = false): Promise<void> => {
    const payloadWithTrimText = { ...payload, text: payload?.text?.trim() }

    if (_isMultirooftop && zipCodeLocation.latitude != null && zipCodeLocation.longitude != null) {
      payloadWithTrimText.userLocationLatitude = zipCodeLocation.latitude
      payloadWithTrimText.userLocationLongitude = zipCodeLocation.longitude
    }

    try {
      const { items: newVehicles } = await loadVehiclesDebounced(payloadWithTrimText)

      if (newVehicles.length < PAGE_SIZE) {
        setHasMoreVehicles(false)
      }

      const newCards = newVehicles
        .map(item => ({
          ...componentProps.VehicleCard,
          prequalify,
          vehicle: item,
          id: item.id
        }))

      setCards(cards => {
        if (isRefresh) {
          return newCards
        }

        if (newCards.length < PAGE_SIZE) {
          setHasMoreVehicles(false)
        }

        return mergeVehicleCards(cards, newCards)
      })
    } finally {
      setIsRefreshingMonthlyPayment(false)
    }
  }, [
    componentProps.VehicleCard,
    dealersInfo.dealerships,
    prequalify,
    zipCodeLocation.latitude,
    zipCodeLocation.longitude
  ])

  /**
   * @param skip - how many items to skip
   * @param isRefresh - if true, then we will replace current cards with new ones
   */
  const fetchVehicles = useCallback(async (skip?: number, isRefresh: boolean = false): Promise<void> => {
    setLoading(true)

    // we need to reset hasMoreVehicles if skip is empty - it means we have fresh fetch
    if (!hasMoreVehicles && (skip == null || skip === 0)) {
      setHasMoreVehicles(true)
    }

    try {
      await loadVehicles({
        ...appliedFilters,
        ...appliedSorting,
        take: PAGE_SIZE,
        skip: skip ?? cards.length
      }, isRefresh)
    } catch {
      console.error('Failed to load vehicles')
    } finally {
      setLoading(false)
    }
  }, [
    appliedSorting,
    hasMoreVehicles,
    loadVehicles,
    cards.length,
    appliedFilters
  ])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (dealersInfo.dealerships.length === 0) {
      return
    }

    /**
     * we need to fetch vehicles when:
     * - filtering was just enabled disregarding other changes
     * (it happens after initial qs parse or after filters drawer is closed)
     * - based by changes but only if filtering is enabled
     */
    const isFilteringLockJustReleased = !refIsFilteringEnabled.current && isFilteringEnabled
    const shouldLoadVehicles = isFilteringEnabled && (isFilteringLockJustReleased ||
      (
        zipCodeLocation.zipCode !== refPrevZipCode.current ||
        !deepEqual(refPrevAppliedFilters.current, appliedFilters) ||
        !deepEqual(refPrevAppliedSorting.current, appliedSorting)
      ))

    /**
       * As we always fetch new filters from back when current filters are changed,
       * we might get identical filters, but with different reference.
       * In this scenario we do not want to fetch vehicles again.
       */
    if (shouldLoadVehicles) {
      setCards([])
      void fetchVehicles(0, true)
    }

    refIsFilteringEnabled.current = isFilteringEnabled
    refPrevAppliedFilters.current = appliedFilters
    refPrevAppliedSorting.current = appliedSorting
    refPrevZipCode.current = zipCodeLocation.zipCode
  }, [
    appliedFilters, appliedSorting, isFilteringEnabled,
    zipCodeLocation.zipCode, dealersInfo.dealerships, fetchVehicles
  ])

  const monthlyPaymentProps = useMonthlyPayments({
    vehicles: cards,
    isRefreshing: isRefreshingMonthlyPayment
  })

  // ========================================== //

  return {
    fetchVehicles,
    vehicles: cards,
    hasMoreVehicles,
    isLoading,
    additionalClassName,
    ...monthlyPaymentProps
  }
}

export default useVehicleInfiniteScroll
