import type { FC, ReactNode } from 'react'
import { FormDropdown } from '@carfluent/common'

import CarDetailsFields from 'website/components/CarDetailsFields'

import { getYearsForDropdownField } from 'website/utils/common'
import type { DictionaryItem } from 'website/api/types'

import { IDS } from './hook/constants'
import { useMakeModelYear } from './hook'

interface FormValues {
  year: DictionaryItem | null
  make: DictionaryItem | null
  model: DictionaryItem | null
}

const yearOptions = getYearsForDropdownField()

interface MakeModelFormProps {
  onSubmit: (values: FormValues) => void
  renderSubmitButton: (cb: FormSubmit, isValid: boolean) => ReactNode
}

const MakeModelYearForm: FC<MakeModelFormProps> = ({
  renderSubmitButton,
  onSubmit: _onSubmit
}) => {
  const {
    values: { year, ...detailsValues },
    onChangeYear,
    onChange,
    onBlur,
    onSubmit,
    isValid
  } = useMakeModelYear({ onSubmit: _onSubmit })
  return (
    <>
      <FormDropdown
        id='year'
        label='Year'
        type='tel'
        onBlur={onBlur}
        onChange={onChangeYear}
        value={year}
        options={yearOptions}
        className='year-dropdown'
      />

      <CarDetailsFields
        ids={IDS}
        onChange={onChange}
        onBlur={onBlur}
        values={detailsValues}
        isTrimHidden
      />

      {renderSubmitButton(onSubmit, isValid)}
    </>
  )
}

export default MakeModelYearForm
