import { type FC } from 'react'
import { IconProps } from 'website/components/types'

const SearchIcon: FC<IconProps> = ({
  className,
  fill = 'rgba(16, 16, 16, 0.3)',
  onClick
}) => {
  return (
    <svg onClick={onClick} className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='11' cy='11' r='5.5' stroke={fill} />
      <path d='M14.916 14.918L18.9993 19.0013' stroke={fill} />
    </svg>
  )
}

export default SearchIcon
