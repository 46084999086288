import { css } from '@emotion/css'

export const createStyleClass = (): string => {
  return css(`
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 600px;
      
      & .cf-no-vehicles-content {
        max-width: 200px;
        text-align: center;

        & img {
          width: 161px;
          margin-bottom: 24px;
        }
      }
    }
  `)
}
