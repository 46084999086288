import type { WorkflowStep } from 'api/types'

/**
 * we need to display steps on sidebar
 * this only for page 'deal/YourDetails' - that is behind the Auth, Wizard
 */
export const YourDetailsStepsArray: WorkflowStep[] = [
  {
    workflowStepStateId: 3,
    workflowStepState: 'Pending',
    templateStepId: 0,
    workTemplateStep: 'PersonalDetails',
    order: 2
  },
  {
    workflowStepStateId: 3,
    workflowStepState: 'Completed',
    templateStepId: 0,
    workTemplateStep: 'Initial',
    order: 1
  },
  {
    workflowStepStateId: 1,
    workflowStepState: 'Pending',
    templateStepId: 0,
    workTemplateStep: 'TradeIn',
    order: 3
  },
  {
    workflowStepStateId: 1,
    workflowStepState: 'Pending',
    templateStepId: 0,
    workTemplateStep: 'Financing',
    order: 4
  },
  {
    workflowStepStateId: 1,
    workflowStepState: 'Pending',
    templateStepId: 0,
    workTemplateStep: 'ServiceAndProtection',
    order: 5
  },
  {
    workflowStepStateId: 6,
    workflowStepState: 'Inactive',
    templateStepId: 0,
    workTemplateStep: 'CreditApplication',
    order: 6
  },
  {
    workflowStepStateId: 1,
    workflowStepState: 'Pending',
    templateStepId: 0,
    workTemplateStep: 'DealerCheck',
    order: 7
  },
  {
    workflowStepStateId: 1,
    workflowStepState: 'Pending',
    templateStepId: 0,
    workTemplateStep: 'DealerCheckCompleted',
    order: 8
  },
  {
    workflowStepStateId: 1,
    workflowStepState: 'Pending',
    templateStepId: 0,
    workTemplateStep: 'Documents',
    order: 9
  },
  {
    workflowStepStateId: 1,
    workflowStepState: 'Pending',
    templateStepId: 0,
    workTemplateStep: 'SignOnline',
    order: 10
  },
  {
    workflowStepStateId: 1,
    workflowStepState: 'Pending',
    templateStepId: 0,
    workTemplateStep: 'PickupAndDelivery',
    order: 11
  },
  {
    workflowStepStateId: 1,
    workflowStepState: 'Pending',
    templateStepId: 0,
    workTemplateStep: 'Payments',
    order: 12
  },
  {
    workflowStepStateId: 6,
    workflowStepState: 'Inactive',
    templateStepId: 0,
    workTemplateStep: 'PaymentSummary',
    order: 13
  }
]
