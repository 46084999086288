import type { KeyboardEvent } from 'react'
import { numberRegex } from '../utils/regexp'

export const GOOGLE_MAP_SCRIPT_BASE_URL = 'https://maps.googleapis.com/maps/api/js'
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE ?? ''

export enum Responsive {
  Mobile = 576,
  WideMobileScreen= 767,
  TabletGallery = 1024,
  Tablet = 1200,
  Desktop = 1440
}

export enum Days {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum VehicleState {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted'
}

export const DEFAULT_TRADE_IN_VEHICLE_VALUE = {
  make: '',
  model: '',
  trim: '',
  year: null,
  vin: null,
  price: null
}

export const DEFAULT_TRADE_IN_DETAILS_FORM_VALUE = {
  mileage: null,
  conditions: null,
  vehicleCollision: null,
  haveAirbagsDeployed: null,
  totalRepairCosts: null,
  mechanicalIssues: null,
  paymentType: null,
  paymentAmount: null
}

export const DEFAULT_ZIP_CODE_LOCATION_VALUE = {
  ip: '',
  zipCode: '',
  city: '',
  state: '',
  country: ''
}

export const DEFAULT_PREQUALIFY_VALUE = {
  apr: 0,
  downPayment: null,
  term: null
}

export const DEFAULT_DEALERSHIP_ID = {
  dealershipId: null
}

export const DEFAULT_PREQUALIFIED_FORM_VALUES = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  birthDate: null,
  email: '',
  addressData: null
}

export const DEFAULT_SETTINGS = {
  apr: 0,
  term: 0,
  cashDownAmount: null,
  cashDownPercentage: null,
  isEstimatedMonthlyPaymentEnabled: false,

  homePageHeading1: '',
  homePageHeading2: '',
  vdpIcon1Heading1: '',
  vdpIcon1Heading2: '',
  vdpIcon2Heading1: '',
  vdpIcon2Heading2: '',
  vdpIcon3Heading1: '',
  vdpIcon3Heading2: ''
}

enum AllowedKeys {
  Backspace = 'Backspace',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Tab = 'Tab'
}

export const MUI_INPUT_PROPS = { disableUnderline: true }
export const NUMBER_INPUT_PROPS = {
  onKeyDown: (e: KeyboardEvent) => {
    const isPrevent = !numberRegex.test(e.key) &&
      (e.key !== AllowedKeys.ArrowLeft) &&
      (e.key !== AllowedKeys.ArrowRight) &&
      (e.key !== AllowedKeys.Backspace) &&
      (e.key !== AllowedKeys.Tab)

    if (isPrevent) {
      e.preventDefault()
    }
  }
}
