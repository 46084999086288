import { css } from '@emotion/css'

const PADDING = 16
const MAX_WIDTH = 375

export default css(`
  position: fixed;
  z-index: 1111;
  top: 0px;
  bottom: 0px;
  transition: right 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 100vw;
  height: 100vh;
  max-width: ${MAX_WIDTH}px;
  right: -100vw;

  & > div:first-child {
    z-index: 2;
    position: absolute;
    overflow-y: scroll;
    
    // very important to keep everything visible on mobiles disregarding their bottom bars
    padding-bottom: 180px;
  }

  // DD-NOTE: we need to animate button separately as we need to keep it fixed
  // because of how it behaves on mobile

  .cf-filters-drawer-footer-btn {
    position: fixed;
    bottom: 0px;
    right: -100vw;
    transition: right 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    z-index: 3;
    padding: 0px ${PADDING}px ${PADDING}px;
    width: min(375px, 100vw);
    box-sizing: border-box;
    background-color: #fff;

    button {
      width: 100%;
    }
  }

  &.is-open .cf-filters-drawer-footer-btn {
    right: 0px;
  }

  .cf-filters-drawer-overlay {
    position: absolute;
    z-index: -1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0,0,0,0.4);
    opacity: 0;
    pointer-events: auto;
  }

  &.is-open {
    right: 0px;

    .cf-filters-drawer-overlay {
      position: fixed;
      opacity: 1;
      z-index: 1;
      transition: opacity 150ms cubic-bezier(0.4, 0, 0.6, 1) 300ms;
    }
  }
`)
