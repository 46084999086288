const formatString = (value?: string | null, pattern?: string): string => {
  if (value == null || pattern == null) {
    return value ?? ''
  }

  let i = 0
  const v = value.toString()
  return pattern.replace(/#/g, _ => v[i++])
}

export default formatString
