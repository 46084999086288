import type { FormValidation } from '@carfluent/common'

import {
  phoneNumber,
  email,
  firstName,
  lastName,
  addressData,
  birthDate
} from 'website/utils/validation/presets'
import { type PrequalifiedFormData } from '.'

const validationRules: FormValidation<PrequalifiedFormData> = {
  firstName,
  lastName,
  phoneNumber,
  email,
  addressData,
  birthDate
}

export default validationRules
