import isMultirooftop from 'website/utils/isMultirooftop'
import DefaultApiProvider from './defaultApiProvider'
import HeadquartersApiProvider from './headquartersApiProvider'
import { type ApiProvider, type DealerInfoPayload } from './types'

const apiProvider: ApiProvider = (() => {
  const dealerId = process.env.REACT_APP_DEALERSHIP_ID ?? ''
  const headquartersId = process.env.REACT_APP_HEADQUARTERS_ID ?? ''

  /**
   * Pipeline can have both dealership and headquarters IDs.
   * dealership ID has a higher priority.
   */
  const _isMultirooftop = isMultirooftop()

  const id = _isMultirooftop ? headquartersId : dealerId

  if (id == null) {
    throw new Error('No dealership or headquarters ID provided')
  }

  const providerInstance = _isMultirooftop
    ? HeadquartersApiProvider
    : DefaultApiProvider

  return {
    dealer: {
      getInfo: async (payload?: DealerInfoPayload) => await providerInstance.getDealerInfo(id, payload),
      getSettings: async () => await providerInstance.getDealerSettings(id),
      getPrequalified: async (payload) => await providerInstance.getPrequalified(payload, id)
    },
    vehicles: {
      getRecommendedVehicles: async (vehicleId?: string) => {
        const res = await providerInstance.getRecommendedVehicles(id, vehicleId)
        return res?.items ?? []
      },
      calculateFilters: async (payload) => await providerInstance.calculateFilters(payload, id),
      getList: async (payload) => await providerInstance.getVehiclesList(payload, id),
      getWindowSticker: async (vin) => await providerInstance.getWindowSticker(vin, id)
    },
    crm: {
      createLead: async (payload) => await providerInstance.createLead(payload, id),
      sendLeadFeedback: async (feedbackId, payload, leadId) =>
        await DefaultApiProvider.sendLeadFeedback(feedbackId, payload, leadId),
      unsubscribeLead: async (unsubscribeParams) => await providerInstance.unsubscribeLead(unsubscribeParams),
      createBot: async (location: DealerInfoPayload) => await providerInstance.createBot({
        dealerId,
        headquartersId,
        ...location
      }),
      createBotMessage: async (thread: string, content: string) => await providerInstance.createBotMessage(thread, content),
      getBotSettings: async () => {
        const closestDealerId = _isMultirooftop ? localStorage.getItem('closestDealerId') : dealerId
        return await DefaultApiProvider.getBotSettings(closestDealerId ?? '')
      },
      getBotChatByThreadId: async (threadId: string) => await DefaultApiProvider.getBotChatByThreadId(threadId)
    },
    tradeIn: {
      getVehiclePrice: async (payload) => await providerInstance.getTradeInPrice(payload, id)
    },
    identity: {
      loginUser: async (payload) => await HeadquartersApiProvider.loginUser(payload, headquartersId),
      sendVerificationCode: async (payload) => await HeadquartersApiProvider.sendVerificationCode(payload, headquartersId),
      registerUser: async (payload) => await HeadquartersApiProvider.registerUser(payload, headquartersId),
      sendResetPasswordEmail: async (payload) => await HeadquartersApiProvider.sendResetPasswordEmail(payload, headquartersId),
      resetPassword: async (payload) => await HeadquartersApiProvider.resetPassword(payload, headquartersId),
      verifyRecaptcha: async (payload) => await DefaultApiProvider.verifyRecaptcha(payload)
    }
  }
})()

export default apiProvider
