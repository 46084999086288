import { type FC, useCallback, useEffect } from 'react'
import capitalize from 'lodash-es/capitalize'
import debounce from 'lodash-es/debounce'
import { Button, cnx } from '@carfluent/common'

import { SupportedComponents, FiltersPanelComponents, type VehiclesFilterProps } from 'website/components/types'
import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import {
  FILTER_LIST_SEPARATOR,
  removeQueryStringFilters,
  updateEntryInQs,
  updateListEntryInQs
} from 'website/utils/qsfilters'
import { DEALER_FILTER_KEY, FilterRenderType, GeneratedFilter, isGeneratedListFilter } from 'website/configs'
import isMultirooftop from 'website/utils/isMultirooftop'
import useMediaQueryByBreakpoints from 'website/hooks/useMediaQueryByBreakpoints'
import { getAppliedFiltersNum } from 'website/utils/apiFilters'

import SvgIcon from 'website/components/_base/SvgIcon'
import VehiclesSorting from 'website/components/VehiclesSorting'
import { useComponentStyles } from 'website/styles/useComponentStyles'

import Drawer from './components/Drawer'
import Filter from './components/Filter'
import ZipCodeInput from './components/ZipCodeInput'
import FilterSection from './components/FilterSection'
import { POPOVER_STYLES } from './styles'

const RANGE_UPDATE_DELAY = 300

const _isMultirooftop = isMultirooftop()

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const onSingleFilterValueChangeDebounced = debounce((
  id: string, value: string | null
): void => {
  updateEntryInQs(id, value)
}, RANGE_UPDATE_DELAY)

const onMultipleFilterValuesChange = (
  id: string, value: string | null, isRemoveFromList?: boolean
): void => {
  updateListEntryInQs(id, value, isRemoveFromList)
}

const useFiltersState = SharedStateHook<Store.VehiclesFilterState>(StoreBranches.VehiclesFilter)
const useFiltersDrawerState = SharedStateHook<Store.VisibilityState>(StoreBranches.FiltersDrawerVisibility)

const VehiclesFilter: FC<VehiclesFilterProps> = ({
  drawerBreakpoints,
  nameInLayout = SupportedComponents.VehiclesFilter,
  sections,
  states,
  title,
  variant
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponents.VehiclesFilter, variant)

  const [dealersInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))

  const [sharedFilters, setFiltersState] = useFiltersState(states?.filters ?? defaultInstance(StoreBranches.VehiclesFilter))
  const [{ isOpen }, setDrawerState] = useFiltersDrawerState(defaultInstance(StoreBranches.FiltersDrawerVisibility))

  const { isMatchingBreakpoints } = useMediaQueryByBreakpoints(drawerBreakpoints)

  const { allFilters, appliedFilters } = sharedFilters

  useEffect(() => {
    /**
     * when filters drawer is opened it is shown for tablet and mobile
     * and there is a need to fetch vehicles with all filters and sorting applied
     * only when drawer closes. So we have this lock mechanism for this.
     */
    setFiltersState((state) => {
      return {
        ...state,
        isFilteringEnabled: !isOpen
      }
    })
  }, [isOpen, setFiltersState])

  const onFiltersClose = useCallback(() => {
    setDrawerState({ isOpen: false })
  }, [setDrawerState])

  const onFiltersChange = useCallback((
    id: string, value: string | null, type: FilterRenderType, isRemoveFromList?: boolean
  ): void => {
    switch (type) {
      case 'range':
      case 'text':
        onSingleFilterValueChangeDebounced(id, value)
        break
      case 'singleDropdown':
      case 'multiDropdown':
        updateEntryInQs(id, value)

        if (id === 'make') {
          updateEntryInQs('model', null)
          updateEntryInQs('trim', null)
        }

        if (id === 'model') {
          updateEntryInQs('trim', null)
        }
        break
      default:
        onMultipleFilterValuesChange(id, value, isRemoveFromList)
    }
  }, [])

  const rootCls = cnx(componentStylesCls.root, nameInLayout, isOpen && 'is-open')
  const appliedFiltesNum = getAppliedFiltersNum(appliedFilters)

  const renderContent = (): JSX.Element => (
    <div className={componentStylesCls.content}>
      <div className='cf-filters-title-section'>
        <p>{title}</p>
        {
          appliedFiltesNum > 0 && (
            <Button
              variant='text'
              onClick={() => removeQueryStringFilters()}
            >
              Clear All ({appliedFiltesNum})
            </Button>
          )
        }
        {
          isMatchingBreakpoints === true && (
            <span className='close-icon' onClick={onFiltersClose}>
              <SvgIcon type='close' />
            </span>
          )
        }
      </div>
      {
        sections.map((section) => {
          const name = section?.componentName

          switch (name) {
            case FiltersPanelComponents.ZipCodeInput:
              return _isMultirooftop
                ? (
                  <FilterSection key={name} label={section?.label} className='zipCode'>
                    <ZipCodeInput syncWithStore />
                  </FilterSection>
                  )
                : null
            case FiltersPanelComponents.VehiclesSorting:
              return (
                <FilterSection key={name} label={section?.label} className='sorting'>
                  <VehiclesSorting {...section.props} states={states} popoverClassName={POPOVER_STYLES} />
                </FilterSection>
              )
            case FiltersPanelComponents.FilterPanel:
              return Array.from(allFilters.keys()).map((id) => {
                const filter = allFilters.get(id)

                if (filter == null || (filter.id === DEALER_FILTER_KEY && !_isMultirooftop)) {
                  return null
                }

                let renderOptionName

                if (filter.id === DEALER_FILTER_KEY && isGeneratedListFilter(filter)) {
                  renderOptionName = (id: string) => {
                    return dealersInfo.dealerships.find((dealer) => dealer.id === Number(id))?.dealerName ?? id
                  }
                }

                const label = filter.name ?? capitalize(id)
                const payload: GeneratedFilter = {
                  ...filter,
                  applied: appliedFilters[id]
                }

                return (
                  <FilterSection
                    key={id}
                    label={label}
                    isCollapsible={filter.isCollapsible}
                    className='filter'
                  >
                    <Filter
                      {...payload}
                      onChange={onFiltersChange}
                      renderOptionName={renderOptionName}
                      listSeparator={FILTER_LIST_SEPARATOR}
                      disabled={filter.isDisabled?.(appliedFilters) ?? false}
                    />
                  </FilterSection>
                )
              })
            default:
              return null
          }
        })
      }
    </div>
  )

  // to avoid jumping rendering we need to wait until we know
  // layout for sure
  if (isMatchingBreakpoints == null) {
    return null
  }

  if (isMatchingBreakpoints) {
    return (
      <Drawer isOpen={isOpen} className={rootCls} onClose={onFiltersClose}>
        {renderContent()}
      </Drawer>
    )
  } else {
    return (
      <div className={rootCls}>
        {renderContent()}
      </div>
    )
  }
}

export default VehiclesFilter
