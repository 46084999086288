import type { JsonTemplate, JsonTemplateData } from 'website/siteGenerator/types'

const stringifyTemplate = <TNames extends string>(data: JsonTemplate<TNames>): JsonTemplateData<TNames> => {
  return {
    ...data,
    template: JSON.stringify(data.template)
  }
}

export default stringifyTemplate
