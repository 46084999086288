import LS from 'services/storage.service/storage'

const ZIP_CODE_LOCATION = 'ZIP_CODE_LOCATION'

export const setZipCodeLocationInLS = (location: Store.ZipCodeLocationState): void => {
  LS.set(ZIP_CODE_LOCATION, location)
}

export const getZipCodeLocationFromLS = (): Store.ZipCodeLocationState | null => {
  return LS.get(ZIP_CODE_LOCATION)
}
