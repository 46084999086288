const patternNumber = '(###) ###-####'

export function formatPhoneNumber (value: string, pattern = patternNumber): string {
  const sequence = value?.toString() ?? ''
  let i = 0

  if (sequence.length === 0) {
    return ''
  }

  return pattern.replace(/#/g, () => sequence[i++] ?? '')
}

export const upperCaseLetters = /[A-Z]/g
export const lowerCaseLetters = /[a-z]/g
