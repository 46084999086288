import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'

import { WorkflowApiProvider } from 'api/workflow.api'
import { Summary } from 'api/types/summary.types'
import { DEFAULT_SUMMARY } from './mock'

class SummaryStore {
  summaryDetails: Summary = DEFAULT_SUMMARY

  get downPayment (): number {
    return this.summaryDetails?.downPayment ?? 0
  }

  get deposit (): number {
    return this.summaryDetails?.deposit ?? 0
  }

  /**
   * Formula from BE:
   * Math.Max(0, ((DownPayment ?? TotalPrice) - (Deposit ?? 0)).Value)
   */
  get totalAmount (): number {
    return parseFloat(this.summaryDetails?.totalAmount?.toString() ?? '0')
  }

  get totalPrice (): number {
    return this.summaryDetails?.totalPrice ?? 0
  }

  get maxCardPaymentAmount (): number {
    return this.summaryDetails?.maxCardPaymentAmount ?? 0
  }

  get totalSumToPay (): number {
    // maybe should return
    // return this.isFinancedEnabled ? this.downPayment : this.totalAmount
    return this.totalAmount
  }

  get totalPaymentPrice (): number {
    return this.totalAmount > 0 ? this.totalAmount : this.totalPrice
  }

  getFreshSummary = async (): Promise<Summary> => {
    this.summaryDetails = await WorkflowApiProvider.summaryDetails()
    return this.summaryDetails
  }

  getFreshDealSummary = async (id: string | number): Promise<Summary> => {
    this.summaryDetails = await WorkflowApiProvider.summaryDealDetails(id)
    return this.summaryDetails
  }

  get isFinancedEnabled (): boolean {
    return this.summaryDetails != null && Number(this.summaryDetails.amountFinanced) > 0
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const SummaryInstance = new SummaryStore()
const SummaryCTX = createContext(SummaryInstance)

export default SummaryCTX
