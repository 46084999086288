import { FC, useEffect, useLayoutEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Routes } from 'routing/constants'

const hashMap: Record<string, string> = {
  '#contacts': '#contacts',
  '#directions': '#contacts'
}

const RouteChangeTracker: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useLayoutEffect(() => {
    const hash = location.hash ?? ''

    if (hash !== '') {
      const el = document.getElementById(hashMap[hash])

      if (el != null) {
        window.scrollTo(el.offsetLeft, el.offsetTop)
      }
    } else {
      window.scrollTo(0, 0)
    }
  }, [location])

  useEffect(() => {
    if ((location.pathname === Routes.YourDetails) && (location.state == null)) {
      navigate('/', { replace: true })
      window.location.hash = '#login'
    }
  }, [location.pathname, location.state, navigate])

  return null
}

export default RouteChangeTracker
