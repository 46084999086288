import type { FC } from 'react'
import { useCallback } from 'react'

import RadioLabelControl from 'website/components/RadioLabelControl'
import Text from 'website/components/Text'

export enum ContactYouValues {
  Email = 'email',
  Phone = 'phone'
}

interface ContactYouProps {
  id: string
  value: string
  onChange: (id: string, value: string) => void
}

const ContactYou: FC<ContactYouProps> = ({
  id,
  value,
  onChange: _onChange
}) => {
  const onChange = useCallback((val: string) => () => {
    _onChange(id, val)
  }, [_onChange])

  return (
    <div>
      <Text
        className='cf-contact-you-title'
        value='What is the best way to contact you?'
      />

      <RadioLabelControl
        label="Phone"
        checked={ContactYouValues.Phone === value}
        onChange={onChange(ContactYouValues.Phone)}
      />

      <RadioLabelControl
        label="Email"
        checked={ContactYouValues.Email === value}
        onChange={onChange(ContactYouValues.Email)}
      />
    </div>

  )
}

export default ContactYou
