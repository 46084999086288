const buildFormDataString = (formData: Record<string, string>): string => {
  return Object.entries(formData)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
}

export const buildLoginData = (grantType: string, formData: Record<string, string>): string => {
  const formDataString = buildFormDataString(formData)
  return `grant_type=${grantType}&client_id=spa_ropf&client_secret=carFluentSPA&scope=api offline_access&${formDataString}`
}

/**
 * For more details check
 * https://identityserver4.readthedocs.io/en/latest/topics/refresh_tokens.html#requesting-an-access-token-using-a-refresh-token
 */
export function bodyRefresh (refreshToken: string): string {
  return `grant_type=refresh_token&client_id=spa_ropf&client_secret=carFluentSPA&refresh_token=${refreshToken}`
}
