import { css } from '@emotion/css'

import { Responsive } from 'website/constants'

export default css(`
  max-width: 600px;
  margin: 0 auto;
  
  .cf-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .cf-condition-title {
      display: flex;
      align-items: center;
    
      p {
        font-family: Roboto, sans-serif;
        font-size: 20px;
        font-weight: 500;
        
        @media screen and (max-width: 400px) {
          padding-right: 30px;
        }
      }
    }
    
    .cf-black-alert-icon {
      display: inline-block;
      margin-bottom: -3px;
      width: 20px;
      cursor: pointer;
      margin-left: 12px;
      
      @media screen and (min-width: 769px) {
        display: none;
      }
    }
    
    .cf-error-wrapper {
      display: flex;
      align-items: center;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      color: #B00020;
      line-height: 16px;
      
      img {
        margin-right: 8px;
      }
    }
  }
  
  .cf-condition-button-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 32px; 
    
    .cf-button {
      width: calc(50% - 6px);
      margin-bottom: 12px;
      min-width: auto;
      
      &.cf-even {
        :nth-last-child(-n + 2) {
          margin-bottom: 0;
        }
      }
      
      &.cf-odd {
        :last-child {
          margin-bottom: 0;
        }
      }
      
      
      .cf-button-content {
        display: flex;
        align-items: center;
        
        img {
          margin-left: 4px;
          display: none;
          
          @media screen and (min-width: 769px) {
            display: block;
          }
        }
      }
    }
  }
`)

export const MODAL_CLASS_NAME = css(`
  .cf-condition-block {
    margin-bottom: 32px;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      font-family: Roboto, sans-serif;
    }
      
    .cf-name {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-family: Roboto, sans-serif;
  }
    
  .cf-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  
  @media screen and (max-width: ${Responsive.Mobile}px) {
    .cf-condition-block {
      margin-bottom: 16px;
    }
    
    .cf-modal-content-scroll-wrapper {
      padding: 0 !important;

      .cf-modal-title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.24);
        margin-bottom: 24px;
      
        h3 {
          font-weight: 500;
        }
      }
    }
  }
`)
