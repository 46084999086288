import type { FC, ReactNode } from 'react'
import { cnx, FormDropdown, FormNumberInput } from '@carfluent/common'

import Condition from 'website/components/Condition'
import { YesNoIds } from 'website/components/TradeInDetailsForm/hook/parser'

import { useTradeIn } from './hook'
import type { UseTradeInProps } from './hook'
import { FormIds } from './hook/constant'
import CLASS_NAME from './styles'

interface TradeInDetailsFormProps extends UseTradeInProps {
  className?: string
  renderActionBar: (onSubmit: FormSubmit, isValid?: boolean) => ReactNode
}

const TradeInDetailsForm: FC<TradeInDetailsFormProps> = ({ className, renderActionBar, ...props }) => {
  const {
    paymentTypes,
    mechanicalIssues,
    conditions,
    vehicleCollision,
    vehicleAirbags,
    onChange,
    onSubmit,
    onBlur,
    values,
    errors,
    touched,
    trimOptions,
    isValid
  } = useTradeIn(props)

  return (
    <div className={cnx(className, CLASS_NAME)}>
      <div className='cf-form-wrapper'>
        <div className='cf-input-wrapper'>
          <h2 className='cf-input-title'>What is your trim?</h2>

          <FormDropdown
            id={FormIds.Trim}
            label='Trim'
            value={values[FormIds.Trim]}
            error={errors[FormIds.Trim] ?? undefined}
            touched={touched[FormIds.Trim]}
            className='cf-form-input'
            onChange={onChange}
            onBlur={onBlur}
            options={trimOptions}
          />
        </div>

        <div className='cf-input-wrapper'>
          <h2 className='cf-input-title'>How many miles is on your odometer?</h2>

          <FormNumberInput
            id={FormIds.Mileage}
            value={values[FormIds.Mileage]}
            error={errors[FormIds.Mileage]}
            touched={touched[FormIds.Mileage]}
            onBlur={onBlur}
            label='Mileage'
            className='cf-form-input'
            onChange={onChange}
          />
        </div>

        <div className='cf-divider' />

        <Condition
          id={FormIds.Conditions}
          value={values[FormIds.Conditions]}
          error={errors[FormIds.Conditions]}
          touched={touched[FormIds.Conditions]}
          onChange={onChange}
          title='What is the condition of your vehicle?'
          options={conditions}
        />

        <Condition
          id={FormIds.VehicleCollision}
          value={values[FormIds.VehicleCollision]}
          error={errors[FormIds.VehicleCollision]}
          touched={touched[FormIds.VehicleCollision]}
          onChange={onChange}
          title='Has the vehicle been in a collision?'
          options={vehicleCollision}
        />

        {values[FormIds.VehicleCollision]?.value === YesNoIds.Yes && (
          <>
            <Condition
              id={FormIds.VehicleAirbags}
              value={values[FormIds.VehicleAirbags]}
              error={errors[FormIds.VehicleAirbags]}
              touched={touched[FormIds.VehicleAirbags]}
              onChange={onChange}
              title={'Have the vehicle\'s airbags ever deployed?'}
              options={vehicleAirbags}
            />

            <div className='cf-input-wrapper'>
              <p className='cf-input-title cf-title-cost'>How much did repairs cost?</p>

              <FormNumberInput
                id={FormIds.RepairCost}
                value={values[FormIds.RepairCost]}
                error={errors[FormIds.RepairCost]}
                touched={touched[FormIds.RepairCost]}
                onChange={onChange}
                onBlur={onBlur}
                label='Total repair costs'
                className='cf-form-input'
                startAdornment='$'
              />

              <p className='g-helper-text'>
                Total sum of repair costs due to collisions for this specific vehicle.
              </p>
            </div>
          </>
        )}

        <Condition
          id={FormIds.MechanicalIssues}
          value={values[FormIds.MechanicalIssues]}
          error={errors[FormIds.MechanicalIssues]}
          touched={touched[FormIds.MechanicalIssues]}
          onChange={onChange}
          title='Are there any mechanical issues?'
          options={mechanicalIssues}
        />

        <div className='cf-divider' />

        <Condition
          id={FormIds.PaymentType}
          value={values[FormIds.PaymentType]}
          error={errors[FormIds.PaymentType]}
          touched={touched[FormIds.PaymentType]}
          subOptionsType='radio'
          onChange={onChange}
          title='Are you making payments on your vehicle?'
          options={paymentTypes}
        />

        {values[FormIds.PaymentType]?.value === YesNoIds.Yes && (
          <div className='cf-input-wrapper'>
            <p className='cf-input-subtitle'>Estimated amount still outstanding:</p>

            <FormNumberInput
              id={FormIds.Amount}
              value={values[FormIds.Amount]}
              error={errors[FormIds.Amount]}
              touched={touched[FormIds.Amount]}
              onChange={onChange}
              label='Amount'
              className='cf-form-input'
              startAdornment='$'
            />
          </div>
        )}

        {renderActionBar(onSubmit as FormSubmit, isValid)}
      </div>
    </div>
  )
}

export default TradeInDetailsForm
