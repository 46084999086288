import { useCallback } from 'react'
import { RecoilState, SetterOrUpdater, useRecoilState } from 'recoil'

import StateAtoms, { StoreBranches } from './atoms'

export { StoreBranches, StateAtoms } from './atoms'

/**
 * README:
 * store branches names must be in PascalCase.
 * Instance naming rules:
 * 1) split by dashes where it has Capital letters
 * 2) Store.<branchName> full name to lower case
 * 3) in the end after dash number of instace might be added
 * Examples:
 * TradeInVehicle -> trade-in-vehicle, trade-in-vehicle-1, ...
 * Dealership -> dealership, dealership-1, ...
 */
export function defaultInstance (branchName: StoreBranches): string {
  const res = branchName
    .replace(/([A-Z])/g, '-$1')
    .toLowerCase()

  return res.startsWith('-') ? res.slice(1) : res
}

export const useSharedState = <T extends Store.RootState>(
  ns: StoreBranches,
  instanceId: string,
  persistState?: (state: T) => void
): [T, SetterOrUpdater<T>] => {
  const stateAtom = StateAtoms[ns]
  const [state, _set] = useRecoilState<T>(stateAtom(instanceId) as any as RecoilState<T>)

  const set = useCallback((updater: T | ((val: T) => T)) => {
    _set((currVal: T) => {
      const newVal = typeof updater === 'function' ? updater(currVal) : updater
      persistState?.(newVal)
      return newVal
    })
  }, [_set, persistState])

  return [state, set]
}

export default <T extends Store.RootState>(ns: StoreBranches) =>
  (instanceId: string, persistState?: (state: T) => void) =>
    useSharedState<T>(ns, instanceId, persistState)
