import type { FC } from 'react'
import { IconProps } from 'website/components/types'

const StarIcon: FC<IconProps> = ({ fill = '#fff', stroke = 'rgba(0, 0, 0, 0.3)' }) => {
  return (
    <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_77463_270655)'>
        <path
          d='M20.5007 29.2859L30.8007 35.5026L28.0673 23.7859L37.1673 15.9026L25.184 14.8859L20.5007 3.83594L15.8173 14.8859L3.83398 15.9026L12.934 23.7859L10.2007 35.5026L20.5007 29.2859Z'
          fill={fill}
          stroke={stroke}
          strokeWidth='3'
        />
      </g>
      <defs>
        <clipPath id='clip0_77463_270655'>
          <rect width='40' height='40' fill='#fff' transform='translate(0.5 0.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StarIcon
