import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import WorkflowCTX from 'store/workflow'
import SummaryCTX from 'store/summary'
import AuthCTX from 'store/auth'
import { type Workflow, WorkflowStateName, WorkflowStepName } from 'api/types'
import {
  DOWN_PAYMENT_TITLE,
  EMPTY_CURRENCY_VALUE,
  MONTHLY_PAYMENT_TITLE,
  TOTAL_PRICE_CAR_INFO_TITLE,
  TOTAL_PRICE_TITLE
} from 'constants/constants'
import { isYourDetailsPage } from 'utils/urlHelpers'
import { formatCurrency, onlyNumbers } from 'utils/format_number'
import getVehicleInfo from './utils'

export interface VehicleInfo {
  mainImageUrl: string
  modelTrim: string
  vehicleStateYearMake: string
  odometer: string | number
}

interface UseCarInfoReturn {
  vehicleInfo: VehicleInfo
  toggleSummaryBillVisibility: () => void
  workflow: Workflow | null
  summaryBillVisible: boolean
  priceBlockData: { title: string, value: string }
}

interface LocationStateProps {
  modelTrim?: string
  salePrice?: string
  vehicleStateYearMake?: string
  mainImageUrl?: string
  odometer?: number | string
}

export const useCarInfo = (): UseCarInfoReturn => {
  const location = useLocation()
  const isIndependentYourDetailsPage = isYourDetailsPage(location.pathname)
  const { isAuthorized } = useContext(AuthCTX)

  const {
    modelTrim = '',
    salePrice = '0',
    vehicleStateYearMake = '',
    mainImageUrl = '',
    odometer = 0
  } = isIndependentYourDetailsPage ? location.state as LocationStateProps : {}

  const [summaryBillVisible, setSummaryBillVisible] = useState(false)
  const { workflow, getStepByName } = useContext(WorkflowCTX)
  const {
    getFreshDealSummary,
    summaryDetails: { monthlyPayment, totalPrice },
    isFinancedEnabled,
    totalSumToPay
  } = useContext(SummaryCTX)

  const isMonthlyPayment = monthlyPayment != null
  const isDealerCheckCompleted = getStepByName(WorkflowStepName.DealerCheck)?.workflowStepState === WorkflowStateName.Completed

  const vehicleInfo = getVehicleInfo(
    {
      modelTrim,
      vehicleStateYearMake,
      mainImageUrl,
      odometer
    },
    isIndependentYourDetailsPage ? null : workflow?.vehicle
  )

  let priceBlockData = {
    title: isMonthlyPayment ? MONTHLY_PAYMENT_TITLE : TOTAL_PRICE_CAR_INFO_TITLE,
    value: formatCurrency(
      Number(isMonthlyPayment
        ? monthlyPayment
        : isIndependentYourDetailsPage
          ? salePrice
          : totalPrice ?? 0),
      EMPTY_CURRENCY_VALUE
    )
  }

  if (isDealerCheckCompleted) {
    priceBlockData = {
      title: !isFinancedEnabled ? TOTAL_PRICE_TITLE : DOWN_PAYMENT_TITLE,
      value: formatCurrency(
        isIndependentYourDetailsPage
          ? typeof salePrice === 'string' ? onlyNumbers(salePrice) : salePrice
          : totalSumToPay ?? 0,
        EMPTY_CURRENCY_VALUE
      )
    }
  }

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const toggleSummaryBillVisibility = (): void => {
    setSummaryBillVisible(!summaryBillVisible)
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (!isAuthorized) {
      return
    }

    const activeStep = workflow?.workflowSteps.find(item => item.workflowStepState === 'Pending') ?? null
    const isDealerCheckActive = activeStep?.workTemplateStep === WorkflowStepName.DealerCheck

    if (workflow != null && activeStep != null && isDealerCheckActive) {
      void getFreshDealSummary(workflow.id)
    }
  }, [workflow, isAuthorized])

  // ========================================== //

  return {
    vehicleInfo,
    toggleSummaryBillVisibility,
    summaryBillVisible,
    workflow,
    priceBlockData
  }
}
