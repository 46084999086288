import type { FC } from 'react'
import { IconProps } from 'website/components/types'

const UncheckedIcon: FC<IconProps> = ({ stroke }) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1' y='1' width='14' height='14' rx='3' fill='white' stroke={stroke} strokeWidth='2' />
    </svg>
  )
}

export default UncheckedIcon
