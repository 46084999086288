import { type FC, useMemo } from 'react'
import { cnx } from '@carfluent/common'

import { type IncludedWithCarAdsProps, SupportedComponents, type CarAdTextFieldsProps, type IncludedWithCarAdsMapperProps } from 'website/components/types'

import Text from 'website/components/Text'
import ExtendedImage from 'website/components/ExtendedImage'

import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const IncludedWithCarAds: FC<IncludedWithCarAdsProps> = ({
  nameInLayout,
  template,
  layout,
  componentProps,
  className,
  variant,
  settings,
  carAdTextFieldsProps
}) => {
  const componentStylesCls = useComponentStyles(nameInLayout, variant)
  const templateCls = useLayoutStyles({ template, layout })

  const pageComponents = useMemo(() => {
    return (componentProps != null) ? Array.from(new Set(Object.keys(componentProps))) : null
  }, [componentProps])

  if (pageComponents == null) {
    return null
  }

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root, className)}>
      <div className={cnx(componentStylesCls.content, templateCls)}>
        {pageComponents.map((component) =>
          componentMapper({
            settings,
            component,
            key: component,
            carAdTextFieldsProps,
            propsMap: componentProps
          })
        )}
      </div>
    </div>
  )
}

export default IncludedWithCarAds

export const componentMapper = ({
  key,
  propsMap,
  component,
  settings = null,
  carAdTextFieldsProps
}: IncludedWithCarAdsMapperProps): React.ReactNode => {
  const props = {
    key,
    nameInLayout: key
  }

  if (component == null || propsMap?.[component] == null) {
    return <IncludedWithCarAds {...props} settings={settings} />
  }

  const componentNameMatch = /^([a-zA-Z]+)\d*$/.exec(component)

  if (componentNameMatch == null) {
    return null
  }

  const componentName = componentNameMatch[1]

  switch (componentName) {
    case SupportedComponents.ExtendedImage:
      return <ExtendedImage {...props} {...propsMap[component]} />
    case SupportedComponents.Text:
      return <Text {...props} {...propsMap[component]} value={carAdTextFieldsProps?.[key as keyof CarAdTextFieldsProps] ?? ''} />
    default:
      return <IncludedWithCarAds {...props} {...propsMap[component]} settings={settings} carAdTextFieldsProps={getCarAdTextFieldsProps(key, settings)} />
  }
}

const VDP_INDEXES: Record<string, number> = {
  Ad1: 1,
  Ad2: 2,
  Ad3: 3
}

const getCarAdTextFieldsProps = (key: string, settings: API.Settings | null): CarAdTextFieldsProps => {
  return {
    Text1: settings?.[`vdpIcon${VDP_INDEXES[key]}Heading1` as keyof API.Settings] as string,
    Text2: settings?.[`vdpIcon${VDP_INDEXES[key]}Heading2` as keyof API.Settings] as string
  }
}
