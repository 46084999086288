import { FC } from 'react'
import { formatCurrencyDecimal, formatCurrency } from '@carfluent/common'

import { type CoverageFeeSettingsDto, type DefaultAmountFeeSettingDto } from 'api/types'

import CollapsedSection from '../shared/collapsed_section'
import SectionRowItem from '../shared/section_row_item'

import { SummarySectionClasses } from '../types'

interface SettingProps extends DefaultAmountFeeSettingDto {
  amount: number
}

export interface FeesAndTaxesProps extends Omit<CoverageFeeSettingsDto, 'carDeliverySetting'> {
  buyerTagSetting: SettingProps
  dealerHandlingFeeSetting: SettingProps
  dealerInventoryTaxSetting: SettingProps
  emissionsSetting: SettingProps
  inspectionFeeSetting: SettingProps
  plateTransferFeeSetting: SettingProps
  registrationFeeSetting: SettingProps
  titleFeeSetting: SettingProps
}

interface EstimatedFeesAndTaxesSectionProps {
  classes?: SummarySectionClasses
  feesAndTaxes: FeesAndTaxesProps | null
  isAlwaysOpened?: boolean
  salesTaxAmount: number | null
  shouldUseSalesTax: boolean
}

const EstimatedFeesAndTaxesSection: FC<EstimatedFeesAndTaxesSectionProps> = ({
  classes,
  feesAndTaxes,
  isAlwaysOpened,
  salesTaxAmount: _salesTaxAmount,
  shouldUseSalesTax
}) => {
  const filteredFeesAndTaxes = feesAndTaxes != null
    ? Object.entries(feesAndTaxes).filter(([key, value]) => typeof value === 'object' && key !== 'carDeliverySetting')
    : []

  const feesAndTaxesAmount = (feesAndTaxes != null)
    ? filteredFeesAndTaxes.reduce((acc, [key, value]) => Number(acc) + Number(value.amount), 0)
    : 0

  const salesTaxAmount = shouldUseSalesTax ? _salesTaxAmount : 0

  const totalFeeAndTaxSum = feesAndTaxesAmount + Number(salesTaxAmount)

  return (
    <CollapsedSection
      label='Estimated fees and taxes'
      value={formatCurrency(totalFeeAndTaxSum)}
      classes={classes}
      isAlwaysOpened={isAlwaysOpened}
    >
      {shouldUseSalesTax && (
        <SectionRowItem
          label='Sales tax'
          value={formatCurrencyDecimal(salesTaxAmount ?? 0)}
          hasLeftSpace
          classes={classes}
        />
      )}

      {(feesAndTaxes != null) && filteredFeesAndTaxes.map(([key, value]) => (
        value?.isEnabled && (
          <SectionRowItem
            key={key}
            label={value.labelName ?? ''}
            value={formatCurrencyDecimal(value.amount)}
            hasLeftSpace
            classes={classes}
          />
        )
      ))}

    </CollapsedSection>
  )
}

export default EstimatedFeesAndTaxesSection
