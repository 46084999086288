import { type FC, useMemo, memo } from 'react'

import { getHtmlString } from 'website/utils/html'

import { createUseStyles } from './styles'

export interface MessageProps {
  id?: number | string
  isSending?: boolean
  isReceived?: boolean
  message: string
}

const Message: FC<MessageProps> = ({
  isReceived = false,
  message
}) => {
  const rootCls = useMemo(() => {
    return createUseStyles({ isReceived })
  }, [isReceived])

  const htmlData = getHtmlString(message)

  return (
    <div className={rootCls}>
      <div className='message-content'>
        <p className='message'>
          <span dangerouslySetInnerHTML={{ __html: htmlData }} />
        </p>
      </div>
    </div>
  )
}

export default memo(Message)
