const transformToRecord = <T extends object>(
  obj: T,
  keyFunc?: (key: string) => string,
  getVal?: (val: any) => string
): Record<string, string> => {
  return Object.entries(obj)
    .filter(([_, value]) => (getVal != null ? getVal(value) : value) != null)
    .reduce<Record<string, string>>(
    (acc, [key, value]) => {
      const nextKey = keyFunc != null ? keyFunc(key) : key
      acc[nextKey] = getVal != null ? getVal(value) : value
      return acc
    }, {})
}

export default transformToRecord
