import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isOk, type OnActionResultHandler } from '@carfluent/common'

import apiProvider from 'website/api/apiProvider'
import SharedStateHook, { StoreBranches } from 'website/store'
import { DEFAULT_TRADE_IN_VEHICLE_VALUE } from 'website/constants'
import Routes, { TradeInSubRoutes, WebsiteRoutes } from 'website/routing/constants'
import type { ErrTouchShortcuts, FormValues } from 'website/components/TradeInDetailsForm/hook/types'

import { serializeData } from './serializer'

const useTradeInState = SharedStateHook<Store.TradeInVehicleState>(StoreBranches.TradeInVehicle)
const useTradeInDetailsState = SharedStateHook<Store.TradeInDetailsVehicleState>(StoreBranches.TradeInDetailsVehicle)

export interface UseTradeInFormPage {
  states: {
    tradeInVehicle: string
    tradeInDetailsVehicle: string
  }
}

export interface UseTradeInFormPageReturn {
  onBack: () => void
  onActionResult: OnActionResultHandler<FormValues, ErrTouchShortcuts>
  baseValues: FormValues
  onUpdateTradeInVehicle: (values: FormValues) => void
  onSubmit: (values: FormValues) => Promise<void>
  isLoading: boolean
  tradeInVehicleDefault: Store.TradeInVehicleState
}

export const useTradeInFormPage = ({ states }: UseTradeInFormPage): UseTradeInFormPageReturn => {
  const navigate = useNavigate()
  const [tradeInVehicle, setTradeInVehicle] = useTradeInState(states.tradeInVehicle)
  const [
    { lastUpdateTs: _, ...tradeInDetailsVehicle },
    setTradeInDetailsVehicle
  ] = useTradeInDetailsState(states.tradeInDetailsVehicle)
  const refTradeInVehicle = useRef<Store.TradeInVehicleState>(tradeInVehicle)
  const refTradeInVehicleDefault = useRef<Store.TradeInVehicleState>(tradeInVehicle)

  const refBaseFormValues = useRef({
    ...tradeInDetailsVehicle,
    trim: null
  })

  const [isLoading, setLoading] = useState<boolean>(false)

  const onBack = useCallback(() => {
    setTradeInVehicle({
      lastUpdateTs: Date.now(),
      ...DEFAULT_TRADE_IN_VEHICLE_VALUE
    })

    navigate(WebsiteRoutes.TradeIn)
  }, [navigate])

  const onUpdateTradeInVehicle = useCallback(({ trim, ...otherProps }: FormValues) => {
    setTradeInDetailsVehicle({
      ...otherProps,
      lastUpdateTs: Date.now()
    })

    setTradeInVehicle(curr => ({
      ...curr,
      trim: trim?.name ?? ''
    }))
  }, [])

  const onSubmit = useCallback(async (values: FormValues) => {
    setLoading(true)
    const { trim: _, ...otherProps } = refTradeInVehicle.current
    const data = serializeData({
      ...otherProps,
      ...values
    })

    const price = await apiProvider.tradeIn.getVehiclePrice(data)

    setTradeInVehicle({
      ...refTradeInVehicle.current,
      lastUpdateTs: Date.now(),
      price
    })
  }, [navigate])

  const onActionResult = useCallback((res) => {
    setLoading(false)

    if (!isOk(res)) {
      setTradeInVehicle({
        ...tradeInVehicle,
        lastUpdateTs: Date.now(),
        price: null
      })
    }

    navigate(Routes.Trade(TradeInSubRoutes.UserInfo))
  }, [navigate, tradeInVehicle])

  useEffect(() => {
    refTradeInVehicle.current = tradeInVehicle
  }, [tradeInVehicle])

  return {
    isLoading,
    baseValues: refBaseFormValues.current,
    tradeInVehicleDefault: refTradeInVehicleDefault.current,
    onUpdateTradeInVehicle,
    onActionResult,
    onSubmit,
    onBack
  }
}
