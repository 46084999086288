import { type FC } from 'react'
import { formatInteger } from '@carfluent/common'
import { type FilterName } from 'website/configs'
import SvgIcon from 'website/components/_base/SvgIcon'
import { isApiRangeFilter, isApiListFilter } from 'website/utils/apiFilters'
import getName from 'website/utils/getName'
import { DEALER_FILTER_KEY } from "website/configs";

export interface FilterChipProps {
  filterName: FilterName
  filter: API.FilterType
  onDelete: (id: FilterName, name: string) => void
  getName?: (v: string) => string
}

const FilterChip: FC<FilterChipProps> = ({
  filterName,
  filter,
  onDelete,
  getName: _getName
}) => {
  let chips: Array<{id: string, filterName: FilterName, text: string }> = []
  
  if (isApiListFilter(filter)) {
    chips = filter.items.map((item) => ({
      id: item.name,
      filterName,
      text: _getName != null ? _getName(item.name) : getName(item.name)
    }))
  } else if (isApiRangeFilter(filter)) {
    chips = [
      {
        id: filterName,
        filterName,
        text: filterName === 'year'
          ? `${filter.from ?? 0} - ${filter.to ?? 0}`
          : `${formatInteger(filter.from ?? 0)} - ${formatInteger(filter.to ?? 0)}`
      }
    ]
  } else if (typeof filter === 'string' && filter !== '') {
    chips = [
      {
        id: filterName,
        filterName,
        text: filter
      }
    ]
  }

  return (
    <>
      {chips.map(({ id, filterName, text }) => (
        <span
          key={id}
          className='cf-chip'
          onClick={() => onDelete(filterName, filterName === DEALER_FILTER_KEY ? id : text)}
        >
          {text}
          <SvgIcon type='close' />
        </span>
      ))}
    </>
  )
}

export default FilterChip
