import { css } from '@emotion/css'

export default css`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #20C39E;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  transition: background-color .2s;
  box-shadow: 0 24px 38px 0 rgba(58, 71, 78, 0.10);
  
  :hover {
    background: rgba(32, 195, 158, 0.80);
  }
`

export const CHAT_CLASS_NAME = css`
  opacity: 0;
  z-index: -1;
  transition: opacity .3s linear, z-index .3s step-end;
  
  &.is-opened {
    opacity: 1;
    z-index: 1102;
    transition: z-index 0.3s step-start, opacity 0.3s linear;
    
    @media screen and (min-width: 1024px) {
      z-index: 100;
    }
  }
`
