import intersection from 'lodash-es/intersection'
import { FullAddressParts } from './types'
import { GOOGLE_MAP_SCRIPT_BASE_URL, GOOGLE_API_KEY } from './constants'
import { loadScript } from 'utils/loadScript'

export const ZIP_CODE_TYPE = 'postal_code'
export const CITY_TYPES = ['locality', 'city', 'postal_town', 'sublocality_level_1', 'sublocality']
export const STATE_TYPE = 'administrative_area_level_1'
export const COUNTY_TYPE = 'administrative_area_level_2'

export const emptyAddress = {
  addressLong: null,
  address: null,
  city: null,
  zipCode: null,
  state: null,
  county: null
}

export function getPostalCode (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => item.types.includes(ZIP_CODE_TYPE))

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export function getCity (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => intersection(item.types, CITY_TYPES).length > 0)

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export function getState (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => Boolean(item.types.includes(STATE_TYPE)) && (item.short_name.length === 2))

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export function getCounty (place?: google.maps.GeocoderResult | null): string | null {
  if (place == null) {
    return null
  }

  const addressPart = place.address_components
    ?.find((item) => item.types.includes(COUNTY_TYPE))

  return addressPart?.short_name ?? addressPart?.long_name ?? null
}

export const fullAddress = (addr?: FullAddressParts | null): string => {
  if (addr == null) {
    return ''
  }
  const { addressLong, address, city, state, zipCode } = addr
  const prefix = [addressLong ?? address, city, state, zipCode].filter(Boolean).join(', ')
  return prefix.length > 0 ? `${prefix}, USA` : ''
}

export const areAddressesEqual = (addr1?: FullAddressParts | null, addr2?: FullAddressParts | null): boolean => {
  return addr1?.address === addr2?.address &&
    addr1?.city === addr2?.city &&
    addr1?.zipCode === addr2?.zipCode &&
    addr1?.state === addr2?.state &&
    addr1?.county === addr2?.county
}

export const getAddressInfo = (value: google.maps.GeocoderResult, type: string, isShort: boolean = true): string => {
  const res = value.address_components.find(item => item.types[0] === type)

  if (res == null) {
    return ''
  }

  return isShort ? res?.short_name : res?.long_name
}

export const addressParts = (
  place: google.maps.GeocoderResult | null
): FullAddressParts | null => {
  if (place == null) {
    return null
  }

  const addressParts = place?.address_components?.slice(0, 2) ?? []
  const addressLong = addressParts.map(item => item.long_name).join(' ') ?? null
  const addressShort = addressParts.map(item => item.short_name).join(' ') ?? null

  try {
    return {
      addressLong,
      address: addressShort,
      city: getCity(place),
      state: getState(place),
      zipCode: getPostalCode(place),
      county: getCounty(place),
      streetNumber: getAddressInfo(place, 'street_number'),
      /**
       * built-in function toJSON or lat or lng are not working as expected
       */
      latLng: JSON.parse(JSON.stringify(place.geometry.location))
    }
  } catch (r) {
    return null
  }
}

export const googleMapScriptUrl = (language = 'en'): string => {
  const languageQueryParam = language !== '' ? `&language=${language}` : ''
  return `${GOOGLE_MAP_SCRIPT_BASE_URL}?key=${GOOGLE_API_KEY}&libraries=places,geometry${languageQueryParam}`
}

export const loadGoogleMapScript = async (language?: string): Promise<void> => {
  if (typeof google !== 'undefined' && google.maps != null) {
    return await Promise.resolve()
  }

  return await loadScript(
    GOOGLE_MAP_SCRIPT_BASE_URL,
    googleMapScriptUrl(language)
  )
}
