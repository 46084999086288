import { type FC, useState, useMemo, useEffect } from 'react'
import { Button, cnx } from '@carfluent/common'
import { isEqual } from 'lodash-es'

import checkIcon from 'website/assets/website-check.svg'
import type { FeatureOptionItem, FeatureOptionListProps } from 'website/components/types'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'

import CLASS_NAME, { ScreenWidth } from './styles'

const MAX_ITEMS_IN_PREVIEW_COL = 4
enum MaxPreviewColumns {
  Mobile = 1,
  Tablet = 2,
  Desktop = 3
}

export const FeatureOptionList: FC<FeatureOptionListProps> = ({
  template,
  layout,
  className,
  nameInLayout,
  options = []
}) => {
  const templateCls = useLayoutStyles({ template, layout })
  const [columnsAmount, setColumnsAmount] = useState(MaxPreviewColumns.Desktop)
  const [isOpen, setIsOpen] = useState(false)

  const previewItems = useMemo(() => {
    const _previewItems = options.slice(0, columnsAmount).reduce<FeatureOptionItem[]>((res, item) => [
      ...res,
      {
        ...item,
        options: item.options.slice(0, MAX_ITEMS_IN_PREVIEW_COL)
      }
    ], [])

    return isEqual(_previewItems, options)
      ? null
      : _previewItems
  }, [columnsAmount, options])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([{ contentRect: { width } }]) => {
      if (width <= ScreenWidth.Mobile) {
        setColumnsAmount(MaxPreviewColumns.Mobile)
      } else if (width <= ScreenWidth.Tablet) {
        setColumnsAmount(MaxPreviewColumns.Tablet)
      } else {
        setColumnsAmount(MaxPreviewColumns.Desktop)
      }
    })

    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.unobserve(document.body)
    }
  }, [options])

  const onClick = (): void => {
    if (previewItems == null) {
      return
    }

    setIsOpen(!isOpen)
  }

  const optionsForView = (previewItems != null && !isOpen)
    ? previewItems
    : options

  return (
    <div className={cnx(nameInLayout, className)}>
      <div className={cnx(CLASS_NAME, templateCls)}>
        <div className='g-options-content'>
          {optionsForView.map(({ name, options }) => (
            <div className='option-column' key={name}>
              <p className='subtitle'>
                {name}
              </p>

              {options.map((item) => {
                return (
                  <div key={item} className='g-option-item'>
                    <img src={checkIcon} alt='' />
                    <p>{item}</p>
                  </div>
                )
              })}
            </div>
          ))}
        </div>

        {previewItems != null && (
          <Button
            onClick={onClick}
            variant='text'
          >
            Show {isOpen ? 'less' : 'more'}
          </Button>
        )}
      </div>
    </div>
  )
}

export default FeatureOptionList
