import { type FC } from 'react'
import { UITools, FormInput } from '@carfluent/common'
import { type InputProps, SupportedComponents } from 'website/components/types'
import CLASS_NAME from './styles'

const { cnx } = UITools

const Input: FC<InputProps> = ({
  nameInLayout,
  className,
  type,
  states,
  variant,
  variantProps,
  id,
  value,
  ...props
}) => {
  return (
    <div className={cnx(nameInLayout ?? SupportedComponents.Input, CLASS_NAME, className)}>
      <FormInput
        id={id ?? ''}
        value={value ?? ''}
        {...props}
      />
    </div>
  )
}

export default Input
