const commonStyles = `
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 8px;
  padding: 0px 16px 40px;
  grid-template-columns: 1fr;

  .DealerInfoFragment {
    .phone-block {
      margin-bottom: 16px;
    }

    span {
      font-weight: 500;
    }
  }

  .Map {
    @media (max-width: 768px) {
      height: 410px;
      margin: 0px;
    }
  }
`

export default {
  DealershipLocationInfo: {
    default: {
      content: `
        ${commonStyles}
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Description Description'
          'DealerInfoFragment Map'
          'Schedule Map';
        column-gap: 32px;
        padding: 40px max(40px, calc((100vw - 1264px) / 2));

        .description-content {
          font-family: Roboto, sans-serif;
          grid-area: Description;
          background: rgba(255, 255, 255, 1);
          padding: 24px;
          border-radius: 12px;
          justify: space-between;
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          h3 {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 4px;
            letter-spacing: 0.15px;
            color: rgba(16, 16, 16, 1);
            font-family: Roboto, sans-serif;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            align-items: center;
            letter-spacing: 0.5px;
            color: rgba(33, 33, 33, 0.5);
          }

          > img {
            width: 110px;
            height: 110px;
            margin-left: 24px;
            margin-right: -24px;
          }
        }

        .dealerInfoContainer {
          padding: 16px;
          margin-bottom: 8px;
          border-radius: 12px;
          background: rgba(255, 255, 255, 1);

          .shop-cars-button {
            .MuiButtonBase-root {
              box-shadow: none;
              border-radius: 12px;
              background: var(--mainColor);
              color: #ffffff;
              padding: 20px;
              margin-top: 16px;

              .MuiButton-label {
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                text-align: center;
                letter-spacing: 1.25px;
                font-family: Roboto, sans-serif;
              }
            }
          }
        }

        .Schedule {
          gap: 8px;
          opacity: 0px;
          padding: 16px;
          border-radius: 12px;
          background: rgba(255, 255, 255, 1);

          > div:first-child {
            max-width: none;
          }

          .schedule-item {
            padding: 4px;
            border-radius: 8px;

            &:nth-child(odd) {
              background: rgba(250, 250, 250, 1);
            }

            .ExtendedTypography:last-of-type {
              flex: none;
              width: 163px;
              text-align: left;
            }
          }
        }

        .Map > div {
          border-radius: 12px;
        }

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
          grid-template-areas:
            'Description'
            'DealerInfoFragment'
            'Schedule'
            'Map';
        }

        @media (max-width: 460px) {
          padding: 40px 16px;

          .description-content img {
            display: none;
          }
        }
      `
    }
  }
}
