import { type FC } from 'react'
import pickBy from 'lodash-es/pickBy'
import identity from 'lodash-es/identity'
import { type RouteObject, useRoutes } from 'react-router-dom'

import DynamicComponent from 'website/components/DynamicComponent'
import Wizard from 'website/pages/Wizard'
import InventoryPage from 'website/components/InventoryPage'

import { type InventoryPageProps, type TemplateConfigs } from 'website/components/types'
import { type ConfigRouteObject } from 'website/types/website'

export interface AppWithRoutesProps {
  routeConfigs: RouteObject[]
}

const definedPagesMapper = (pageName: string, _props?: TemplateConfigs): React.ReactNode => {
  switch (pageName) {
    case 'Wizard':
      return <Wizard />
    case 'InventoryPage':
      return <InventoryPage {..._props as InventoryPageProps} />
    default: {
      return <></>
    }
  }
}

export const generateRoutes = (routeConfigs: ConfigRouteObject[]): RouteObject[] =>
  routeConfigs.reduce<RouteObject[]>((acc, curr) => {
    const {
      element,
      children,
      path,
      index,
      caseSensitive,
      config
    } = curr

    const key = config?.nameInLayout ?? element ?? path

    /**
     * each route should have at least nameInLayout in its config
     * so that routing would be considered different components.
     * As we generate everything in iteration basically it is
     * important to keep an eye on this.
     */

    const route = {
      path,
      index,
      caseSensitive,
      element: element != null
        ? definedPagesMapper(element, config)
        : <DynamicComponent
            {...config}
            key={key}
            nameInLayout={key}
          />,
      children: children != null
        ? generateRoutes(children)
        : undefined
    }

    /**
     * it is important to trim undefined | null to avoid any side effects
     * with passing undefined|null element or children
     * */

    acc.push(pickBy(route, identity))

    return acc
  }, [])

/**
 * separated it not to trigger useRoutes hook with empty
 * array to avoid possible routing setup side effects
 */
const Routing: FC<AppWithRoutesProps> =
  ({ routeConfigs }) => useRoutes(routeConfigs)

export default Routing
