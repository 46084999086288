import { css } from '@emotion/css'

export default css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 213px auto auto;
  grid-template-areas: 'gallery' 'briefInfo' 'mainContent';
  row-gap: 32px;
  margin-top: 32px;
  width: 100%;
  
  @media screen and (min-width: 600px) {
    grid-template-rows: minmax(426px, max-content) auto auto;
    width: calc(100% - 80px);
  }
  
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 24px 328px;
    grid-template-rows: minmax(360px, max-content) auto;
    grid-template-areas: 'gallery . briefInfo' 'mainContent mainContent mainContent';
  }


  @media screen and (min-width: 1440px) {
    grid-template-columns: 968px 64px 328px;
    grid-template-rows: 576px minmax(240px,auto);
    grid-template-areas: 'gallery . briefInfo' 'mainContent . .';
    width: auto;
  }

  .gallery-block {
    grid-area: gallery;
    
    @media screen and (max-width: 480px) {
      border-radius: 0;
    }
  }
  
  .brief-info-block {
    grid-area: briefInfo;
    padding: 0 16px;
    
    @media screen and (min-width: 600px) {
      padding: 0;
    }

    > div {
      margin-bottom: 16px;
    }
  }
  
  .main-content {
    grid-area: mainContent;
    padding: 0 16px;

    @media screen and (min-width: 600px) {
      padding: 0;
    }
    
    > div {
      margin-bottom: 32px;
    }
  }
  
  .space-between-wrapper {
    display: grid;
    column-gap: 16px;
    row-gap: 16px;

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  .poker-column-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
    column-gap: 16px;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .triple-column-wrapper {
    display: grid;
    column-gap: 16px;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .column-wrapper {
    display: grid;
    row-gap: 16px;

    &.hide-on-mobile {
      display: none;

      @media screen and (min-width: 768px) {
        display: grid;
      }
    }
  }
  
  .text-block {
    height: 16px;
    border-radius: 8px;
  }
  
  .info-block {
    height: 56px;
    border-radius: 8px;
  }
  
  .longest-block {
    width: 75%;
  }
  
  .long-block {
    width: 65%;
  }
  
  .medium-block {
    width: 50%;
  }
  
  .small-block {
    width: 40%;
  }
  
  .short-block {
    width: 30%;
  }
  
  .strict-block {
    width: 200px;
  }
  
  .short-strict-block {
    width: 103px;
  }
  
`
