import { css } from '@emotion/css'

export const ARROW_OFFSET = 16
export const ARROW_CLS = 'cf-list-scroll-horz-btn'

export default css(`
  &.cf-slider-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100vw;
    pointer-events: none;
    z-index: 2;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "left . right";
    align-items: center;

    .${ARROW_CLS} {
      background: #fff;
      box-shadow: 0px 0px 8px 5px #00000005;
      pointer-events: none;
      opacity: 0;

      padding: 0px;
      width: 44px;
      height: 44px;

      &.right {
        grid-area: right;
        margin-right: ${ARROW_OFFSET}px;
      }

      &.left {
        grid-area: left;
        margin-left: ${ARROW_OFFSET}px;

        svg {
          transform: rotate(180deg);
        }
      }

      &.visible {
        pointer-events: all;
        opacity: 1;
      }
    }
  }
`)
