import React, { type FC, Suspense, useCallback } from 'react'
import { isDealAppOutsideFlow, isOnAuthPage, isYourDetailsPage as checkYourDetailsPage } from 'utils/urlHelpers'
import Header from 'website/components/Header'
import { getHeaderConfigs } from 'website/configs'

const WizardApp = React.lazy(async () => {
  const module = await import('../_CarfluentApp/src')
  return { default: module.default }
})

const Wizard: FC = () => {
  const renderHeader = useCallback((pathname: string) => {
    const isAuthPage = isOnAuthPage(pathname)
    const isYourDetailsPage = checkYourDetailsPage(pathname)
    const isOutsideFlowPage = isDealAppOutsideFlow(pathname)

    const headerConfigsType = isAuthPage
      ? 'auth'
      : isOutsideFlowPage
        ? 'outsideFlow'
        : isYourDetailsPage
          ? 'yourDetailsPage'
          : 'flow'

    const props = {
      ...getHeaderConfigs(headerConfigsType),
      variant: 'dealFlow',
      variantProps: {
        isAuthPage,
        isOutsideFlowPage,
        isYourDetailsPage
      },
      useDealFlowNavigation: true
    }

    return <Header {...props} />
  }, [])

  return (
    <Suspense fallback={<div />}>
      <WizardApp renderHeader={renderHeader} />
    </Suspense>
  )
}

export default Wizard
