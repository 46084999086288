interface GetHeightProps {
  height: number
  minHeight: number
  maxHeight: number
  value: string
  lineHeight: number
  reducedLines: number | null
  additionalHeight?: number
}

export const getNumberHeight = (height: string): number => {
  const numberHeight = Number(height.split('px')[0])

  return isNaN(numberHeight) ? 0 : numberHeight
}

export const getHeight = ({
  height,
  minHeight,
  maxHeight,
  value,
  lineHeight,
  reducedLines,
  additionalHeight
}: GetHeightProps): number => {
  let result = minHeight
  const isValueExist = value != null && value !== ''

  if (additionalHeight != null) {
    result = result + additionalHeight
  }

  if (!isValueExist) {
    return result
  }

  if (height > result) {
    result = height
  }

  if (result > maxHeight) {
    result = maxHeight
  }

  if (reducedLines != null && result !== minHeight) {
    result = result - (reducedLines * lineHeight)
  }

  return result
}
