import { type FC, type KeyboardEvent, type ChangeEvent, useState, useEffect } from 'react'
import debounce from 'lodash-es/debounce'
import { cnx } from '@carfluent/common'

import { SupportedComponents, VehiclesSearchProps } from 'website/components/types'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import SharedStateHook, { StoreBranches } from 'website/store'

import ComponentRoot from 'website/components/_base/ComponentRoot'
import SvgIcon from 'website/components/_base/SvgIcon'
import Input from 'website/components/Input'
import { updateEntryInQs } from 'website/utils/qsfilters'

const SHARED_UPDATE_DELAY = 1500

const START_ADORNMENT = <SvgIcon type='search' />

const updateEntryInQsDebounced = debounce(updateEntryInQs, SHARED_UPDATE_DELAY)

const useFiltersState = SharedStateHook<Store.VehiclesFilterState>(StoreBranches.VehiclesFilter)

const VehiclesSearch: FC<VehiclesSearchProps> = ({
  layout,
  nameInLayout = SupportedComponents.VehiclesSearch,
  states,
  variant,
  template
}) => {
  const [filters] = useFiltersState(states.filters)
  const { appliedFilters: { text } } = filters

  const layoutStylesCls = useLayoutStyles({ template, layout })
  const componentStylesCls = useComponentStyles(SupportedComponents.VehiclesSearch, variant)
  const [inputText, setInputText] = useState<string | null>(text ?? null)

  const onKeyPress = (evt: KeyboardEvent<HTMLInputElement>): void => {
    if (evt.key === 'Enter') {
      const text = evt.currentTarget.value ?? ''
      setInputText(text)
      updateEntryInQsDebounced('text', text)
    }
  }

  useEffect(() => {
    setInputText(text ?? '')
  }, [text])

  return (
    <ComponentRoot
      nameInLayout={nameInLayout}
      classes={{ content: cnx(layoutStylesCls, componentStylesCls.content) }}
    >
      <Input
        placeholder='Search'
        className='g-input-search'
        value={inputText ?? ''}
        startAdornment={START_ADORNMENT}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setInputText(e.target.value)
          updateEntryInQsDebounced('text', e.target.value)
        }}
        onKeyUp={onKeyPress}
        maxLength={100}
      />
    </ComponentRoot>
  )
}

export default VehiclesSearch
