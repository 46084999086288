import type { FormValidation } from '@carfluent/common'

import { DictionaryItem } from 'website/api/types'
import { string } from 'website/utils/validation/presets'

export interface FormValues {
  year: DictionaryItem | null
  make: DictionaryItem | null
  model: DictionaryItem | null
}

const createValidationRules = (): FormValidation<FormValues> => {
  return {
    year: string,
    model: string,
    make: string
  }
}

export default createValidationRules()
