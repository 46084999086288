import { type VehicleCardProps } from 'website/components/types'

const mergeVehicleCards = (cards1: VehicleCardProps[], cards2: VehicleCardProps[]): VehicleCardProps[] => {
  if (cards2.length === 0) {
    return cards1
  }

  const res: VehicleCardProps[] = []
  const matched = new Set<number | string>()

  cards1.forEach(c => {
    const match = cards2.find(c2 => c2.id === c.id)
    if (match == null) {
      res.push(c)
    } else {
      matched.add(c.id)
      res.push({ ...c, ...match })
    }
  })

  cards2.forEach(c => {
    if (!matched.has(c.id)) {
      res.push(c)
    }
  })

  return res
}

export default mergeVehicleCards
