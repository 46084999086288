import { FC, ReactNode, useMemo } from 'react'
import GalleryItem, { ImageBackgroundType } from './GalleryItem'
import useGalleryItems, { UseGalleryItemsProps, ITEM_WIDTH } from './hook'
import createStyleClass from './styles'

import arrowRight from 'website/assets/arrow_right.svg'
import arrowLeft from 'website/assets/arrow_left.svg'
import { type GalleryMediaProps } from 'website/components/types'

/**
 * sets which dot is always active counting from the start of visible dots
 */
const ACTIVE_DOT_OFFSET = 2

/**
 * makes extra buffer for dots section to support infinite swiping animation
 */
const VISIBLE_DOTS = 5

type GalleryItemsProps = Omit<UseGalleryItemsProps, 'itemsLength'> & {
  media: GalleryMediaProps[]
  className?: string
  showArrows?: boolean
  styleSelected?: boolean
  isVideoControlsAreShown?: boolean
  imgBackgroundType?: ImageBackgroundType
}

const GalleryItems: FC<GalleryItemsProps> = (props) => {
  const {
    media,
    selected,
    isActive,
    className,
    itemWidth = ITEM_WIDTH,
    imgBackgroundType = 'cover',
    showDots = false,
    showArrows = false,
    styleSelected = true,
    isVideoControlsAreShown = false
  } = props
  const itemsLength = media.length

  const rootCls = useMemo(() => createStyleClass({ itemWidth, styleSelected }), [itemWidth, styleSelected])

  /**
   * 1 dot is always visible as we do not allow to pass itemsLength limit
   */
  const dots = useMemo(() => new Array(itemsLength + VISIBLE_DOTS - 1).fill(true), [itemsLength])

  const {
    elRef,
    dotsElRef,
    onClick,
    onMoveLeft,
    onMoveRight,
    bind
  } = useGalleryItems({ ...props, itemsLength })

  return (
    <div className={`${rootCls} ${className ?? ''}`}>
      {showArrows && (
        <>
          <button
            onClick={onMoveLeft}
            className='gallery-arrow left'
          >
            <img src={arrowLeft} alt='left' />
          </button>

          <button
            onClick={onMoveRight}
            className='gallery-arrow right'
          >
            <img src={arrowRight} alt='right' />
          </button>
        </>
      )}

      <div className='gallery-container' {...bind()}>
        {
          showDots && (
            <div className='gallery-dots-container'>
              <div className='gallery-dots-list' ref={dotsElRef}>
                {
                  dots.map((_, i): ReactNode => {
                    const isActive = (selected ?? 0) + ACTIVE_DOT_OFFSET === i

                    return (
                      <div
                        key={i}
                        className={`gallery-dot ${isActive ? 'active' : ''}`}
                      />
                    )
                  })
                }
              </div>
            </div>
          )
        }
        <div className='gallery-items-wrapper' ref={elRef}>
          {
            media.map((v, i) => (
              <GalleryItem
                key={i}
                media={v}
                index={i}
                isActive={isActive}
                isSelected={selected === i}
                imgBackgroundType={imgBackgroundType}
                isVideoControlsAreShown={isVideoControlsAreShown}
                onClick={onClick}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default GalleryItems
