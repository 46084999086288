import bannerCar from 'website/assets/about_us_car_nima_sarram.png'

const assets: Record<string, string> = {
  bannerCar,

  /** nested templates */
  /** -- they are replaced during parsing if proper order is fulfilled -- */

  aboutUsPromosForAboutUsPageTemplate: '',
  dealerInfoTemplate: ''
}

export default assets
