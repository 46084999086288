import { type FC, useCallback } from 'react'
import { cnx } from '@carfluent/common'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from 'website/components/Button'
import apiProvider from 'website/api/apiProvider'
import Routes from 'website/routing/constants'

import { type UnsubscribeButtonProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const UnsubscribeButton: FC<UnsubscribeButtonProps> = ({
  nameInLayout = SupportedComponents.UnsubscribeButton,
  variant,
  componentProps
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const componentStylesCls = useComponentStyles(SupportedComponents.UnsubscribeButton, variant)

  const onUnsubscribeClick = useCallback(async () => {
    await apiProvider.crm.unsubscribeLead(location.search)
    navigate(Routes.Unsubscribed())
  }, [location.search, navigate])

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root)}>
      <Button
        {...componentProps.Button}
        onClick={onUnsubscribeClick}
        isLoading={false}
      />
    </div>

  )
}

export default UnsubscribeButton
