import { css } from '@emotion/css'
import { Responsive } from 'constants/constants'

export default css(`
  &&.cf-modal-container {

    .cf-modal-content-scroll-wrapper { padding: 0; }

    .cf-modal-root {
      @media screen and (max-width: ${Responsive.MobileMaxWidth}px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .cf-modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 334px;
      max-width: 480px; 
      padding: 40px 20px 0 20px;

      .label-text, .label-text-big {
        font-family: Roboto, sans-serif;
        color: var(--titleMainColor);
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
      }

      .label-text-big {
        color: #000;
        text-align: center;
        margin-top: 24px;
        margin-bottom: 16px;
        font-size: 34px;
        line-height: 36px;
      }

      img {
        width: 186px;
        height: 170px;
      }

      @media screen and (min-width: ${Responsive.MobileMaxWidth}px) {
        margin: 40px 40px 0;
        padding: 0;
      }

      @media screen and (min-width: ${Responsive.WideMobileScreen}px) {
        min-width: 400px;
      }
    }

    .cf-modal-footer {
      margin-top: 24px;

      .cf-button-root { 
        width: 100%;
        margin: 0 40px;

        .cf-button-content {
          font-family: Roboto, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 1.25px;
          text-transform: uppercase;
        }
      }
    }
  }
`)
