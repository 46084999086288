import type { FormValidation } from '@carfluent/common'

import { amount, ssn } from 'website/utils/validation/presets'
import type { FormValuesIncomeSSN } from 'website/components/GetPrequalifiedForm/hook/useSubmitGetPrequalifiedFormAction'

const validationRules: FormValidation<FormValuesIncomeSSN> = {
  yearIncome: amount,
  lastDigits: (value) => ssn(value == null ? '' : value.toString())
}

export default validationRules
