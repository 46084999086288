import type { FC, FocusEvent } from 'react'
import { FormDropdown } from '@carfluent/common'

import { useCarDetailsFields } from './hook'
import type { UseCarDetailsProps, IdKeys } from './hook'
import {
  DEFAULT_CAR_DETAILS_IDS,
  Labels
} from './hook/constants'

interface MakeModelFormProps extends Omit<UseCarDetailsProps, 'ids' | 'isTrimHidden'> {
  ids?: {
    make: string
    model: string
    trim?: string
  }
  errors?: Record<IdKeys, string | undefined>
  touched?: Record<IdKeys, boolean | undefined>
  onBlur?: (evt: FocusEvent<HTMLDivElement>) => void
  isTrimHidden?: boolean
}

const CarDetailsFields: FC<MakeModelFormProps> = ({
  ids = DEFAULT_CAR_DETAILS_IDS,
  onChange,
  values,
  touched,
  errors,
  onBlur,
  setFieldTouched,
  isTrimHidden = false
}) => {
  const {
    makes,
    models,
    trims,
    onChangeMake,
    onChangeModel,
    onChangeTrim
  } = useCarDetailsFields({
    values,
    onChange,
    ids,
    setFieldTouched,
    isTrimHidden
  })

  return (
    <>
      <FormDropdown
        id={ids.make}
        label={Labels.Make}
        value={values.make}
        error={errors?.make}
        touched={touched?.make}
        disabled={makes.length === 0}
        className='make-dropdown'
        onBlur={onBlur}
        onChange={onChangeMake}
        options={makes}
      />

      <FormDropdown
        id={ids.model}
        label={Labels.Model}
        value={values.model}
        error={errors?.model}
        touched={touched?.model}
        disabled={models.length === 0}
        className='model-dropdown'
        onBlur={onBlur}
        onChange={onChangeModel}
        options={models}
      />

      {!isTrimHidden && (
        <FormDropdown
          id={ids?.trim ?? ''}
          label={Labels.Trim}
          value={values?.trim ?? null}
          error={errors?.trim}
          touched={touched?.trim}
          disabled={trims.length === 0}
          className='trim-dropdown'
          onBlur={onBlur}
          onChange={onChangeTrim}
          options={trims}
        />
      )}
    </>
  )
}

export default CarDetailsFields
