import type { FC } from 'react'
import { UITools } from '@carfluent/common'

import { SupportedComponents, TextWithFillerProps } from 'website/components/types'
import Text from 'website/components/Text'
import Filler from 'website/components/Filler'

import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import STYLE_CLASSES from './styles'

const { cn } = UITools

const TextWithFiller: FC<TextWithFillerProps> = ({
  nameInLayout = SupportedComponents.TextWithFiller,
  className,
  template,
  layout,
  label,
  config,
  value,
  fillerConfig
}) => {
  const templateCls = useLayoutStyles({ template: template, layout })

  return (
    <div className={cn(nameInLayout, className)}>
      <div className={templateCls}>
        <Text nameInLayout='' config={config} label={label} />

        <div className={STYLE_CLASSES}>
          {(fillerConfig?.color !== '' && fillerConfig?.color != null) && (
            <Filler nameInLayout='' {...fillerConfig} />
          )}
          <Text nameInLayout='' config={config} value={value} />
        </div>
      </div>
    </div>
  )
}

export default TextWithFiller
