import { type FC } from 'react'
import { SvgIconProps } from 'website/components/types'
import ArrowRightIcon from './ArrowRightIcon'
import ArrowDownIcon from './ArrowDownIcon'
import CloseIcon from './CloseIcon'
import MapIcon from './MapIcon'
import PhoneIcon from './PhoneIcon'
import UpDownArrowsIcon from './UpDownArrowsIcon'
import CheckedIcon from './CheckedIcon'
import FilterIcon from './FilterIcon'
import SearchIcon from './SearchIcon'
import LocationIcon from './LocationIcon'
import ArrowRightAlt from './ArrowRightAlt'

const SvgIcon: FC<SvgIconProps> = ({ type, ...iconProps }) => {
  switch (type) {
    case 'phone':
      return <PhoneIcon {...iconProps} />
    case 'location':
      return <LocationIcon {...iconProps} />
    case 'map':
      return <MapIcon {...iconProps} />
    case 'arrowRight':
      return <ArrowRightIcon {...iconProps} />
    case 'arrowRightAlt':
      return <ArrowRightAlt {...iconProps} />
    case 'arrowDown':
      return <ArrowDownIcon {...iconProps} />
    case 'upDownArrows':
      return <UpDownArrowsIcon {...iconProps} />
    case 'close':
      return <CloseIcon {...iconProps} />
    case 'checked':
      return <CheckedIcon {...iconProps} />
    case 'filters':
      return <FilterIcon {...iconProps} />
    case 'search':
      return <SearchIcon {...iconProps} />
    default:
      return null
  }
}

export default SvgIcon
