import {
  type FilterName,
  type AllFilters,
  type ActiveFilters,
  isGeneratedListFilter,
  isGeneratedRangeFilter,
  RANGE_FILTER_KEYS
} from 'website/configs'

export const isApiListFilter = (filter: API.FilterType | undefined): filter is { items: any[] } => {
  return (filter as any)?.items != null
}

export const isApiRangeFilter = (filter: API.FilterType | undefined): filter is API.RangeFilter => {
  return (filter as any)?.from !== undefined || (filter as any)?.to !== undefined
}

export const getAppliedFiltersNum = (appliedFilters: ActiveFilters): number => {
  return Object.values(appliedFilters).reduce((acc: number, curr) => {
    return acc + (isApiListFilter(curr) ? curr.items.length : 1)
  }, 0)
}

const updateConfigs = (filter1: API.ListFilter, filter2: API.ListFilter): API.ListFilter => {
  const presentFilters = new Set(filter1.items.map(f => f.name))

  return {
    items: filter1.items
      .map(f1 => {
        const next = filter2.items.find(f2 => f1.name === f2.name) ?? { ...f1, count: 0 }
        presentFilters.add(next.name)
        return next
      })
      .concat(filter2.items.filter(f2 => !presentFilters.has(f2.name)))
  }
}

const mergeFilters = (allFilters: AllFilters, activeFilters: ActiveFilters): AllFilters => {
  const result = new Map(allFilters)

  for (const key of Object.keys(activeFilters)) {
    const filterId = key as FilterName

    const filterFromActive = activeFilters[filterId]
    const filterFromAll = result.get(filterId)

    /**
     * there are must not be the situation when filter is totally missing in allFilters.
     */
    if (filterFromAll == null) {
      console.warn('missing filter in allFilters', filterId)
      continue
    }

    if (isGeneratedListFilter(filterFromAll)) {
      const activeList = isApiListFilter(filterFromActive)
        ? filterFromActive
        : { items: [] }

      result.set(filterId, {
        ...filterFromAll,
        config: updateConfigs(filterFromAll.config, activeList)
      })

      continue
    }

    if (isGeneratedRangeFilter(filterFromAll)) {
      const activeFilter = isApiRangeFilter(filterFromActive)
        ? filterFromActive
        : filterFromAll.config

      result.set(filterId, {
        ...filterFromAll,
        config: activeFilter
      })
    }
  }

  return result
}

/**
 * How it works:
 * - if there is an active filter NOT present in the allFilters, it is added to the result
 * - if there is an active filter present in the allFilters, its count is updated in allFilters
 * - if there is a filter in allFilters which is not present in the activeFilters,
 * its count is set to zero.
 * Range filters are managed separately to properly preserve their state.
 * @param allFilters all filters suported by the system. Should be taken from Store.
 * @param appliedFilters currently applied filters used to preserve range filters state
 * It contains some default filters which are always visible.
 * @param activeFilters filters with non-zero counts returned from API
 * @returns new merged filters for Store
 */
export const mergeFiltersWithAppliedRanges = (
  allFilters: AllFilters,
  appliedFilters: ActiveFilters,
  activeFilters: ActiveFilters
): AllFilters => {
  /**
   * range filters are not applied from backend response,
   * but they must be set with current values to properly manage their state in sync
   */

  RANGE_FILTER_KEYS.forEach(key => {
    activeFilters[key] = {
      from: appliedFilters[key]?.from ?? null,
      to: appliedFilters[key]?.to ?? null
    }
  })

  return mergeFilters(allFilters, activeFilters)
}
