import { useEffect, useMemo, useState, useRef } from 'react'
import { useForm, type UseFormReturn, type OnActionResultHandler } from '@carfluent/common'

import { TradeInApiProvider } from 'website/api/tradeIn.api'
import AppraisalAPIProvider from 'website/api/appraisal.api'
import type { DictionaryItem } from 'website/api/types'
import type { ConditionOptionProps } from 'website/components/types'
import { parseTrims } from 'website/components/CarDetailsFields/hook/parser'
import useAsyncEffect from 'hooks/useAsyncEffect'

import { parseConditions, parseConditionsWithSubOptions } from './parser'
import { getYesNo } from './utils'
import validationRules from './validator'
import { FormIds } from './constant'
import type { FormValues, ErrTouchShortcuts } from './types'

type conditionOptions = 'conditions'
| 'mechanicalIssues'
| 'paymentTypes'
| 'vehicleCollision'
| 'vehicleAirbags'

interface UseTradeInReturn extends UseFormReturn<FormValues, ErrTouchShortcuts>, Record<conditionOptions, ConditionOptionProps[]> {
  trimOptions: DictionaryItem[]
}

export interface UseTradeInProps {
  baseValues: FormValues
  make?: string
  model?: string
  trim?: string
  vin?: string | null
  onValuesChanges: (values: FormValues) => void
  onSubmit: (values: FormValues) => Promise<void>
  onActionResult?: OnActionResultHandler<FormValues, ErrTouchShortcuts>
}

export const useTradeIn = ({
  baseValues,
  onValuesChanges,
  make,
  model,
  trim,
  onSubmit,
  onActionResult,
  vin
}: UseTradeInProps): UseTradeInReturn => {
  const [conditions, setConditions] = useState<ConditionOptionProps[]>([])
  const [mechanicalIssues, setMechanicalIssues] = useState<ConditionOptionProps[]>([])
  const [paymentTypes, setPaymentsType] = useState<ConditionOptionProps[]>([])
  const [trimOptions, setTrimOptions] = useState<DictionaryItem[]>([])
  const vehicleCollision = useMemo(getYesNo, [])
  const vehicleAirbags = useMemo(getYesNo, [])
  const initedTrim = useRef<boolean>(false)

  const form = useForm<FormValues, ErrTouchShortcuts>({
    baseValues,
    validationRules,
    submitAction: onSubmit,
    onActionResult
  })

  useAsyncEffect(async () => {
    const [
      _conditions,
      _paymentTypes,
      _mechanicalIssues
    ] = await Promise.all([
      TradeInApiProvider.tradeInConditions(),
      TradeInApiProvider.tradeInPaymentType(),
      TradeInApiProvider.tradeInMechanicalIssueTypes()
    ])

    setConditions(parseConditions(_conditions))
    setMechanicalIssues(parseConditionsWithSubOptions(_mechanicalIssues))
    setPaymentsType(parseConditionsWithSubOptions(_paymentTypes))
  }, [])

  useEffect(() => {
    onValuesChanges(form.values)
  }, [onValuesChanges, form.values])

  useAsyncEffect(async () => {
    if (initedTrim.current) {
      return
    }

    if (vin != null && vin !== '') {
      const data = await TradeInApiProvider.getTrimsByVin(vin)
      const trimOptions = data.map((name: string, id: number) => ({ name, id }))
      setTrimOptions(trimOptions)
      const trimInOptions = trimOptions.find(({ name }) => name === trim)
      if (trimInOptions != null) {
        form.onChange(FormIds.Trim, trimInOptions)
      }

      return
    }

    const { items } = await AppraisalAPIProvider.getMakes()
    const makeId = items.find(({ name }) => name === make)?.id

    if (makeId == null) {
      return
    }

    const { items: modelsItems } = await AppraisalAPIProvider.getModels(makeId)
    const modelId = modelsItems.find(({ name }) => name === model)?.id

    if (modelId == null) {
      return
    }

    const { items: trimsItems } = await AppraisalAPIProvider.getTrims(modelId)

    const trims = parseTrims(trimsItems)
    setTrimOptions(trims)

    const trimItem = trims.find(({ name }) => name === trim)
    if (trimItem != null) {
      form.onChange(FormIds.Trim, trimItem)
    }

    initedTrim.current = true
  }, [make, model, trim, vin, form.onChange])

  return {
    ...form,
    conditions,
    mechanicalIssues,
    paymentTypes,
    vehicleCollision,
    vehicleAirbags,
    trimOptions
  }
}
