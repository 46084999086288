import { css } from '@emotion/css'
import { cnx } from '@carfluent/common'

import { type ConfigRouteObject } from 'website/types/website'

import {
  componentThemeStyleProps,
  type ComponentStylesFromTheme,
  type ComponentThemeCssStyles,
  type TemplateConfigs
} from 'website/components/types'

import {
  type LayoutStyles,
  type LayoutStyleWithBreakpoint,
  type VariantProps
} from 'website/siteGenerator/types'

import { colorMap } from './colorsMap'

const defaultVariantKey = 'default'

/**
 * The order of breakpoints in layoutStyles is important. They must go from lowest to highest supported
 * @param layoutStyles grid layout config
 * @returns css className with responsive grid styles
 */
export const generateCssFromGridLayout = (layoutStyles: LayoutStyles): string => {
  const allComponents = getAllComponentsFromGridLayout(layoutStyles)

  const res = layoutStyles.reduce<string>((acc: string, curr: LayoutStyleWithBreakpoint) => {
    const currTemplateAreas = getTemplateAreaAsString(curr['grid-template-areas'])
    const components = new Set(getComponentNamesFromGridArea(currTemplateAreas))
    const { breakpoint = 0, 'grid-template-areas': gridTemplateAreas, ...otherStyles } = curr

    return `${acc}
    @media (min-width: ${breakpoint}px) {
      ${styleObjectToCss(otherStyles)}
      display: grid;
      grid-template-areas: ${transformGridArea(currTemplateAreas)};
      ${curr['grid-template-areas'] != null
      ? allComponents.reduce<string>((a, c) => {
        const style = `display: ${components.has(c) ? 'block' : 'none'};`
        return `${a}
          & > .${c} { ${style} }
          & > div > .${c} { ${style} }
        `
      }, '')
      : ''
    }}`
  }, '')

  return cnx(css(res))
}

export const transformGridArea = (areaStyle: string): string => {
  const rowGroups = areaStyle?.match(/('[^']+')/g)
  return rowGroups?.reduce((acc, curr) => {
    return `${acc}\n"${curr.replace(/'/g, '')}"`
  }, '') ?? ''
}

export const styleObjectToCss = (styles: KeyVal): string => {
  return Object.keys(styles).reduce((acc, curr) => {
    return `${acc} ${curr}: ${styles[curr] as string};\n`
  }, '')
}

export const createEmptyComponentStyles = (): ComponentStylesFromTheme => {
  const emptyStyles = componentThemeStyleProps.reduce<Partial<ComponentStylesFromTheme>>((acc, curr) => {
    acc[curr] = ''
    return acc
  }, {})

  return emptyStyles as ComponentStylesFromTheme
}

/**
 * @returns merged style of custom theme with a default one.
 * Custom theme has higher priority.
 */
export const componentStylesFromTheme = (
  styles?: ComponentThemeCssStyles,
  variant = defaultVariantKey,
  props: VariantProps = {}
): ComponentStylesFromTheme => {
  return componentThemeStyleProps
    .reduce((acc, curr) => {
      const defaultKeys = styles?.[defaultVariantKey]
      const customKeys = variant === defaultVariantKey
        ? {}
        : styles?.[variant]

      const defaultVariant = defaultKeys?.[curr] ?? ''
      const customVariant = customKeys?.[curr] ?? ''

      const defaultStyle = typeof defaultVariant === 'string'
        ? defaultVariant
        : defaultVariant?.(props)

      const customStyle = typeof customVariant === 'string'
        ? customVariant
        : customVariant?.(props)

      acc[curr] = cnx(css(defaultStyle), css(customStyle))
      return acc
    }, createEmptyComponentStyles())
}

export const getAllComponentsFromGridLayout = (layoutStyles?: LayoutStyles): string[] => {
  if (layoutStyles == null) {
    return []
  }

  const components = layoutStyles.reduce<string[]>((acc, curr) => {
    return [...acc, ...getComponentNamesFromGridArea(getTemplateAreaAsString(curr['grid-template-areas']))]
  }, [])

  return Array.from(new Set(components))
}

export const getComponentNamesFromGridArea = (area: string = ''): string[] => {
  // . - dot is a wildcard for empty space
  return area
    .replace(/['.\n]/g, '')
    .split(' ')
    .filter(Boolean)
}

export const getTemplateAreaAsString = (area: string | string[]): string => {
  return Array.isArray(area) ? area.map(s => `'${s}'`).join(' ') : area
}

const iterateOverTemplateConfig = (
  ref: { components: Set<string> },
  componentName: string = '',
  val: TemplateConfigs = {}
): void => {
  if (componentName !== '') {
    ref.components.add(componentName)
  }

  /**
   * we must add all components from the componentProps. Component might not use any layout,
   * but still have component configs in componentProps
   */
  const next = Object.keys(val.componentProps ?? [])

  /**
   * we must add all components from the layout. Component might pass props to them or may not,
   * so inner components might be missing in componentProps.
   */
  if (val.layout != null) {
    next.push(...getAllComponentsFromGridLayout(val.layout))
  }

  let temp = null

  for (const k of next) {
    temp = val.componentProps?.[k]
    iterateOverTemplateConfig(ref, temp?.nameInLayout ?? k, temp)
  }
}

export const getComponentsFromAllConfigTemplates = (
  routes: ConfigRouteObject[],
  ref: {components: Set<string>}
): string[] => {
  for (let i = 0; i < routes.length; i++) {
    const { config } = routes[i]
    getComponentsFromAllConfigTemplates(routes[i].children ?? [], ref)
    iterateOverTemplateConfig(ref, routes[i].element, config)
    /**
     * adding component's nameInLayout if it is a dynamic element
     */
    if (config?.nameInLayout != null) {
      ref.components.add(config.nameInLayout)
    }
  }

  return Array.from(ref.components)
}

/**
 * This is used to generate Top Component classes for the application to make layouts work.
 */

export const generateComponentClasses = (config: ConfigRouteObject[]): string => {
  const ref = { components: new Set<string>() }
  const allComponents = getComponentsFromAllConfigTemplates(config, ref)
  const defaultValue: KeyVal = {}

  /**
   * min-width is given to overcome an issue with overflow = ellipsis on texts inside the containers.
   * without setting min-width to 0 text overflow does not know it is overflowing anything because the design is fluid.
   */

  const firstChildStyle = ':first-child { box-sizing: border-box; width: 100%; height: 100%; }'

  const res = allComponents
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: `
        .${curr} { grid-area: ${curr}; min-width: 0; }
        .${curr} > div${firstChildStyle}
        .${curr} > section${firstChildStyle}
        .${curr} > img${firstChildStyle}
        `
      }
    }, defaultValue)

  return Object.values(res).join(' ')
}

export const addDynamicStyle = (config: ConfigRouteObject[]): void => {
  const style = document.createElement('style')
  style.innerHTML = generateComponentClasses(config)
  document.getElementsByTagName('head')[0].appendChild(style)
}

export const addSeoMeta = (dealerName: string): void => {
  /**
   * dynamic favicon is not supported by safari
   */
  let title = document.querySelector<HTMLTitleElement>('title')
  let description = document.querySelector<HTMLMetaElement>("meta[name~='description']")

  if (title == null) {
    title = document.createElement('title')
    document.getElementsByTagName('head')[0].appendChild(title)
  }

  if (description == null) {
    description = document.createElement('meta')
    description.name = 'description'
    document.getElementsByTagName('head')[0].appendChild(description)
  }

  title.innerText = dealerName
  description.content = dealerName
}

export const convertColorToHex = (color?: string): string => {
  if (color == null) {
    return 'rgba(0, 0, 0, 0.8)'
  }

  const colorMatch = colorMap[color.toLowerCase()]
  if (colorMatch != null) {
    return colorMatch
  }

  return color
}
