import appraiseCarIcon from 'website/assets/appraiseCar.svg'
import tradeSellIcon from 'website/assets/tradeSell.svg'
import getPaidIcon from 'website/assets/getPaid.svg'
import needForSpeed from 'website/assets/needForSpeed.png'

const assets: Record<string, string> = {
  needForSpeed,
  appraiseCarIcon,
  tradeSellIcon,
  getPaidIcon
}

export default assets
