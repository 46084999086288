import { type KeyboardEvent, RefObject } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { type Lead } from 'website/api/types/leads'
import { type MessageProps } from 'website/components/Messenger/components/Message'

export enum KeyCode {
  Enter = 'Enter',
}

export interface UseChatProps {
  threadId: string | null
  isOpened: boolean
  defaultMessages: MessageProps[] | null
}

export interface UseChatPropsReturn {
  setIsRequestCallOpened: (isOpen: boolean) => void
  isRequestCallOpened: boolean
  isSending: boolean
  isFirstTyping: boolean
  messages: MessageProps[]
  value: string
  setValue: (value: string) => void
  onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  onSend: () => void
  onSubmitRequestCall: (lead: Lead) => void
  isDisabledSend: boolean
  refChatContent: RefObject<HTMLDivElement>
  refChatContainer: RefObject<HTMLDivElement>
  refRecaptcha: RefObject<ReCAPTCHA>
}
