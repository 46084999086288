import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, useLoader, useSnackbar } from '@carfluent/common'
import type { UseFormReturn } from '@carfluent/common'

import VehicleApiProvider from 'website/api/vehicles.api'
import SharedStateHook, { StoreBranches } from 'website/store'
import { TradeInSubRoutes } from 'website/routing/constants'

import validationRules, { DEFAULT_FORM_VALUES } from './validator'
import type { FormValues } from './validator'

const useSharedState = SharedStateHook<Store.TradeInVehicleState>(StoreBranches.TradeInVehicle)

const ERROR_FAILED = 'VIN seems to be wrong. Please check'

export interface UseVinNumberProps {
  states: {
    tradeInVehicle: string
  }
}

export interface UseVinNumber extends UseFormReturn<FormValues> {
  isLoading: boolean
}

const useVinNumber = (props: UseVinNumberProps): UseVinNumber => {
  const navigate = useNavigate()
  const [tradeInVehicle, setTradeInVehicle] = useSharedState(props.states.tradeInVehicle)
  const { isLoading, stopLoader, startLoader } = useLoader()
  const { showAlert } = useSnackbar()

  const submitAction = useCallback(async ({ vin }: FormValues) => {
    startLoader()

    const res = await VehicleApiProvider.getVehicleByVin(vin)

    if (res.make == null && res.model == null) {
      navigate(TradeInSubRoutes.NoCarInfo)

      return
    }

    setTradeInVehicle({
      ...tradeInVehicle,
      make: res.make ?? '',
      model: res.model ?? '',
      trim: res.trim ?? '',
      year: res.year,
      vin,
      lastUpdateTs: Date.now()
    })

    navigate(TradeInSubRoutes.Car)
  }, [tradeInVehicle, navigate, startLoader])

  const onActionResult = useCallback(({ kind }) => {
    stopLoader()

    if (kind !== 'Ok') {
      showAlert(ERROR_FAILED)
    }
  }, [stopLoader, navigate, showAlert])

  const form = useForm<FormValues>({
    baseValues: DEFAULT_FORM_VALUES,
    submitAction,
    validationRules,
    onActionResult
  })

  return {
    ...form,
    isLoading
  }
}

export default useVinNumber
