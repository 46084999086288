import type { ConditionOptionProps } from 'website/components/types'

import { YesNo, YesNoIds } from './parser'

export const getYesNo = (): ConditionOptionProps[] => ([
  {
    title: YesNo.Yes,
    value: YesNoIds.Yes
  },
  {
    title: YesNo.No,
    value: YesNoIds.No
  }
])
