import { WrapperRequest } from './wrapper.api'

class CustomersCoreApi extends WrapperRequest {
  url: string = process.env.REACT_APP_CUSTOMERSCORE ?? ''
  
  async getClosestDealerId (payload: API.ClosestDealerPayload): Promise<API.ClosestDealer> {
    return await this.post(`${this.url}/api/v1/Dealers/closest`, payload)
  }
}

const CustomersCoreApiProvider = new CustomersCoreApi()

export default CustomersCoreApiProvider
