/**
 * For additional details, see:
 * https://dev.azure.com/carfluent/CarFluent/_wiki/wikis/CarFluent.wiki/24/Number-formats
 *
 * Also, see 'utils/__tests__/format_numbers.ts' for detailed behavior description.
 */

/**
 * Internal, try to use formatX instead.
 */

import { utils } from '@carfluent/common'

export const { formatCurrency } = utils.formatters

const DEFAULT_LOCALE = 'en-US'

export const numberWithDecimal = (
  x: number | string,
  min = 0,
  max = 2,
  allowNegative = false
): string => {
  const num = Number(x)
  const config = {
    minimumFractionDigits: min,
    maximumFractionDigits: max
  }

  return (num > 0 || allowNegative)
    ? new Intl.NumberFormat(DEFAULT_LOCALE, config).format(num)
    : ''
}

// ========================================== //
//              Public formatters             //
// ========================================== //

/**
 * Formats numbers as integers.
 * Empty string, NaN or invalid string repesentations will be
 * formated as `emptyValue`. Zero will be rendered as 'zeroValue`.
 *
 * If you want to be sure that function always render non-empty
 * string, please use `formatInteger(num, '0')`.
 *
 * IMPORTANT: this function is used to format both labels and inputs,
 * so it provides a separated configuration for 0 and "".
 * Empty string is often needed to allow placeholder inside input.
 */
export const formatInteger = (
  x?: number | string,
  emptyValue = '',
  zeroValue = '0',
  allowNegative = false
): string => {
  const num = Number(x)

  if ((x === '') || (Number.isNaN(num))) {
    return emptyValue
  }

  const formattedValue = numberWithDecimal(num, 0, 0, allowNegative)
  const hasFormattedValue = Boolean(formattedValue)

  return hasFormattedValue ? formattedValue : zeroValue
}

export const formatPercentages = (x: number | string, emptyValue = '0.00%'): string => {
  const formattedValue = numberWithDecimal(x, 2, 2)
  const hasFormattedValue = Boolean(formattedValue)
  const resolvedValue = hasFormattedValue ? `${formattedValue}%` : emptyValue

  return resolvedValue
}

export const formatDecimal = (x: number | string, emptyValue = ''): string => {
  const formattedValue = numberWithDecimal(x, 2, 2)
  const hasFormattedValue = Boolean(formattedValue)
  const resolvedValue = hasFormattedValue ? formattedValue : emptyValue

  return resolvedValue
}

export const onlyNumbers = (x: string): string => x.replace(/\D/g, '')
