import defer from './defer'

/**
 * Loads script by url if there is no script tag in the DOM yet
 * @param baseUrl script url used to find script tag in the DOM
 * @param fullUrl fully formed script url with query string. By default is the same as @baseUrl
 */
export const loadScript = async (
  baseUrl: string,
  fullUrl: string = baseUrl, // AZ-NOTE: maybe we need to improve naming, it's not always a "full URL".
  isGM = true
): Promise<void> => {
  const scriptElements = document.querySelectorAll(
    `script[src*='${baseUrl}']`
  )

  if (scriptElements?.length > 0) {
    return
  }

  const deferred = defer()

  fullUrl = `${fullUrl}${isGM ? '&callback=loadGMScript' : ''}`

  const el = document.createElement('script')
  el.src = fullUrl
  
  if (window.loadGMScript == null && isGM) {
    window.loadGMScript = () => {
      deferred.resolve?.()
    }
  } else {
    el.onload = () => {
      deferred.resolve?.()
    }
  }

  document.body.appendChild(el)

  return deferred.promise
}

export const loadNonGMScript = async (
  baseUrl: string,
  fullUrl: string = baseUrl
): Promise<void> => {
  return loadScript(baseUrl, fullUrl, false)
}
