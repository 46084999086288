import { type FC } from 'react'
import { type IconProps } from 'website/components/types'

const ArrowRightIcon: FC<IconProps> = ({
  className,
  fill = '#fff'
}) => {
  return (
    <svg className={className} width='17' height='9' viewBox='0 0 17 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M16.1507 4.05759C16.1505 4.0574 16.1503 4.05718 16.1501 4.05699L12.8844 0.806998C12.6397 0.563529 12.244 0.564435 12.0005 0.809123C11.757 1.05378 11.7579 1.4495 12.0025 1.693L14.1952 3.87499H0.958984C0.613797 3.87499 0.333984 4.15481 0.333984 4.49999C0.333984 4.84518 0.613797 5.12499 0.958984 5.12499H14.1951L12.0026 7.30699C11.7579 7.55049 11.757 7.94621 12.0005 8.19086C12.244 8.43558 12.6398 8.43643 12.8844 8.19299L16.1501 4.94299C16.1503 4.9428 16.1505 4.94259 16.1507 4.9424C16.3955 4.69809 16.3947 4.30109 16.1507 4.05759Z' fill={fill} />
    </svg>
  )
}

export default ArrowRightIcon
