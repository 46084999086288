import type { Condition, TradeInVehicle, TradeInVehiclePricePayload } from 'website/api/types/tradeIn'

import { WrapperRequest } from './wrapper.api'

class TradeInApi extends WrapperRequest {
  url = (version = 1): string => `${process.env.REACT_APP_CUSTOMERSCORE ?? ''}/api/v${version}/TradeIn`

  async tradeInPaymentType (version = 1): Promise<Condition[]> {
    return await this.get(`/api/v${version}/TradeIn/payment-types`)
  }

  async tradeInMechanicalIssueTypes (version = 1): Promise<Condition[]> {
    return await this.get(`/api/v${version}/TradeIn/mechanical-issue-types`)
  }

  async tradeInConditions (version = 1): Promise<Condition[]> {
    return await this.get(`/api/v${version}/TradeIn/vehicle/conditions`)
  }

  async getTrimsByVin (vin: string, version = 1): Promise<string[]> {
    return await this.get(`/api/v${version}/TradeIn/vehicle/${vin}/trims`)
  }
}

export const TradeInApiProvider = new TradeInApi()
