import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { UITools } from '@carfluent/common'
import { RouteOutletProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const { cn } = UITools

const RouteOutlet: FC<RouteOutletProps> = ({
  context,
  nameInLayout,
  variant,
  className
}): React.ReactElement | null => {
  const componentStylesCls = useComponentStyles(SupportedComponents.RouteOutlet, variant)

  return (
    <div className={cn(nameInLayout ?? SupportedComponents.RouteOutlet, componentStylesCls.root, className)}>
      <Outlet context={context} />
    </div>
  )
}

export default RouteOutlet
