import queryString from 'query-string'
import {
  type ActiveFilters,
  type VehiclesSorting,
  isListFilterKey,
  isRangeFilterKey,
  DEFAULT_SORTING
} from 'website/configs'
import { FILTER_LIST_SEPARATOR, _updateEntryInQs, _updateListEntryInQs } from './utils'

export {
  FILTER_LIST_SEPARATOR
}

interface FiltersAndSorting {
  filters: ActiveFilters
  sorting: VehiclesSorting
}

const parseFilters = (string: string): FiltersAndSorting => {
  const filters: ActiveFilters = {}
  const parsed = queryString.parse(string)

  let sortField: VehiclesSorting['sortField'] | null = DEFAULT_SORTING.sortField
  let sortOrder: VehiclesSorting['sortOrder'] | null = DEFAULT_SORTING.sortOrder

  for (const [key, value] of Object.entries(parsed)) {
    if (typeof value === 'string') {
      if (key === 'sortField') {
        sortField = value
        continue
      }

      if (key === 'sortOrder') {
        sortOrder = value as VehiclesSorting['sortOrder']
        continue
      }

      if (key === 'text') {
        filters[key] = value
        continue
      }

      if (isRangeFilterKey(key) && value.includes('-')) {
        const [from, to] = value.split('-')
        filters[key] = { from: +from, to: +to }
        continue
      }

      if (isListFilterKey(key)) {
        if (value.includes(FILTER_LIST_SEPARATOR)) {
          filters[key] = { items: value.split(FILTER_LIST_SEPARATOR).map(name => ({ name })) }
        } else {
          filters[key] = { items: [{ name: value }] }
        }
      }
    }
  }

  return {
    filters: filters ?? {},
    sorting: { sortField, sortOrder }
  }
}

/**
 * Used to trigger vehicleSearchChanged event which is used to
 * update vehicle search store from qs.
 */
export const dispatchVehiclesSearchChangedEvent = (): void => {
  window.dispatchEvent(new Event('vehicleSearchChanged', { bubbles: true, cancelable: true }))
}

/**
 * @param entries qs entries to update (should not be used for list entries)
 * @param triggerEvent whether to trigger vehicleSearchChanged event
 */
export const updateMultipleEntriesInQs = (
  entries: Record<string, string | null>,
  triggerEvent = true
): void => {
  let res = ''
  const currentUrl = new URL(window.location.href)

  for (const [k, v] of Object.entries(entries)) {
    res = _updateEntryInQs(currentUrl, k, v)
  }

  const prevLocation = window.location.href
  window.history.replaceState({}, '', res)

  if (triggerEvent && res !== prevLocation) {
    dispatchVehiclesSearchChangedEvent()
  }
}

/**
 * @param key qs entry key
 * @param value qs entry value
 * @param triggerEvent whether to trigger vehicleSearchChanged event
 */
export const updateEntryInQs = (
  key: string,
  value?: string | null,
  triggerEvent = true
): void => {
  const currentUrl = new URL(window.location.href)
  const res = _updateEntryInQs(currentUrl, key, value)
  const prevLocation = window.location.href
  window.history.replaceState({}, '', res)

  if (triggerEvent && res !== prevLocation) {
    dispatchVehiclesSearchChangedEvent()
  }
}

/**
 * @param key qs entry key
 * @param value qs entry value
 * @param isDeleting flag to delete entry
 * @param triggerEvent whether to trigger vehicleSearchChanged event
 */
export const updateListEntryInQs = (
  key: string,
  value: string | null,
  isDeleting = false,
  triggerEvent = true
): void => {
  const currentUrl = new URL(window.location.href)
  const res = _updateListEntryInQs(currentUrl, key, value, isDeleting)
  const prevLocation = window.location.href
  window.history.replaceState({}, '', res)

  if (triggerEvent && res !== prevLocation) {
    dispatchVehiclesSearchChangedEvent()
  }
}

export const getQueryStringFilters = (): FiltersAndSorting => {
  return parseFilters(window.location?.search)
}

export const removeQueryStringFilters = (triggerEvent = true): void => {
  const parsed = queryString.parse(window.location?.search)
  const prevLocation = window.location.href

  const url = new URL(window.location.href)
  for (const key of Object.keys(parsed)) {
    if (key === 'sortField' || key === 'sortOrder') {
      continue
    }

    url.searchParams.delete(key)
  }

  window.history.replaceState({}, '', url)

  if (triggerEvent && window.location.href !== prevLocation) {
    dispatchVehiclesSearchChangedEvent()
  }
}
