import type { FC } from 'react'
import { cn } from '@carfluent/common'

import { ServiceType } from 'website/api/types'
import useModal from 'website/hooks/useModal'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { GetPrequalifiedComponentProps, SupportedComponents } from 'website/components/types'

import GetPrequalifiedButton from './components/Button'
import VehicleServiceRequestModalForm from '../_base/VehicleServiceRequestModalForm'

const defaultFormProps = {
  vin: null,
  serviceType: ServiceType.Financing
}

const GetPrequalifiedComponent: FC<GetPrequalifiedComponentProps> = ({
  dealerId,
  nameInLayout = SupportedComponents.GetPrequalifiedComponent,
  variant,
  buttonProps = {},
  formProps = defaultFormProps,
  className
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponents.GetPrequalifiedComponent, variant)
  const modalProps = useModal()

  return (
    <div className={cn(nameInLayout, componentStylesCls.root, className)}>
      <GetPrequalifiedButton
        text={buttonProps.text}
        variant={buttonProps.variant}
        variantProps={buttonProps.variantProps}
        // eslint-disable-next-line
        onClick={buttonProps.onClick ?? modalProps.onOpenModal}
      />

      <VehicleServiceRequestModalForm
        dealerId={dealerId}
        {...formProps}
        {...modalProps}
      />
    </div>
  )
}

export default GetPrequalifiedComponent
