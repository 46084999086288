import { type FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { parseISO, differenceInDays } from 'date-fns'
import queryString from 'query-string'
import { cnx, Button } from '@carfluent/common'
import useCustomSnackbar from 'hooks/useCustomSnackbar'
import apiProvider from 'website/api/apiProvider'
import { type FeedbackFormProps, SupportedComponents } from '../types'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import RadioButton from '../RadioButton'
import StarRating from '../StarRating'
import CLASS_NAME from './styles'

const MAX_LENGTH = 500
const EXPIRES_AFTER = 7

interface QuerySearch {
  id?: string
  leadId?: string
  createdAt?: string
  dealerId?: string
}

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const FeedbackForm: FC<FeedbackFormProps> = ({
  nameInLayout = SupportedComponents.FeedbackForm
}) => {
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const { showAlert } = useCustomSnackbar()

  const [isDealershipRecommended, setIsDealershipRecommended] = useState(true)
  const [feedback, setFeedback] = useState('')
  const [satisfactionLevel, setSatisfactionLevel] = useState(0)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { search } = useLocation()
  const { id, leadId, createdAt, dealerId } = queryString.parse(search) as QuerySearch

  const dealership = dealerInfo.dealerships.find(d => d.id.toString() === dealerId)
  const linkCreated = createdAt != null ? parseISO(createdAt) : null
  const isExpired = linkCreated != null && differenceInDays(new Date(), linkCreated) > EXPIRES_AFTER

  if (isExpired) {
    return (
      <div className={cnx(CLASS_NAME, 'feedback-provided', nameInLayout)}>
        <div>
          <p>Link is expired.</p>
          <p>If you haven't responded and wish to do so, please contact us.</p>
        </div>
      </div>
    )
  }

  if (isSubmitted) {
    return (
      <div className={cnx(CLASS_NAME, 'feedback-submitted', nameInLayout)}>
        <div>
          <h1>We appreciate your feedback!</h1>
          {
            satisfactionLevel < 5
              ? (
                <p>Your input is valuable and helps us improve.</p>
                )
              : (
                <>
                  <p>We are happy that you had good experience with us!</p>
                  <p>To help our business grow please leave your</p>
                  <p>feedback on Google Reviews.</p>
                </>
                )
          }
        </div>
        {
          satisfactionLevel > 4 && (
            <Button
              className='google-review'
              onClick={() => {
                const link = dealership?.googleMapsLink
                if (link == null) {
                  showAlert('Google Maps link is not provided')
                  return
                }

                window.open(link, '_blank')
                window.location.replace('/')
              }}
            >
              LEAVE GOOGLE REVIEW
            </Button>
          )
        }
      </div>
    )
  }

  return (
    <div className={cnx(CLASS_NAME, nameInLayout)}>
      <div className='feedback-content'>
        <h1>Customer Feedback form</h1>
        <h3>Would you recommend our dealership to your friends and colleagues?</h3>
        <div className='yes-no'>
          <RadioButton
            value={isDealershipRecommended}
            onChange={() => setIsDealershipRecommended(true)}
            label='Yes'
          />
          <RadioButton
            value={!isDealershipRecommended}
            onChange={() => setIsDealershipRecommended(false)}
            label='No'
          />
        </div>
        <h3>Please leave us your open feedback</h3>
        <textarea
          value={feedback}
          onChange={e => setFeedback(e.target.value)}
          placeholder='Type here'
          spellCheck={false}
          maxLength={MAX_LENGTH}
        />
        <StarRating
          subtitle='How satisfied are you with our company overall?'
          value={satisfactionLevel}
          onChange={setSatisfactionLevel}
        />
        <Button
          onClick={async () => {
            if (id != null) {
              try {
                await apiProvider.crm.sendLeadFeedback(
                  id,
                  {
                    isDealershipRecommended,
                    feedback,
                    satisfactionLevel
                  },
                  leadId
                )

                setIsSubmitted(true)
              } catch (e: any) {
                showAlert(e?.response?.data?.message ?? 'Feedback submission failed')
              }
            }
          }}
        >
          SUBMIT
        </Button>
      </div>
    </div>
  )
}

export default FeedbackForm
