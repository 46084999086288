import { type FC } from 'react'
import { cnx } from '@carfluent/common'
import { type DealerLocationsPopoverProps, type IconTypes, SupportedComponentNames } from 'website/components/types'
import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import SvgIcon from 'website/components/_base/SvgIcon'
import formatString from 'website/utils/formatString'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const COMPONENT_NAME = SupportedComponentNames.DealerLocationsPopover

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const DEFAULT_PHONE_CONFIG = {
  icon: 'phone' as IconTypes,
  format: '(###) ###-####'
}

const DealerLocationsPopover: FC<DealerLocationsPopoverProps> = ({
  className,
  phoneConfig = DEFAULT_PHONE_CONFIG,
  variant
}) => {
  const componentStyles = useComponentStyles(COMPONENT_NAME, variant)
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))

  const firstDealerShip = dealerInfo.dealerships[0]

  return (
    <div className={cnx(COMPONENT_NAME, componentStyles.root, className)}>
      <div className='locations-header'>
        {firstDealerShip != null && (
          <a href={`tel:${firstDealerShip?.phone ?? ''}`} className='closest-dealership-phone-number'>
            <SvgIcon type='phone' />

            <span>
              {formatString(firstDealerShip.phone, phoneConfig?.format)}
            </span>
          </a>
        )}
      </div>

    </div>
  )
}

export default DealerLocationsPopover
