import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import pDebounce from 'p-debounce'

import ApiProvider from 'website/api/apiProvider'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import { GetPrequalifiedResponse } from 'website/api/types/financing'
import {
  useMonthlyPayments,
  type UseMonthlyPaymentsReturn
} from 'website/hooks/useMonthlyPayments'

const usePrequalifyState = SharedStateHook<Store.PrequalifyState>(StoreBranches.Prequalify)
const API_CALL_DELAY = 300
const DEFAULT_LIST_SIZE = 4

export interface UseRecommendedVehiclesProps {
  maxItemsNumber?: number
}

interface UseRecommendedVehiclesReturn extends UseMonthlyPaymentsReturn {
  isLoading: boolean
  vehicles: API.VehicleList
  prequalify: GetPrequalifiedResponse<number>
}

export const useRecommendedVehicles = (
  props: UseRecommendedVehiclesProps
): UseRecommendedVehiclesReturn => {
  const { vehicleId } = useParams()
  const { maxItemsNumber = DEFAULT_LIST_SIZE } = props
  const [isLoading, setIsLoading] = useState(true)
  const [vehicles, setVehicles] = useState<API.VehicleList>([])
  const [prequalify] = usePrequalifyState(defaultInstance(StoreBranches.Prequalify))

  const monthlyPayments = useMonthlyPayments({ vehicles })

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const loadVehicles = useCallback(pDebounce(async (): Promise<void> => {
    try {
      setIsLoading(true)

      const result = await ApiProvider.vehicles.getRecommendedVehicles(vehicleId)
      const vehicles = result.filter(item => item.id !== Number(vehicleId)).slice(0, maxItemsNumber)

      setVehicles(vehicles)
    } finally {
      setIsLoading(false)
    }
  }, API_CALL_DELAY), [vehicleId])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    void loadVehicles()
  }, [loadVehicles])

  // ========================================== //

  return {
    isLoading,
    vehicles,
    prequalify,
    ...monthlyPayments
  }
}
