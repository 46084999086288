import { css } from '@emotion/css'

export const CLASS_NAME = css(`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 0 16px;
  
  @media (min-width: 680px) {
    padding: 64px 0 0;
  }
  
  .cf-form-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 32px;
    
    .cf-form-field-error {
      font-family: Roboto, sans-serif;
    }
    
    .cf-form-date-picker {
      .MuiFilledInput-underline:after, .MuiFilledInput-underline:before {
        display: none;
      }
    }
  
    .cf-form-input {
      width: 100%;
      margin-bottom: 32px;
      max-height: 56px;
      
      :last-child {
        margin-bottom: 0px;
      }
      
      .MuiFormControl-root {
        width: 100%;
        border-radius: 12px;
      }
      
      @media (min-width: 480px) {
         width: calc(50% - 8px);
         
         &.cf-long-input {
          width: 100%;
         }
      }
      
      &.cf-long-input {
        @media (min-width: 480px) {
           width: 100%;
        }
      }
    }  
  }
  
  .disclosure {
    color: rgba(33, 33, 33, 0.50);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  
  .cf-continue-button {
    margin-bottom: 40px;
  }
  
  .cf-back-button {
    width: 65px;
    margin: 0 auto;
  }
  
  .cf-contact-you-title {
    margin-bottom: 15px;
    
    p {
       font-size: 20px;
       font-weight: 500;
    }
  }
  
`)

export const RECAPTCHA_CLASS_NAMES = css(`
  position: absolute;
  z-index: 100;
`)
