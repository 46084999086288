import type { FC } from 'react'
import { observer } from 'mobx-react-lite'

import WorkflowStepper from 'components/stepper'
import CarInfo from 'components/car_info'
import { useStyles } from './styles'

interface SidebarProps {
  toggleDrawer: (isOpen?: boolean) => void
}

const Sidebar: FC<SidebarProps> = observer(({ toggleDrawer }) => {
  const styles = useStyles()

  return (
    <div>
      <div
        data-test-id='pnl-car-info'
        className={styles.carInfo}
      >
        <CarInfo />
      </div>
      <WorkflowStepper toggleDrawer={toggleDrawer} />
    </div>
  )
})

export default Sidebar
