export enum ServiceType {
  TestDrive = 1,
  AskQuestion,
  Financing,
  CarOffer
}

export interface DictionaryItem<T = string> {
  id: number
  name: T
}

export interface ListResponse<T> {
  items: T[]
}
