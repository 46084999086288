import { formatCurrency, serializers as s } from '@carfluent/common'

import { type Lead } from 'website/api/types/leads'
import { GetPrequalified } from 'website/api/types/financing'
import { formatPhoneNumber } from 'utils/constants'
import { isAddressValid } from 'website/utils/addressGuards'
import type { PrequalifiedFormData } from '.'

const HOT_TEMPERATURE_ID = 1
const FINANCING_SOURCE_ID = 21
export const TRADE_IN_SOURCE_ID = 22

export const DEFAULT_API_DATA = {
  vehicleId: null,
  assignedUserId: null,
  leadSourceId: FINANCING_SOURCE_ID,
  leadTemperatureId: HOT_TEMPERATURE_ID,
  comments: null,
  vehiclesIds: null
}

interface SerializeData extends PrequalifiedFormData {
  dealerId?: number | string | null
  locationLatitude?: number
  locationLongitude?: number
  yearIncome?: number | null
  lastDigits?: number | null
}

export const serializeFinancingData = ({
  addressData,
  phoneNumber,
  birthDate,
  yearIncome,
  lastDigits,
  ...otherProps
}: SerializeData): GetPrequalified | null => {
  if (!isAddressValid(addressData)) {
    return null
  }

  return {
    ...otherProps,
    yearIncome,
    birthDate: s.serializeDateTime(birthDate),
    phoneNumber,
    lastDigits: lastDigits?.toString(),
    zipCode: addressData.zipCode,
    address: addressData.address,
    state: addressData.state,
    city: addressData.city
  }
}

export const serializeLeadData = async ({
  addressData,
  phoneNumber,
  birthDate,
  firstName,
  lastName,
  email,
  yearIncome,
  ...otherProps
}: SerializeData): Promise<Lead | null> => {
  if (!isAddressValid(addressData)) {
    return null
  }

  return {
    firstName,
    lastName,
    email,
    ...otherProps,
    ...DEFAULT_API_DATA,
    birthDate: s.serializeDateTime(birthDate),
    phoneNumber,
    zipCode: addressData.zipCode,
    address: addressData.address,
    state: addressData.state,
    city: addressData.city,
    additionalData: {
      Name: `${firstName} ${lastName}`,
      Phone: formatPhoneNumber(phoneNumber),
      DateOfBirth: birthDate?.toString(),
      Email: email,
      Address: addressData.address,
      YearIncome: formatCurrency(yearIncome),
      Date: new Date().toString()
    }
  }
}
