import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { UITools } from '@carfluent/common'
import { CarouselProps, CarouselConfigs, SupportedComponents } from 'website/components/types'
import DynamicComponent from 'website/components/DynamicComponent'
import { useStyles } from './styles'

type Direction = 'left' | 'right'

const { cn } = UITools

const DEFAULT_CONFIGS: CarouselConfigs = {
  pauseOnMouseEnter: false,
  interval: 3000
}

const Carousel: FC<CarouselProps> = ({
  slideProps,
  configs: _configs
}) => {
  const styles = useStyles()

  const [activeIndex, setActiveIndex] = useState(0)
  const [paused, setPaused] = useState(false)

  const configs = useMemo(() => ({ ...DEFAULT_CONFIGS, ..._configs }), [_configs])

  const updateIndex = useCallback((dir: Direction): void => {
    setActiveIndex(idx => {
      if (dir === 'right') {
        return idx - 1 < 0
          ? slideProps.length - 1
          : idx - 1
      }

      return idx + 1 > slideProps.length - 1
        ? 0
        : idx + 1
    })
  }, [slideProps.length])

  useEffect(() => {
    const intervalHandle = setInterval(() => {
      if (!paused) {
        updateIndex('left')
      }
    }, configs.interval)

    return () => {
      if (intervalHandle != null) {
        clearInterval(intervalHandle)
      }
    }
  }, [paused, configs.interval])

  const swiperConfigs = useMemo(() => {
    return {
      onSwipedLeft: () => updateIndex('left'),
      onSwipedRight: () => updateIndex('right')
    }
  }, [updateIndex])

  const handlers = useSwipeable(swiperConfigs)

  const onMouseEnter = useCallback(() => {
    if (configs.pauseOnMouseEnter === true) {
      setPaused(true)
    }
  }, [configs.pauseOnMouseEnter])

  const onMouseLeave = useCallback(() => {
    setPaused(false)
  }, [])

  return (
    <div
      className={cn(SupportedComponents.Carousel, styles.root)}
      {...handlers}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={styles.content}
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {
          slideProps.map((props, i) => {
            return (
              <DynamicComponent
                key={props.nameInLayout ?? i}
                className={styles.item}
                {...props}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default Carousel
