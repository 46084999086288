import { RefObject, useEffect, useRef, useState } from 'react'
import pDebounce from 'p-debounce'

export interface UseWidthResizeReturn {
  elRef: RefObject<HTMLDivElement>
  width: number
}

const RESIZE_DEBOUNCE_DELAY = 1000

interface UseWidthResizeProps {
  onResize?: (width: number) => void
}

/**
 *
 * @param onResize callback to be called on resize
 * @returns {
 *  elRef - reference to element for which width is being tracked;
 *  widthRef - reference to current width of the element
 * }
 *
 */
const useWidthResize = ({
  onResize
}: UseWidthResizeProps = {}): UseWidthResizeReturn => {
  const elRef = useRef<HTMLDivElement>(null)
  const widthRef = useRef<number>(0)
  const onResizeRef = useRef(onResize)
  const [width, setWidth] = useState(0)

  const setWidthDebauncedRef = useRef(pDebounce(setWidth, RESIZE_DEBOUNCE_DELAY))

  useEffect(() => {
    onResizeRef.current = onResize
  }, [onResize])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([{ contentRect: { width } }]) => {
      void setWidthDebauncedRef.current(width)

      /**
       * we do not trigger anything for initial resize
       */
      if (widthRef.current > 0) {
        onResizeRef.current?.(width)
      }

      widthRef.current = width
    })

    if (elRef.current != null) {
      resizeObserver.observe(elRef.current)
    }

    return () => {
      if (elRef.current != null) {
        resizeObserver.unobserve(elRef.current)
      }
    }
  }, [])

  return {
    elRef,
    width
  }
}

export default useWidthResize
